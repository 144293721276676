import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
})
export class InputsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
