import { Component, OnInit, AfterViewInit , Pipe, PipeTransform} from '@angular/core';
import {TpserviceService} from '../../../Services/tpservice.service';
import swal from "sweetalert2";
import {Router} from '@angular/router';
import {fmtransactions} from '../../../Models/fmtransactions';
import {fmtabledata} from '../../../Models/fmtabledata';
import{fmdata} from '../../../Models/fmdata';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './fm.component.html',
  providers:[TpserviceService]

})

@Pipe({ name: 'search' })
export class FmComponent implements OnInit, AfterViewInit, PipeTransform {
  checkflag:boolean=false;
  
    lrs: any[] = [];
    fmdata:fmdata;
    fmdatastate:string="Save";
    inwards: any[] = [];
    dctabledata:fmtransactions[]=[];
    sendcallbackfm:string=""; 
    selectedcstr:string="";
selecteddctrxn:string="";
fmtabledata:fmtabledata[]=[];
com:string=",";
openstr:string="(";
closestr:string=")";
spliter:string="@@";
driverdata:any={};
placedata:any={};
vehiclesdata:any={};
branchesdata:any={};
callbackdata:string;
getdate:any;
lrsearch= '';
branch:string="1";
deliverytype:string="";
memono:string="";
memodate:string="";  
vehicleno:string="";
vehicleowner:string="";
vehicletype:string="";
owneraddress:string="";
ownercontact:string="";
drivername:string="";
licenseno:string="";
mobileno:string="";
fromloc:string="";
toloc:string="";
memotype:string="";
totalpackages:string=""; 
 totalweight:string="";
 totalfreight:string="";
  advanceamount:string="";
 balanceamount:string="";
vehiclepass:string="";
diesel:string="";
othercharges:string="";
deliverycharges:string="";
mathadi:string="";
seal:string="";
 advanceref:string="";
parkingcharges:string="";
 remark:string="";
 disablestate:any;
 query:any;
constructor(private tps:TpserviceService,private route:Router) {


  }


  ngOnInit() {


    if(localStorage.getItem("fmmasterdatastate")=="1")
    {
      this.fmdatastate="Update";
    this.displaydfmdata();

    localStorage.removeItem("fmmasterdatastate");
    
    }
    else{
     
    }
    this.getformdata();
    this.disablestate=localStorage.getItem("disablestate");
  
      this.getdate=Date.now();
      this.lrs = [   
          {lrno:'2018501'},

      ];

      this.inwards = [
          {lrno:'2018553'},
         
      ]

  }
  ngAfterViewInit(){

    $('#form-sample-1').validate({
      rules: {
        name: {
          minlength: 2,
          required: !0
        },
        email: {

          required: !0,
          email: !0
        },

        fax: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },
        url: {
          required: !0,
          url: !0
        },
        number: {
          required: !0,
          number: !0
        },
        min: {
          required: !0,
          minlength: 3
        },
        IFSC: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },

        MICR: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },
        phone: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },
        GST: {
          required: !0,
          minlength: 15,
          maxlength: 15
        },
        bcode: {
          required: !0,
          minlength: 2,
          maxlength: 2
        },
        max: {
          required: !0,
          maxlength: 4
        },
        password: {
          required: !0
        },
        password_confirmation: {
          required: !0,
          equalTo: '#password'
        }
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
    });





  }


    public transform(value, keys: string, term: string) {

        if (!term) return value;
        return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));

    }


    getformdata()
    {
      const ob={

        checkeddata:this.branch
      }
  
      this.tps.getVehicle(ob).subscribe(
        data =>this.vehiclesdata=data
       ,error=> swal('Please check your internet connection',error, 'error' )
            
    );
    const obj={

      checkeddata:this.branch
    }
    this.tps.getDrivers(obj).subscribe(
      data =>this.driverdata=data
     ,error=> swal('Please check your internet connection',error, 'error' )
          
    );
  
     this.tps.getPlace().subscribe(
    data =>
        this.placedata = data,error=> swal('Please check your internet connection',error, 'error' )
    );
   
    this.tps.getBranch().subscribe(
      data =>
          this.branchesdata = data
  );
  const ob1={
cons:"v"

  }
  
  this.tps.getDclistData(ob1).subscribe(
    data =>
        this.dctabledata = data
);
    }


    onsubmitfm()
    {
      if(this.selectedcstr=="()" || this.selectedcstr=="")
      {
       swal('warning','Please choose atleast one DC', 'warning' );
      }else
      {
        if(this.fmdatastate=="Update")
        {
this.updatefmmaster();

        }
        else{
      var  datatb=this.openstr+this.selectedcstr.substr(0,this.selectedcstr.length-1)+this.closestr;
    var lrdatatb=this.selecteddctrxn.substr(0,this.selecteddctrxn.length-1);
let fmobj={

	memo_no:this.memono,
	memo_date:this.memodate,
	vehicle_number:this.vehicleno,
	vehicle_owner:this.vehicleowner,
	vehicle_type:this.vehicletype,
	owner_address:this.owneraddress,
	owner_contact:this.ownercontact,
	driver_name:this.drivername,
	license_name:this.licenseno,
	mobile_no:this.mobileno,
	from_loc:this.fromloc,
	to_loc:this.toloc,
	total_packages:this.totalpackages,
	total_weight:this.totalweight,
	total_freight:this.totalfreight,
	advance_amount:this.advanceamount,
	balance_amount:this.balanceamount,
	vehicle_pass:this.vehiclepass,
	diesel:this.diesel,
	other_charges:this.othercharges,
	delivery_charges:this.deliverycharges,
	mathadi:this.mathadi,
	seal:this.seal,
	advance_ref:this.advanceref,
	parking_charges:this.parkingcharges,
	fm_type:this.memotype,
	branch:this.branch,
  remarks:this.remark,
  dcdata:datatb ,
	fmdata:lrdatatb
}

this.tps.addFM(fmobj).subscribe(res =>this.aftersave(res)
, error1 => swal('Error',error1, 'error' )
);
}
}  
    }
    aftersave(data){

     
      swal('Success',data['messasge'], 'success' );
      this.route.navigate(['/pages/Fm-List']);


    }     
    onchangevehicle(e)
    {
      let index=this.vehiclesdata.findIndex(datas=>datas.vehicle_id == e.target.value);
  this.vehicleowner=this.vehiclesdata[index].vehical_owner_name;
  this.vehicletype=this.vehiclesdata[index].vehicle_type;
  this.owneraddress=this.vehiclesdata[index].address;
  this.ownercontact=this.vehiclesdata[index].telephoneno;
    }
  
    onchangeselect(e)
    {                                                                                                                  
  let index=this.driverdata.findIndex(datas=>datas.driver_id == e.target.value);//=JSON.parse(e.target.value);
  this.licenseno=this.driverdata[index].licenseno;
  this.mobileno=this.driverdata[index].mobileno;
  
  
    }     
    checkclick(data,e)
  {
if(e.target.checked)
{
  var temp=this.selectedcstr.replace(data+this.com,"");
  var tmp1=this.selecteddctrxn.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"")
  this.selectedcstr=temp;
  this.selecteddctrxn=tmp1;
  this.selectedcstr+=data+this.com;
this.selecteddctrxn+=this.openstr+data+this.com+"@@"+this.closestr+this.com;
}
else{

  var temp1=this.selectedcstr.replace(data+this.com,"");
  var temp=this.selecteddctrxn.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"");
  this.selectedcstr=temp1;
  this.selecteddctrxn=temp;
 

}
console.log(this.selectedcstr);
console.log(this.selecteddctrxn);
  }
  
  insertabledata()
  {
let  datatb=this.openstr+this.selectedcstr.substr(0,this.selectedcstr.length-1)+this.closestr;
console.log("data is_"+datatb);
const obj={

  checkeddata:datatb

}

  this.tps.getFmtabledata(obj).subscribe(
      data =>
          this.fmtabledata = data,
      error=>
    swal('Please check your internet connection',error, 'error' )
  );
 
  }

  displaydfmdata()
  {
    this.fmdata=JSON.parse(localStorage.getItem("fmmasterdata"));
    
localStorage.removeItem("fmmasterdata");
console.log(this.fmdata);
 this.checkflag=true;
 this.memono=this.fmdata.fmbo.memo_no,
	this.memodate=this.fmdata.fmbo.memo_date,
	this.vehicleno=this.fmdata.fmbo.vehicle_number,
	this.vehicleowner=this.fmdata.fmbo.vehicle_owner,
	this.vehicletype=this.fmdata.fmbo.vehicle_type,
	this.owneraddress=this.fmdata.fmbo.owner_address,
	this.ownercontact=this.fmdata.fmbo.owner_contact,
	this.drivername=this.fmdata.fmbo.driver_name,
	this.licenseno=this.fmdata.fmbo.license_name,
	this.mobileno=this.fmdata.fmbo.mobile_no,
	this.fromloc=this.fmdata.fmbo.from_loc,
	this.toloc=this.fmdata.fmbo.to_loc,
	this.totalpackages=this.fmdata.fmbo.total_packages,
	this.totalweight=this.fmdata.fmbo.total_weight,
	this.totalfreight=this.fmdata.fmbo.total_freight,
	this.advanceamount=this.fmdata.fmbo.advance_amount,
	this.balanceamount=this.fmdata.fmbo.balance_amount,
	this.vehiclepass=this.fmdata.fmbo.vehicle_pass,
	this.diesel=this.fmdata.fmbo.diesel,
	this.othercharges=this.fmdata.fmbo.other_charges,
	this.deliverycharges=this.fmdata.fmbo.delivery_charges,
	this.mathadi=this.fmdata.fmbo.mathadi,
	this.seal=this.fmdata.fmbo.seal,
	this.advanceref=this.fmdata.fmbo.advance_ref,
	this.parkingcharges=this.fmdata.fmbo.parking_charges,
	this.memotype=this.fmdata.fmbo.fm_type,
	this.branch=this.fmdata.fmbo.branch,
  this.remark=this.fmdata.fmbo.remarks,
	this.fmtabledata=this.fmdata.fmmastertabledataBO;

this.splitdata();
  }

 

splitdata()
{
  let strdata:string="";
  let strdatalr:string="";
this.fmtabledata.forEach(function(element){
  if(!strdata.includes(element.dc_id.toString()))
  {
  strdata+=element.dc_id.toString()+",";
  strdatalr+="("+element.dc_id.toString()+","+"@@),"
  }
})

console.log(strdata.toString());
this.selectedcstr=strdata;
this.sendcallbackfm=this.openstr+strdata.substr(0,strdata.length-1)+this.closestr;
this.selecteddctrxn=strdatalr;

}





checkdata(obj):boolean{

  if(this.fmtabledata.some(e => e.dc_id == obj))
  {
return true;
  }
  else{
    return false;
  }
}



updatefmmaster(){

  if(this.selectedcstr=="()")
  {
    swal('warning', 'warning' ),error=> swal('Please choose atleast one DC',error, 'warning' )
  }
  else{
  var  datatbupdate=this.openstr+this.selectedcstr.substr(0,this.selectedcstr.length-1)+this.closestr;
  var fmdatatbupdate=this.selecteddctrxn.substr(0,this.selecteddctrxn.length-1);
  console.log("data is_"+datatbupdate);
  console.log("lrdata is"+fmdatatbupdate);
const dcobj={
  fm_id:this.fmdata.fmbo.fm_id,
  memo_no:this.memono,
	memo_date:this.memodate,
	vehicle_number:this.vehicleno,
	vehicle_owner:this.vehicleowner,
	vehicle_type:this.vehicletype,
	owner_address:this.owneraddress,
	owner_contact:this.ownercontact,
	driver_name:this.drivername,
	license_name:this.licenseno,
	mobile_no:this.mobileno,
	from_loc:this.fromloc,
	to_loc:this.toloc,
	total_packages:this.totalpackages,
	total_weight:this.totalweight,
	total_freight:this.totalfreight,
	advance_amount:this.advanceamount,
	balance_amount:this.balanceamount,
	vehicle_pass:this.vehiclepass,
	diesel:this.diesel,
	other_charges:this.othercharges,
	delivery_charges:this.deliverycharges,
	mathadi:this.mathadi,
	seal:this.seal,
	advance_ref:this.advanceref,
	parking_charges:this.parkingcharges,
	fm_type:this.memotype,
	branch:this.branch,
  remarks:this.remark,
  dcdata:datatbupdate,
	fmdata:fmdatatbupdate,
  fmcallback:this.sendcallbackfm

}
this.tps.updateFM(dcobj).subscribe(
  data =>this.afterupdate(data)
  
      
);
  }
}

afterupdate(data)
{
swal('Success',data['messasge'], 'success' ),error=> swal('Please check your internet connection',error, 'error' )
this.route.navigate(['pages/Fm-List']);

}
redirect(){
  this.route.navigate(['pages/Fm-List']);
}

}