import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { TpserviceService } from '../../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { vehicletype } from '../../../Models/vehicletype';
import { vehicleowner } from '../../../Models/vehicleowner';
import { vehicle } from '../../../Models/vehicle';
declare var $: any;

@Component({
  selector: 'app-form-advanced',
  templateUrl: './vehicle.component.html',
  providers: [TpserviceService]
})
export class VehicleComponent implements OnInit, AfterViewInit {

  vehicleobj: vehicle;
  voname: string = "";
  vtype: string = "";
  vehicleno: string = "";
  capacity: string = "";
  make: string = "";
  chasisno: string = "";
  regdate: string = "";
  description: string = "";
  engineno: string = "";
  datastate: string = "Save";
  branch: string = "1";
  pucno: string = "";
  pucexpdate: string = "";
  body: string = "";
  vehicleexpdate: string = "";
  branchlist: any = [];
  vehicletypes: vehicletype[];
  vehicleowners: vehicleowner[];
  disablestate: any;
  constructor(private _script: ScriptLoaderService, private tps: TpserviceService, public route: Router) { }

  ngOnInit() {
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");

    this.getvtypelist();
    this.getvolist();

    if (localStorage.getItem("vehicledatastate") == "1") {
      this.getvehicledata();
      localStorage.removeItem("vehicledatastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        voname: {
          maxlength: 100,
          required: !0
        },
        vehicleno: {
          required: !0,
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

    var flag = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && flag == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        flag = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      flag = false;
    });

    $(document).ready(function () {
      $("#voname").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#voname").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.voname = id;
    });

    $(document).ready(function () {
      $("#vtype").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#vtype").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.vtype = id;
    });
  }

  onsubmit() {
    if (!(this.datastate == "Update")) {
      const obj = {
        vehicleownerid: this.voname,
        vehicletypeid: this.vtype,
        vehicleno: this.vehicleno,
        capacity: this.capacity,
        make: this.make,
        chasisno: this.chasisno,
        vehicleregdate: this.regdate,
        description: this.description,
        engineno: this.engineno,
        branch: this.branch,
        pucno: this.pucno,
        pucexpdate: this.pucexpdate,
        vehicleexpdate: this.vehicleexpdate,
        body: this.body
      }
      this.tps.addVehicle(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updatevehicle();
    }
  }

  aftersubmit(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/Vehicle-List']);
  }

  updatevehicle() {
    const obj = {
      vehicleownerid: this.voname,
      vehicletypeid: this.vtype,
      vehicleno: this.vehicleno,
      capacity: this.capacity,
      make: this.make,
      chasisno: this.chasisno,
      vehicleregdate: this.regdate,
      description: this.description,
      engineno: this.engineno,
      vehicleid: this.vehicleobj.vehicleid,
      branch: this.branch,
      pucno: this.pucno,
      pucexpdate: this.pucexpdate,
      vehicleexpdate: this.vehicleexpdate,
      body: this.body
    }

    this.tps.updateVehicle(obj).subscribe(
      data => this.aftersubmit(data),
      error => swal('Please check your internet connection', error, 'error')
    )
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
        data =>this.branchlist = data
      );
  }

  getvehicledata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.datastate = "Update";
    this.vehicleobj = JSON.parse(localStorage.getItem("vehicledata"));
    localStorage.removeItem("vehicledata");
    this.voname = this.vehicleobj.vehical_owner_name;
    this.vtype = this.vehicleobj.vehicle_type;
    this.vehicleno = this.vehicleobj.vehicleno;
    this.capacity = this.vehicleobj.capacity;
    this.make = this.vehicleobj.make;
    this.chasisno = this.vehicleobj.chasisno;
    this.regdate = this.vehicleobj.reg_date;
    this.description = this.vehicleobj.description;
    this.engineno = this.vehicleobj.engineno;
    this.pucno = this.vehicleobj.pucno;
    this.pucexpdate = this.vehicleobj.pucexpdate;
    this.vehicleexpdate = this.vehicleobj.vehicleexpdate;
    this.body = this.vehicleobj.body;
    swal.close();
  }

  getvtypelist() {
    const ob = {
      checkeddata: this.branch
    }
    this.tps.getVehicletypelist(ob).subscribe(
        data => this.vehicletypes = data
      );
  }


  getvolist() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getVehicleOwners(obj).subscribe(
        data => this.vehicleowners = data
      );
  }

  redirect() {
    this.route.navigate(['pages/Vehicle-List']);
  }

}
