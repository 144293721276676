
import { HttpClientModule , HttpClient,HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import{lrmaster} from '../Models/lrmaster';
import{lrdata} from '../Models/lrdata'; 
import { tabledata } from '../Models/tabledata';
@Injectable()
export class Dataservices
 {
    public lrdata:lrdata=null;
constructor(){}

settabledata(lrdatas:lrdata)
{
   
this.lrdata=lrdatas;

}

gettabledata():lrdata{
    let datatosend = this.lrdata;
    this.lrdata=null;
return datatosend;
}

}