import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
import swal from 'sweetalert2';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Router } from '@angular/router';
import { customer } from '../../../Models/customer';
declare var require: any;
var cities = require('../../../Models/cities.json')
var states = require('../../../Models/states.json')
@Component({
  selector: 'app-form-layouts',
  templateUrl: './customer.component.html',
  providers: [TpserviceService]
})
export class CustomerComponent implements OnInit, AfterViewInit {

  register: any;
  customerobj: customer;
  customername: string = "";
  branchlist: any = [];
  address: string = "";
  telephone: string = "";
  faxno: string = "";
  cstno: string = "";
  gstno: string = "";
  city: string = "";
  state: string = "";
  email: string = "";
  vatno: string = "";
  eccno: string = "";
  vendorcode: string = "";
  cities: any[] = [];
  place: any;
  states: any[] = [];
  refname: string = "";
  refaddr: string = "";
  refdesg: string = "";
  refemail: string = "";
  reffax: string = "";
  datastate: string = "Save";
  branch: string = "";
  disablestate: any;
  row_num: string;
  flag: boolean;
  //contacts:contacts[]=[];
  public rows: Array<{
    cpname: string;
    cpaddr: string;
    cpdesg: string;
    cpemail: string;
    cpfax: string;
  }> = [];

  constructor(private route: Router, private tps: TpserviceService) { }

  ngOnInit() {
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.cities = cities;
    this.states = states['states'];
    this.disablestate = localStorage.getItem("disablestate");
    if (localStorage.getItem("custdatastate") == "1") {
      this.getcustomerdata();
      localStorage.removeItem("custdatastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        customername: {
          minlength: 2,
          required: !0
        },
        address: {
          required: !0
        },
        state: {
          required: !0
        },
        gstno: {
          required: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

    var flag = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && flag == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        flag = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      flag = false;
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
    });

    $(document).ready(function () {
      $("#state").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#state").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.state = id;
    });

    $(document).ready(function () {
      $("#city").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#city").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.city = id;
    });

  }


  displayplaces() {
    this.tps.getPlace().subscribe(
        data => this.place = data
    );
}

  onsubmit() {

    if (!(this.datastate == "Update")) {
      const obj = {
        customername: this.customername,
        address: this.address,
        telephoneno: this.telephone,
        faxno: this.faxno,
        cstno: this.cstno,
        gstno: this.gstno,
        city: this.city,
        state: this.state,
        emailid: this.email,
        vendorcode: this.vendorcode,
        vatno: this.vatno,
        eccno: this.eccno,
        branch: this.branch,
        contacts: this.rows
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.addCustomer(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updatecustomer();
    }

  }

  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/Customer-List']);
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getBranch().subscribe(
      data =>
        this.branchlist = data
    );
    swal.close();
  }

  getcustomerdata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.customerobj = JSON.parse(localStorage.getItem("customerdata"));
    localStorage.removeItem('customerdata');
    this.vendorcode = this.customerobj[0].vendorcode;
    this.customername = this.customerobj[0].customername;
    this.email = this.customerobj[0].emailid;
    this.address = this.customerobj[0].address;
    this.telephone = this.customerobj[0].telephoneno;
    this.faxno = this.customerobj[0].faxno;
    this.cstno = this.customerobj[0].cstno;
    this.gstno = this.customerobj[0].gstno;
    this.city = this.customerobj[0].city;
    this.state = this.customerobj[0].state;
    this.email = this.customerobj[0].emailid;
    this.vendorcode = this.customerobj[0].vendorcode;
    this.vatno = this.customerobj[0].vatno;
    this.eccno = this.customerobj[0].eccno;
    this.branch = this.customerobj[0].branch;
    this.rows = this.customerobj[0].contacts;
    localStorage.removeItem("customerdata")
    swal.close();
  }

  updatecustomer() {
    const obj = {
      customername: this.customername,
      address: this.address,
      telephoneno: this.telephone,
      faxno: this.faxno,
      gstno: this.gstno,
      city: this.city,
      state: this.state,
      emailid: this.email,
      vendorcode: this.vendorcode,
      branch: this.branch,
      cid: this.customerobj[0].cid,
      contacts: this.rows
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updateCustomer(obj).subscribe(
      data => this.afterupdate(data),
      error => swal('Please check your internet connection', error, 'error'))
  }

  afterupdate(data) {
    swal.close();
    swal('Success', data['messasge'], 'success')
    this.route.navigate(['pages/Customer-List']);
  }

  redirect() {
    this.route.navigate(['pages/Customer-List']);
  }

  addref() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
  }

  clear() {
    this.refname = "";
    this.refaddr = "";
    this.refdesg = "";
    this.refemail = "";
    this.reffax = "";
  }

  editrow(row, index) {
    this.row_num = index;
    this.refname = row.cpname;
    this.refaddr = row.cpaddr;
    this.refdesg = row.cpdesg;
    this.refemail = row.cpaddr;
    this.reffax = row.cpfax;
    this.flag = true;
  }

  onupdate() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
    this.flag = false;
    this.deleterow(this.row_num);
  }

  deleterow(index) {
    this.rowdatadelete(index);
    this.rows.splice(index, 1);
  }

  rowdatadelete(index) {
    var tmp: string;
    var openpr: "(";
    var closepr: ")";
    var com: ",";
    var data_str: String = "";
    var raw_str: String = "";
    data_str = "'" + this.rows[index].cpname + "'" + com + "'" + this.rows[index].cpaddr + "'" + com + "'" + this.rows[index].cpdesg + "'" + com + "'" + this.rows[index].cpemail + "'" + com + "'" + this.rows[index].cpfax + "'" + com + "@@";
    raw_str = openpr + data_str + closepr + com;
  }
}
