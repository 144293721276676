import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ArticleserviceService} from '../../articleservice.service';


declare var $;


@Component({
  selector: 'app-datatables',
  templateUrl: './datatables.component.html',
  providers:[ArticleserviceService],
})
export class DatatablesComponent implements OnInit, AfterViewInit {

  peoples: {'hi', 'people' };

  art: Object;
  obj: any;
  constructor(private arti: ArticleserviceService) { }


  ngOnInit() {

    this.displayArticle();
  }

  ngAfterViewInit() {

    $('#datatable').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
        columnDefs: [{
            targets: 'no-sort',
            orderable: false
        }]
    });

    const table = $('#datatable').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(4).search($(this).val()).draw();
    });
  }

  displayArticle(){
console.log("in fuction") ;
    this.arti.getArticle().subscribe(
      data =>
        this.art = data
    );

  }
}
