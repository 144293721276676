import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../../../Services/tpservice.service';
import {FMReport} from '../../../../Models/fmreport';
import {DatePipe} from '@angular/common';
import { Excellservice } from '../../../../Services/excellservice.service';
import swal from "sweetalert2";
import { customer } from '../../../../Models/customer';
import { vehicle } from '../../../../Models/vehicle';
declare var $: any;

@Component({
  selector: 'app-fm-branchwise-report',
  templateUrl: './fm-branchwise-report.component.html',
  styleUrls: ['./fm-branchwise-report.component.css'],
  providers:[TpserviceService,DatePipe,Excellservice]
})
export class FmBranchwiseReportComponent implements OnInit {
  branch:string="0";
  branchlist:any=[];
  USRBRSTATE:any;
  fmlistbybranch:FMReport[];
  fmlistbybranchexport:FMReport[]=[];
  flag:boolean;
  todate:any;
  fromdate:any;
  vehicles:vehicle[];
  vehicleno:string="0";

  constructor(private tps:TpserviceService,private datepipe:DatePipe,private excellservice:Excellservice,private detectchages: ChangeDetectorRef) { }

  ngOnInit() {
    this.displaybranches();
    this.getvehiclelist();
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
  } 
  
  ngAfterViewInit() {
    var self=this;

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     });

     $(document).ready(function(){
      $("#vehicleno").select2({
        tags: "true",
        theme: "classic",
        allowClear: true
      });
    });

    $("#vehicleno").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.vehicleno=id;
     });
    
  }

  getlist(){
    this.fmlistbybranch=[];
    this.flag=false;
    this.getfmlistbybranch();
  }

  getfmlistbybranch(){
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      branch:this.branch,
      vehicle_no:this.vehicleno,
      type:2
    }
    // this.tps.getFmReportbyDate(obj).subscribe(  
    //   data => this.gettabledata(data)
    // );
  }

  gettabledata(data){
    this.fmlistbybranch=[];
    $("#products-table").dataTable().fnDestroy();
    this.fmlistbybranch=data;
    console.log(this.fmlistbybranch);
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  displaybranches() {

    this.tps.getBranch().subscribe(
        data => this.branchlist = data
    );
  }

  getvehiclelist(){
    const ob={
      
      checkeddata:this.branch
    }

    this.tps.getVehicle(ob).subscribe(
      data =>this.vehicles=data
     ,error=> console.log(error)
          
  );
  }


  Exportasexcell(){

    if(this.fromdate=='' && this.todate==''){
      swal('Warning','Please Select Date First !','warning');
    }
    else
    {
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      branch:this.branch,
      vehicle_no:this.vehicleno,
      type:2
    }
  
  //   this.tps.getFmReportbyDate(obj).subscribe(
  //     data => this.Afterdata(data)
  // );
  }
  }
  
  Afterdata(data){
    this.fmlistbybranch=[];
    this.fmlistbybranchexport=data;
    this.excellservice.exportAsExcelFile(this.fmlistbybranchexport, 'FMBYBRANCHReport');
  } 

}
