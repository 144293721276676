import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../Services/tpservice.service';
import { rbmaster } from '../../Models/rbmaster';
import{ Router } from '@angular/router';
import swal from "sweetalert2";

declare var $:any;

@Component({
  selector: 'app-regular-bill-list',
  templateUrl: './regular-bill-list.component.html',
  styleUrls: ['./regular-bill-list.component.css'],
  providers:[TpserviceService]
})
export class RegularBillListComponent implements OnInit {

  branches:any="";
  branch:string="";

  com:string=",";
openstr:string="(";
closestr:string=")";
selectedrblist:string="";
  public rblist:rbmaster[]=[];

  constructor(private tps:TpserviceService, private changedetect:ChangeDetectorRef, private route:Router) { }

  ngOnInit() {

    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.displayrb();
  }

  ngAfterViewInit(){
    var self=this;
     $(document).ready(function(){
         $("#branch").select2({
           theme:"classic",
           allowClear: true
         });
       });
     
       $("#branch").on('select2:select', function(evt){
        var id = evt.params.data.id;
        self.branch=id;
        self.onchange();
        });
   }

  displayrb() {
    localStorage.setItem("branch",this.branch);
    const obj={

        branchcode:this.branch
    }
    this.tps.getRbmaster(obj).subscribe(
        data => this.getdata1(data)
    );
}

getdata1(data)
{
    $("#products-table").dataTable().fnDestroy();
    this.rblist=data
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    }); 
   
}

  displaybranches() {

    this.tps.getBranch().subscribe(
        data =>
            this.branches = data
    );
  }

  getrbdata(id)
{
    const obj={
        billno:id
      }
  this.tps.getRbData(obj).subscribe(
    data => this.calldcafterdata(data)     
);
}

calldcafterdata(data){
   localStorage.setItem("data",JSON.stringify(data));
   localStorage.setItem("datastate","1");
   this.route.navigate(['/Transactions/br']);
}

onchange(){
    this.displayrb();
    localStorage.setItem("branch",this.branch);
  }

  onselected(data,e,index)
  {
if(e.target.checked)
{
   var temp=this.selectedrblist.replace(data+this.com,"");
  this.selectedrblist=temp;
   this.selectedrblist+=data+this.com;
}
else{

   var temp1=this.selectedrblist.replace(data+this.com,"");
   this.selectedrblist=temp1;

}
}

  deleterb()
{
  var strdata=this.openstr+this.selectedrblist.substring(0,this.selectedrblist.length-1)+this.closestr;
  console.log(strdata);

  if(strdata=="" || strdata=="()")
    {
        swal({
            title: 'Nothing to delete!',
            text: "Please select at least one record!",
            type: 'warning',
        })
    }
    else{
    swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
         const dcobject={
    selected:strdata

}
this.tps.deleterb(dcobject).subscribe(
    data=>this.callafterdel(data), error1 => console.log(error1));
    this.selectedrblist="";
 }        
})
}
}
callafterdel(data){
  swal('Success', 'Regular Bill '+data['messasge'].toString()+' deleted successfully','success' );
  this.rblist=[];
  $('#products-table').dataTable().fnClearTable();
  this.displayrb();
}

}
