import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../../../Services/tpservice.service';
import {LRReport} from '../../../../Models/lrreport';
import {DatePipe} from '@angular/common';
import { Excellservice } from '../../../../Services/excellservice.service';
import swal from "sweetalert2";
import { customer } from '../../../../Models/customer';
declare var $: any;

@Component({
  selector: 'app-lr-customerwise-report',
  templateUrl: './lr-customerwise-report.component.html',
  styleUrls: ['./lr-customerwise-report.component.css'],
  providers:[TpserviceService,Excellservice,DatePipe]
})
export class LrCustomerwiseReportComponent implements OnInit {

  branch:string="";
  branchlist:any=[];
  USRBRSTATE:any;
  cust: customer[];
  public consigner: any="0";
  lrlistbycustomer:LRReport[];
  lrlistbycustomerexport:LRReport[]=[];
  flag:boolean;
  todate:any;
  fromdate:any;

  constructor(private tps:TpserviceService,private datepipe:DatePipe,private excellservice:Excellservice,private detectchages: ChangeDetectorRef) { }

  ngOnInit() {
    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
    this.displayCustomers();
    console.log(this.cust);
  }

  ngAfterViewInit() {
    var self=this;

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    $(document).ready(function(){
      $("#consigner").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#consigner").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.consigner=id;
     });
    
  }

  getlist(){
    this.lrlistbycustomer=[];
    this.flag=false;
    this.getlrlistbycustomer();
  }

  getlrlistbycustomer(){
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      cust_name:this.consigner
    }
    // this.tps.getLrReportbyCustomer(obj).subscribe(  
    //   data => this.gettabledata(data)
    // );
  }

  gettabledata(data){
    this.lrlistbycustomer=[];
    $("#products-table").dataTable().fnDestroy();
    this.lrlistbycustomer=data;
    console.log(this.lrlistbycustomer);
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  displaybranches() {

    this.tps.getBranch().subscribe(
        data => this.branchlist = data
    );
  }

  displayCustomers() {

        this.tps.getallCustomer().subscribe(
            data =>
                this.cust = data
        );

    }


  Exportasexcell(){

    if(this.fromdate=='' && this.todate==''){
      swal('Warning','Please Select Date First !','warning');
    }
    else
    {
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      cust_name:this.consigner
    }
  
  //   this.tps.getLrReportbyCustomer(obj).subscribe(
  //     data => this.Afterdata(data)
  // );
  }
  }
  
  Afterdata(data){
    this.lrlistbycustomer=[];
    this.lrlistbycustomerexport=data;
    this.excellservice.exportAsExcelFile(this.lrlistbycustomerexport, 'DCBYDATEReport');
  } 

}
