import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { customer } from '../../Models/customer';
import { deleteBo } from '../../Models/deleteBo';
import { permissions } from '../../Models/permissions';
import { TpserviceService } from '../../Services/tpservice.service';
declare var $: any;

@Component({
  selector: 'app-customer-email-list',
  templateUrl: './customer-email-list.component.html',
  styleUrls: ['./customer-email-list.component.css'],
  providers: [TpserviceService]
})
export class CustomerEmailListComponent implements OnInit {
  permissions: permissions;
  grant_edit: any;
  selectedcheck: string = "";
  openstr = "(";
  closestr = ")";
  customerlist: customer[];
  deletestr: deleteBo[] = [];
  deletedcusts: string = "";
  com = ",";

  constructor(private tps: TpserviceService,private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MCUSE;
    this.displayCustomerEmails();
  }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.customerlist = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      "sDom": 'rtip',
    });
    var table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  displayCustomerEmails() {

    const obj = {
      
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getEmails(obj).subscribe(
      data =>this.gettabledata(data)
    );
  }


  onselected(data, e, index) {
    console.log("data");
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      console.log("temp"+temp);
      this.selectedcheck = temp;

      this.selectedcheck += data + this.com;

    }
    else {

      var temp1 = this.selectedcheck.replace(data + this.com, "");

      this.selectedcheck = temp1;



    }
  }

  deletedc() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closestr;
    console.log("strdata"+strdata);
    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const obj = {
            id: strdata
          }
         this.tps.deleteEmailId(obj).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }


  callafterdel(data) {
    this.deletestr = data;
    this.buildcustno(this.deletestr);
    if (this.deletedcusts == "" || this.deletedcusts == "()") {

      swal('success', "Customer Deleted Successfully!!!", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "can not delete this Customer " + this.deletedcusts + ", already used in LR Records..".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.selectedcheck = "";
    this.deletedcusts = '';
    this.displayCustomerEmails();
  }

  buildcustno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletedcusts = this.deletedcusts + data[i].id + ",";
      }
    }
  }

  
  getEmaildata(data) {
    console.log("getemailid...."+data);
    const obj = {
      id: data
    }

    this.tps.getEmailById(obj).subscribe(
      data => this.afterdata(data),
      error => console.log(error)
    )
  }

  afterdata(data) {
    console.log("data"+JSON.stringify(data));
    localStorage.setItem("emaildata", JSON.stringify(data));
    localStorage.setItem("emaildatastate", "1");
    this.route.navigate(['forms/emails']);
  }


}
