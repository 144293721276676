import { BrowserModule } from '@angular/platform-browser';
import { NgModule,PipeTransform,Pipe } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './/app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LayoutModule } from './/layouts/layout.module';
import { ScriptLoaderService } from './_services/script-loader.service';
import {ArticlesListComponent} from './pages/Article-List/articles-list.component';
import {BankComponent} from './pages/forms/Bank/bank.component';
import {BankListComponent} from './pages/Bank-List/bank-list.component';
import {BankAccountsComponent} from './pages/forms/Bank-Account/bank-accounts.component';
import {BranchesComponent} from './pages/forms/Branches/branches.component';
import {AccountListComponent} from './pages/Account-List/account-list.component';
import {BranchListComponent} from './pages/Branch-List/branch-list.component';
import {CustomerComponent} from './pages/forms/Customer/customer.component';
import {CustomerListComponent} from './pages/Customer-List/customer-list.component';
import {DriverComponent} from './pages/forms/Driver/driver.component';
import {DriverListComponent} from './pages/Driver-List/driver-list.component';
import {PetrolpumpComponent} from './pages/forms/Petrolpump/petrolpump.component';
import {PetrolpumpListComponent} from './pages/PetrolPump-List/petrolpump-list.component';
import {LrComponent} from './pages/Transactions/Lorry-Receipt/lr.component';
import {LrlistComponent} from './pages/LR-List/lrlist.component';
import {DcComponent} from './pages/Transactions/Delivery-Challan/dc.component';
import {CommonModule} from '@angular/common';
import {DclistComponent} from './pages/DC-List/dclist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import {dcfilter} from './pages/Transactions/Delivery-Challan/dcfilter';
import {FmListComponent} from './pages/FM-List/fm-list.component';
import {FmComponent} from './pages/Transactions/Freight-Memo/fm.component';
import {InwardComponent} from './pages/Transactions/Inward-Register/inward.component';
import {InwardlistComponent} from './pages/InwardRegister-List/inwardlist.component';
import { FmcollectionComponent } from './pages/Transactions/Freight-Memo/fmcollection/fmcollection.component';
import { CollectionfmlistComponent } from './pages/FM-List/CollectionFM/collectionfmlist.component';
import { FmlineComponent } from './pages/Transactions/Freight-Memo/fmline/fmline.component';
import { FmlinelistComponent } from './pages/FM-List/LineFM/fmlinelist.component';
import { FmcustomerlistComponent } from './pages/FM-List/CustomerFM/fmcustomerlist.component';
import { FmcustomersComponent } from './pages/Transactions/Freight-Memo/fmcustomer/fmcustomers.component';
import { EmployeeListComponent } from './pages/Employee-List/employeelist.component';
import { EmployeesComponent } from './pages/forms/Employees/employee.component';
import { VehicleComponent } from './pages/forms/Vehicle/vehicle.component';
import { PlacesListComponent } from './pages/Places-List/placeslist.component';
import { PlaceComponent } from './pages/forms/Places/place.component';
import { VehicleListComponent } from './pages/Vehicle-List/vehiclelist.component';
import { VehicleOwnerComponent } from './pages/forms/Vehicle-Owner/vehicle-owner.component';
import { VehicleOwnerListComponent } from './pages/VehicleOwner-List/vehicleownerlist.component';
import { VehicleTypeComponent } from './pages/forms/Vehicle-Type/vehicle-type.component';
import { VehicleTypeListComponent } from './pages/VehicleType-List/vehicletypelist.component';
import { LrBillComponent } from './pages/Transactions/Lorry-Receipt/lr_bill/lrbill.component';
import { Login5Component } from '../../app/pages/login-5/login-5.component';
import { UserRegistration } from './pages/forms/Users/user-registration/userregistration.componenet';
import { UserlistComponent } from './pages/User-List/userlist.component';
import { MISlistComponent } from './pages/MIS-List/mislist.component';
import { TransporterbillComponent } from './pages/Transactions/transporterbill/transporterbill.component';
import { PODUploadComponent } from './pages/Transactions/podupload/podupload.component';
import { StockReportComponent } from './pages/Reports/stock-report/stock-report.component';
import { TransporterBillListComponent } from './pages/transporter-bill-list/transporter-bill-list.component';
import { PODUploadListComponent } from './pages/podupload-list/podupload-list.component';
import { LrDatewiseReportComponent } from './pages/Reports/LR-Reports/lr-datewise-report/lr-datewise-report.component';
import { LrBranchwiseReportComponent } from './pages/Reports/LR-Reports/lr-branchwise-report/lr-branchwise-report.component';
import { LrCustomerwiseReportComponent } from './pages/Reports/LR-Reports/lr-customerwise-report/lr-customerwise-report.component';
import { DcDatewiseReportComponent } from './pages/Reports/DC-Reports/dc-datewise-report/dc-datewise-report.component';
import { DcBranchwiseReportComponent } from './pages/Reports/DC-Reports/dc-branchwise-report/dc-branchwise-report.component';
import { DcVehiclewiseReportComponent } from './pages/Reports/DC-Reports/dc-vehiclewise-report/dc-vehiclewise-report.component';
import { FmDatewiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-datewise-report/fm-datewise-report.component';
import { FmBranchwiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-branchwise-report/fm-branchwise-report.component';
import { FmVehiclewiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-vehiclewise-report/fm-vehiclewise-report.component';
import { StockReportbyBranchComponent } from './pages/Reports/stock-reportby-branch/stock-reportby-branch.component';
import { TransporterBillReportComponent } from './pages/Reports/transporter-bill-report/transporter-bill-report.component';
import { TouchingFMComponent } from './pages/Transactions/Freight-Memo/touching-fm/touching-fm.component';
import { TouchingFMListComponent } from './pages/FM-List/touching-fmlist/touching-fmlist.component';
import { TyreSupplierListComponent } from './pages/tyre-supplier-list/tyre-supplier-list.component';
import { TyreSupplierComponent } from './pages/forms/tyre-supplier/tyre-supplier.component';
import { AgentListComponent } from './pages/agent-list/agent-list.component';
import { AgentComponent } from './pages/forms/agent/agent.component';
import { PartyListComponent } from './pages/party-list/party-list.component';
import { PartyComponent } from './pages/forms/party/party.component';
import { LRFreightChallanListComponent } from './pages/lr-freight-challan-list/lr-freight-challan-list.component';
import { LRFreightChallanComponent } from './pages/Transactions/lr-freight-challan/lr-freight-challan.component';
import { BillListComponent } from './pages/bill-list/bill-list.component';
import { BillComponent } from './pages/Transactions/bill/bill.component';
import { PaymentCollectionComponent } from './pages/payment-collection/payment-collection.component';
import { PaymentAdviceVehicleComponent } from './pages/payment-advice-vehicle/payment-advice-vehicle.component';
import { PaymentAdvicePetrolComponent } from './pages/payment-advice-petrol/payment-advice-petrol.component';
import { PaymentAdviceTyreComponent } from './pages/payment-advice-tyre/payment-advice-tyre.component';
import { BankTransactionComponent } from './pages/Transactions/bank-transaction/bank-transaction.component';
import { BankTransHistoryComponent } from './pages/Transactions/bank-trans-history/bank-trans-history.component';
import { TDSTransactioHistoryComponent } from './pages/Transactions/tds-transactio-history/tds-transactio-history.component';
import { AllTransactionHistoryComponent } from './pages/Transactions/all-transaction-history/all-transaction-history.component';
import { AdvancePaymentComponent } from './pages/Transactions/advance-payment/advance-payment.component';
import { AdvancePayHistoryComponent } from './pages/Transactions/advance-pay-history/advance-pay-history.component';
import { PettyCashComponent } from './pages/petty-cash/petty-cash.component';
import { PettyCashHistoryComponent } from './pages/Transactions/petty-cash-history/petty-cash-history.component';
import { LoadingTripListComponent } from './pages/loading-trip-list/loading-trip-list.component';
import { LoadingTripComponent } from './pages/Transactions/loading-trip/loading-trip.component';
import { MaterialInwardComponent } from './pages/material-inward/material-inward.component';
import { MaterialInwardDetailsComponent } from './pages/Transactions/material-inward-details/material-inward-details.component';
import { CashMemoListComponent } from './pages/cash-memo-list/cash-memo-list.component';
import { CashMemoComponent } from './pages/Transactions/cash-memo/cash-memo.component';
import { LrReportComponent } from './pages/Reports/lr-report/lr-report.component';
import { LoadingTripReportComponent } from './pages/Reports/loading-trip-report/loading-trip-report.component';
import { BillRegisterComponent } from './pages/Reports/bill-register/bill-register.component';
import { BilledLrStatusComponent } from './pages/Reports/billed-lr-status/billed-lr-status.component';
import { LorryReceiptRegisterComponent } from './pages/Reports/lorry-receipt-register/lorry-receipt-register.component';
import { PetrolReportComponent } from './pages/Reports/petrol-report/petrol-report.component';
import { MaterialInwardRegisterComponent } from './pages/Reports/material-inward-register/material-inward-register.component';
import { ExportReportComponent } from './pages/Reports/export-report/export-report.component';
import { PaymentCollectionReportComponent } from './pages/Reports/payment-collection-report/payment-collection-report.component';
import { PendingBillForPaymentComponent } from './pages/Reports/pending-bill-for-payment/pending-bill-for-payment.component';
import { CustomerEmailListComponent } from './pages/customer-email-list/customer-email-list.component';
import { EmailComponent } from './pages/forms/email/email.component';
import { CashmemoPaymentCollectionComponent } from './pages/cashmemo-payment-collection/cashmemo-payment-collection.component';
import { CashMemoPaymentCollectionReportComponent } from './pages/Reports/cash-memo-payment-collection-report/cash-memo-payment-collection-report.component';


 
@NgModule({
  declarations: [
    AppComponent,
    UserRegistration,
    ArticlesListComponent,
    BankComponent,
    BankListComponent,
    BankAccountsComponent,
    AccountListComponent,
    BranchesComponent,
    BranchListComponent,
    CustomerComponent,
    CustomerListComponent,
    DriverComponent,
    DriverListComponent,
    PetrolpumpComponent,
    PetrolpumpListComponent,
    LrComponent,
    Login5Component,
    LrlistComponent,
    DcComponent,
    DclistComponent,
    UserlistComponent,
    DcComponent,dcfilter,
    FmListComponent,FmComponent,
    FmcollectionComponent,
    CollectionfmlistComponent,
    FmlineComponent,
    FmlinelistComponent,
    FmcustomerlistComponent,
    FmcustomersComponent,
    InwardComponent,InwardlistComponent,
    PlacesListComponent,
    PlaceComponent,
    EmployeeListComponent,
    EmployeesComponent,
    VehicleComponent,
    VehicleListComponent,
    VehicleOwnerComponent,
    VehicleOwnerListComponent,
    VehicleTypeComponent,
    VehicleTypeListComponent,
    LrBillComponent,
    MISlistComponent,
    TransporterbillComponent,
    PODUploadComponent,
    StockReportComponent,
    TransporterBillListComponent,
    PODUploadListComponent,
    LrDatewiseReportComponent,
    LrBranchwiseReportComponent,
    LrCustomerwiseReportComponent,
    DcDatewiseReportComponent,
    DcBranchwiseReportComponent,
    DcVehiclewiseReportComponent,
    FmDatewiseReportComponent,
    FmBranchwiseReportComponent,
    FmVehiclewiseReportComponent,
    StockReportbyBranchComponent,
    TransporterBillReportComponent,
    TouchingFMComponent,
    TouchingFMListComponent,
    TyreSupplierListComponent,
    TyreSupplierComponent,
    AgentListComponent,
    AgentComponent,
    PartyListComponent,
    PartyComponent,
    LRFreightChallanListComponent,
    LRFreightChallanComponent,
    BillListComponent,
    BillComponent,
    PaymentCollectionComponent,
    PaymentAdviceVehicleComponent,
    PaymentAdvicePetrolComponent,
    PaymentAdviceTyreComponent,
    BankTransactionComponent,
    BankTransHistoryComponent,
    TDSTransactioHistoryComponent,
    AllTransactionHistoryComponent,
    AdvancePaymentComponent,
    AdvancePayHistoryComponent,
    PettyCashComponent,
    PettyCashHistoryComponent,
    LoadingTripListComponent,
    LoadingTripComponent,
    MaterialInwardComponent,
    MaterialInwardDetailsComponent,
    CashMemoListComponent,
    CashMemoComponent,
    LrReportComponent,
    LoadingTripReportComponent,
    BillRegisterComponent,
    BilledLrStatusComponent,
    LorryReceiptRegisterComponent,
    PetrolReportComponent,
    MaterialInwardRegisterComponent,
    ExportReportComponent,
    PaymentCollectionReportComponent,
    PendingBillForPaymentComponent,
    CustomerEmailListComponent,
    EmailComponent,
    CashmemoPaymentCollectionComponent,
    CashMemoPaymentCollectionReportComponent,
    
    
    
  ],
  imports: [
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,
      LayoutModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      SweetAlert2Module.forRoot(),


  ],
  exports:[

dcfilter
  ],
  providers: [ScriptLoaderService],
  bootstrap: [AppComponent]
})

export class AppModule { }
