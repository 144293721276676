import { NgModule, Pipe } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './/layouts/layout.component';
import { CustomersComponent } from './pages/Transactions/customers/customers.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardEcommerceComponent } from './pages/dashboard-ecommerce/dashboard-ecommerce.component';
import { DashboardBlogComponent } from './pages/dashboard-blog/dashboard-blog.component';
import { Dashboard4Component } from './pages/dashboard-4/dashboard-4.component';
import { Dashboard5Component } from './pages/dashboard-5/dashboard-5.component';
import { Dashboard6Component } from './pages/dashboard-6/dashboard-6.component';
import { Dashboard7Component } from './pages/dashboard-7/dashboard-7.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorsComponent } from './pages/ui/colors/colors.component';
import { TypographyComponent } from './pages/ui/typography/typography.component';
import { PanelsComponent } from './pages/ui/panels/panels.component';
import { TabsLineComponent } from './pages/ui/tabs-line/tabs-line.component';
import { TabsPillComponent } from './pages/ui/tabs-pill/tabs-pill.component';
import { AlertsComponent } from './pages/ui/alerts/alerts.component';
import { ToastrComponent } from './pages/ui/toastr/toastr.component';
import { TooltipComponent } from './pages/ui/tooltip/tooltip.component';
import { BadgesProgressComponent } from './pages/ui/badges-progress/badges-progress.component';
import { ListComponent } from './pages/ui/list/list.component';
import { IdleTimerComponent } from './pages/ui/idle-timer/idle-timer.component';
import { ClipboardComponent } from './pages/ui/clipboard/clipboard.component';

import { ButtonDefaultComponent } from './pages/ui/buttons/button-default/button-default.component';
import { ButtonRoundedComponent } from './pages/ui/buttons/button-rounded/button-rounded.component';
import { ButtonSquareComponent } from './pages/ui/buttons/button-square/button-square.component';
import { ButtonAirComponent } from './pages/ui/buttons/button-air/button-air.component';
import { ButtonIconComponent } from './pages/ui/buttons/button-icon/button-icon.component';
import { ButtonLabeledComponent } from './pages/ui/buttons/button-labeled/button-labeled.component';
import { ButtonAnimatedComponent } from './pages/ui/buttons/button-animated/button-animated.component';
import { ButtonFabComponent } from './pages/ui/buttons/button-fab/button-fab.component';
import { ButtonGroupsComponent } from './pages/ui/buttons/button-groups/button-groups.component';
import { ButtonDropdownsComponent } from './pages/ui/buttons/button-dropdowns/button-dropdowns.component';
import { ButtonSocialComponent } from './pages/ui/buttons/button-social/button-social.component';

import { WidgetsStatisticsComponent } from './pages/widgets-statistics/widgets-statistics.component';
import { WidgetsListComponent } from './pages/widgets-list/widgets-list.component';
import { WidgetsUserComponent } from './pages/widgets-user/widgets-user.component';
import { WidgetsBlogComponent } from './pages/widgets-blog/widgets-blog.component';

import { InputsComponent } from './pages/forms/inputs/inputs.component';
import { SwitchComponent } from './pages/forms/switch/switch.component';
import { CheckboxRadioComponent } from './pages/forms/checkbox-radio/checkbox-radio.component';
import { InputGroupsComponent } from './pages/forms/input-groups/input-groups.component';
import { ArticlesComponent } from './pages/forms/Articles/Articles.component';
import { InputMasksComponent } from './pages/forms/input-masks/input-masks.component';
import { FormValidationComponent } from './pages/forms/form-validation/form-validation.component';
import { TextEditorsComponent } from './pages/forms/text-editors/text-editors.component';
import { FormAdvancedComponent } from './pages/forms/form-advanced/form-advanced.component';
import { AutocompleteComponent } from './pages/forms/autocomplete/autocomplete.component';
import { WizardComponent } from './pages/forms/wizard/wizard.component';

import { TablesComponent } from './pages/tables/tables.component';
import { DatatablesComponent } from './pages/datatables/datatables.component';

import { ChartjsComponent } from './pages/charts/chartjs/chartjs.component';
import { MorrisChartComponent } from './pages/charts/morris-chart/morris-chart.component';
import { PeityChartComponent } from './pages/charts/peity-chart/peity-chart.component';
import { SparklineChartComponent } from './pages/charts/sparkline-chart/sparkline-chart.component';

import { MapsVectorComponent } from './pages/maps-vector/maps-vector.component';

import { IconsComponent } from './pages/ui/icons/icons.component';

import { MailboxComponent } from './pages/mailbox/mailbox.component';
import { BlogListComponent } from './pages/blog/blog-list/blog-list.component';
import { ArticleComponent } from './pages/blog/article/article.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProductsListComponent } from './pages/Transactions/products-list/products-list.component';
import { AddProductComponent } from './pages/Transactions/add-product/add-product.component';
import { OrdersListComponent } from './pages/Transactions/orders-list/orders-list.component';
import { OrderDetailsComponent } from './pages/Transactions/order-details/order-details.component';
import { InvoiceComponent } from './pages/Transactions/invoice/invoice.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SearchComponent } from './pages/search/search.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { PricingTable1Component } from './pages/pricing-table-1/pricing-table-1.component';
import { PricingTable2Component } from './pages/pricing-table-2/pricing-table-2.component';
import { PricingTable3Component } from './pages/pricing-table-3/pricing-table-3.component';
import { PricingTable4Component } from './pages/pricing-table-4/pricing-table-4.component';
import { PricingTable5Component } from './pages/pricing-table-5/pricing-table-5.component';
import { PricingTable6Component } from './pages/pricing-table-6/pricing-table-6.component';

import { Login2Component } from './pages/login-2/login-2.component';
import { Login3Component } from './pages/login-3/login-3.component';
import { Login4Component } from './pages/login-4/login-4.component';
import { Login5Component } from './pages/login-5/login-5.component';
import { LockscreenComponent } from './pages/lockscreen/lockscreen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { Error404Component } from './pages/error-404/error-404.component';
import { Error4042Component } from './pages/error-404-2/error-404-2.component';
import { Error403Component } from './pages/error-403/error-403.component';
import { Error500Component } from './pages/error-500/error-500.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ArticlesListComponent } from './pages/Article-List/articles-list.component';
import { BankComponent } from './pages/forms/Bank/bank.component';
import { BankListComponent } from './pages/Bank-List/bank-list.component';
import { BankAccountsComponent } from './pages/forms/Bank-Account/bank-accounts.component';
import { AccountListComponent } from './pages/Account-List/account-list.component';
import { BranchesComponent } from './pages/forms/Branches/branches.component';
import { BranchListComponent } from './pages/Branch-List/branch-list.component';
import { CustomerComponent } from './pages/forms/Customer/customer.component';
import { CustomerListComponent } from './pages/Customer-List/customer-list.component';
import { DriverComponent } from './pages/forms/Driver/driver.component';
import { DriverListComponent } from './pages/Driver-List/driver-list.component';
import { PetrolpumpListComponent } from './pages/PetrolPump-List/petrolpump-list.component';
import { PetrolpumpComponent } from './pages/forms/Petrolpump/petrolpump.component';
import { LrComponent } from './pages/Transactions/Lorry-Receipt/lr.component';
import { LrlistComponent } from './pages/LR-List/lrlist.component';
import { DcComponent } from './pages/Transactions/Delivery-Challan/dc.component';
import { DclistComponent } from './pages/DC-List/dclist.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SwalDefaults } from '@toverux/ngx-sweetalert2/di';
import { dcfilter } from './pages/Transactions/Delivery-Challan/dcfilter';
import { FmComponent } from './pages/Transactions/Freight-Memo/fm.component';
import { FmListComponent } from './pages/FM-List/fm-list.component';
import { InwardComponent } from './pages/Transactions/Inward-Register/inward.component';
import { InwardlistComponent } from './pages/InwardRegister-List/inwardlist.component';
import { FmcollectionComponent } from './pages/Transactions/Freight-Memo/fmcollection/fmcollection.component';
import { FmcustomersComponent } from './pages/Transactions/Freight-Memo/fmcustomer/fmcustomers.component';
import { FmlineComponent } from './pages/Transactions/Freight-Memo/fmline/fmline.component';
import { FmcustomerlistComponent } from './pages/FM-List/CustomerFM/fmcustomerlist.component';
import { CollectionfmlistComponent } from './pages/FM-List/CollectionFM/collectionfmlist.component';
import { FmlinelistComponent } from './pages/FM-List/LineFM/fmlinelist.component';
import { PlaceComponent } from './pages/forms/Places/place.component';
import { EmployeesComponent } from './pages/forms/Employees/employee.component';
import { VehicleComponent } from './pages/forms/Vehicle/vehicle.component';
import { VehicleOwnerComponent } from './pages/forms/Vehicle-Owner/vehicle-owner.component';
import { VehicleTypeComponent } from './pages/forms/Vehicle-Type/vehicle-type.component';
import { PlacesListComponent } from './pages/Places-List/placeslist.component';
import { EmployeeListComponent } from './pages/Employee-List/employeelist.component';
import { VehicleListComponent } from './pages/Vehicle-List/vehiclelist.component';
import { VehicleTypeListComponent } from './pages/VehicleType-List/vehicletypelist.component';
import { VehicleOwnerListComponent } from './pages/VehicleOwner-List/vehicleownerlist.component';
import { LrBillComponent } from './pages/Transactions/Lorry-Receipt/lr_bill/lrbill.component';
import { UserRegistration } from './pages/forms/Users/user-registration/userregistration.componenet';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { UserlistComponent } from './pages/User-List/userlist.component';
import { MISlistComponent } from './pages/MIS-List/mislist.component';
import { TransporterbillComponent } from './pages/Transactions/transporterbill/transporterbill.component';
import { PODUploadComponent } from './pages/Transactions/podupload/podupload.component';
import { StockReportComponent } from './pages/Reports/stock-report/stock-report.component';
import { TransporterBillListComponent } from './pages/transporter-bill-list/transporter-bill-list.component';
import { PODUploadListComponent } from './pages/podupload-list/podupload-list.component';
import { RegularBillListComponent } from './pages/regular-bill-list/regular-bill-list.component';
import { RegularBillComponent } from './pages/regular-bill/regular-bill.component';
import { LrDatewiseReportComponent } from './pages/Reports/LR-Reports/lr-datewise-report/lr-datewise-report.component';
import { LrBranchwiseReportComponent } from './pages/Reports/LR-Reports/lr-branchwise-report/lr-branchwise-report.component';
import { LrCustomerwiseReportComponent } from './pages/Reports/LR-Reports/lr-customerwise-report/lr-customerwise-report.component';
import { DcDatewiseReportComponent } from './pages/Reports/DC-Reports/dc-datewise-report/dc-datewise-report.component';
import { DcBranchwiseReportComponent } from './pages/Reports/DC-Reports/dc-branchwise-report/dc-branchwise-report.component';
import { DcVehiclewiseReportComponent } from './pages/Reports/DC-Reports/dc-vehiclewise-report/dc-vehiclewise-report.component';
import { FmDatewiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-datewise-report/fm-datewise-report.component';
import { FmBranchwiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-branchwise-report/fm-branchwise-report.component';
import { FmVehiclewiseReportComponent } from './pages/Reports/Freight-Memo-Reports/fm-vehiclewise-report/fm-vehiclewise-report.component';
import { StockReportbyBranchComponent } from './pages/Reports/stock-reportby-branch/stock-reportby-branch.component';
import { TransporterBillReportComponent } from './pages/Reports/transporter-bill-report/transporter-bill-report.component';
import { TouchingFMComponent } from './pages/Transactions/Freight-Memo/touching-fm/touching-fm.component';
import { TouchingFMListComponent } from './pages/FM-List/touching-fmlist/touching-fmlist.component';
import { TyreSupplierListComponent } from './pages/tyre-supplier-list/tyre-supplier-list.component';
import { TyreSupplierComponent } from './pages/forms/tyre-supplier/tyre-supplier.component';
import { AgentListComponent } from './pages/agent-list/agent-list.component';
import { AgentComponent } from './pages/forms/agent/agent.component';
import { PartyListComponent } from './pages/party-list/party-list.component';
import { PartyComponent } from './pages/forms/party/party.component';
import { LRFreightChallanListComponent } from './pages/lr-freight-challan-list/lr-freight-challan-list.component';
import { LRFreightChallanComponent } from './pages/Transactions/lr-freight-challan/lr-freight-challan.component';
import { BillListComponent } from './pages/bill-list/bill-list.component';
import { BillComponent } from './pages/Transactions/bill/bill.component';
import { PaymentCollectionComponent } from './pages/payment-collection/payment-collection.component';
import { PaymentAdviceVehicleComponent } from './pages/payment-advice-vehicle/payment-advice-vehicle.component';
import { PaymentAdvicePetrolComponent } from './pages/payment-advice-petrol/payment-advice-petrol.component';
import { PaymentAdviceTyreComponent } from './pages/payment-advice-tyre/payment-advice-tyre.component';
import { BankTransactionComponent } from './pages/Transactions/bank-transaction/bank-transaction.component';
import { BankTransHistoryComponent } from './pages/Transactions/bank-trans-history/bank-trans-history.component';
import { TDSTransactioHistoryComponent } from './pages/Transactions/tds-transactio-history/tds-transactio-history.component';
import { AllTransactionHistoryComponent } from './pages/Transactions/all-transaction-history/all-transaction-history.component';
import { AdvancePaymentComponent } from './pages/Transactions/advance-payment/advance-payment.component';
import { AdvancePayHistoryComponent } from './pages/Transactions/advance-pay-history/advance-pay-history.component';
import { PettyCashComponent } from './pages/petty-cash/petty-cash.component';
import { PettyCashHistoryComponent } from './pages/Transactions/petty-cash-history/petty-cash-history.component';
import { LoadingTripListComponent } from './pages/loading-trip-list/loading-trip-list.component';
import { LoadingTripComponent } from './pages/Transactions/loading-trip/loading-trip.component';
import { MaterialInwardComponent } from './pages/material-inward/material-inward.component';
import { MaterialInwardDetailsComponent } from './pages/Transactions/material-inward-details/material-inward-details.component';
import { CashMemoListComponent } from './pages/cash-memo-list/cash-memo-list.component';
import { CashMemoComponent } from './pages/Transactions/cash-memo/cash-memo.component';
import { LrReportComponent } from './pages/Reports/lr-report/lr-report.component';
import { LoadingTripReportComponent } from './pages/Reports/loading-trip-report/loading-trip-report.component';
import { BillRegisterComponent } from './pages/Reports/bill-register/bill-register.component';
import { BilledLrStatusComponent } from './pages/Reports/billed-lr-status/billed-lr-status.component';
import { LorryReceiptRegisterComponent } from './pages/Reports/lorry-receipt-register/lorry-receipt-register.component';
import { PetrolReportComponent } from './pages/Reports/petrol-report/petrol-report.component';
import { MaterialInwardRegisterComponent } from './pages/Reports/material-inward-register/material-inward-register.component';
import { ExportReportComponent } from './pages/Reports/export-report/export-report.component';
import { PaymentCollectionReportComponent } from './pages/Reports/payment-collection-report/payment-collection-report.component';
import { PendingBillForPaymentComponent } from './pages/Reports/pending-bill-for-payment/pending-bill-for-payment.component';
import { CustomerEmailListComponent } from './pages/customer-email-list/customer-email-list.component';
import { EmailComponent } from './pages/forms/email/email.component';
import { CashmemoPaymentCollectionComponent } from './pages/cashmemo-payment-collection/cashmemo-payment-collection.component';
import { CashMemoPaymentCollectionReportComponent } from './pages/Reports/cash-memo-payment-collection-report/cash-memo-payment-collection-report.component';


const routes: Routes = [
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    {
        'path': '',
        'component': LayoutComponent,
        'children': [
            {
                path: 'index',
                component: HomeComponent
            },
            {
                path: 'dashboard_ecommerce',
                component: DashboardEcommerceComponent
            },
            {
                path: 'dashboard_blog',
                component: DashboardBlogComponent
            },
            {
                path: 'dashboard_4',
                component: Dashboard4Component
            },
            {
                path: 'dashboard_5',
                component: Dashboard5Component
            },
            {
                path: 'dashboard_6',
                component: Dashboard6Component
            },
            {
                path: 'dashboard_7',
                component: Dashboard7Component
            },
            {
                path: 'ui/colors',
                component: ColorsComponent
            },
            {
                path: 'forms/user-registration',
                component: UserRegistration
            },
            {
                path: 'ui/typography',
                component: TypographyComponent
            },
            {
                path: 'ui/panels',
                component: PanelsComponent
            },
            {
                path: 'ui/tabs-pill',
                component: TabsPillComponent
            },
            {
                path: 'ui/tabs-line',
                component: TabsLineComponent
            },
            {
                path: 'ui/alerts',
                component: AlertsComponent
            },
            {
                path: 'ui/toastr',
                component: ToastrComponent
            },
            {
                path: 'ui/tooltip_popover',
                component: TooltipComponent
            },
            {
                path: 'ui/badges_progress',
                component: BadgesProgressComponent
            },
            {
                path: 'ui/lists',
                component: ListComponent
            },
            {
                path: 'ui/clipboard',
                component: ClipboardComponent
            },
            {
                path: 'ui/idle-timer',
                component: IdleTimerComponent
            },
            {
                path: 'ui/icons',
                component: IconsComponent
            },
            {
                path: 'buttons/buttons-default',
                component: ButtonDefaultComponent
            },
            {
                path: 'buttons/buttons-rounded',
                component: ButtonRoundedComponent
            },
            {
                path: 'buttons/buttons-square',
                component: ButtonSquareComponent
            },
            {
                path: 'buttons/buttons-air',
                component: ButtonAirComponent
            },
            {
                path: 'buttons/button-icon',
                component: ButtonIconComponent
            },
            {
                path: 'buttons/button-labeled',
                component: ButtonLabeledComponent
            },
            {
                path: 'buttons/button-animated',
                component: ButtonAnimatedComponent
            },
            {
                path: 'buttons/buttons-fab',
                component: ButtonFabComponent
            },
            {
                path: 'buttons/button-groups',
                component: ButtonGroupsComponent
            },
            {
                path: 'buttons/button-dropdowns',
                component: ButtonDropdownsComponent
            },
            {
                path: 'buttons/buttons-social',
                component: ButtonSocialComponent
            },
            {
                path: 'widgets/statistics',
                component: WidgetsStatisticsComponent
            },
            {
                path: 'widgets/list',
                component: WidgetsListComponent
            },
            {
                path: 'widgets/user',
                component: WidgetsUserComponent
            },
            {
                path: 'widgets/blog',
                component: WidgetsBlogComponent
            },
            {
                path: 'forms/form-controls/inputs',
                component: InputsComponent
            },
            {
                path: 'forms/form-controls/switch',
                component: SwitchComponent
            },
            {
                path: 'forms/form-controls/checkbox-radio',
                component: CheckboxRadioComponent
            },
            {
                path: 'forms/form-controls/input-groups',
                component: InputGroupsComponent
            },
            {
                path: 'forms/Article',
                component: ArticlesComponent
            },
            {
                path: 'forms/form_advanced',
                component: FormAdvancedComponent
            },
            {
                path: 'forms/form_masks',
                component: InputMasksComponent
            },

            {
                path: 'forms/Branches',
                component: BranchesComponent
            },
            {
                path: 'forms/form_validation',
                component: FormValidationComponent
            },
            {
                path: 'forms/customers',
                component: CustomerComponent
            },
            {
                path: 'forms/emails',
                component: EmailComponent
            },
            {
                path: 'forms/places',
                component: PlaceComponent
            },

            {
                path: 'forms/employee',
                component: EmployeesComponent
            },
            {
                path: 'forms/vehicle',
                component: VehicleComponent
            },
            {
                path: 'forms/vehicle-owner',
                component: VehicleOwnerComponent
            },
            {
                path: 'forms/vehicle-type',
                component: VehicleTypeComponent
            },
            {
                path: 'pages/rb',
                component: RegularBillListComponent
            },
            {
                path: 'forms/petrolpump',
                component: PetrolpumpComponent
            },
            {
                path: 'forms/Agent',
                component: AgentComponent
            },
            {
                path: 'forms/Party',
                component: PartyComponent
            },
            {
                path: 'forms/driver',
                component: DriverComponent
            },
            {
                path: 'forms/text_editors',
                component: TextEditorsComponent
            },
            {
                path: 'forms/Bank-Account',
                component: BankAccountsComponent
            },

            {
                path: 'forms/autocomplete',
                component: AutocompleteComponent
            },
            {
                path: 'forms/Bank',
                component: BankComponent
            },
            {
                path: 'forms/tyresupplier',
                component: TyreSupplierComponent
            },
            {
                path: 'tables/basic',
                component: TablesComponent
            },
            {
                path: 'tables/datatables',
                component: DatatablesComponent
            },
            {
                path: 'charts/chartjs',
                component: ChartjsComponent
            },
            {
                path: 'charts/charts_morris',
                component: MorrisChartComponent
            },
            {
                path: 'charts/charts_peity',
                component: PeityChartComponent
            },
            {
                path: 'charts/charts_sparkline',
                component: SparklineChartComponent
            },
            {
                path: 'maps_vector',
                component: MapsVectorComponent
            },
            {
                path: 'mailbox',
                component: MailboxComponent
            },
            {
                path: 'blog/blog_list',
                component: BlogListComponent
            },
            {
                path: 'blog/article',
                component: ArticleComponent
            },
            {
                path: 'calendar',
                component: CalendarComponent
            },
            {
                path: 'Transactions/lr',
                component: LrComponent
            },
            {
                path: 'Transactions/dc',
                component: DcComponent
            },
            {
                path: 'Transactions/loading_trip',
                component: LoadingTripComponent
            },
            {
                path: 'Transactions/Bill',
                component: BillComponent
            },
            {
                path: 'Transactions/CashMemo',
                component: CashMemoComponent
            },
            {
                path: 'Transactions/fmcollection',
                component: FmcollectionComponent
            },
            {
                path: 'Transactions/materialinward',
                component: MaterialInwardDetailsComponent
            },
            {
                path: 'Transactions/fmcustomers',
                component: FmcustomersComponent
            },
            {
                path: 'Transactions/fmline',
                component: FmlineComponent
            },
            {
                path: 'Transactions/fmtouching',
                component: TouchingFMComponent
            },
            {
                path: 'Transactions/fm',
                component: FmComponent
            },
            {
                path: 'Transactions/products_list',
                component: ProductsListComponent
            },
            {
                path: 'Transactions/MIS_list',
                component: MISlistComponent
            },
            {
                path: 'Transactions/br',
                component: RegularBillComponent
            },
            {
                path: 'pages/Article-Lists',
                component: ArticlesListComponent
            },
            {
                path: 'pages/Customer-List',
                component: CustomerListComponent
            },
            {
                path: 'pages/customer-email-list',
                component: CustomerEmailListComponent
            },
            {
                path: 'User-List',
                component: UserlistComponent
            },

            {
                path: 'pages/Bank-List',
                component: BankListComponent
            },

            {
                path: 'pages/Account-List',
                component: AccountListComponent
            },
            {
                path: 'pages/Driver-List',
                component: DriverListComponent
            },
            {
                path: 'pages/Petrolpump-List',
                component: PetrolpumpListComponent
            },
            {
                path: 'pages/Branch-List',
                component: BranchListComponent
            },
            {
                path: 'pages/Places-List',
                component: PlacesListComponent
            },
            {
                path: 'pages/Employee-List',
                component: EmployeeListComponent
            },
            {
                path: 'pages/Vehicle-List',
                component: VehicleListComponent
            },
            {
                path: 'pages/VehicleType-List',
                component: VehicleTypeListComponent
            },
            {
                path: 'pages/VehicleOwner-List',
                component: VehicleOwnerListComponent
            },
            {
                path: 'pages/TyreSupplier-List',
                component: TyreSupplierListComponent
            },
            {
                path: 'pages/Agent-List',
                component: AgentListComponent
            },
            {
                path: 'pages/Party-List',
                component: PartyListComponent
            },
            {
                path: 'pages/LR',
                component: LrlistComponent
            },

            {
                path: 'pages/Inward',
                component: InwardlistComponent
            },
            {
                path: 'pages/loading_trip',
                component: LoadingTripListComponent
            },
            {
                path: 'pages/LR-Freight-challan',
                component: LRFreightChallanListComponent
            },
            {
                path: 'pages/Fm-List',
                component: FmListComponent
            },
            {
                path: 'pages/materialinward',
                component: MaterialInwardComponent
            },
            {
                path: 'pages/Bill-List',
                component: BillListComponent
            },
            {
                path: 'pages/Cashmemo-List',
                component: CashMemoListComponent
            },
            {
                path: 'pages/Payment-Collection',
                component: PaymentCollectionComponent
            },
            {
                path: 'pages/Cashmemo-Payment-Collection',
                component: CashmemoPaymentCollectionComponent
            },
            {
                path: 'pages/Payment-Advice-Vehicle',
                component: PaymentAdviceVehicleComponent
            },
            {
                path: 'pages/Payment-Advice-Petrol',
                component: PaymentAdvicePetrolComponent
            },
            {
                path: 'pages/Payment-Advice-Tyre',
                component: PaymentAdviceTyreComponent
            },
            {
                path: 'pages/Fmcollectionlist',
                component: CollectionfmlistComponent
            },
            {
                path: 'pages/Fmcustomerslist',
                component: FmcustomerlistComponent
            },
            {
                path: 'pages/Fmlinelist',
                component: FmlinelistComponent
            },
            {
                path: 'pages/Fmtouchinglist',
                component: TouchingFMListComponent
            },
            {
                path: 'pages/TransporterBillList',
                component: TransporterBillListComponent
            },
            {
                path: 'pages/PODUploadList',
                component: PODUploadListComponent
            },
            {
                path: 'pages/Transactions/add_product',
                component: AddProductComponent
            },
            {
                path: 'pages/Transactions/orders_list',
                component: OrdersListComponent
            },
            {
                path: 'pages/Transactions/order_details',
                component: OrderDetailsComponent
            },
            {
                path: 'pages/Transactions/invoice',
                component: InvoiceComponent
            },
            {
                path: 'pages/Transactions/customers',
                component: CustomersComponent
            },
            {
                path: 'pages/Transactions/banktransaction',
                component: BankTransactionComponent
            },
            {
                path: 'pages/Transactions/banktranshistory',
                component: BankTransHistoryComponent
            },
            {
                path: 'pages/Transactions/tdstranshistory',
                component: TDSTransactioHistoryComponent
            },
            {
                path: 'pages/Transactions/alltranshistory',
                component: AllTransactionHistoryComponent
            },
            {
                path: 'pages/Transactions/advancepayment',
                component: AdvancePaymentComponent
            },
            {
                path: 'pages/Transactions/advancepayhistory',
                component: AdvancePayHistoryComponent
            },
            {
                path: 'pages/Transactions/pettycashtransaction',
                component: PettyCashComponent
            },
            {
                path: 'pages/Transactions/pettycashhistory',
                component: PettyCashHistoryComponent
            },
            {
                path: 'Transactions/Inwards',
                component: InwardComponent
            },
            {
                path: 'Transactions/Transporterbill',
                component: TransporterbillComponent
            },
            {
                path: 'Transactions/PODUpload',
                component: PODUploadComponent
            },
            {
                path: 'Transactions/LR-Freight-challan',
                component: LRFreightChallanComponent
            },
            {
                path: 'Reports/StockReport',
                component: StockReportComponent
            },
            {
                path: 'Reports/lrreport',
                component: LrReportComponent
            },
            {
                path: 'Reports/lrregister',
                component: LorryReceiptRegisterComponent
            },
            {
                path: 'Reports/loadingtripsheet',
                component: LoadingTripReportComponent
            },
            {
                path: 'Reports/Billregister',
                component: BillRegisterComponent
            },
            {
                path: 'Reports/Billedlr',
                component: BilledLrStatusComponent
            },
            {
                path: 'Reports/petrolreport',
                component: PetrolReportComponent
            },
            {
                path: 'Reports/materialinward',
                component: MaterialInwardRegisterComponent
            },
            {
                path: 'Reports/exportreport',
                component: ExportReportComponent
            },
            {
                path: 'Reports/paymentcollection',
                component: PaymentCollectionReportComponent
            },
            {
                path: 'Reports/cashmemopaymentcollection',
                component: CashMemoPaymentCollectionReportComponent
            },
            {
                path: 'Reports/pendingbills',
                component: PendingBillForPaymentComponent
            },
            {
                'path': 'faq',
                'component': FaqComponent
            },
            {
                'path': 'search',
                'component': SearchComponent
            },
            {
                'path': 'timeline',
                'component': TimelineComponent
            },
            {
                'path': 'pricing-table-1',
                'component': PricingTable1Component
            },
            {
                'path': 'pricing-table-2',
                'component': PricingTable2Component
            },
            {
                'path': 'pricing-table-3',
                'component': PricingTable3Component
            },
            {
                'path': 'pricing-table-4',
                'component': PricingTable4Component
            },
            {
                'path': 'permissions',
                'component': PermissionsComponent
            },
            {
                'path': 'pricing-table-5',
                'component': PricingTable5Component
            },
            {
                'path': 'pricing-table-6',
                'component': PricingTable6Component

            },
            {
                'path': 'lrreports/datewise',
                'component': LrDatewiseReportComponent
            },
            {
                'path': 'lrreports/branchwise',
                'component': LrBranchwiseReportComponent
            },
            {
                'path': 'lrreports/customerwise',
                'component': LrCustomerwiseReportComponent
            },
            {
                'path': 'dcreports/datewise',
                'component': DcDatewiseReportComponent
            },
            {
                'path': 'dcreports/branchwise',
                'component': DcBranchwiseReportComponent
            },
            {
                'path': 'dcreports/vehiclewise',
                'component': DcVehiclewiseReportComponent
            },
            {
                'path': 'fmreports/collection',
                'component': FmDatewiseReportComponent
            },
            {
                'path': 'fmreports/line',
                'component': FmBranchwiseReportComponent
            },
            {
                'path': 'fmreports/custend',
                'component': FmVehiclewiseReportComponent
            },
            {
                'path': 'Reports/branchwise',
                'component': StockReportbyBranchComponent
            },
            {
                'path': 'Reports/trreport',
                'component': TransporterBillReportComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
        ]
    },

    {
        'path': 'login-2',
        'component': Login2Component
    },
    {
        'path': 'login-3',
        'component': Login3Component
    },
    {
        'path': 'login-4',
        'component': Login4Component
    },
    {
        'path': 'login-5',
        'component': Login5Component
    },
    {
        'path': 'lockscreen',
        'component': LockscreenComponent
    },
    {
        'path': 'forgot_password',
        'component': ForgotPasswordComponent
    },
    {
        'path': 'error_404',
        'component': Error404Component
    },
    {
        'path': 'error_404-2',
        'component': Error4042Component
    },
    {
        'path': 'error_403',
        'component': Error403Component
    },
    {
        'path': 'error_500',
        'component': Error500Component
    },
    {
        'path': 'forms/user-registration',
        'component': UserRegistration
    },
    {
        'path': 'maintenance',
        'component': MaintenanceComponent
    },
    {
        'path': 'bill/lr',
        'component': LrBillComponent
    },
    {
        'path': '**',
        'redirectTo': 'error_404',
        'pathMatch': 'full'
    },
];

@NgModule({
    declarations: [
        HomeComponent,
        RegularBillComponent,
        RegularBillListComponent,
        DashboardEcommerceComponent,
        DashboardBlogComponent,
        Dashboard4Component,
        Dashboard5Component,
        Dashboard6Component,
        Dashboard7Component,
        ColorsComponent,
        TypographyComponent,
        PanelsComponent,
        TabsLineComponent,
        TabsPillComponent,
        AlertsComponent,
        ToastrComponent,
        TooltipComponent,
        BadgesProgressComponent,
        ListComponent,
        IdleTimerComponent,
        ClipboardComponent,
        ButtonDefaultComponent,
        ButtonRoundedComponent,
        ButtonSquareComponent,
        ButtonAirComponent,
        ButtonIconComponent,
        ButtonLabeledComponent,
        ButtonAnimatedComponent,
        ButtonFabComponent,
        ButtonGroupsComponent,
        ButtonDropdownsComponent,
        ButtonSocialComponent,
        WidgetsStatisticsComponent,
        WidgetsListComponent,
        WidgetsUserComponent,
        WidgetsBlogComponent,
        InputsComponent,
        SwitchComponent,
        CheckboxRadioComponent,
        InputGroupsComponent,
        ArticlesComponent,
        InputMasksComponent,
        FormValidationComponent,
        TextEditorsComponent,
        FormAdvancedComponent,
        AutocompleteComponent,
        WizardComponent,
        TablesComponent,
        DatatablesComponent,
        ChartjsComponent,
        MorrisChartComponent,
        PeityChartComponent,
        SparklineChartComponent,
        MapsVectorComponent,
        IconsComponent,
        MailboxComponent,
        BlogListComponent,
        ArticleComponent,
        CalendarComponent,
        ProductsListComponent,
        AddProductComponent,
        OrdersListComponent,
        OrderDetailsComponent,
        InvoiceComponent,
        CustomersComponent,
        FaqComponent,
        SearchComponent,
        TimelineComponent,
        PricingTable1Component,
        PricingTable2Component,
        PricingTable3Component,
        PricingTable4Component,
        PricingTable5Component,
        PricingTable6Component,
        PermissionsComponent,
        Login2Component,
        Login3Component,
        Login4Component,
        Login5Component,
        LockscreenComponent,
        ForgotPasswordComponent,
        ProfileComponent,
        Error404Component,
        Error4042Component,
        Error403Component,
        Error500Component,
        MaintenanceComponent,
        // TransporterbillComponent,
    ],
    imports: [RouterModule.forRoot(routes), BrowserModule, CommonModule, ReactiveFormsModule, FormsModule, SweetAlert2Module.forRoot()],
    exports: [
        RouterModule,
    ]
})

export class AppRoutingModule { }
