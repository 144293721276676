import { Component, OnInit, AfterViewInit } from '@angular/core';
import swal from 'sweetalert2';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Router } from '@angular/router';
import { tyresupplier } from '../../../Models/tyresupplier';
declare var $: any;
@Component({
  selector: 'app-tyre-supplier',
  templateUrl: './tyre-supplier.component.html',
  styleUrls: ['./tyre-supplier.component.css'],
  providers: [TpserviceService]
})
export class TyreSupplierComponent implements OnInit, AfterViewInit {

  datastate: string = "Save";
  id: string = "";
  name: string = "";
  address: string = "";
  city: string = "";
  telephone: string = "";
  email: string = "";
  pan: string = "";
  vendorcode: string = "";
  vatno: string = "";
  cst: string = "";
  ecc: string = "";
  refname: string = "";
  refaddr: string = "";
  refdesg: string = "";
  refemail: string = "";
  reffax: string = "";
  row_num: string;
  flag: boolean;
  disablestate: string = "";
  supplier: tyresupplier;
  public rows: Array<{
    cpname: string;
    cpaddr: string;
    cpdesg: string;
    cpemail: string;
    cpfax: string;
  }> = [];

  constructor(private tps: TpserviceService, private route: Router) { }

  ngOnInit() {
    this.disablestate = localStorage.getItem("disablestate");

    if (localStorage.getItem("supplierdatastate") == "1") {
      this.getvehicledata();
      localStorage.removeItem("supplierdatastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        name: {
          minlength: 2,
          required: !0
        },
        email: {
          required: !0,
          email: !0
        },
        telephone: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
  }

  onsubmit() {

    if (!(this.datastate == "Update")) {
      const obj = {
        suppliername: this.name,
        address: this.address,
        city: this.city,
        telephoneno: this.telephone,
        emailid: this.email,
        panno: this.pan,
        vendorcode: this.vendorcode,
        vatno: this.vatno,
        cstno: this.cst,
        eccno: this.ecc,
        contacts: this.rows
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.addtyresupplier(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updateyresupplier();
    }
  }

  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/TyreSupplier-List']);
  }
  
  redirect() {
    this.route.navigate(['pages/TyreSupplier-List']);
  }

  addref() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
  }

  clear() {
    this.refname = "";
    this.refaddr = "";
    this.refdesg = "";
    this.refemail = "";
    this.reffax = "";
  }

  editrow(row, index) {
    this.row_num = index;
    this.refname = row.cpname;
    this.refaddr = row.cpaddr;
    this.refdesg = row.cpdesg;
    this.refemail = row.cpemail;
    this.reffax = row.cpfax;
    this.flag = true;
  }

  onupdate() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
    this.flag = false;
    this.deleterow(this.row_num);
  }

  deleterow(index) {
    this.rowdatadelete(index);
    this.rows.splice(index, 1);
  }

  rowdatadelete(index) {
    var tmp: string;
    var openpr: "(";
    var closepr: ")";
    var com: ",";
    var data_str: String = "";
    var raw_str: String = "";
    data_str = "'" + this.rows[index].cpname + "'" + com + "'" + this.rows[index].cpaddr + "'" + com + "'" + this.rows[index].cpdesg + "'" + com + "'" + this.rows[index].cpemail + "'" + com + "'" + this.rows[index].cpfax + "'" + com + "@@";
    raw_str = openpr + data_str + closepr + com;
  }

  getvehicledata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.supplier = JSON.parse(localStorage.getItem("supplierdata"));
    localStorage.removeItem("supplierdata");
    this.id = this.supplier.sid;
    this.name = this.supplier.suppliername;
    this.address = this.supplier.address;
    this.telephone = this.supplier.telephoneno;
    this.pan = this.supplier.panno;
    this.city = this.supplier.city;
    this.email = this.supplier.emailid;
    this.vendorcode = this.supplier.vendorcode;
    this.cst = this.supplier.cstno;
    this.vatno = this.supplier.vatno;
    this.ecc = this.supplier.eccno;
    this.rows = this.supplier.contacts;
    swal.close();
  }

  updateyresupplier() {
    const obj = {
      sid: this.id,
      suppliername: this.name,
      address: this.address,
      city: this.city,
      telephoneno: this.telephone,
      emailid: this.email,
      panno: this.pan,
      vendorcode: this.vendorcode,
      vatno: this.vatno,
      cstno: this.cst,
      eccno: this.ecc,
      contacts: this.rows
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updatetyresupplier(obj).subscribe(
      data => this.aftersubmit(data)
    );
  }

}
