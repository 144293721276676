
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { Router } from '@angular/router';
import { Branch } from '../../Models/Branch';
import { customer } from '../../Models/customer';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';
import { payment_tabledata } from '../../Models/payment_tabledata';
import { user } from '../../Models/user';

declare var $: any;

@Component({
  selector: 'app-cashmemo-payment-collection',
  templateUrl: './cashmemo-payment-collection.component.html',
  styleUrls: ['./cashmemo-payment-collection.component.css'],
  providers: [TpserviceService, DatePipe]
})
export class CashmemoPaymentCollectionComponent implements OnInit, AfterViewInit {
  datastate: string = "save";
  branch: string = "";
  branches: Branch;
  customer: string = "";
  cust: customer[];
  bills: payment_tabledata[];
  billstosave: payment_tabledata[]=[];
  date: string = "";
  outstanding: any = 0;
  tot_recei_amt: any = 0;
  tot_party_ded: any = 0;
  tot_tds_ded: any = 0;
  pay_mode: string = "";
  bank: string = "";
  chq_no: string = "";
  chq_date: string = "";
  flag: boolean = true;
  bill_type:string="";
  userdata:user;
  user:string="";

  constructor(private tps: TpserviceService, private route: Router, private datepipe:DatePipe, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.user = this.userdata.id;
    this.date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.chq_date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
   // this.displayCustomers();
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        bill_type: {
          required: !0
        },
        customer: {
          required: !0,
        },
        date: {
          required: !0,
        },
        received_amt: {
          required: !0,
        },
        pay_mode: {
          required: !0,
        },        
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.submit();
      }
    });
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
    });
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
   
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data =>this.getafterCustomersdata(data)
    );
  }

  getafterCustomersdata(data) {
    this.cust = data
    swal.close();
  }
  getbills() {
    const obj = {
      customer_id: this.customer
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
   // this.tps.getBillsForPaymentCollection(obj).subscribe(
    this.tps.getCashmemosForPaymentCollection(obj).subscribe(
      data => this.afterGetBills(data)
    );
  }

  afterGetBills(data){
    $("#products-table").dataTable().fnDestroy();
    this.bills = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 10,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  calculation(i) {
    var total = 0;
    var totpartyded = 0;
    var tottdsded = 0;
    var outstand = 0;
    this.bills.forEach(function (element) {
      total = Number(total) + Number(element.received) + Number(element.party_ded) + Number(element.tds_ded);
      totpartyded = totpartyded + Number(element.party_ded);
      tottdsded = tottdsded + Number(element.tds_ded);
      outstand = outstand + Number(element.rem_amt)
    })
    this.tot_recei_amt = total;
    this.tot_party_ded = totpartyded;
    this.tot_tds_ded = tottdsded;
    this.outstanding = outstand;
  }

  setflag() {
    if (!(this.pay_mode == "Cash")) {
      this.flag = false;
    }
    else {
      this.flag = true;
    }
  }

  submit(){
    for (let i = 0; i < this.bills.length; i++) {
     // console.log(this.bills[i].received);
      this.bills[i].rem_amt = String(Number(this.bills[i].rem_amt) - Number(this.bills[i].received));
      if (String(this.bills[i].received) == "0" || this.bills[i].received == "" || this.bills[i].received == undefined || this.bills[i].received == null) {

      }
      else {
        this.billstosave.push(this.bills[i]);
      }
    }
    this.saveCollectionTrasaction();
  }
  saveCollectionTrasaction(){
    if(this.billstosave.length!=0){

    const obj={
      type:this.bill_type,
      customer_id:this.customer,
      date:this.date,
      receive:this.tot_recei_amt,
      party_ded:this.tot_party_ded,
      tds_ded:this.tot_tds_ded,
      pay_mode:this.pay_mode,
      bank_name:this.bank,
      /*cheque_rtgs_no:this.chq_no,
      cheque_rtgs_date:this.chq_date,*/
      branch:this.branch,
      user_id:this.user,
      bills:this.billstosave
    }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
   // this.tps.addPaymentCollection(obj).subscribe(
    this.tps.addCashPaymentCollection(obj).subscribe(
      data => this.aftersubmit(data)
    );
    }
    else{
      swal('Alert','No bill selected. Please check!!!', 'warning');
    }
  }

  aftersubmit(data){
    swal.close();
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    swal("Success", data['messasge'], "success");
    this.billstosave.length = 0;
    this.getbills();
  }

  redirect(){
    this.route.navigate(['/'])
  }

}

