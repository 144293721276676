export class Lr {

        public sr: string;
        public inv: string;
        public desc: string;
        public art: string;
        public nart: string;
    public qty: string;
    public inamt: string;
    public cftl: string;
    public cftw: string;
    public cfth: string;
    public 1220: string;
    public cft: string;
    public acwt: string;
    public chwt: string;
    public rtp: string;
    public rt: string;

}