import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-inward',
  templateUrl: './material-inward.component.html',
  styleUrls: ['./material-inward.component.css']
})
export class MaterialInwardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
