
import { HttpClientModule , HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {article} from '../app/Models/article';

const endpoint: String = 'http://192.168.1.144:8080/';

@Injectable()
export class ArticleserviceService {

  constructor( private httpclient: HttpClient, private httpmodule: HttpClientModule) { }

  getArticle(){
    return this.httpclient.post(endpoint + 'tp/api/transport/articleslist', null);
  }

  addArticle(article: any){
    return this.httpclient.post(endpoint + 'tp/api/transport/addarticle', article );
  }
    addLR(LROBJ: any){
        return this.httpclient.post(endpoint + 'tp/api/transport/addlrmaster', LROBJ );
    }

    getCustomer(){
        return this.httpclient.post(endpoint + 'tp/api/transport/customerlist', null);
    }

    getPlace(){
        return this.httpclient.post(endpoint + 'tp/api/transport/placelist', null);
    }
    getBranch(){
        return this.httpclient.post(endpoint + 'tp/api/transport/branchlist', null);
    }


}

