import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Branch } from '../../Models/Branch';
import { pod } from '../../Models/pod';
import { Router } from '@angular/router';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
const path="https://files.omsairamlogistics.ml/home/";
declare var $: any;
@Component({
  selector: 'app-podupload-list',
  templateUrl: './podupload-list.component.html',
  styleUrls: ['./podupload-list.component.css'],
  providers:[TpserviceService]
})
export class PODUploadListComponent implements OnInit,AfterViewInit {

  constructor(private tps:TpserviceService,private route:Router,private changedetect:ChangeDetectorRef) { }
  selectedcheck:string="";
  com:string=",";
  podlist:pod[]
  openstr:string="(";
  closedtr:string=")";
  branch:string="";
  branches:Branch[];
  grant_edit:any;
  permissions:permissions;
  disablestate:any;
  podobject:pod;

  deletelist:string="";
  public multipleselect:Array<any>=[];
  deletestr:deleteBo[]=[];
  deletedpodnos:string="";

  ngOnInit() {
    this.permissions=JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit=+this.permissions.TPODE;
    this.displaybranches();
   

  this.disablestate=localStorage.getItem("disablestate");
 

  }

  ngAfterViewInit() {
   
    var self=this;
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     self.onchange();
     }); 

  }

  gettabledata(data)
  {
    $("#products-table").dataTable().fnDestroy();
    this.podlist=data;
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }

  onselected(data,e,index)
  {
if(e.target.checked)
{
   var temp=this.selectedcheck.replace(data+this.com,"");
   
  this.selectedcheck=temp;
 
   this.selectedcheck+=data+this.com;
   
}
else{

   var temp1=this.selectedcheck.replace(data+this.com,"");
  
   this.selectedcheck=temp1;
}
}

getpodlist()
{ 
  const obj={
    checkeddata:this.branch
}
  this.tps.getPodlist(obj).subscribe(
    data => this.gettabledata(data)
);
}

Multiselect(index,ev){
  if(this.podlist[index].selected=='true'){
    this.podlist[index].selected='false';
  }
  else{
    this.podlist[index].selected='true';
  }
}

Removechecked_()
{  
  //var strdata=this.openstr+this.selectedcheck.substring(0,this.selectedcheck.length-1)+this.closedtr;
 
  const com=",";
   this.deletelist="";
   var records=0;
        for(var i=0;i<this.podlist.length;i++)
        {
      if(this.podlist[i].selected=='true')
      {        
          this.multipleselect.push(i);
          this.deletelist+=this.podlist[i].id+com;
          records++;
      }
    }

  if(this.deletelist=="" || this.deletelist=="()")
    {
        swal({
            title: 'Nothing to delete!',
            text: "Please select at least one record!",
            type: 'warning',
        })
    }
    else{
    swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {

          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts=this.multipleselect.length;
          for (var i = this.multipleselect.length -1; i >= 0; i--)
          {
            this.podlist.splice(this.multipleselect[i],1);
            this.podlist.sort();
          }
          
          this.deletelist=this.deletelist.substring(0,this.deletelist.length-1);
          this.deletepod();
          this.multipleselect=[];
        }
      })
    }
  }

deletepod(){
  const dcobject={
    checkeddata:this.deletelist
  }
  this.tps.deletePod(dcobject).subscribe(
    data=>this.callafterdel(data), error1 => console.log(error1));
    this.deletedpodnos="";
  }

callafterdel(data){
  this.deletestr=data;
this.buildpodno(this.deletestr); 
   if(this.deletedpodnos=="" || this.deletedpodnos=="()"){
       
       swal('success', "POD Deleted Successfully",'success' );
       $('#products-table').dataTable().fnClearTable();
   }
   else{        
       swal('warning',"Can not delete these Pods " +this.deletedpodnos + " already used".toString(),'warning' );
   $('#products-table').dataTable().fnClearTable();
   }
   this.deletedpodnos="";
   this.getpodlist();
}

buildpodno(data){
  for(var i=0;i<data.length;i++){
      if(data[i].status=="1")
      {
        this.deletedpodnos=this.deletedpodnos+data[i].id+",";
       
    }
  }
}

getdcmasterdata(obj)
{
  this.tps.getDcData(obj).subscribe(
    data => this.calldcafterdata(data)
     
);

}

calldcafterdata(data){

  localStorage.setItem("dcmasterdata",JSON.stringify(data));
 localStorage.setItem("dcmasterdatastate","1");
  this.route.navigate(['/Transactions/dc']);

}

displaybranches() {

  this.tps.getBranchlist().subscribe(
      data =>
         this.Afterbranches(data)
  );



}

Afterbranches(data){
  this.branches = data;
  this.branch=localStorage.getItem("branch");
this.getpodlist();

}

onchange(){

  localStorage.setItem("branch",this.branch);

  this.getpodlist();
}

getpoddata(data){

const obj={

id:data

}

this.tps.getPODObject(obj).subscribe(

data=>this.afterpoddata(data)
)

}
afterpoddata(data){
  this.podobject=data
  
  localStorage.setItem("poddata",JSON.stringify(this.podobject),);
  localStorage.setItem("poddatastate","1");
  this.route.navigate(['Transactions/PODUpload']);
}

downloadfile(data){

  window.open(path+data+".JPEG");
}
}
