import {NgModule ,Pipe} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import { LayoutComponent } from './/layout.component';
import { AppHeader } from './/app-header/app-header.component';
import { AppFooter } from './/app-footer/app-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';


@NgModule({
	declarations: [
	  LayoutComponent,
	  AppHeader,
	  AppFooter
	],
	exports: [
	  LayoutComponent,
	  AppHeader,
	  AppFooter,
	],
	imports: [
		RouterModule,
		BrowserModule,
		CommonModule,
    FormsModule,
		ReactiveFormsModule,
		SweetAlert2Module.forRoot(),
	]
})
export class LayoutModule {
}