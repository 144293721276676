import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { Dataservices } from '../../Services/dataservices.service';
import { lrmaster } from '../../Models/lrmaster';
import { lrdata } from '../../Models/lrdata';
import { checkdata } from '../../Models/checkdata';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
const path = "https://filesosl.cloudbin.net/zips/"

declare var $: any;

@Component({
    selector: 'app-products-list',
    templateUrl: './lr-list.component.html',
    providers: [TpserviceService, Dataservices]
})
export class LrlistComponent implements OnInit, AfterViewInit {
    deletedlrnos: string = "";
    permissions: permissions;
    grant_edit: any;
    branch: string;
    pdfurl: string = "";
    branchlist: any = "";
    str: string = "";
    deletestr: deleteBo[] = [];
    checkdata: checkdata;
    pageid: any = "1";
    public pdflrdata: string = "";
    public lrdata: lrdata = null;
    public lrlist: lrmaster[] = [];
    public deletelist: String = "";
    public multipleselect: Array<any> = [];
    public checkboxesall: boolean;
    public pdfdata: string = "";
    public firstrowno: any;
    public lastrowno: any;
    public checkbox_: boolean = false;
    public lrbilldata: Array<any> = [];
    disablestate: any;
    emailarray: string = "";
    email: string = "";
    id: string = "";
    lr_no:string = "";
    emails: Array<{
        to: string;
    }>;
    text: string = "";
    location:string;
    last: any;
    totalcount: any;
    search: any = "";
    customer_search:any="";
    customerList:any;

    constructor(private tps: TpserviceService, private dts: Dataservices, private route: Router, private changedetect: ChangeDetectorRef) { }

    ngOnInit() {
        this.permissions = JSON.parse(localStorage.getItem("permissions"));
        this.grant_edit = +this.permissions.TLRE;
        
        this.displaybranches();
        this.branch = localStorage.getItem("branch");
        console.log(" this.branch"+ this.branch);
        this.disablestate = localStorage.getItem("disablestate");
        console.log("this.disablestate"+this.disablestate);
        this.displaylr();
    }

    ngAfterViewInit() {
        var self = this;
        $('#compose-box').validate({
            rules: {
                cemail: {
                    required: !0
                },
            },
            errorClass: 'help-block error',
            highlight: function (e) {
                $(e).closest('.form-group.row').addClass('has-error');
            },
            unhighlight: function (e) {
                $(e).closest('.form-group.row').removeClass('has-error');
            },
            submitHandler: function (form) {
                self.onsubmit();
            }
        });

        $(document).ready(function () {
            $("#branch").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#branch").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.branch = id;
            self.displaylr();
        });

        $(document).ready(function () {
            $("#customer_search").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#customer_search").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.customer_search = id;
        });

        $(document).ready(function () {
            $("#email").select2({
                theme: "classic",
                allowClear: true,
                tags: true,
                width: 300,
                tokenSeparators: [','],
                dropdownParent: $('#compose-box')
            });
        });

        $("#email").on('select2:select', function (evt) {
            self.emailarray = ($("#email").val());
        });
    }

    displaybranches() {
        this.tps.getBranch().subscribe(
            data => this.afterGetBranches(data)
        );

    }
    afterGetBranches(data){
        this.branchlist = data;
        this.branch = localStorage.getItem("branch");
        this.displayCustomers();
    }
    displayCustomers() {
        const obj = {
          checkeddata: this.branch
        }
        this.tps.getCustomer(obj).subscribe(
          data => this.aftergetCustomers(data)
        );
      }
    
      aftergetCustomers(data) {
        this.customerList = data;
        
      }


    displaylr() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
        });
        swal.showLoading();
        (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
        this.pageid = 1;
        const obj = {
            checkeddata: this.branch,
            checkeddata1: this.pageid
        }
        this.tps.getLrmaster(obj).subscribe(
            data => this.getdata1(data)
        );
    }

    getdata1(data) {
        swal.close();
        $("#products-table").dataTable().fnDestroy();
        this.lrlist = data
        if(this.lrlist.length == 0){
            this.last = 0;
        this.totalcount = null;
        this.firstrowno = 0;
        this.lastrowno = 0;
        }else{
        this.last = this.lrlist[0].tot_count;
        this.totalcount = this.lrlist[0].tot_row_count;
        this.firstrowno = this.lrlist[0].row_count;
        this.lastrowno = this.lrlist[this.lrlist.length - 1].row_count;
    }
        this.changedetect.detectChanges();
        $('#products-table').DataTable({
            pageLength: 20,
            //pagingType: 'full_numbers',
            bPaginate: false,
            //fixedHeader: true,
            fixedHeader: false,

            responsive: true, 
            ordering: false,
            bInfo: false,
            'sDom': 'rtip',
            // 'sDom': 'lfrti, rtip',
            // 'dom': 'lfrti', 
        });
        const table = $('#products-table').DataTable();
        // $('#key-search').on('keyup', function () {
        //     table.search(this.value).draw();
        // });
        $('#type-filter').on('change', function () {
            table.column(2).search($(this).val()).draw();
        });
    }

    // displaylr() {
    //     localStorage.setItem("branch", this.branch);
    //     const obj = {
    //         checkeddata: this.branch
    //     }
    //     swal({
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         width: 100,
    //         heightAuto: true,
    //         background: "rgba(0, 0, 0, .1)",
    //       });
    //     swal.showLoading();
    //     this.tps.getLrmaster(obj).subscribe(
    //         data => this.getdata1(data)
    //     );
    // }

    // getdata1(data) {
    //     $("#products-table").dataTable().fnDestroy();
    //     this.lrlist = data;
    //     swal.close();
    //     this.changedetect.detectChanges();
    //     $('#products-table').DataTable({
    //         pageLength: 20,
    //         //pagingType: 'full_numbers',
    //         fixedHeader: true,
    //         responsive: true,
    //         ordering: false,
    //         'sDom': 'rtip',
    //     });
    //     const table = $('#products-table').DataTable();
    //     $('#key-search').on('keyup', function () {
    //         table.search(this.value).draw();
    //     });
    //     $('#type-filter').on('change', function () {
    //         table.column(2).search($(this).val()).draw();
    //     });

    // }

    checklrupdate(obj) {
        this.tps.checklrupdate(obj).subscribe(
            data => this.getlrdata(obj, data)
        );

    }

    getlrdata(obj, data1) {
        this.tps.getLrData(obj).subscribe(
            data => this.callafterdata(data, data1)

        );
    }

    callafterdata(data, data1) {

        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("datastate", "1");
        this.route.navigate(['/Transactions/lr']);
        // if (data1.messasge == -1) {
        //     localStorage.setItem("data", JSON.stringify(data));
        //     localStorage.setItem("datastate", "1");
        //     this.route.navigate(['/Transactions/lr']);
        // }
        // else {
        //     swal({
        //         title: 'Can not edit this LR',
        //         text: "Lr already used in " + data1.messasge + " DC",
        //         type: 'warning',
        //     })
        // }
    }

    Deleteall(index) {
        this.lrlist.splice(index, 1);
        //console.log(this.lrlist);
    }

    prev() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
        });
        swal.showLoading();
        (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
        if (this.pageid > 1) {
            this.pageid = this.pageid - 1;
            (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
            (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
        }
        else {
            this.pageid = 1;
            (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
            (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
        }


        if(this.search == "" && this.customer_search == ""){
           const obj = {
            checkeddata: this.branch,
            checkeddata1: this.pageid
        }
        this.tps.getLrmaster(obj).subscribe(
            data => this.getdata1(data)
        );
            // swal('Alert','Please select search parameter first!!!','warning');
        }
        else{
            const obj ={
                lr_no: this.search,
                branch:this.branch,
                customer_id:this.customer_search,
                page_id: this.pageid 
            } 
            //console.log(obj)
            swal({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                width: 100,
                heightAuto: true,
                background: "rgba(0, 0, 0, .1)",
            });
            swal.showLoading();
            this.tps.Lrsearch(obj).subscribe(
                data => this.getdata1(data)
            );
        }
    }

    next() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
        });
        swal.showLoading();
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
        if (this.pageid == this.last) {
            (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
            (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
        }
        else {
            this.pageid = this.pageid + 1;
        }
        if(this.search == "" && this.customer_search == ""){
            const obj = {
             checkeddata: this.branch,
             checkeddata1: this.pageid
         }
         this.tps.getLrmaster(obj).subscribe(
             data => this.getdata1(data)
         );
             // swal('Alert','Please select search parameter first!!!','warning');
         }
         else{
             const obj ={
                 lr_no: this.search,
                 branch:this.branch,
                 customer_id:this.customer_search,
                 page_id: this.pageid 
             } 
             //console.log(obj)
             swal({
                 allowOutsideClick: false,
                 allowEscapeKey: false,
                 allowEnterKey: false,
                 width: 100,
                 heightAuto: true,
                 background: "rgba(0, 0, 0, .1)",
             });
             swal.showLoading();
             this.tps.Lrsearch(obj).subscribe(
                 data => this.getdata1(data)
             );
         }
    }

    input() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
        });
        swal.showLoading();
        if (this.pageid <= 0 || this.pageid == null || this.pageid == 1) {
            this.pageid = 1;
            (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
            (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
        }
        else {
            (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
            (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
        }
        if (this.pageid == this.last || this.pageid > this.last) {
            this.pageid = this.last;
            (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
            (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
        }
        else {
            (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
            (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
        }
        if(this.search == "" && this.customer_search == ""){
            const obj = {
             checkeddata: this.branch,
             checkeddata1: this.pageid
         }
         this.tps.getLrmaster(obj).subscribe(
             data => this.getdata1(data)
         );
             // swal('Alert','Please select search parameter first!!!','warning');
         }
         else{
             const obj ={
                 lr_no: this.search,
                 branch:this.branch,
                 customer_id:this.customer_search,
                 page_id: this.pageid 
             } 
             //console.log(obj)
             swal({
                 allowOutsideClick: false,
                 allowEscapeKey: false,
                 allowEnterKey: false,
                 width: 100,
                 heightAuto: true,
                 background: "rgba(0, 0, 0, .1)",
             });
             swal.showLoading();
             this.tps.Lrsearch(obj).subscribe(
                 data => this.getdata1(data)
             );
         }
    }

    lastpage() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
        });
        swal.showLoading();
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
        this.pageid = this.last;
        if (this.pageid == this.last) {
            (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
            (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
        }
        if(this.search == "" && this.customer_search == ""){
            const obj = {
             checkeddata: this.branch,
             checkeddata1: this.pageid
         }
         this.tps.getLrmaster(obj).subscribe(
             data => this.getdata1(data)
         );
             // swal('Alert','Please select search parameter first!!!','warning');
         }
         else{
             const obj ={
                 lr_no: this.search,
                 branch:this.branch,
                 customer_id:this.customer_search,
                 page_id: this.pageid 
             } 
             //console.log(obj)
             swal({
                 allowOutsideClick: false,
                 allowEscapeKey: false,
                 allowEnterKey: false,
                 width: 100,
                 heightAuto: true,
                 background: "rgba(0, 0, 0, .1)",
             });
             swal.showLoading();
             this.tps.Lrsearch(obj).subscribe(
                 data => this.getdata1(data)
             );
         }
    }

    lrsearch(){
        if(this.search == "" && this.customer_search == ""){
            this.displaylr();
            // swal('Alert','Please select search parameter first!!!','warning');
        }
        else{
            this.pageid = 1;
            const obj ={
                lr_no: this.search,
                branch:this.branch,
                customer_id:this.customer_search,
                page_id: this.pageid 
            } 
            //console.log(obj)
            swal({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                width: 100,
                heightAuto: true,
                background: "rgba(0, 0, 0, .1)",
            });
            swal.showLoading();
            this.tps.Lrsearch(obj).subscribe(
                data => this.getdata1(data)
            );
        }
    }

    Multiselect(index, ev) {
        if (this.lrlist[index].selected == 'false') {

            this.lrlist[index].selected = 'true';
        }
        else {
            this.lrlist[index].selected = 'false';
        }

    }

    Removechecked_() {
        const com = ",";
        this.deletelist = "";
        var records = 0;
        for (var i = 0; i < this.lrlist.length; i++) {
            if (this.lrlist[i].selected == 'true') {
                this.multipleselect.push(i);
                this.deletelist += this.lrlist[i].lrid + com;
                records++;
            }
        }

        if (this.deletelist == "" || this.deletelist == "()") {
            swal({
                title: 'Nothing to delete!',
                text: "Please select at least one record!",
                type: 'warning',
            })
        }
        else {
            swal({
                title: 'Are you sure?',
                text: "Selected " + records + ".You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
                    var lenghts = this.multipleselect.length;
                    for (var i = this.multipleselect.length - 1; i >= 0; i--) {
                        this.lrlist.splice(this.multipleselect[i], 1);
                        this.lrlist.sort();
                    }
                    const open = "("; const close = ")";
                    this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
                    this.deleteLr();
                    this.multipleselect = [];
                }
            })
        }
    }

    delarr: lrmaster[] = [];
    checks(item: lrmaster) {
        if (this.delarr.find(x => x == item)) {
            this.delarr.splice(this.delarr.indexOf(item), 1)
        }
        else {
            this.delarr.push(item);
        }

    }
    i: number = 0;

    lrbillpdfpreview(data) {
        const ob = {
            lrid: data
        }
       // console.log("pdfdatapreview" + this.pdfdata);
        this.tps.getLrBillData(ob).subscribe(
            data => this.callafterpdfdatapreview(data));
    }

    checkPdf(location,id){
        this.location = "";
        this.location = location;
        //console.log(location+','+id);
        const OBJ = {
            checkeddata:id,
            checkeddata1:"lrs"
        }
        this.tps.checkpdf(OBJ).subscribe(
            data => this.afterCheckPdf(data)
        )
    }

    afterCheckPdf(data){
       // console.log(data);
        this.pdfview(this.location);
    }

    pdfview(location) {
        swal({
            //title: 'Download File',
            showCancelButton: true,
            width: '80%',
            showCloseButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',
            preConfirm: () => {
                document.getElementById("download").style.display = 'none';
                return document.getElementById("viewfile");
            }
        })
    }

    checkPrintpdf(location,id){
        this.location = "";
        this.location = location;
        const OBJ = {
            checkeddata:id,
            checkeddata1:"lrs"
        }
        this.tps.checkpdf(OBJ).subscribe(
            data => this.afterCheckPrintPdf(data)
        )
    }
    afterCheckPrintPdf(data){
        this.printpdf(this.location);
    }

    printpdf(location) {
        var url = location;
        url = url.replace("view.pdf", "print.pdf")
        swal({
            //title: 'Download File',
            showCancelButton: true,
            width: '80%',
            showCloseButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            html: '<embed id="viewfile" src="' + url + '" height="500px" width="100%"/>',
            preConfirm: () => {
                document.getElementById("download").style.display = 'none';
                return document.getElementById("viewfile");
            }
        })
    }

    lrbill() {
        if (this.pdflrdata == "") {

            swal({
                title: 'Please select LR!',
                text: "",
                type: 'warning',
            })

        }
        else {
            const ob = {
                checkeddata: this.pdflrdata
            }
            this.tps.makeZip(ob).subscribe(
                data =>
                    this.redirect(data)
            );
            this.pdflrdata = "";
        }
    }

    redirect(data) {
        this.pdfurl = data['messasge'];
        window.open(path + data['messasge'], '_blank');
    }

    public callafterpdfdata() {
        localStorage.setItem("pdfdata", JSON.stringify(this.lrbilldata));
        //console.log(this.lrbilldata);
        this.route.navigate(['/bill/lr']);
    }

    callafterpdfdatapreview(data) {
        this.checkdata = data;
        window.open(this.checkdata.checkeddata);
    }

    print(data) {
        this.str += data["messasge"];
    }

    deleteLr() {
        var lrs = this.deletelist.split(",");
        const obj = {
            selected: this.deletelist
        }
        this.tps.deleteLr(obj).subscribe(
            data => this.callafterdel(data)
            , error1 => console.log(error1))

    }

    callafterdel(data) {
        this.deletestr = data;
        this.buildlrno(this.deletestr);
        if (this.deletedlrnos == "" || this.deletedlrnos == "()") {
            swal('success', "Lr Deleted Successfully", 'success');
            $('#products-table').dataTable().fnClearTable();
        }
        else {
            swal('warning', "can not delete these lrs " + this.deletedlrnos + ", already used".toString(), 'warning');
            $('#products-table').dataTable().fnClearTable();
        }
        this.str = "";
        this.displaylr();
    }

    buildlrno(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].status == "1") {
                this.deletedlrnos = this.deletedlrnos + data[i].id + ",";
            }
        }
    }

    view(obj, obj1, lr_no) {
        $('#getUnit').modal('show');
        this.id = obj;
        this.emails = obj1;
        this.lr_no = lr_no;
    }

    close() {
        $('#getUnit').modal('hide');
        this.clear();
    }

    onsubmit() {
        const obj = {
            id: this.id,
            lr_no:this.lr_no,
            emails: this.emailarray,
            text: this.text,
            type: 'lrs',
        }
        this.tps.SendMail(obj).subscribe(
            data => this.aftersubmit(data)
        );
    }

    aftersubmit(data){
        if(data['messasge']=="1"){
        swal('Success', "Email Sent Successfully!!!", 'success');
        }
        else{
            swal('Error', "Failed!!!", 'error');
        }
        $('#getUnit').modal('hide');
        this.clear();
      }
    
      clear(){
        this.text="";
        $("#email").val('').trigger('change');
      }
}