import { Component, OnInit, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { TpserviceService } from '../../../../Services/tpservice.service';
import swal from "sweetalert2";
import { Router } from '@angular/router';
import { fmtransactions } from '../../../../Models/fmtransactions';
import { fmtabledata } from '../../../../Models/fmtabledata';
import { fmdata } from '../../../../Models/fmdata';
import { DatePipe } from '@angular/common';
import { user } from '../../../../Models/user';
import { permissions } from '../../../../Models/permissions';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './fmline.component.html',
  providers: [TpserviceService, DatePipe]

})

@Pipe({ name: 'search' })
export class FmlineComponent implements OnInit, AfterViewInit, PipeTransform {
  checkflag: boolean = false;

  lrs: any[] = [];
  fmdata: fmdata;
  fmdatastate: string = "Save";
  inwards: any[] = [];
  dctabledata: fmtransactions[] = [];
  sendcallbackfm: string = "";
  selectedcstr: string = "";
  selecteddctrxn: string = "";
  fmtabledata: fmtabledata[] = [];
  com: string = ",";
  openstr: string = "(";
  closestr: string = ")";
  spliter: string = "@@";
  driverdata: any = {};
  placedata: any = {};
  vehiclesdata: any = {};
  branchesdata: any = {};
  callbackdata: string;
  getdate: any;
  lrsearch = '';
  branch: string = "";
  deliverytype: string = "";
  transportmode: string = "";
  memono: string = "";
  memodate: string = "";
  vehicleno: string = "";
  vehicleowner: string = "";
  vehicletype: string = "";
  owneraddress: string = "";
  ownercontact: string = "";
  drivername: string = "";
  licenseno: string = "";
  mobileno: string = "";
  drivername2: string = "";
  licenseno2: string = "";
  mobileno2: string = "";
  fromloc: string = "";
  toloc: string = "";
  memotype: string = "2";
  totalpackages: any = "0";
  totalweight: any = 0;
  totalfreight: any = 0;
  advanceamount: any = 0;
  balanceamount: any = "0";
  vehiclepass: string = "0";
  diesel: string = "0";
  othercharges: any = "0";
  deliverycharges: any = "0";
  mathadi: string = "0";
  seal: string = "0";
  advanceref: string = "";
  parkingcharges: any = "0";
  remark: string = "";
  disablestate: any;
  query: any;
  userdata: user;
  user: string = "";
  permissions: permissions;
  Oprint: any = "";
  value: any = "";
  memoid: any = "";

  constructor(private tps: TpserviceService, private route: Router, private datepipe: DatePipe) { }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.user = this.userdata.id;
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.Oprint = this.permissions.Oprint;

    if (localStorage.getItem("fmmasterdatastate") == "1") {
      this.fmdatastate = "Update";
      this.displaydfmdata();
      this.displaydc();
      localStorage.removeItem("fmmasterdatastate");
    }
    this.getbranch();
    this.disablestate = localStorage.getItem("disablestate");
    this.getdate = Date.now();
  }

  ngAfterViewInit() {
    var self = this;
    this.memodate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    $('#form-sample-1').validate({
      rules: {
        memono: {

          required: !0
        },
        memodate: {

          required: !0,

        },

        vehicleno: {
          required: !0,

        },
        vehicleowner: {
          required: !0,

        },
        drivername: {
          required: !0,

        },
        licenseno: {
          required: !0,

        },
        mobileno: {
          required: !0,
          minlength: 10,

        },

        fromloc: {
          required: !0,

        },
        toloc: {
          required: !0,

        },
        memotype: {
          required: !0,

        },
        totalpackages: {
          required: !0,

        },
        totalweight: {
          required: !0,

        },
        totalfreight: {
          required: !0
        },
        deliverycharges: {
          required: !0,

        },
        mathadi: {
          required: !0,

        },
        remark: {
          required: !0,

        }

      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmitfm();
      }
    });

    var flag = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && flag == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        flag = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      flag = false;
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
    });

    $(document).ready(function () {
      $("#toloc").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#toloc").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.toloc = id;
    });

  }

  call(data) {
    this.value = data;
  }

  onbranch() {
    let index = this.branchesdata.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branchesdata[index].placeid;
  }

  calculate() {
    this.balanceamount = +this.totalfreight - +this.advanceamount;
  }

  public transform(value, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
  }

  getbranch() {
    this.tps.getBranch().subscribe(
      data =>
        this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branchesdata = data;
    this.branch = localStorage.getItem("branch");
    this.generatefmno();
    this.onbranch();
    this.getformdata();
    this.displaydc();
  }

  afterplace(data) {
    this.placedata = data
    this.onbranch();
    this.displaydc();
  }

  getformdata() {
    const ob = {
      checkeddata: this.branch
    }
    this.tps.getVehicle(ob).subscribe(
      data => this.vehiclesdata = data
      , error => swal('Please check your internet connection', error, 'error')
      );
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getDrivers(obj).subscribe(
      data => this.driverdata = data
      , error => swal('Please check your internet connection', error, 'error')
      );
      this.tps.getPlace().subscribe(
        data =>
        this.afterplace(data), error => swal('Please check your internet connection', error, 'error')
        );
        const fmbr = {
          checkeddata1: 2,
          checkeddata: this.branch
        }
        this.tps.getDclistData(fmbr).subscribe(
          data =>this.dctabledata = data
          );
        }

        displaydc() {
          const obj = {
            id: this.memono,
            type: 2
          }
          this.tps.getDCforfmupdate(obj).subscribe(
            data => this.dctabledata = data
            );
          }

  onsubmitfm() {
    if (this.selectedcstr == "()" || this.selectedcstr == "") {
      swal('warning', 'Please choose atleast one DC', 'warning');
    } else {
      if (this.fmdatastate == "Update") {
        this.updatefmmaster();

      }
      else {
        var datatb = this.openstr + this.selectedcstr.substr(0, this.selectedcstr.length - 1) + this.closestr;
        var lrdatatb = this.selecteddctrxn.substr(0, this.selecteddctrxn.length - 1);
        let fmobj = {

          memo_no: this.memono,
          memo_date: this.memodate,
          vehicle_number: this.vehicleno,
          vehicle_owner: this.vehicleowner,
          vehicle_type: this.vehicletype,
          owner_address: this.owneraddress,
          owner_contact: this.ownercontact,
          driver_name: this.drivername,
          license_name: this.licenseno,
          mobile_no: this.mobileno,
          driver_name2: this.drivername2,
          license_name2: this.licenseno2,
          mobile_no2: this.mobileno2,
          from_loc: this.fromloc,
          to_loc: this.toloc,
          total_packages: this.totalpackages,
          total_weight: this.totalweight,
          total_freight: this.totalfreight,
          advance_amount: this.advanceamount,
          balance_amount: this.balanceamount,
          vehicle_pass: this.vehiclepass,
          diesel: this.diesel,
          other_charges: this.othercharges,
          delivery_charges: this.deliverycharges,
          mathadi: this.mathadi,
          seal: this.seal,
          advance_ref: this.advanceref,
          parking_charges: this.parkingcharges,
          fm_type: this.memotype,
          user_id: this.user,
          branch: this.branch,
          remarks: this.remark,
          dcdata: datatb,
          fmdata: lrdatatb
        }
        swal({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          width: 150
        });
        swal.showLoading();
        if (this.value == 1) {
          this.tps.addFM(fmobj).subscribe(res => this.aftersave(res)
            , error1 => swal('Error', error1, 'error'));
        }
        else {
          this.tps.addFM(fmobj).subscribe(res => this.aftersave1(res)
            , error1 => swal('Error', error1, 'error'));
        }
      }
    }
  }

  aftersave(data) {
    this.memoid = data['res_code'];
    swal('Success', data['messasge'], 'success');
    //this.route.navigate(['/pages/Fm-List']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("oprint")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  aftersave1(data) {
    this.memoid = data['res_code'];
    swal('Success', data['messasge'], 'success');
    //this.route.navigate(['/pages/Fm-List']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("oprint")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
    this.pdfredirect(1);
  }

  onchangevehicle(e) {
    let index = this.vehiclesdata.findIndex(datas => datas.vehicle_id == e.target.value);
    this.vehicleowner = this.vehiclesdata[index].vehical_owner_name;
    this.vehicletype = this.vehiclesdata[index].vehicle_type;
    this.owneraddress = this.vehiclesdata[index].address;
    this.ownercontact = this.vehiclesdata[index].telephoneno;
  }

  changedata() {
    localStorage.setItem("branch", this.branch);
    this.onbranch();
    this.getformdata();
  }

  onchangeselect(e) {
    let index = this.driverdata.findIndex(datas => datas.driver_id == e.target.value);//=JSON.parse(e.target.value);
    this.licenseno = this.driverdata[index].licenseno;
    this.mobileno = this.driverdata[index].mobileno;
  }

  checkclick(data, e) {
    if (e.target.checked) {
      var temp = this.selectedcstr.replace(data + this.com, "");
      var tmp1 = this.selecteddctrxn.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "")
      this.selectedcstr = temp;
      this.selecteddctrxn = tmp1;
      this.selectedcstr += data + this.com;
      this.selecteddctrxn += this.openstr + data + this.com + "@@" + this.closestr + this.com;
    }
    else {

      var temp1 = this.selectedcstr.replace(data + this.com, "");
      var temp = this.selecteddctrxn.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "");
      this.selectedcstr = temp1;
      this.selecteddctrxn = temp;

    }
    console.log(this.selectedcstr);
    console.log(this.selecteddctrxn);
  }

  insertabledata() {
    let datatb = this.openstr + this.selectedcstr.substr(0, this.selectedcstr.length - 1) + this.closestr;
    console.log("data is_" + datatb);
    const obj = {
      checkeddata: datatb
    }
    this.tps.getFmtabledata(obj).subscribe(
      data =>
        this.afterfmtabledata(data),
      error =>
        swal('Please check your internet connection', error, 'error')
    );
  }

  afterfmtabledata(data) {
    this.fmtabledata = data;
    this.vehicleno = this.fmtabledata[0].vehicle_number;
    this.drivername = this.fmtabledata[0].driver_name;
    this.licenseno = this.fmtabledata[0].license_name;
    this.mobileno = this.fmtabledata[0].mobile_no;
    this.drivername2 = this.fmtabledata[0].driver_name2;
    this.licenseno2 = this.fmtabledata[0].license_name2;
    this.mobileno2 = this.fmtabledata[0].mobile_no2;
    let index = this.branchesdata.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branchesdata[index].placeid;
    this.toloc = this.fmtabledata[0].to_locid;
    this.deliverytype = this.fmtabledata[0].delivery_type;
    this.transportmode = this.fmtabledata[0].transportmode;
    this.onchangevehicleafterdata();
    var totalwt = 0;
    var totalpck = 0;
    this.fmtabledata.forEach(function (element) {
      totalpck = totalpck + +element.no_of_articles;
      totalwt = totalwt + +element.char_wt;
    })
    this.totalpackages = totalpck;
    this.totalweight = totalwt;
  }

  onchangevehicleafterdata() {
    let index = this.vehiclesdata.findIndex(datas => datas.vehicle_id == this.vehicleno);
    this.vehicleowner = this.vehiclesdata[index].vehical_owner_name;
    this.vehicletype = this.vehiclesdata[index].vehicle_type;
    this.owneraddress = this.vehiclesdata[index].address;
    this.ownercontact = this.vehiclesdata[index].telephoneno;
  }

  displaydfmdata() {
    this.fmdata = JSON.parse(localStorage.getItem("fmmasterdata"));
    localStorage.removeItem("fmmasterdata");
    this.checkflag = true;
    this.memoid = this.fmdata.fmbo.fm_id,
    this.memono = this.fmdata.fmbo.memo_no,
    this.memodate = this.fmdata.fmbo.memo_date,
    this.vehicleno = this.fmdata.fmbo.vehicle_number,
    this.vehicleowner = this.fmdata.fmbo.vehicle_owner,
    this.vehicletype = this.fmdata.fmbo.vehicle_type,
    this.owneraddress = this.fmdata.fmbo.owner_address,
    this.ownercontact = this.fmdata.fmbo.owner_contact,
    this.drivername = this.fmdata.fmbo.driver_name,
    this.licenseno = this.fmdata.fmbo.license_name,
    this.mobileno = this.fmdata.fmbo.mobile_no,
    this.drivername2 = this.fmdata.fmbo.driver_name2,
    this.licenseno2 = this.fmdata.fmbo.license_name2,
    this.mobileno2 = this.fmdata.fmbo.mobile_no2,
    this.fromloc = this.fmdata.fmbo.from_loc,
    this.toloc = this.fmdata.fmbo.to_loc,
    this.totalpackages = this.fmdata.fmbo.total_packages,
    this.totalweight = this.fmdata.fmbo.total_weight,
    this.totalfreight = this.fmdata.fmbo.total_freight,
    this.advanceamount = this.fmdata.fmbo.advance_amount,
    this.balanceamount = this.fmdata.fmbo.balance_amount,
    this.vehiclepass = this.fmdata.fmbo.vehicle_pass,
    this.diesel = this.fmdata.fmbo.diesel,
    this.othercharges = this.fmdata.fmbo.other_charges,
    this.deliverycharges = this.fmdata.fmbo.delivery_charges,
    this.mathadi = this.fmdata.fmbo.mathadi,
    this.seal = this.fmdata.fmbo.seal,
    this.advanceref = this.fmdata.fmbo.advance_ref,
    this.parkingcharges = this.fmdata.fmbo.parking_charges,
    this.memotype = this.fmdata.fmbo.fm_type,
    this.branch = this.fmdata.fmbo.branch,
    this.remark = this.fmdata.fmbo.remarks,
    this.deliverytype = this.fmdata.fmmastertabledataBO[0].delivery_type;
    this.transportmode = this.fmdata.fmmastertabledataBO[0].transportmode;
    this.fmtabledata = this.fmdata.fmmastertabledataBO;
    this.splitdata();
  }



  splitdata() {
    let strdata: string = "";
    let strdatalr: string = "";
    this.fmtabledata.forEach(function (element) {
      if (!strdata.includes(element.dc_id.toString())) {
        strdata += element.dc_id.toString() + ",";
        strdatalr += "(" + element.dc_id.toString() + "," + "@@),"
      }
    })
    this.selectedcstr = strdata;
    this.sendcallbackfm = this.openstr + strdata.substr(0, strdata.length - 1) + this.closestr;
    this.selecteddctrxn = strdatalr;

  }

  checkdata(obj): boolean {
    if (this.fmtabledata.some(e => e.dc_id == obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  updatefmmaster() {
    if (this.selectedcstr == "()") {
      swal('warning', 'warning'), error => swal('Please choose atleast one DC', error, 'warning')
    }
    else {
      var datatbupdate = this.openstr + this.selectedcstr.substr(0, this.selectedcstr.length - 1) + this.closestr;
      var fmdatatbupdate = this.selecteddctrxn.substr(0, this.selecteddctrxn.length - 1);
      console.log("data is_" + datatbupdate);
      console.log("lrdata is" + fmdatatbupdate);
      const dcobj = {
        fm_id: this.fmdata.fmbo.fm_id,
        memo_no: this.memono,
        memo_date: this.memodate,
        vehicle_number: this.vehicleno,
        vehicle_owner: this.vehicleowner,
        vehicle_type: this.vehicletype,
        owner_address: this.owneraddress,
        owner_contact: this.ownercontact,
        driver_name: this.drivername,
        license_name: this.licenseno,
        mobile_no: this.mobileno,
        driver_name2: this.drivername2,
        license_name2: this.licenseno2,
        mobile_no2: this.mobileno2,
        from_loc: this.fromloc,
        to_loc: this.toloc,
        total_packages: this.totalpackages,
        total_weight: this.totalweight,
        total_freight: this.totalfreight,
        advance_amount: this.advanceamount,
        balance_amount: this.balanceamount,
        vehicle_pass: this.vehiclepass,
        diesel: this.diesel,
        other_charges: this.othercharges,
        delivery_charges: this.deliverycharges,
        mathadi: this.mathadi,
        seal: this.seal,
        advance_ref: this.advanceref,
        parking_charges: this.parkingcharges,
        fm_type: this.memotype,
        user_id: this.user,
        branch: this.branch,
        remarks: this.remark,
        dcdata: datatbupdate,
        fmdata: fmdatatbupdate,
        fmcallback: this.sendcallbackfm

      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 150
      });
      swal.showLoading();
      if (this.value == 1) {
        this.tps.updateFM(dcobj).subscribe(
          data => this.afterupdate(data)
        );
      }
      else {
        this.tps.updateFM(dcobj).subscribe(
          data => this.afterupdate1(data)
        );
      }
    }
  }

  generatefmno() {
    if (!(this.fmdatastate == "Update")) {
      const obj = {
        checkeddata: this.branch
      }

      this.tps.getFmno(obj).subscribe(
        data => this.memono = data['messasge']
      );
    }
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error');
    //this.route.navigate(['pages/Fmlinelist']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("oprint")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  afterupdate1(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error');
    //this.route.navigate(['pages/Fmlinelist']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("oprint")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
    this.pdfredirect(1);
  }

  pdfredirect(val) {

    const ob = {
      id: this.memoid,
      type: 'fm_master',

    }
    this.tps.viewpdf(ob).subscribe(
      data => this.afterpdf(data, val)
      , error => swal('Please check your internet connection', error, 'error')
    );
  }

  afterpdf(data, val) {
    var url = data["location"];
    if (val == "0" || val == undefined) {
      url = url.replace(".pdf", "Dup.pdf")
    }
    if (this.memono == "") {

      swal('warning', 'Please add Memo first!', 'warning')
    }
    else {

      swal({
        //title: 'Download File',
        showCancelButton: true,
        width: '80%',
        showCloseButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        html: '<embed id="viewfile" src="' + url + '" height="500px" width="100%"/>',
        preConfirm: () => {
          return document.getElementById("viewfile");
        }
      })
      //       alert(this.lr_no);
      //    // window.open('  bills/2018/13144.pdf', '_blank');
      //     window.open(pdfurl+this.lr_no+'.pdf');
    }
  }

  redirect() {
    this.route.navigate(['pages/Fmlinelist']);
  }
}