import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-badges-progress',
  templateUrl: './badges-progress.component.html',
})
export class BadgesProgressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
