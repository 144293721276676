import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
import { Branch } from '../../Models/Branch';
import { loadingtripsheet } from '../../Models/loadingtipsheet';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { vehicle } from '../../Models/vehicle';

declare var $: any;

@Component({
  selector: 'app-loading-trip-list',
  templateUrl: './loading-trip-list.component.html',
  styleUrls: ['./loading-trip-list.component.css'],
  providers: [TpserviceService]
})
export class LoadingTripListComponent implements OnInit, AfterViewInit {

  permissions: permissions;
  grant_edit: any;
  branch: string = "";
  branches: Branch;
  pdfurl: string = "";
  branchlist: any = "";
  str: string = "";
  deletestr: deleteBo[] = [];
  multipleselect: Array<any> = [];
  deletelist: string = "";
  checkboxesall: boolean;
  pdfdata: string = "";
  checkbox_: boolean = false;
  lrbilldata: Array<any> = [];
  disablestate: any;
  loadingsheetlist: loadingtripsheet[];
  deletedlsnos: string;
  emailarray: string = "";
  email: string = "";
  id: string = "";
  lsno: string = "";
  vehicle_no: string = "";
  emails: Array<{
    to: string;
  }>;
  text: string = "";
  location: string;
  memo_no:any="";
  vehicle_id:any="";
  public firstrowno: any;
  public lastrowno: any;
  last: any;
  totalcount: any;
  vehicles: vehicle[] = [];
  public pageid: any = 1;
  public search: any = "";

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.TLRE;
    this.displaybranches();
    this.branch = localStorage.getItem('branch');
    this.getloadingtriplist();
  }

  ngAfterViewInit() {
    var self = this;
    $('#compose-box').validate({
      rules: {
        cemail: {
          required: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.getloadingtriplist();
    });

    $(document).ready(function () {
      $("#vehicle_id").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#vehicle_id").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.vehicle_id = id;
    });

    $(document).ready(function () {
      $("#email").select2({
        theme: "classic",
        allowClear: true,
        tags: true,
        width: 300,
        tokenSeparators: [','],
        dropdownParent: $('#compose-box')
      });
    });

    $("#email").on('select2:select', function (evt) {
      self.emailarray = ($("#email").val());
    });
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
    this.getvehicle();
  }
  
  getvehicle() {
    const ob = {
      checkeddata: this.branch
    }
    
    this.tps.getVehicle(ob).subscribe(
      data => this.afterGetVehicle(data)
    );
  }

  afterGetVehicle(data){
    this.vehicles = data;
  }

  getloadingtriplist() {
    localStorage.setItem("branch", this.branch);
    this.pageid = 1;
    const obj = {
      checkeddata: this.branch,
      checkeddata1: this.pageid,
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    }); 
    swal.showLoading();
    this.tps.getLoadingtriplistPage(obj).subscribe(
      data => this.gettabledata(data)
    );
  }
  
  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.loadingsheetlist = data;
    swal.close();
    if(this.loadingsheetlist.length == 0)
    {
      this.last = 0;
    this.totalcount = null
    this.firstrowno = 0;
    this.lastrowno = 0;
    }
    else
    {
      this.last = this.loadingsheetlist[0].tot_count;
    this.totalcount = this.loadingsheetlist[0].tot_row_count;
    this.firstrowno = this.loadingsheetlist[0].row_count;
    this.lastrowno = this.loadingsheetlist[this.loadingsheetlist.length - 1].row_count;

    }
    
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      bPaginate: false,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      bInfo: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  getloadingsheetdata(obj) {
    const ob = {
      id: obj
    }
    this.tps.getloadingsheet(ob).subscribe(
      data => this.calldcafterdata(data)
    );
  }

  calldcafterdata(data) {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("datastate", "1");
    this.route.navigate(['/Transactions/loading_trip']);
  }

  Multiselect(index, ev) {
    if (this.loadingsheetlist[index].selected == 'true') {
      this.loadingsheetlist[index].selected = 'false';
    }
    else {
      this.loadingsheetlist[index].selected = 'true';
    }
  }

  Removechecked_() {
    const com = ",";
    this.deletelist = "";
    var records = 0;
    for (var i = 0; i < this.loadingsheetlist.length; i++) {
      if (this.loadingsheetlist[i].selected == 'true') {
        this.multipleselect.push(i);
        this.deletelist += this.loadingsheetlist[i].dc_id + com;
        records++;
      }
    }
    if (this.deletelist == "" || this.deletelist == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts = this.multipleselect.length;
          for (var i = this.multipleselect.length - 1; i >= 0; i--) {
            this.loadingsheetlist.splice(this.multipleselect[i], 1);
            this.loadingsheetlist.sort();
          }
          this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
          this.deleteLS();
          this.multipleselect = [];
        }
      })
    }
  }

  deleteLS() {
    const dcobject = {
      selected: this.deletelist
    }
    this.tps.deleteLS(dcobject).subscribe(
      data => this.callafterdel(data), error1 => console.log(error1));
  }

  callafterdel(data) {
    swal('success', data['messasge'], 'success');
    $('#products-table').dataTable().fnClearTable();
    this.getloadingtriplist();
  }

  checkPdf(location, id) {
    this.location = "";
    this.location = location;
    // console.log(location+','+id);
    const OBJ = {
      checkeddata: id,
      checkeddata1: "dcs"
    }
    this.tps.checkpdf(OBJ).subscribe(
      data => this.afterCheckPdf(data)
    )
  }

  afterCheckPdf(data) {
    this.pdfview(this.location);
  }

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',

      preConfirm: () => {
        document.getElementById("download").style.display = 'none';
        return document.getElementById("viewfile");
      }
    })
  }

  sendMail(obj, obj1, lsno, vehicle_no) {
    $('#getUnit').modal('show');
    this.id = obj;
    this.emails = obj1;
    this.lsno = lsno;
    this.vehicle_no = vehicle_no;
    // console.log(this.lsno,this.vehicle_no);
  }

  onsubmit() {
    const obj = {
      id: this.id,
      ls_no: this.lsno,
      vehicle_no: this.vehicle_no,
      emails: this.emailarray,
      text: this.text,
      type: 'DC',
    }
    this.tps.SendMail(obj).subscribe(
      data => this.aftersubmit(data)
    );
  }

  aftersubmit(data) {
    if (data['messasge'] == "1") {
      swal('Success', "Email Sent Successfully!!!", 'success');
    }
    else {
      swal('Error', "Failed!!!", 'error');
    }
    $('#getUnit').modal('hide');
    this.clear();
  }

  clear() {
    this.text = "";
    this.lsno = "";
    this.vehicle_no = "";
    this.email = "";
    this.emailarray = "";
    $("#email").val('').trigger('change');
  }

  close() {
    $('#getUnit').modal('hide');
    this.clear();
  }
   


  prev() {
    swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
    if (this.pageid > 1) {
        this.pageid = this.pageid - 1;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    }
    else {
        this.pageid = 1;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
    }
  /*  const obj = {
        checkeddata: this.branch,
        checkeddata1: this.pageid 
    }
    this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
    );*/
    if (this.memo_no == "" && this.vehicle_id == "") {
      const obj = {
        checkeddata: this.branch,
        checkeddata1: this.pageid 
    }
    this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        dc_no: this.memo_no,
        branch: this.branch,
        vehicle_number: this.vehicle_id,
        page_id:this.pageid
      } 
      //console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.LTSsearchPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
}

next() {
    swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    if (this.pageid == this.last) {
        (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
    }
    else {
        this.pageid = this.pageid + 1;
    }
   /* const obj = {
        checkeddata: this.branch,
        checkeddata1: this.pageid
    }
    this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
    );*/
    if (this.memo_no == "" && this.vehicle_id == "") {
      const obj = {
        checkeddata: this.branch,
        checkeddata1: this.pageid 
    }
    this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        dc_no: this.memo_no,
        branch: this.branch,
        vehicle_number: this.vehicle_id,
        page_id:this.pageid
      } 
      //console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.LTSsearchPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
}



input() {
  swal({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    width: 100,
    heightAuto: true,
    background: "rgba(0, 0, 0, .1)",
  });
  swal.showLoading();
  // console.log('totalcount:', this.totalcount);
  // console.log('pageid:', this.pageid);
  // console.log('totalcount:', this.last);
  if ((Number(this.totalcount) <= 20 && Number(this.pageid != 1)) || Number(this.pageid) > Number(this.last)) {
    swal('Alert!!!', 'Record Not Available', 'warning');
  }
  else {
    if (this.pageid <= 0 || this.pageid == null || this.pageid == 1) {
      this.pageid = 1;
      (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
    }
    else {
      (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
      (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    }
    if (this.pageid == this.last || this.pageid > this.last) {
      this.pageid = this.last;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
    }
    else {
      (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
      (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
    }
  /*  if (this.bill_search == "" && this.customer_search == "") {
      const obj = { 
        branch: this.branch, 
        page_id: this.pageid
      }  
      this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
                branch: this.branch,
                customer_id: this.customer_search, 
                page_id: this.pageid,
      }
      this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    } */
    if (this.memo_no == "" && this.vehicle_id == "") {
      const obj = {
        checkeddata: this.branch,
        checkeddata1: this.pageid 
    }
    this.tps.getLoadingtriplistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        dc_no: this.memo_no,
        branch: this.branch,
        vehicle_number: this.vehicle_id,
        page_id:this.pageid
      } 
      //console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.LTSsearchPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
}




lastpage() {
  swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
  });
  swal.showLoading();
  (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
  (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
  this.pageid = this.last;
  if (this.pageid == this.last) {
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
  }
 /* const obj = {
      checkeddata: this.branch,
      checkeddata1: this.last
  }
  this.tps.getLoadingtriplistPage(obj).subscribe(
      data => this.gettabledata(data)
  );*/

  if (this.memo_no == "" && this.vehicle_id == "") {
    const obj = {
      checkeddata: this.branch,
      checkeddata1: this.pageid 
  }
  this.tps.getLoadingtriplistPage(obj).subscribe(
      data => this.gettabledata(data)
  );
  }
  else {
    const obj = {
      dc_no: this.memo_no,
      branch: this.branch,
      vehicle_number: this.vehicle_id,
      page_id:this.pageid
    } 
    //console.log(obj);
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.LTSsearchPage(obj).subscribe(
      data => this.gettabledata(data)
    );
  }

  
}


  ltssearch() {
    if (this.memo_no == "" && this.vehicle_id == "") {
      this.getloadingtriplist();
     }
    else {
      this.pageid = 1;
      const obj = {
        dc_no: this.memo_no,
        branch: this.branch,
        vehicle_number: this.vehicle_id,
        page_id:this.pageid
      } 
      //console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.LTSsearchPage(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
}
