import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-advice-vehicle',
  templateUrl: './payment-advice-vehicle.component.html',
  styleUrls: ['./payment-advice-vehicle.component.css']
})
export class PaymentAdviceVehicleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
