import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-social',
  templateUrl: './button-social.component.html',
})
export class ButtonSocialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
