import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Branch } from '../../../Models/Branch';
import { fmmaster } from '../../../Models/fmmaster';
import { vehicle } from '../../../Models/vehicle';
import swal from 'sweetalert2';
import { user } from '../../../Models/user';
import { Router } from '@angular/router';
import { trdata } from '../../../Models/trdata';
import { trtabledata } from '../../../Models/trtabledata';
import { vehicleowner } from '../../../Models/vehicleowner';
import { memofortp } from '../../../Models/memofortp';

declare var $: any;

@Component({
  selector: 'app-transporterbill',
  templateUrl: './transporterbill.component.html',
  styleUrls: ['./transporterbill.component.css'],
  providers: [TpserviceService]
})
export class TransporterbillComponent implements OnInit {

  branches: Branch[];
  branch: string;
  billno: string;
  trbillno: string;
  bill_date: string;
  vehicleno: string;
  transportername: string;
  vehicletype: string;
  contactno: string;
  address: string;
  totalpay: any = 0;
  cgst: any = 0;
  cgstval: any = 0;
  sgst: any = 0;
  sgstval: any = 0;
  igst: any = 0;
  igstval: any = 0;
  total: any;
  transporterbankname: string;
  bank: string;
  accountname: string;
  accountnumber: string;
  ifsccode: string;
  chequeno: string;
  accountdate: string;
  memodata: fmmaster[];
  memodatas: fmmaster[];
  vehicles: vehicle[];
  memos: string;
  selectedstr: string = "";
  com: string = ",";
  openstr: string = "(";
  closestr: string = ")";
  selectedmemo: string = "";
  user: user;
  date: string;
  datastate: any = "Save";
  trdata: trdata;
  sendcallbacktr: string = "";
  id: string;
  selectedtr: string = "";
  trtabledata: trtabledata[] = [];
  vehicleowners: vehicleowner[];
  selectedvehicle: string;
  selectedvehiclelist: string;
  flag: boolean[] = [];
  datatb2: string = "";

  public rowdata: Array<{

    fm_id: string;
    memo_no: string;
    memo_date: string;
    vehicle_number: string;
    from_loc: string;
    to_loc: string;
    actual_freight: any;
    total_freight: any;
    advance_amount: any;
    balance_amount: any;

  }> = [];
  constructor(private tps: TpserviceService, private route: Router) { }

  ngOnInit() {
    this.displaybranches();

    this.user = JSON.parse(localStorage.getItem("userdata"));
    if (localStorage.getItem("datastate") == "1") {
      this.datastate = "Update"

      const ob = {
        checkeddata: this.branch
      }

      this.tps.getVehicleOwners(ob).subscribe(
        data => this.aftertransporter1(data)
      );
      this.displaytrdata();
      this.onchangetransporter(this.transportername);
      for (let i = 0; i < this.memodata.length; i++) {
        if (this.memodata[i].total_freight == 0 || this.memodata[i].total_freight == undefined) {
          this.flag[i] = true;
        }
        else {
          this.flag[i] = false;
        }
      }
      this.calculation(0);
      localStorage.removeItem("datastate");
    }
    else {
      this.generatetrbillno();
      this.gettransporter();
    }
  }

  ngAfterViewInit() {

    var self = this;

    $('#form-sample-1').validate({
      rules: {
        billno: {
          required: !0
        },
        trbillno: {
          required: !0
        },
        bill_date: {

          required: !0

        },

        vehicleno: {
          required: !0

        },
        transportername: {
          required: !0

        },
        bank: {

          required: !0

        },

        accountname: {
          required: !0

        },
        accountnumber: {
          required: !0

        },
        ifsccode: {
          required: !0

        },
        chequeno: {
          required: !0

        },
        accountdate: {
          required: !0,
        }
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.submit();
      }
    });

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });

    $(document).ready(function () {
      $("#transportername").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#transportername").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.transportername = id;
      self.onchangetransporter(id);
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onchange();
    });

  }
  displaybranches() {

    this.tps.getBranchlist().subscribe(
      data => this.afterdata(data)
    );
  }
  afterdata(data) {
    this.branches = data;
    this.branch = localStorage.getItem("branch");
  }

  generatetrbillno() {

    this.tps.getTrbillno().subscribe(
      data =>
        this.billno = data['messasge']
    );
  }

  onchange() {

    localStorage.setItem("branch", this.branch);

  }

  gettransporter() {

    const ob = {
      checkeddata: this.branch
    }

    this.tps.getVehicleOwners(ob).subscribe(
      data => this.aftertransporter(data)
    );
  }

  aftertransporter(data) {
    this.vehicleowners = data;
  }
  aftertransporter1(data) {
    this.vehicleowners = data;
    this.getmemos1();
  }

  onchangetransporter(id) {
    const ob = {
      checkeddata: id
    }

    this.tps.getVehiclefortpbill(ob).subscribe(
      data => this.aftertransport(data)
      , error => console.log(error)

    );
  }

  aftertransport(data) {
    this.vehicles = data;
    this.transporterbankname = this.vehicles[0].vehical_owner_name;
    this.bank = this.vehicles[0].bank;
    this.accountnumber = this.vehicles[0].accnum;
    this.ifsccode = this.vehicles[0].ifsccode;
  }

  checkvehicle(obj, index) {
    if (this.trtabledata.some(e => e.vehicle_number == obj)) {
      this.vehicles[index].selected = 'true';
      return true;
    }
    else {
      return false;
    }
  }

  Multiselect(index, ev) {
    if (this.vehicles[index].selected == 'true') {
      this.vehicles[index].selected = 'false';
    }
    else {
      this.vehicles[index].selected = 'true';
    }
  }

  vehiclelist() {
    this.selectedvehicle = "";
    for (var i = 0; i < this.vehicles.length; i++) {
      if (this.vehicles[i].selected == "true") {
        this.selectedvehicle = this.selectedvehicle + this.vehicles[i].vehicleid + this.com;
      }
    }
  }

  onchangevehicle(e) {

    let index = this.vehicles.findIndex(datas => datas.vehicleid == e.target.value);
    this.transportername = this.vehicles[index].vehical_owner_name;
    this.transporterbankname = this.vehicles[index].vehical_owner_name;
    this.vehicletype = this.vehicles[index].vehicle_type;
    this.address = this.vehicles[index].address;
    this.contactno = this.vehicles[index].telephoneno;
    this.getmemos();
  }


  getmemos() {
    this.vehiclelist();
    var datatb1 = this.openstr + this.selectedvehicle.substr(0, this.selectedvehicle.length - 1) + this.closestr;
    const ob = {

      checkeddata: datatb1
    }
    this.tps.gettpbillmemolist(ob).subscribe(
      data => this.aftergetmemos(data)
    );
  }

  getmemos1() {
    this.vehiclelist();
    var datatb1 = this.openstr + this.selectedvehicle.substr(0, this.selectedvehicle.length - 1) + this.closestr;

    const ob = {

      checkeddata: this.id,
      checkeddata1: datatb1
    }
    this.tps.gettpbillmemolist1(ob).subscribe(
      data => this.aftergetmemos(data)
    );

  }

  aftergetmemos(data) {
    this.memodatas = data;
  }

  checkdata(obj): boolean {

    if (this.trtabledata.some(e => e.memo_no == obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkclick(data, e) {
    if (e.target.checked) {
      var temp = this.selectedstr.replace(data + this.com, "");
      var tmp1 = this.selectedmemo.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "")
      this.selectedstr = temp;
      this.selectedmemo = tmp1;
      this.selectedstr += data + this.com;
      this.selectedmemo += this.openstr + data + this.com + "@@" + this.com + this.memodata + this.closestr + this.com;
    }

    else {
      var temp1 = this.selectedstr.replace(data + this.com, "");
      var temp = this.selectedmemo.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "");
      this.selectedstr = temp1;
      this.selectedmemo = temp;
    }

  }

  getmemodata() {
    let datatb = this.openstr + this.selectedstr.substr(0, this.selectedstr.length - 1) + this.closestr;

    const ob = {

      checkeddata: datatb
    }

    this.tps.gettpbillmemodata(ob).subscribe(
      data => this.aftergetmemodata(data)
      , error => console.log(error)

    );
  }

  aftergetmemodata(data) {
    this.memodata = data;
    this.calculation(0);
    for (let i = 0; i < this.memodata.length; i++) {
      if (this.memodata[i].total_freight == 0 || this.memodata[i].total_freight == undefined) {
        this.flag[i] = true;
      }
      else {
        this.flag[i] = false;
      }
    }
  }

  calculation(i) {
    var total = 0;
    this.memodata.forEach(function (element) {
      if (element.actual_freight == 0 || element.actual_freight == undefined) {
        element.balance_amount = Number(element.total_freight) - Number(element.advance_amount);

      }
      else {
        element.balance_amount = Number(element.actual_freight) - Number(element.advance_amount);
      }
      total = total + Number(element.balance_amount);
    })
    this.totalpay = total;
  }

  calcgst() {

    const temp = +this.totalpay;
    this.igstval = 0;
    this.cgstval = 0;
    this.sgstval = 0;
    this.sgstval = this.sgst * temp / 100;
    this.igstval = this.igst * temp / 100;
    this.cgstval = this.cgst * temp / 100;
    this.addgst();
  }

  addgst() {
    this.total = this.totalpay + +this.igstval + +this.cgstval + +this.sgstval;
  }



  submit() {
    if (this.trbillno == "" || this.bill_date == "" || this.vehicleno == "") {
      swal('warning', 'Please choose atleast one LR', 'warning');
    }
    else {

      if (!(this.datastate == "Update")) {

        let datatb1 = this.selectedmemo.substr(0, this.selectedmemo.length - 1);
        for (let i = 0; i < this.memodata.length; i++) {
          this.datatb2 = this.datatb2 + this.openstr + this.memodata[i].fm_id + this.com + this.memodata[i].actual_freight + this.com + "@@" + this.closestr + this.com;
        }
        this.memos = this.datatb2.substr(0, this.datatb2.length - 1);
        const obj = {
          memodata: datatb1,
          billno: this.billno,
          trbillno: this.trbillno,
          billdate: this.bill_date,
          transportername: this.transportername,
          vehicleid: this.vehicleno,
          bank: this.bank,
          vehicletype: this.vehicletype,
          contactno: this.contactno,
          address: this.address,
          totalpay: this.totalpay,
          sgst: this.sgst,
          sgstval: this.sgstval,
          cgst: this.cgst,
          cgstval: this.cgstval,
          igst: this.igst,
          igstval: this.igstval,
          total: this.total,
          accountname: this.accountname,
          accountnumber: this.accountnumber,
          ifsccode: this.ifsccode,
          checkno: this.chequeno,
          date: this.bill_date,
          branch: this.branch,
          user: this.user.id,
          memos: this.memos
        }

        swal({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          width: 150
        });
        swal.showLoading();

        this.tps.addTPbill(obj).subscribe(
          data => this.aftersubmit(data),
          error => console.log(error))
      }

      else {

        this.updatetrmaster();

      }
    }
  }

  aftersubmit(data) {
    this.id = data["res_code"];
    swal("success", data['messasge'], "success");
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  updatetrmaster() {

    if (this.selectedstr == "()") {
      swal('warning', 'warning'), error => swal('Please choose atleast one LR', error, 'warning')
    }
    else {
      let datatb1 = this.selectedmemo.substr(0, this.selectedmemo.length - 1);
      for (let i = 0; i < this.memodata.length; i++) {
        this.datatb2 = this.datatb2 + this.openstr + this.memodata[i].fm_id + this.com + this.memodata[i].actual_freight + this.com + "@@" + this.closestr + this.com;
      }
      this.memos = this.datatb2.substr(0, this.datatb2.length - 1);
      const obj = {
        memodata: datatb1,
        id: this.id,
        billno: this.billno,
        trbillno: this.trbillno,
        billdate: this.bill_date,
        vehicleid: this.vehicleno,
        bank: this.bank,
        vehicletype: this.vehicletype,
        contactno: this.contactno,
        address: this.address,
        totalpay: this.totalpay,
        sgst: this.sgst,
        sgstval: this.sgstval,
        cgst: this.cgst,
        cgstval: this.cgstval,
        igst: this.igst,
        igstval: this.igstval,
        total: this.total,
        accountname: this.accountname,
        accountnumber: this.accountnumber,
        ifsccode: this.ifsccode,
        checkno: this.chequeno,
        date: this.bill_date,
        branch: this.branch,
        user: this.user.id,
        memos: this.memos
      }

      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 150
      });
      swal.showLoading();
      this.tps.updateTR(obj).subscribe(
        data => this.afterupdate(data));
    }
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => console.log(error);
    //this.route.navigate(['pages/TransporterBillList']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;

  }

  displaytrdata() {
    this.trdata = JSON.parse(localStorage.getItem("data"));
    localStorage.removeItem("data");
    this.id = this.trdata.trbo.id;
    this.billno = this.trdata.trbo.billno;
    this.trbillno = this.trdata.trbo.trbillno;
    this.bill_date = this.trdata.trbo.billdate;
    this.vehicleno = this.trdata.trbo.vehicleid;
    this.bank = this.trdata.trbo.bank;
    this.vehicletype = this.trdata.trbo.vehicletype;
    this.transportername = this.trdata.trbo.transportername;
    this.contactno = this.trdata.trbo.contactno;
    this.address = this.trdata.trbo.address;
    this.totalpay = this.trdata.trbo.totalpay;
    this.sgst = this.trdata.trbo.sgst;
    this.sgstval = this.trdata.trbo.sgstval;
    this.cgst = this.trdata.trbo.cgst;
    this.cgstval = this.trdata.trbo.cgstval;
    this.igst = this.trdata.trbo.igst;
    this.igstval = this.trdata.trbo.igstval;
    this.total = this.trdata.trbo.total;
    this.accountname = this.trdata.trbo.accountname;
    this.transporterbankname = this.trdata.trbo.transportername;
    this.accountnumber = this.trdata.trbo.accountnumber;
    this.ifsccode = this.trdata.trbo.ifsccode;
    this.chequeno = this.trdata.trbo.checkno;
    this.accountdate = this.trdata.trbo.date;
    this.branch = this.trdata.trbo.branch;
    this.user.id = this.trdata.trbo.user;
    this.memodata = this.trdata.trtablebo;
    this.rowdata = this.trdata.trtablebo;
    this.trtabledata = this.trdata.trtablebo;
    this.splitdata();
  }

  splitdata() {
    let strdata: string = "";
    let strdatatr: string = "";
    this.rowdata.forEach(function (element) {
      if (!strdata.includes(element.fm_id.toString())) {
        strdata += element.fm_id.toString() + ",";
        strdatatr += "(" + element.fm_id.toString() + "," + "@@),"
      }
    })
    this.selectedstr = strdata;
    this.sendcallbacktr = strdata;
    this.sendcallbacktr = this.openstr + strdata.substr(0, strdata.length - 1) + this.closestr;
    this.selectedtr = strdatatr;
  }

  redirect() {
    this.route.navigate(['pages/TransporterBillList']);
  }

  pdfredirect() {
    const ob = {
      id: this.id,
      type: 'transportbill_master',
    }
    this.tps.viewpdf(ob).subscribe(
      data => this.afterpdf(data)
      , error => swal('Please check your internet connection', error, 'error')

    );
  }

  afterpdf(data) {
    if (this.billno == "") {

      swal('warning', 'Please add LR first!', 'warning')
    }
    else {

      swal({
        showCancelButton: true,
        width: '80%',
        showCloseButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        html: '<embed id="viewfile" src="' + data['location'] + '" height="500px" width="100%"/>',
        preConfirm: () => {
          return document.getElementById("viewfile");
        }
      })
    }
  }

}