import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { user } from '../../Models/user';
import { permissions } from '../../Models/permissions';
declare var $: any;

@Component({
    selector: 'app-login-5',
    templateUrl: './login-5.component.html',
    styleUrls:['./login-5.component.css'],
    providers: [TpserviceService]
})
export class Login5Component implements OnInit, AfterViewInit, OnDestroy {

    username: string = "";
    permissions: permissions[];
    password: string = "";
    response: any;
    responsestatus: string = "";
    userobj: user;
    constructor(private tps: TpserviceService, private route: Router) { }

    ngOnInit() {
        localStorage.removeItem('userdata');
        $('body').addClass('empty-layout');
    }

    ngAfterViewInit() {
        var self = this;
        $('#login-form').validate({
            errorClass: "help-block",
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                }
            },
            highlight: function (e) {
                $(e).closest(".form-group").addClass("has-error")
            },
            unhighlight: function (e) {
                $(e).closest(".form-group").removeClass("has-error")
            },
            submitHandler: function (form) {
                self.checkUser();
            }
        });
    }

    ngOnDestroy() {
        $('body').removeClass('empty-layout');
    }


    checkUser() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 150
        });
        swal.showLoading();
        const obj = {
            username: this.username,
            password: this.password
        }

        this.tps.checkUser(obj).subscribe(

            data => this.afterdata(data)
        )
    }

    afterdata(data) {

        this.responsestatus = data['messasge'];
        if (this.responsestatus == '-1' || this.responsestatus == '-11' || this.responsestatus == '-12') {
            swal.close();
            swal("Error", "Please Check Your Credentials", "error");
            this.reset();

        }
        else if (this.responsestatus == '-10' || this.responsestatus == '10' || this.responsestatus == '13') {
            swal.close();
            swal("Error", "User Is Not Active", "error");
            this.reset();
        }


        else if (this.responsestatus == '11') {
            swal.close();
            swal("Warning", "User Is Not Authorized", "warning");
            this.reset();

        }
        else if (this.responsestatus == '12') {
            this.getuserdetails();
        }
    }

    reset() {
        this.password = "";
    }

    getuserdetails() {
        const obj = {
            checkeddata: this.username
        }
        this.tps.getUser(obj).subscribe(
            data => this.afteruserdata(data)
        )
    }

    afteruserdata(data) {
        this.userobj = data;
        localStorage.setItem("userdata", JSON.stringify(this.userobj));
        localStorage.setItem("branch", this.userobj.branch);
        this.getuserpermissions();
    }

    getuserpermissions() {
        const obj = {
            checkeddata: this.userobj.id
        }
        this.tps.getUserPermissions(obj).subscribe(
            data => this.afterpermissionsdata(data)
        )
        swal.close();
    }


    afterpermissionsdata(data) {
        this.permissions = data;
        localStorage.setItem("permissions", JSON.stringify(this.permissions));
        this.route.navigate(['/index']);
    }
}
