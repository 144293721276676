import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { LRReport } from '../../../Models/lrreport';
import { Excellservice } from '../../../Services/excellservice.service';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs/Observable';

declare var $: any;

@Component({
  selector: 'app-lr-report',
  templateUrl: './lr-report.component.html',
  styleUrls: ['./lr-report.component.css'],
  providers: [TpserviceService, Dataservices, DatePipe, Excellservice]
})
export class LrReportComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  branch: string;
  branchlist: any;
  cust: any;
  consignee: string = "";
  consigner: string = "";
  lrList:LRReport[]=[];
  flag: boolean;

  constructor(private tps: TpserviceService, private excellservice: Excellservice, private dts: Dataservices, private datepipe: DatePipe, private route: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.displaybranches();
  }

  ngAfterViewInit() {
    var self = this;

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onbranchchange();
    });

    $(document).ready(function () {
      $("#consignee").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#consignee").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.consignee = id;
    });

    $(document).ready(function () {
      $("#consigner").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#consigner").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.consigner = id;
    });
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.aftergetBranch(data)
    );
  }

  aftergetBranch(data) {
    this.branchlist = data;
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.cust = data;
    this.getLRListByDefault();
  }
  onbranchchange() {
    localStorage.setItem("branch", this.branch);
    this.getLRListByDefault();
  }

  getLRList() {
    if (this.consigner != "" && this.consignee == "") {
      this.getLRListByConsignor();
    }
    else if (this.consigner == "" && this.consignee != "") {
      this.getLRListByConsignee();
    }
    else if (this.consigner != "" && this.consignee != "") {
      this.getLRListByConsignorAndConsignee();
    }
    else{
      this.getLRListByDefault();
    }
  }

  getLRListByDefault() {
    const OBJ = {
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getPendingLrListForreportByDefault(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  getLRListByConsignor() {
    const OBJ = {
      branch: this.branch,
      consignor: this.consigner
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getPendingLrListForreportByconsignor(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  getLRListByConsignee() {
    const OBJ = {
      branch: this.branch,
      consignee: this.consignee
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getPendingLrListForreportByconsignee(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  getLRListByConsignorAndConsignee() {
    const OBJ = {
      branch: this.branch,
      consignor: this.consigner,
      consignee: this.consignee
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getPendingLrListForreportByAll(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }


  afterGetLrData(data){
    $("#products-table").dataTable().fnDestroy();
    this.lrList = data;
    this.flag=false;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 20,
        //pagingType: 'full_numbers',
        fixedHeader: true,
        responsive: true,
        ordering: false,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  Exportasexcell(){
     // console.log(this.table.nativeElement);
   //  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
   //  console.log("ws:",ws);
     // const wb: XLSX.WorkBook = XLSX.utils.book_new();
     
     // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     //console.log(excelBuffer);
     //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
     
     
     /* save to file */
     //this.excellservice.saveAsExcelFile(excelBuffer, 'LR_REPORTFILE');
     //XLSX.writeFile(wb, 'SheetJS.xlsx');
     this.excellservice.exportAsExcelFile(this.lrList, 'LRREPORTFILE');
  }

}
