import {Component, OnInit, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {Lr} from './Lr';
import {Router} from '@angular/router';
import {TpserviceService} from '../../../../Services/tpservice.service';
import{Dataservices} from '../../../../Services/dataservices.service'; 
import swal from 'sweetalert2';
import {FormGroup, FormBuilder} from '@angular/forms';
import {lrdata}  from '../../../../Models/lrdata';
import { articles } from '../../../../Models/articles';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  
declare var $: any;
const openpr="("; 
const closepr=")";
var datastr:String="";
const com=",";
@Component({
    selector: 'app-form-layouts',
    templateUrl: './lrbill.component.html',
    providers: [TpserviceService,Dataservices]
})
export class LrBillComponent implements OnInit, AfterViewInit {
    LRForm: FormGroup;
    lrid:string="";
    cust: any;
    place: any;
    datenow:any;
    lrdata:lrdata=null;
    lrbilldata:Array<any>;
    branches: any;
    lrlist:any;
    public datastate:any="Add";
    ob: any={};
    ob1: any={};
articles:articles[]=[];
    public row_num: any;
    public flag: boolean;
    public lr: Lr;
    private art: string;
    public id: number;
    public cft: string;
    public cfth: string;
    public cftw: string;
    public desc: string;
    public cftl: string;
    public inamt: string;
    public inv: string;
    public nart: string;
    public rt: string;
    public qty: string;
    public sr: string;
    public invdt: string;
    public tw: string;
    public chwt: string;
    public rtp: string;
    public wtact: string;
    public mainstr:String="";
    public total: any;
   
    public hamali: any;
    public collection: any;
    public dates: any;
    public lrmaster_id:string="@@";
    public Statistical: any;
    public sub_total: any;
    public igst: any;

    public sgst: any;

    public cgst: any;

    public lr_no: any;
    public lr_date: any;
    public truck_tempo_number: any;
    public consigner: any;
    public consigner_address: any;
    public consignee: any;
    public consignee_address: any;
    public loc_from: any;
    public loc_to: any;
    public branch: any=1;
    public freight: any;
    public extra_charges: any;


    public delivery: any;
    public wt_charges: any;
    public other_charges: any;


    public igst_val: any;

    public cgst_val: any;

    public sgst_val: any;

    public delivery_type: any;
    public delivery_days: any;
    public weight_type: any;
    public paybill: any;
    public to_billed: any;
    public collect_at_branch: any;
    public remarks: any;
    pdfflag:number=0;
public rowdata={

    sr_no:"",
    inv_no:"",
    inv_date:"",
    description:"",
    articles:"",
    no_of_articles:"",
    quantity:"",
    inv_amt:"",
    cftl:"",
    cftw:"",
    cfth:"",
    t10_12_20:"",
    cft:"",
    actual_wt:"",
    char_wt:"",
    rate_per:"",
    rate:"",
    lr_master_id:""

};
    public rows: Array<{
        
        
	    sr_no:string;
	    inv_no:string;
	    inv_date:string;
	    description:string;
	    articles:string;
	    no_of_articles:string;
	    quantity:string;
	    inv_amt:string;
	    cftl:string;
	    cftw:string;
	    cfth:string;
	    t10_12_20:string;
	    cft:string;
	    actual_wt:string;
	    char_wt:string;
	    rate_per:string;
	    rate:string;
        lr_master_id:string;
    }> = [];



    constructor(private fb: FormBuilder, private router: Router, private tps: TpserviceService,private dts:Dataservices) {
        this.lr = new Lr();
    }


    ngOnInit() {
        this.pdfpreview();
      
    }

    ngAfterViewInit() {
       
        if(this.pdfflag==1)
        {
this.generatepdf();
        }
        else{
            this.displaylrdata();
        }
    }
     
    displaylrdata() {

    this.lrbilldata=JSON.parse(localStorage.getItem('pdfdata'));

        if(this.lrbilldata==[]){
        swal('Success', "Please select at least one LR",'warning' );
    }
    else{
        this.lrbilldata.forEach(d => {
            console.log("in>"+d);
            const ob={
                lrid:d
                    }
                   
                   // console.log("pdfdata"+this.pdfdata);
                  this.tps.getLrBillData(ob).subscribe(
                            data => this.callafterpdfdata(data)    
                        );
                    });
         
       // this.lrbilldata.forEach(function(element){  })
    
}}
    callafterpdfdata(data){
        this.lrdata=data;
    this.lrid=this.lrdata.lorrymasterBO.lrid;
    this.lr_no=this.lrdata.lorrymasterBO.lr_no;
    this.lr_date=this.lrdata.lorrymasterBO.lr_date;
    this.truck_tempo_number=this.lrdata.lorrymasterBO.truck_tempo_number;
    this.consigner=this.lrdata.lorrymasterBO.consigner;
    this.consigner_address=this.lrdata.lorrymasterBO.consigner_address;
    this.consignee=this.lrdata.lorrymasterBO.consignee;
    this.consignee_address=this.lrdata.lorrymasterBO.consignee_address;
    this.loc_from=this.lrdata.lorrymasterBO.loc_from;
    this.loc_to=this.lrdata.lorrymasterBO.loc_to;
    this.branch=this.lrdata.lorrymasterBO.branch;
    this.freight=this.lrdata.lorrymasterBO.freight;
    this.delivery=this.lrdata.lorrymasterBO.delivery;
    this.other_charges=this.lrdata.lorrymasterBO.other_charges;
    this.delivery_type=this.lrdata.lorrymasterBO.delivery_type;
    this.delivery_days=this.lrdata.lorrymasterBO.delivery_days;
    this.to_billed=this.lrdata.lorrymasterBO.to_billed;
    this.collect_at_branch=this.lrdata.lorrymasterBO.collect_at_branch;
    this.remarks=this.lrdata.lorrymasterBO.remarks;
    this.total=this.lrdata.lorrymasterBO.total;
    this.hamali=this.lrdata.lorrymasterBO.hamali;
    //this.rows=this.lrdata.tabledata;
    this.rows.push(this.rowdata);
    this.rows.push(this.rowdata);
    this.rows.push(this.rowdata);
    this.rows.push(this.rowdata);
    this.rows.push(this.rowdata);
     this.datenow = new Date().toLocaleDateString();
    console.log(this.lrdata);
       localStorage.removeItem("pdfdata");
       this.pdfflag=1;
       this.generatepdf();
    }

  

    public generatepdf()  
    {  
      var data = document.getElementById('contentToConvert');  
      html2canvas(data).then(canvas => {  
       // canvas.scale=2;
        var imgWidth = 298;   
        var pageHeight = 295;    
        var imgHeight = canvas.height * imgWidth / canvas.width;  
        var heightLeft = imgHeight;  
    
        const contentDataURL = canvas.toDataURL('image/png')  
        let pdf = new jspdf('l', 'mm', 'a4',true); 
        var position = 0;  
       // html2canvas: {scale: 2, logging: true},
        //pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight)  
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight,'','FAST');
      // pdf.save(this.lr_no+'.pdf');
    //    function open_data_uri_window(url) {
    //     var html = '<html>' +
    //       '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
    //       '<body>' +
    //       '<iframe src="' + url + '"></iframe>' +
    //       '</body></html>';
    //     window.open()
    //    document.write(html)
    //   }
    //pdf.output('dataurlnewwindow'); 

      //open_data_uri_window(a); 
     // pdf.output('dataurlnewwindow');
     
    
      });  

     // this.router.navigate(['/pages/LR']);
    }  

    public pdfpreview()
    {
        this.lrdata=JSON.parse(localStorage.getItem("pdfdatapreview"));
        localStorage.removeItem("pdfdatapreview");

        this.lrid=this.lrdata.lorrymasterBO.lrid;
        this.lr_no=this.lrdata.lorrymasterBO.lr_no;
        this.lr_date=this.lrdata.lorrymasterBO.lr_date;
        this.truck_tempo_number=this.lrdata.lorrymasterBO.truck_tempo_number;
        this.consigner=this.lrdata.lorrymasterBO.consigner;
        this.consigner_address=this.lrdata.lorrymasterBO.consigner_address;
        this.consignee=this.lrdata.lorrymasterBO.consignee;
        this.consignee_address=this.lrdata.lorrymasterBO.consignee_address;
        this.loc_from=this.lrdata.lorrymasterBO.loc_from;
        this.loc_to=this.lrdata.lorrymasterBO.loc_to;
        this.branch=this.lrdata.lorrymasterBO.branch;
        this.freight=this.lrdata.lorrymasterBO.freight;
        this.delivery=this.lrdata.lorrymasterBO.delivery;
        this.other_charges=this.lrdata.lorrymasterBO.other_charges;
        this.delivery_type=this.lrdata.lorrymasterBO.delivery_type;
        this.delivery_days=this.lrdata.lorrymasterBO.delivery_days;
        this.to_billed=this.lrdata.lorrymasterBO.to_billed;
        this.collect_at_branch=this.lrdata.lorrymasterBO.collect_at_branch;
        this.remarks=this.lrdata.lorrymasterBO.remarks;
        this.total=this.lrdata.lorrymasterBO.total;
        this.hamali=this.lrdata.lorrymasterBO.hamali;
        //this.rows=this.lrdata.tabledata;
        this.rows.push(this.rowdata);
        this.rows.push(this.rowdata);
        this.rows.push(this.rowdata);
        this.rows.push(this.rowdata);
        this.rows.push(this.rowdata);
         this.datenow = new Date().toLocaleDateString();
        console.log(this.lrdata);
           this.generatepdf();


    }
}











