import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widgets-blog',
  templateUrl: './widgets-blog.component.html',
})
export class WidgetsBlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
