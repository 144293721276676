import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import swal from 'sweetalert2';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Router } from '@angular/router';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { vehicleowner } from '../../../Models/vehicleowner';
declare var $: any;

@Component({
  selector: 'app-form-advanced',
  templateUrl: './vehicle-owner.component.html',
  providers: [TpserviceService]
})
export class VehicleOwnerComponent implements OnInit, AfterViewInit {

  datastate: string = "Save";
  name: string = "";
  ownertype: string = "";
  address: string = "";
  city: string = "";
  telephone: string = "";
  email: string = "";
  pan: string = "";
  vendorcode: string = "";
  vatno: string = "";
  cstno: string = "";
  eccno: string = "";
  gstno: string = "";
  branchlist: any = [];
  branch: string = "1";
  voobject: vehicleowner;
  bank: string = "";
  accnum: string = "";
  ifsccode: string = "";
  disablestate: any;
  refname: string = "";
  refaddr: string = "";
  refdesg: string = "";
  refemail: string = "";
  reffax: string = "";
  row_num: string = "";
  flag: boolean;

  public rows: Array<{
    cpname: string;
    cpaddr: string;
    cpdesg: string;
    cpemail: string;
    cpfax: string;
  }> = [];

  constructor(private _script: ScriptLoaderService, private tps: TpserviceService, private route: Router) { }

  ngOnInit() {
    this.displaybranches();

    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");

    if (localStorage.getItem("vodatastate") == "1") {
      this.getvodata();
      localStorage.removeItem("vodatastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        name: {
          maxlength: 100,
          required: !0
        },
        address: {
          required: !0,
        },
        pan: {
          required: !0,
        },
        telephone: {
          required: !0,
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
  }

  onsubmit() {
    if (!(this.datastate == "Update")) {
      const obj = {
        vehicleownername: this.name,
        address: this.address,
        city: this.city,
        telephoneno: this.telephone,
        emailid: this.email,
        panno: this.pan,
        vendorcode: this.vendorcode,
        vatno: this.vatno,
        cstno: this.cstno,
        eccno: this.eccno,
        contacts: this.rows
      }
      this.tps.addVehicleOwner(obj).subscribe(
        data => this.aftersubmit(data));
    }
    else {
      this.updatevehicleowner();
    }
  }

  updatevehicleowner() {
    const obj = {
      vid: this.voobject.vid,
      vehicleownername: this.name,
      address: this.address,
      city: this.city,
      telephoneno: this.telephone,
      emailid: this.email,
      panno: this.pan,
      vendorcode: this.vendorcode,
      vatno: this.vatno,
      cstno: this.cstno,
      eccno: this.eccno,
      contacts: this.rows
    }
    this.tps.updateVehicleowner(obj).subscribe(
      data => this.aftersubmit(data));
  }

  getvodata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.voobject = JSON.parse(localStorage.getItem("vodata"));
    localStorage.removeItem("vodata");
    this.name = this.voobject.vehicleownername;
    this.email = this.voobject.emailid;
    this.address = this.voobject.address;
    this.telephone = this.voobject.telephoneno;
    this.pan = this.voobject.panno;
    this.gstno = this.voobject.gstno;
    this.ownertype = this.voobject.ownertype;
    this.vendorcode = this.voobject.vendorcode;
    this.city = this.voobject.city;
    this.vatno = this.voobject.vatno;
    this.cstno = this.voobject.cstno;
    this.eccno = this.voobject.eccno;
    this.rows = this.voobject.contacts;
    swal.close();
  }

  aftersubmit(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/VehicleOwner-List']);
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data =>
        this.branchlist = data
    );
  }

  redirect() {
    this.route.navigate(['pages/VehicleOwner-List']);
  }

  addref() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
  }

  clear() {
    this.refname = "";
    this.refaddr = "";
    this.refdesg = "";
    this.refemail = "";
    this.reffax = "";
  }

  editrow(row, index) {
    this.row_num = index;
    this.refname = row.cpname;
    this.refaddr = row.cpaddr;
    this.refdesg = row.cpdesg;
    this.refemail = row.cpaddr;
    this.reffax = row.cpfax;
    this.flag = true;
  }

  onupdate() {
    this.rows.push({
      cpname: this.refname,
      cpaddr: this.refaddr,
      cpdesg: this.refdesg,
      cpemail: this.refemail,
      cpfax: this.reffax
    });
    this.clear();
    this.flag = false;
    this.deleterow(this.row_num);
  }

  deleterow(index) {
    this.rowdatadelete(index);
    this.rows.splice(index, 1);
  }

  rowdatadelete(index) {
    var openpr: "(";
    var closepr: ")";
    var com: ",";
    var data_str: String = "";
    var raw_str: String = "";
    data_str = "'" + this.rows[index].cpname + "'" + com + "'" + this.rows[index].cpaddr + "'" + com + "'" + this.rows[index].cpdesg + "'" + com + "'" + this.rows[index].cpemail + "'" + com + "'" + this.rows[index].cpfax + "'" + com + "@@";
    raw_str = openpr + data_str + closepr + com;
  }
}
