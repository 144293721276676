import { HttpClientModule, HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { lrmaster } from '../Models/lrmaster';
import { mis } from '../Models/mis';
import { lrdata } from '../Models/lrdata';
import { dcdata } from '../Models/dcdata';
import { dctabledata } from '../Models/dctabledata';
import { vehicle } from '../Models/vehicle';
import { fmtabledata } from '../Models/fmtabledata'
import { driverlist } from '../Models/driverlist';
import { dclist } from '../Models/dclist';
import { fmtransactions } from '../Models/fmtransactions';
import { fmlist } from '../Models/fmlist';
import { irlist } from '../Models/irlist';
import { fmdata } from '../Models/fmdata';
import { irmaster } from '../Models/irmaster';
import { Inwarddata } from '../Models/Inwarddata';
import { places } from '../Models/places';
import { Branch } from '../Models/Branch';
import { articles } from '../Models/articles';
import { customer } from '../Models/customer';
import { drivers } from '../Models/drivers';
import { driverobject } from '../Models/driverobject';
import { employees } from '../Models/emplyees';
import { vehicleowner } from '../Models/vehicleowner';
import { vehicletype } from '../Models/vehicletype';
import { article } from '../Models/article';
import { user } from '../Models/user';
import { permissions } from '../Models/permissions';
import { pod } from '../Models/pod';
import { HttpRequest } from 'selenium-webdriver/http';
import { fmmaster } from '../Models/fmmaster';
import { trdata } from '../Models/trdata';
import { deleteBo } from '../Models/deleteBo';
import { LRReport } from '../Models/lrreport';
import { DCReport } from '../Models/dcreport';
import { FMReport } from '../Models/fmreport';
import { trbillreport } from '../Models/trbillreport';
import { Billreport } from '../Models/billreport';
import { Paymentcollectionreport } from '../Models/paymentcollectionreport';
import { bill } from '../Models/bill';
import { payment_tabledata } from '../Models/payment_tabledata';
// const endpoint: String = 'http://192.168.43.175:8080/utsav/';
// const endpoint: String = 'http://192.168.0.130:8080/utsav/';
//const endpoint:string= 'http://localhost:8080/utsav/';  
const endpoint: String = 'https://ws.utsavroadlines.com/'; // live server
//const endpoint: String = 'https://utsavws.databin.in/';
//const endpoint: String = 'https://wsutsav.databin.in/';  //databin test server
var objs = {
    checkeddata: ''
};
@Injectable()
export class TpserviceService {
 
    private options = { headers: new HttpHeaders().set('Access-Control-Allow-Origin:', '*') };
    constructor(private httpclient: HttpClient, private httpmodule: HttpClientModule) { }


    getArticles(OBJ: any): Observable<articles[]> {
        return this.httpclient.post<articles[]>(endpoint + 'api/transport/getAllArticles', OBJ);
    }
    addArticle(article: any) {
        return this.httpclient.post(endpoint + 'api/transport/addArticle', article);
    }
    activateUser(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/activateuser', obj);
    }
    deleteUser(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/deleteuser', obj);
    }
    addBranch(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addBranch', OBJ);
    }
    getDcno(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/generatedcno', OBJ);
    }
    getTrbillno() {
        return this.httpclient.post(endpoint + 'api/transport/gentrbillno', objs);
    }

    getUsers(OBJ: any): Observable<user[]> {
        return this.httpclient.post<user[]>(endpoint + 'api/transport/userlist', OBJ);
    }
    getMis(OBJ: any): Observable<mis[]> {
        return this.httpclient.post<mis[]>(endpoint + 'api/transport/getmisreport', OBJ);
    }
    getStock(OBJ: any): Observable<mis[]> {
        return this.httpclient.post<mis[]>(endpoint + 'api/transport/getstockreport', OBJ);
    }
    getBillListForreportByDefault(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getBillListForreportByDefault', OBJ);
    }
    getBillListForreportByDate(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getBillListForreportByDate', OBJ);
    }
    getBillListForreportByCustomer(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getBillListForreportByCustomer', OBJ);
    }
    getCashMemoListForreportByDefault(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getCashMemoListForreportByDefault', OBJ);
    }
    getCashMemoListForreportByDate(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getCashMemoListForreportByDate', OBJ);
    }
    getCashMemoListForreportByCustomer(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getCashMemoListForreportByCustomer', OBJ);
    }
    getNotBilledLRListForreport(OBJ: any): Observable<Billreport[]> {
        return this.httpclient.post<Billreport[]>(endpoint + 'api/transport/getNotBilledLRListForreport', OBJ);
    }
    getLsListForreportByDate(OBJ: any): Observable<DCReport[]> {
        return this.httpclient.post<DCReport[]>(endpoint + 'api/transport/getLsListForreportByDate', OBJ);
    }
    getLsListForreportByDefault(OBJ: any): Observable<DCReport[]> {
        return this.httpclient.post<DCReport[]>(endpoint + 'api/transport/getLsListForreportByDefault', OBJ);
    }
    getLsListForreportByConsignor(OBJ: any): Observable<DCReport[]> {
        return this.httpclient.post<DCReport[]>(endpoint + 'api/transport/getLsListForreportByConsignor', OBJ);
    }
    getLsListForreportByLrNo(OBJ: any): Observable<DCReport[]> {
        return this.httpclient.post<DCReport[]>(endpoint + 'api/transport/getLsListForreportByLrNo', OBJ);
    }
    getLsListForreportByAll(OBJ: any): Observable<DCReport[]> {
        return this.httpclient.post<DCReport[]>(endpoint + 'api/transport/getLsListForreportByAll', OBJ);
    }
    getPendingLrListForreportByDefault(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getPendingLrListForreportByDefault', OBJ);
    }
    getPendingLrListForreportByconsignor(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getPendingLrListForreportByconsignor', OBJ);
    }
    getPendingLrListForreportByconsignee(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getPendingLrListForreportByconsignee', OBJ);
    }
    getPendingLrListForreportByAll(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getPendingLrListForreportByAll', OBJ);
    }
    getLrListForreportByDefault(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getLrListForreportByDefault', OBJ);
    }
    getLrListForreportByDate(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getLrListForreportByDate', OBJ);
    }
    getLrListForreportBycustomer(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getLrListForreportBycustomer', OBJ);
    }
    getLrListForreportByPayMode(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getLrListForreportByPayMode', OBJ);
    }
    getLrListForreportByAll(OBJ: any): Observable<LRReport[]> {
        return this.httpclient.post<LRReport[]>(endpoint + 'api/transport/getLrListForreportByAll', OBJ);
    }
    getFmReportbyBranch(OBJ: any): Observable<FMReport[]> {
        return this.httpclient.post<FMReport[]>(endpoint + 'api/transport/frieghtMemobranchwise', OBJ);
    }
    getPayCollListForreportByDefault(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPayCollListForreportByDefault', OBJ);
    }
    getCashPayCollListForreportByDefault(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getCashPayCollListForreportByDefault', OBJ);
    }
    getPayCollListForreportByDate(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPayCollListForreportByDate', OBJ);
    }
    getCashPayCollListForreportByDate(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getCashPayCollListForreportByDate', OBJ);
    }
    getPayCollListForreportByCustomer(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPayCollListForreportByCustomer', OBJ);
    }
    getCashPayCollListForreportByCustomer(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getCashPayCollListForreportByCustomer', OBJ);
    }
    getPendingBillListForreportByDefault(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPendingBillListForreportByDefault', OBJ);
    }
    getPendingBillListForreportByDate(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPendingBillListForreportByDate', OBJ);
    }
    getPendingBillListForreportByCustomer(OBJ: any): Observable<Paymentcollectionreport[]> {
        return this.httpclient.post<Paymentcollectionreport[]>(endpoint + 'api/transport/getPendingBillListForreportByCustomer', OBJ);
    }
    getFmReportbyVehicle(OBJ: any): Observable<FMReport[]> {
        return this.httpclient.post<FMReport[]>(endpoint + 'api/transport/frieghtMemovehiclewise', OBJ);
    }
    checkUser(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/checkuser', OBJ);
    }
    
    checkpdf(OBJ: any): Observable<any> {
        return this.httpclient.post<any>(endpoint + 'api/transport/checkPdf', OBJ);
    }

    getUser(OBJ: any): Observable<user> {
        return this.httpclient.post<user>(endpoint + 'api/transport/getuser', OBJ);
    }

    getUserPermission(OBJ: any): Observable<user> {
        return this.httpclient.post<user>(endpoint + 'api/transport/getuserpermissions', OBJ);
    }

    getNewUsers(OBJ: any): Observable<user[]> {
        return this.httpclient.post<user[]>(endpoint + 'api/transport/newuserlist', OBJ);
    }

    addPlace(place: any) {
        return this.httpclient.post(endpoint + 'api/transport/addPlace', place);
    }
    addPump(pump: any) {
        return this.httpclient.post(endpoint + 'api/transport/addPetrolPump', pump);
    }
    addEmailId(COBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addEmailId', COBJ);
    }
    addCustomer(COBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addCustomer', COBJ);
    }
    addtyresupplier(COBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addTyreSupplier', COBJ);
    }
    updatetyresupplier(COBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateTyreSupplier', COBJ);
    }
    addPod(pod: any) {
        return this.httpclient.post(endpoint + 'api/transport/addpod', pod);
    }

    updatePod(pod: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatepod', pod);
    }

    addDriver(driverobj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addDriver', driverobj);
    }
    addEmployee(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addEmployee', obj);
    }
    addVehicleOwner(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addVehicleOwner', obj);
    }
    addVehicle(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addVehicle', obj);
    }
    addVehicleType(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addVehicleType', obj);
    }
    registerUser(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/registeruser', obj);
    }

    UpdateUser(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateuser', obj);
    }

    grantPermissions(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/addpermissions', obj);
    }

    grantPermissionsUpdate(obj: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatepermissions', obj);
    }

    addLR(LROBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addlrmaster', LROBJ);
    }

    addTPbill(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addbilldetails', OBJ);
    }
    viewpdf(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/getPdf', OBJ);
    }
    viewirpdf(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/getIrPdf', OBJ);
    }
    updateLR(LROBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateLRMaster', LROBJ);
    }

    addLR_Trxn(LRTXOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addlrtrxn', LRTXOBJ, this.options);
    }

    addDC(DCOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/adddcmaster', DCOBJ);
    }

    addLoadingSheet(LSOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addLoadingSheet', LSOBJ);
    }

    addBill(BillOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addBill', BillOBJ);
    }

    addCashMemo(BillOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addCashMemo', BillOBJ);
    }

    updateBill(BillOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateBill', BillOBJ);
    }

    updateCashMemo(BillOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateCashMemo', BillOBJ);
    }

    addFM(FMOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addfmmaster', FMOBJ);
    }
    addIR(IROBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addirmaster', IROBJ);
    }

    updateIR(IROBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateirmaster', IROBJ);
    }
    updateDC(DCUPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatedcmaster', DCUPDATEOBJ);
    }
    updateLoadingSheet(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateLoadingSheet', OBJ);
    }
    updateTR(TRUPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatetrbilldetails', TRUPDATEOBJ);
    }
    updateRB(TRUPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateregularbill', TRUPDATEOBJ);
    }
    updateBranch(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateBranch', UPDATEOBJ);
    }
    updateCustomer(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateCustomer', UPDATEOBJ);
    }
    updateEmailId(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateEmailId', UPDATEOBJ);
    }
    updateEmployee(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateEmployee', UPDATEOBJ);
    }
    updateArticle(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateArticle', UPDATEOBJ);
    }
    updateVehicleowner(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateVehicleOwner', UPDATEOBJ);
    }
    updateDriver(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateDriver', UPDATEOBJ);
    }

    updatePlace(PLACEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatePlace', PLACEOBJ);
    }

    updatePetrolPump(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatePetrolPump', OBJ);
    }

    updateVehicletype(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateVehicleType', OBJ);
    }

    updateVehicle(UPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updateVehicle', UPDATEOBJ);
    }
    updateFM(FMUPDATEOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/updatefmmaster', FMUPDATEOBJ);
    }
    getDctabledata(obj: any): Observable<dctabledata[]> {
        return this.httpclient.post<dctabledata[]>(endpoint + 'api/transport/dctabledata', obj);
    }
    getLRDetailsFromCheckbox(obj: any): Observable<dctabledata[]> {
        return this.httpclient.post<dctabledata[]>(endpoint + 'api/transport/getLRDetailsFromCheckbox', obj);
    }
    getlrdataforpod(obj: any): Observable<dctabledata[]> {
        return this.httpclient.post<dctabledata[]>(endpoint + 'api/transport/lrdataforpod', obj);
    }
    updateDctabledata(obj: any): Observable<dctabledata[]> {
        return this.httpclient.post<dctabledata[]>(endpoint + 'api/transport/dcupdatetabledata', obj);
    }
    getFmtabledata(obj: any): Observable<fmtabledata[]> {
        return this.httpclient.post<fmtabledata[]>(endpoint + 'api/transport/fmtrxntablelist', obj);
    }
    getPlacelist(): Observable<places[]> {
        return this.httpclient.post<places[]>(endpoint + 'api/transport/placelist', objs);
    }

    getVehicletypelist(OBJ: any): Observable<vehicletype[]> {
        return this.httpclient.post<vehicletype[]>(endpoint + 'api/transport/getAllVehicleType', OBJ);
    }
    getBranchlist(): Observable<Branch[]> {
        return this.httpclient.post<Branch[]>(endpoint + 'api/transport/getAllBranches', objs);
    }
    getBranchObject(obj: any): Observable<Branch> {
        return this.httpclient.post<Branch>(endpoint + 'api/transport/getBranchById', obj);
    }
    getUserPermissions(obj: any): Observable<permissions> {
        return this.httpclient.post<permissions>(endpoint + 'api/transport/getuserpermissions', obj);
    }
    getCusts(OBJ: any): Observable<customer[]> {
        return this.httpclient.post<customer[]>(endpoint + 'api/transport/getAllCusts', OBJ);
    }
    getCustomer(OBJ: any): Observable<customer[]> {
        return this.httpclient.post<customer[]>(endpoint + 'api/transport/getAllCustomers', OBJ);
    }
    getCustomerdetail(OBJ: any): Observable<customer[]> {
        return this.httpclient.post<customer[]>(endpoint + 'api/transport/getAllCustomersdetail', OBJ);
    }
    getEmails(OBJ: any): Observable<customer[]> {
        return this.httpclient.post<customer[]>(endpoint + 'api/transport/getAllEmails', OBJ);
    }
    getBillsForPaymentCollection(OBJ: any): Observable<payment_tabledata[]> {
        return this.httpclient.post<payment_tabledata[]>(endpoint + 'api/transport/getBillsForPayColl', OBJ);
    }

    getCashmemosForPaymentCollection(OBJ: any): Observable<payment_tabledata[]> {
        return this.httpclient.post<payment_tabledata[]>(endpoint + 'api/transport/getCashMemoForPayColl', OBJ);
    }

    addPaymentCollection(PCOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addPayCollection', PCOBJ);
    }
    addCashPaymentCollection(PCOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addCashPayCollection', PCOBJ);
    }



    getLRforBill(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getLRForBillByCust', OBJ);
    }

    getlrforbillByThirdParty(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getLRForForBillByThirdPartyCust', OBJ);
    }

    getLRforCashMemo(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getLRForCashMemoByCust', OBJ);
    }

    getVehicleOwners(OBJ: any): Observable<vehicleowner[]> {
        return this.httpclient.post<vehicleowner[]>(endpoint + 'api/transport/getAllVehicleOwner', OBJ);
    }
    getPlace() {
        return this.httpclient.post(endpoint + 'api/transport/getAllPlaces', objs);
    }
    getPetrolPump() {
        return this.httpclient.post(endpoint + 'api/transport/getAllPetrolPumps', objs);
    }

    getBranch() {
        return this.httpclient.post(endpoint + 'api/transport/getAllBranches', objs);
    }

    getallCustomer(): Observable<customer[]> {
        return this.httpclient.post<customer[]>(endpoint + 'api/transport/allcustomerlist', objs);
    }

    getallvehicle(): Observable<vehicle[]> {
        return this.httpclient.post<vehicle[]>(endpoint + 'api/transport/allvehiclelist', objs);
    }

    getallvehicleowner(): Observable<vehicleowner[]> {
        return this.httpclient.post<vehicleowner[]>(endpoint + 'api/transport/allvehicleownerlist', objs);
    }

    getLrmaster(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getAllLRs', OBJ);
    }
    Lrsearch(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/lr_search', OBJ);
    }
    getLRForLoadingTrip(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getLRForLoadingSheet', OBJ);
    }
    getLRForLoadingTripUpdate(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getLRForLSUpdate', OBJ);
    }
    getLrfordcupdate(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getlrfordcupdate', OBJ);
    }
    getLrmasterfordc(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/lrmasterlistfordc', OBJ);
    }
    getLrmasterforpod(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/lrmasterlistforpod', OBJ);
    }
    getVehicle(OBJ: any): Observable<vehicle[]> {
        return this.httpclient.post<vehicle[]>(endpoint + 'api/transport/getAllVehicles', OBJ);
    }
    getVehicledetail(OBJ: any): Observable<vehicle[]> {
        return this.httpclient.post<vehicle[]>(endpoint + 'api/transport/getAllVehiclesdetail', OBJ);
    }
    getVehiclefortpbill(OBJ: any): Observable<vehicle[]> {
        return this.httpclient.post<vehicle[]>(endpoint + 'api/transport/vehiclesfortp', OBJ);
    }
    getLrno(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/generatelrno', OBJ);
    }
    getFmno(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/generatefmno', OBJ);
    }
    sendstockreport(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/sendstockreport', OBJ);
    }
    getDrivers(OBJ: any): Observable<driverlist[]> {
        return this.httpclient.post<driverlist[]>(endpoint + 'api/transport/drivershortlist', OBJ);
    }
    getPODObject(OBJ: any): Observable<pod> {
        return this.httpclient.post<pod>(endpoint + 'api/transport/getpodobject', OBJ);
    }

    getVehicleownerObject(OBJ: any): Observable<vehicleowner> {
        return this.httpclient.post<vehicleowner>(endpoint + 'api/transport/getVehicleOwnerById', OBJ);
    }

    getEmployeeObject(OBJ: any): Observable<employees> {
        return this.httpclient.post<employees>(endpoint + 'api/transport/getEmployeeById', OBJ);
    }
    getDriverObject(OBJ: any): Observable<driverobject> {
        return this.httpclient.post<driverobject>(endpoint + 'api/transport/getDriverById', OBJ);
    }

    getVehicleObject(OBJ: any): Observable<driverobject> {
        return this.httpclient.post<driverobject>(endpoint + 'api/transport/getVehicleById', OBJ);
    }
    getTyreSupplierById(OBJ: any): Observable<driverobject> {
        return this.httpclient.post<driverobject>(endpoint + 'api/transport/getTyreSupplierById', OBJ);
    }
    getDriverlist(): Observable<drivers[]> {
        return this.httpclient.post<drivers[]>(endpoint + 'api/transport/getAllDrivers', objs);
    }
    getTyreSupplier(): Observable<drivers[]> {
        return this.httpclient.post<drivers[]>(endpoint + 'api/transport/getAllTyreSuppliers', objs);
    }
    getEmployeeslist(OBJ: any): Observable<employees[]> {
        return this.httpclient.post<employees[]>(endpoint + 'api/transport/getAllEmployees', OBJ);
    }

    getDclist(OBJ: any): Observable<[dclist]> {
        return this.httpclient.post<[dclist]>(endpoint + 'api/transport/dcmasterlist', OBJ);
    }

    getLoadingtriplist(OBJ: any): Observable<[dclist]> {
        return this.httpclient.post<[dclist]>(endpoint + 'api/transport/getAllLoadingSheets', OBJ);
    }
    getLoadingtriplistPage(OBJ: any): Observable<[dclist]> {
        return this.httpclient.post<[dclist]>(endpoint + 'api/transport/getAllLoadingSheetsPage', OBJ);
    }

    LTSsearch(OBJ: any): Observable<[dclist]> {
        return this.httpclient.post<[dclist]>(endpoint + 'api/transport/lts_search', OBJ);
    }

    LTSsearchPage(OBJ: any): Observable<[dclist]> {
        return this.httpclient.post<[dclist]>(endpoint + 'api/transport/lts_searchPage', OBJ);
    }

    getBilllist(OBJ: any): Observable<[bill]> {
        return this.httpclient.post<[bill]>(endpoint + 'api/transport/getAllBills', OBJ);
    }

    BillSearch(OBJ: any): Observable<[bill]> {
        return this.httpclient.post<[bill]>(endpoint + 'api/transport/bill_search', OBJ);
    }
 
    getCashMemolist(OBJ: any): Observable<[bill]> {
        return this.httpclient.post<[bill]>(endpoint + 'api/transport/getAllCashMemos', OBJ);
    }

    getCashMemolistPage(OBJ: any): Observable<[bill]> {
        return this.httpclient.post<[bill]>(endpoint + 'api/transport/getAllCashMemosPage', OBJ);
    }

    CashMemoSearch(OBJ: any): Observable<[bill]> {
        return this.httpclient.post<[bill]>(endpoint + 'api/transport/cashmemo_search', OBJ);
    }

    getFmtablelist(obj): Observable<[fmlist]> {
        return this.httpclient.post<[fmlist]>(endpoint + 'api/transport/fmlistdata', obj);
    }
    getplace(obj): Observable<places> { 
        return this.httpclient.post<places>(endpoint + 'api/transport/getPlaceById', obj);
    }
    getPetrolPumpbyid(obj): Observable<places> {
        return this.httpclient.post<places>(endpoint + 'api/transport/getPetrolPumpById', obj);
    }
    getarticleobject(obj): Observable<articles> {
        return this.httpclient.post<articles>(endpoint + 'api/transport/getArticleById', obj);
    }
    getvtypeobject(obj): Observable<vehicletype> {
        return this.httpclient.post<vehicletype>(endpoint + 'api/transport/getVehicleTypeById', obj);
    }
    getIrmaster(obj): Observable<[irmaster]> {
        return this.httpclient.post<[irmaster]>(endpoint + 'api/transport/findirmaster', obj);
    }
    getInwarddata(obj): Observable<Inwarddata[]> {
        return this.httpclient.post<Inwarddata[]>(endpoint + 'api/transport/inwardlrdata', obj);
    }

    getIrtablelist(obj): Observable<irlist[]> {
        return this.httpclient.post<irlist[]>(endpoint + 'api/transport/irtablelist', obj);
    }

    getPodlist(obj): Observable<pod[]> {
        return this.httpclient.post<pod[]>(endpoint + 'api/transport/poduploadlist', obj);
    }

    getTransportbillvehicladata(obj): Observable<fmmaster[]> {
        return this.httpclient.post<fmmaster[]>(endpoint + 'api/transport/fmtransportobject', obj);
    }

    getLrData(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/getLRById', obj);
    }
    getLrDataForBill(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/getAllLRDetailsById', obj);
    }
    checklrupdate(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/checkLRForUpdate', obj);
    }
    checkdcupdate(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/checkdcupdate', obj);
    }
    checkmemoupdate(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/checkmemoupdate', obj);
    }
    getLrBillData(obj): Observable<lrdata> {
        return this.httpclient.post<lrdata>(endpoint + 'api/transport/lrobjectbill', obj);
    }
    getDclistData(obj: any): Observable<fmtransactions[]> {
        return this.httpclient.post<fmtransactions[]>(endpoint + 'api/transport/dclistforfm', obj);
    }
    getDCforfmupdate(OBJ: any): Observable<fmtransactions[]> {
        return this.httpclient.post<fmtransactions[]>(endpoint + 'api/transport/getdcforfmupdate', OBJ);
    }
    getDclistDataIR(obj: any): Observable<fmtransactions[]> {
        return this.httpclient.post<fmtransactions[]>(endpoint + 'api/transport/dclistforinward', obj);
    }
    getDclistDataIRAll(obj: any): Observable<fmtransactions[]> {
        return this.httpclient.post<fmtransactions[]>(endpoint + 'api/transport/dclistallforinward', obj);
    }
    getDcData(obj): Observable<dcdata> {
        return this.httpclient.post<dcdata>(endpoint + 'api/transport/dcobject', obj);
    }
    getBillData(obj): Observable<bill> {
        return this.httpclient.post<bill>(endpoint + 'api/transport/getBillById', obj);
    }
    getCashMemoData(obj): Observable<bill> {
        return this.httpclient.post<bill>(endpoint + 'api/transport/getCashMemoById', obj);
    }
    SendMail(obj) {
        return this.httpclient.post(endpoint + 'api/transport/sendEmailPDF', obj);
    }
    getloadingsheet(obj): Observable<dcdata> {
        return this.httpclient.post<dcdata>(endpoint + 'api/transport/getLoadingSheetById', obj);
    }
    getTrData(obj): Observable<trdata> {
        return this.httpclient.post<trdata>(endpoint + 'api/transport/gettrbillbyid', obj);
    }

    getRbData(obj): Observable<trdata> {
        return this.httpclient.post<trdata>(endpoint + 'api/transport/getregbillbyid', obj);
    }
    getCustomerbyId(obj: any): Observable<customer> {
        return this.httpclient.post<customer>(endpoint + 'api/transport/getCustomerById', obj);
    }
    getEmailById(obj: any): Observable<customer> {
        return this.httpclient.post<customer>(endpoint + 'api/transport/getEmailById', obj);
    }
    getFmData(obj): Observable<fmdata> {
        return this.httpclient.post<fmdata>(endpoint + 'api/transport/fmobject', obj);
    }

    deleteLr(obj): Observable<deleteBo[]> {
        return this.httpclient.post<deleteBo[]>(endpoint + 'api/transport/deleteLRMaster', obj);
    }

    deletePlace(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletePlaces', obj);
    }
    deletePetrolPump(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletePetrolPump', obj);
    }
    deleteTyreSupplier(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteTyreSupplier', obj);
    }
    deleteEmployee(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteEmployee', obj);
    }
    deleteCustomer(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteCustomer', obj);
    }
    deleteEmailId(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteEmailId', obj);
    }
    deleteArticle(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteArticle', obj);
    }
    deleteVehicleOwner(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteVehicleOwner', obj);
    }
    deleteVehicleType(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteVehicleType', obj);
    }
    deleteVehicle(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteVehicle', obj);
    }
    deleteDriver(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteDriver', obj);
    }
    deleteBranch(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteBranches', obj);
    }
    deleteDc(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletedc', obj);
    }
    deleteBill(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteBills', obj);
    }
    deleteLS(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteLoadingSheets', obj);
    }
    deleteIr(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteir', obj);
    }
    deleterb(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deleteregbill', obj);
    }
    deletetr(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletetrbill', obj);
    }
    deleteFm(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletefm', obj);
    }
    deletePod(obj) {
        return this.httpclient.post(endpoint + 'api/transport/deletepod', obj);
    }
    makeZip(OBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/zipbuilderlr', OBJ);
    }
    UploadFile(file: any) {
        return this.httpclient.post(endpoint + 'api/transport/upload', file);
    }
    private docToFormData(doc) {
        let formData: FormData = new FormData();
        formData.append('file', doc.file, doc.file.name);
        formData.append('metadata', new Blob([JSON.stringify(doc)], { type: "application/json" }));
        return formData;
    }
    gettpbillmemolist(obj: any): Observable<fmmaster[]> {
        return this.httpclient.post<fmmaster[]>(endpoint + 'api/transport/tpbillmemolist', obj);
    }
    gettpbillmemolist1(obj: any): Observable<fmmaster[]> {
        return this.httpclient.post<fmmaster[]>(endpoint + 'api/transport/checkedfmlistfortpbill', obj);
    }
    getrbbilllrlist1(obj: any): Observable<fmmaster[]> {
        return this.httpclient.post<fmmaster[]>(endpoint + 'api/transport/getlrbyregbill', obj);
    }
    gettpbillmemodata(obj: any): Observable<fmmaster[]> {
        return this.httpclient.post<fmmaster[]>(endpoint + 'api/transport/fmmasterlistfortpbill', obj);
    }
    getLrmasterforrb(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getlrfrompod', OBJ);
    }
    getLrmasterforrb1(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/getlrbyregbill', OBJ);
    }
    addRB(DCOBJ: any) {
        return this.httpclient.post(endpoint + 'api/transport/addregularbill', DCOBJ);
    }
    getRbmaster(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/regularbilllist', OBJ);
    }
    getTRmaster(OBJ: any): Observable<lrmaster[]> {
        return this.httpclient.post<lrmaster[]>(endpoint + 'api/transport/transporterbilllist', OBJ);
    }
}
