import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { DatePipe } from '@angular/common';
import { vehicleowner } from '../../../Models/vehicleowner';
import { vehicle } from '../../../Models/vehicle';
import { trbillreport } from '../../../Models/trbillreport';
import swal from "sweetalert2";
import { Excellservice } from '../../../Services/excellservice.service';
declare var $: any;

@Component({
  selector: 'app-transporter-bill-report',
  templateUrl: './transporter-bill-report.component.html',
  styleUrls: ['./transporter-bill-report.component.css'],
  providers:[TpserviceService, DatePipe, Excellservice]
})
export class TransporterBillReportComponent implements OnInit {

  USRBRSTATE:string="";
  todate:string="";
  fromdate:string="";
  transporter:vehicleowner[];
  vehicles:vehicle[];
  trans_name:string="0";
  trreportlist:trbillreport[];
  trbilllistexport:trbillreport[];
  vehicleno:string="0";
  flag:boolean;

  constructor(private tps:TpserviceService, private datepipe:DatePipe, private detectchages: ChangeDetectorRef, private excellservice:Excellservice) { }

  ngOnInit() {
    this.flag=true;
    //this.displaybranches();
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
    this.getvehicleownerlist();
    this.getvehiclelist();
  }

  ngAfterViewInit() {

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    var self=this;
    $(document).ready(function(){
      $("#trans_name").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#trans_name").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.trans_name=id;
     });

     $(document).ready(function(){
      $("#vehicleno").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#vehicleno").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.vehicleno=id;
     });
    
  }

  gettabledata(data)
  {
    this.trreportlist=[];
    $("#products-table").dataTable().fnDestroy();
    this.trreportlist=data;
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }

  getvehicleownerlist(){
    this.tps.getallvehicleowner().subscribe(
      data =>this.transporter=data
     ,error=> console.log(error)
          
  );
  }

  getvehiclelist(){
    this.tps.getallvehicle().subscribe(
      data =>this.vehicles=data
     ,error=> console.log(error)
          
  );
  }

  getlist(){
    this.trreportlist=[];
    this.flag=false;
    this.gettrreportlist();
  }

  gettrreportlist()
  {
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      vehicle_no: this.vehicleno,
      owner:this.trans_name
    }
  //   this.tps.getTRbillReport(obj).subscribe(
      
  //     data => this.gettabledata(data)
  // );
}

Exportasexcell(){

  if(this.fromdate=='' && this.todate==''){
    swal('Warning','Please Select Date First !','warning');
  }
  else
  {
  const obj={
    from_date:this.fromdate,
    to_date:this.todate,
    vehicle_no: this.vehicleno,
    owner:this.trans_name
  }

//   this.tps.getTRbillReport(obj).subscribe(
//     data => this.Afterdata(data)
// );
}
}

Afterdata(data){
  this.trbilllistexport=[];
  this.trbilllistexport=data;
  this.excellservice.exportAsExcelFile(this.trbilllistexport, 'TransporterBillReport');
}

}
