import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-table-6',
  templateUrl: './pricing-table-6.component.html',
})
export class PricingTable6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
