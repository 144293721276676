import { Component, NgModule, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
declare var $: any;
import { CommonModule } from '@angular/common';
import { TpserviceService } from '../../../Services/tpservice.service';
import { places } from '../../../Models/places';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Branch } from '../../../Models/Branch';
NgModule({
  declarations: [],
  imports: [CommonModule],


});
@Component({
  selector: 'app-form-layouts',
  templateUrl: './branches.component.html',
  providers: [TpserviceService],
})
export class BranchesComponent implements OnInit, AfterViewInit {
  places: places[];
  branchcode: string;
  abbrevation: string;
  name: string;
  description: string;
  place: string;
  datastate: string = "Save";
  branchdata: Branch;
  constructor(private tps: TpserviceService, private router: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.getplacelist();
    if (localStorage.getItem("branchdatastate") == "1") {
      this.datastate = "Update";
      this.displaybranchdata();

      localStorage.removeItem("branchdatastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        name: {
          required: !0
        },
        abbrevation: {
          required: !0,

        },
        place: {
          required: !0,
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onSubmit();
      }
    });

    var flag = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && flag == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        flag = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      flag = false;
    });

    $(document).ready(function () {
      $("#place").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#place").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.place = id;
    });
  }

  getplacelist(){
    this.tps.getPlace().subscribe(
      data => this.getdefault(data)
    );
  }
  
  getdefault(data) {
    this.places = data;
  }

  onSubmit() {
    if (!(this.datastate == "Update")) {
      const obj1 = {
        branchcode: this.branchcode,
        description: this.description,
        abbrevation: this.abbrevation,
        branchname: this.name,
        placeid: this.place
      }
      this.tps.addBranch(obj1).subscribe(
        data => this.aftersubmit(data)


      );
    }
    else {
      this.updatebranch();
    }
  }

  aftersubmit(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Branch-List']);

  }
  updatebranch() {


    const obj = {
      branchcode: this.branchcode,
      abbrevation: this.abbrevation,
      description: this.description,
      branchname: this.name,
      bid: this.branchdata.bid,
      placeid: this.place
    }
    this.tps.updateBranch(obj).subscribe(
      data => this.afterupdate(data)


    );


  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Branch-List']);

  }
  displaybranchdata() {

    this.branchdata = JSON.parse(localStorage.getItem("branchdata"));
    localStorage.removeItem("branchdata");
    this.branchcode = this.branchdata.branchcode;
    this.abbrevation = this.branchdata.abbrevation;
    this.name = this.branchdata.branchname;
    this.description = this.branchdata.description;
    this.place = this.branchdata.placeid;
  }
  redirect() {

    this.router.navigate(['pages/Branch-List']);
  }
}
