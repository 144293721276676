import { Component, OnInit, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { dcdata } from '../../../Models/dcdata';
import { lrmaster } from '../../../Models/lrmaster';
import { dctabledata } from '../../../Models/dctabledata';
import { vehicle } from '../../../Models/vehicle';
import { driverlist } from '../../../Models/driverlist';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Inwarddata } from '../../../Models/Inwarddata';
import { permissions } from '../../../Models/permissions';
import { DatePipe } from '@angular/common';
import { Branch } from '../../../Models/Branch';
import { user } from '../../../Models/user';

const openpr = "(";
const closepr = ")";
var datastr: String = "";
const com = ",";

declare var $: any;

@Component({
  selector: 'app-form-layouts',
  templateUrl: './dc.component.html',
  providers: [TpserviceService, DatePipe]
})

@Pipe({ name: 'search' })
export class DcComponent implements OnInit, AfterViewInit, PipeTransform {
  permissions: permissions;
  inwarddata: Inwarddata[] = [];
  vehicles: vehicle[] = [];
  driver: driverlist[] = [];
  vehicleobject: any = {};
  driverobj: any = {};
  place: any = {};
  dcdata: dcdata;
  dcupdateid: any = "";
  datastate: any = "Save";
  userdata: user;
  user: string = "";
  branches: Branch[];
  query: any;
  id: string;
  check: boolean;



  lrs: any[] = [];
  inwards: any[] = [];
  dctabledata: dctabledata[] = [];
  getdate: any;
  lrdata: lrmaster[] = [];
  lrsearch = '';
  selectedlrstr: string = "";
  selectedirstr: string = "";
  selectedlr: string = "";
  openstr: string = "(";
  closestr: string = ")";
  com: string = ",";
  sendcallbacklr: string = "";
  checkflag: boolean = false;
  branch: string = "";
  dcno: string = "";
  dcdate: any = "";
  vehicleno: string = "";
  vehicleowner: string = "";
  vehicletype: string = "";
  owneraddress: string = "";
  ownercontact: string = "";
  drivername: string = "";
  licenseno: string = "";
  mobileno: string = "";
  drivername2: string = "";
  licenseno2: string = "";
  mobileno2: string = "";
  toloc: string = "";
  fromloc: string = "";
  transportmode: string = "Surface";
  deliverytype: string = "Collection";
  totalpackages: any = "0";
  totalweight: any = "0";
  remark: string = "";
  disablestate: any;
  mainstr: string = "";
  selectedinv: string = "";
  multipleselect: Array<any> = [];

  public rowdata: Array<{
    id: string,
    sr_no: string,
    inv_no: string,
    inv_date: string;
    description: string;
    articles: string;
    no_of_articles: string;
    quantity: string;
    inv_amt: string;
    lr_no: string;
    lr_date: string;
    consigner: string;
    from_loc: string;
    consignee: string;
    to_loc: string;
    actual_wt: string;
    lr_master_id: string;
  }> = [];

  constructor(private tps: TpserviceService, private route: Router, private datepipe: DatePipe) { }

  ngOnInit() {
    this.check = false;
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.user = this.userdata.id;
    this.dcdate = Date.now();
    this.displaybranches();
    this.disablestate = localStorage.getItem("disablestate");
    if (localStorage.getItem("datastate") == "1") {

      this.datastate = "Update"
      this.displaydcdata();
      this.displaylr1();
      localStorage.removeItem("datastate");

      for (var i = 0; i < this.dctabledata.length; i++) {
        if (this.dctabledata[i].status == '1') {
          this.dctabledata[i].selected = "true";
        }
        else {
          this.dctabledata[i].selected = "false";
        }
      }
    }
    else {
      this.displaylr();
    }
    this.getdate = Date.now();
    this.lrs = [ ];
    this.inwards = [ ]
  }

  ngAfterViewInit() {
    var self = this;
    this.dcdate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    $('#form-sample-1').validate({
      rules: {
        dcno: {
          required: !0
        },
        dcdate: {

          required: !0,

        },
        vehicleno: {
          required: !0,

        },
        drivername: {
          required: !0,

        },
        licenseno: {
          required: !0,

        },
        mobileno: {
          required: !0,
          minlength: 10,
          number: !0
        },
        toloc: {
          required: !0

        },

        fromloc: {
          required: !0

        },
        transportmode: {
          required: !0

        },
        deliverytype: {
          required: !0

        },
        totalpackages: {
          required: !0,

        },
        totalweight: {
          required: !0,

        },
        remark: {
          required: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.submitdcmaster();
      }
    });

    var flag = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && flag == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        flag = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      flag = false;
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.changed();
    });

    $(document).ready(function () {
      $("#vehicleno").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#vehicleno").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.vehicleno = id;
      self.onchangevehicle(evt);
    });

    $(document).ready(function () {
      $("#drivername").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#drivername").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.drivername = id;
      self.onchangeselect(evt);
    });

    $(document).ready(function () {
      $("#drivername2").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#drivername2").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.drivername2 = id;
      self.onchangeselect2(evt);
    });

    $(document).ready(function () {
      $("#toloc").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#toloc").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.toloc = id;
    });
  }

  public transform(value, keys: string, term: string) {
    if (!term) return value;
    return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));

  }

  changed() {
    localStorage.setItem("branch", this.branch);
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getInwarddata(obj).subscribe(
      data => this.inwarddata = data
    );
    this.displaylr();
    this.generatedcno();
    let index = this.branches.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branches[index].placeid;
    this.onbranch();
  }

  onbranch() {
    let index = this.branches.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branches[index].placeid;
  }

  displaylr() {
    const obj = {
      branch: this.branch,
      dcid: this.dcno
    }
    this.tps.getLrfordcupdate(obj).subscribe(
      data => this.lrdata = data
    );
  }

  displaylr1() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getLrmaster(obj).subscribe(
      data => this.lrdata = data
    );
  }

  checkclicklr(data, e) {
    if (e.target.checked) {
      var temp = this.selectedlrstr.replace(data + this.com, "");
      var tmp1 = this.selectedlr.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "")
      this.selectedlrstr = temp;
      this.selectedlr = tmp1;
      this.selectedlrstr += data + this.com;
      this.selectedlr += this.openstr + data + this.com + "@@" + this.closestr + this.com;
    }
    else {
      var temp1 = this.selectedlrstr.replace(data + this.com, "");
      var temp = this.selectedlr.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "");
      this.selectedlrstr = temp1;
      this.selectedlr = temp;
    }
  }

  checkclickir(data, e) {
    if (e.target.checked) {
      var temp = this.selectedirstr.replace(data + this.com, "");
      var tmp1 = this.selectedlr.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "")
      this.selectedirstr = temp;
      this.selectedlr = tmp1;
      this.selectedirstr += data + this.com;
      this.selectedlr += this.openstr + data + this.com + "@@" + this.closestr + this.com;
    }
    else {
      var temp1 = this.selectedirstr.replace(data + this.com, "");
      var temp = this.selectedlr.replace(this.openstr + data + this.com + "@@" + this.closestr + this.com, "");
      this.selectedirstr = temp1;
      this.selectedlr = temp;
    }
  }


  addtabledata() {
    if (this.datastate == "Save") {
      this.insertabledata();
    }
    else {
      this.updatedctabledata();
    }
  }

  updatedctabledata() {
    let datatb = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
    let datatb1 = this.openstr + this.selectedirstr.substr(0, this.selectedirstr.length - 1) + this.closestr;
    // console.log("data is_"+datatb);
    const obj = {
      checkeddata: datatb,
      checkeddata1: this.dcdata.dcbo.dc_id,
      checkeddata2: datatb1,
    }
    this.tps.updateDctabledata(obj).subscribe(
      data =>
        this.afterupdatedctabledata(data)
    );
  }

  afterupdatedctabledata(data) {
    var totalwt = 0;
    var totalpck = 0;
    this.dctabledata = data;
    this.dctabledata.forEach(function (element) {
      totalpck = totalpck + +element.no_of_articles;
      totalwt = totalwt + +element.actual_wt;
    })
    for (var i = 0; i < this.dctabledata.length; i++) {
      if (this.dctabledata[i].status == '1') {
        this.dctabledata[i].selected = "true";
      }
      else {
        this.dctabledata[i].selected = "false";
      }
    }
    this.totalpackages = totalpck;
    this.totalweight = totalwt;
  }

  insertabledata() {
    let datatb = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
    let datatb1 = this.openstr + this.selectedirstr.substr(0, this.selectedirstr.length - 1) + this.closestr;
    // console.log("data is_"+datatb);
    const obj = {
      checkeddata: datatb,
      checkeddata1: datatb1
    }
    this.tps.getDctabledata(obj).subscribe(
      data =>this.afterdctabledata(data)
    );
  }

  afterdctabledata(data) {
    var totalwt = 0;
    var totalpck = 0;
    this.dctabledata = data;
    this.dctabledata.forEach(function (element) {
      totalpck = totalpck + +element.no_of_articles;
      totalwt = totalwt + +element.actual_wt;
    })
    for (var i = 0; i < this.dctabledata.length; i++) {
      this.dctabledata[i].selected = "true";
    }
    this.totalpackages = totalpck;
    this.totalweight = totalwt;
  }

  Multiselect(index, ev) {
    if (this.dctabledata[index].selected == 'false') {
      this.dctabledata[index].selected = 'true';
    }
    else {
      this.dctabledata[index].selected = 'false';
    }
  }

  invoice() {
    const com = ",";
    this.selectedinv = "";
    var records = 0;
    for (var i = 0; i < this.dctabledata.length; i++) {
      if (this.dctabledata[i].selected == 'true') {
        this.multipleselect.push(i);
        this.selectedinv = this.selectedinv + this.openstr + this.dctabledata[i].lr_master_id + com + this.dctabledata[i].id + com + "1,0,@@" + this.closestr + com;
        records++;
      }
    }
  }

  submitdcmaster() {
    if ((this.selectedlrstr == "()" || this.selectedlrstr == "") && (this.selectedirstr == "()" || this.selectedirstr == "")) {
      swal('warning', 'Please choose atleast one LR', 'warning');
    }
    else {
      this.invoice();
      if (this.selectedinv == "()" || this.selectedinv == "") {
        swal('warning', 'Please choose atleast one Invoice', 'warning');
      }
      else {
        if (!(this.datastate == "Update")) {
          var datatb = this.selectedlrstr.substr(0, this.selectedlrstr.length - 1);
          var datatb1 = this.selectedirstr.substr(0, this.selectedirstr.length - 1);
          var datatb2 = this.openstr + datatb + com + datatb1 + this.closestr;
          var lrdatatb = this.selectedlr.substr(0, this.selectedlr.length - 1);
          var invdata = this.selectedinv.substr(0, this.selectedinv.length - 1);

          const dcobj = {
            dc_no: this.dcno,
            dc_date: this.dcdate,
            vehicle_number: this.vehicleno,
            vehicle_owner: this.vehicleowner,
            vehicle_type: this.vehicletype,
            owner_address: this.owneraddress,
            owner_contact: this.ownercontact,
            driver_name: this.drivername,
            license_name: this.licenseno,
            mobile_no: this.mobileno,
            driver_name2: this.drivername2,
            license_name2: this.licenseno2,
            mobile_no2: this.mobileno2,
            from_loc: this.fromloc,
            to_loc: this.toloc,
            transport_mode: this.transportmode,
            delivery_type: this.deliverytype,
            total_packages: this.totalpackages,
            total_weight: this.totalweight,
            user_id: this.user,
            remarks: this.remark,
            dcdata: invdata,
            lrdata: datatb2,
            branch: this.branch
          }
          swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 150
          });
          swal.showLoading();
          this.tps.addDC(dcobj).subscribe(
            data => this.aftersubmit(data)
          );
        }
        else {
          this.updatedcmaster();
        }
      }
    }
  }

  aftersubmit(data) {
    this.id = data['res_code'];
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error');
    //this.route.navigate(['pages/DC']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;

  }

  onselect() {
    if (this.deliverytype == "Customer End") {
      this.check = true;
    }
    else {
      this.check = false;
    }
  }

  getformdata() {
    const ob = {
      checkeddata: this.branch
    }
    this.tps.getVehicle(ob).subscribe(
      data => this.vehicles = data
      , error => console.log(error)
    );
    const obj = {
      checkeddata: this.branch
    }
    this.generatedcno();
    this.tps.getDrivers(obj).subscribe(
      data => this.driver = data
      , error => console.log(error)
    );

    this.displaylr();
    this.inwardlrdata();
    this.tps.getPlace().subscribe(
      data =>
        this.afterplace(data), error => console.log(error)
    );
    
    this.onbranch();
  }

  afterplace(data) {
    this.place = data
    this.onbranch();
  }

  inwardlrdata(){
    const obj={
      checkeddata: this.branch
    }
    this.tps.getInwarddata(obj).subscribe(
      data => this.afterinwardlrdata(data));
  }

  afterinwardlrdata(data){
    this.inwarddata=data;
  }

  onchangevehicle(e) {
    let index = this.vehicles.findIndex(datas => datas.vehicleid == e.target.value);
    if (this.vehicles[index].status == "0") {
      swal('warning', 'The Vehicle is Expired!!', 'warning');
      this.vehicleowner = "";
      this.vehicletype = "";
      this.owneraddress = "";
      this.ownercontact = "";
    }
    else {
      this.vehicleowner = this.vehicles[index].vehical_owner_name;
      this.vehicletype = this.vehicles[index].vehicle_type;
      this.owneraddress = this.vehicles[index].address;
      this.ownercontact = this.vehicles[index].telephoneno;
    }
  }

  onchangeselect(e) {
    let index = this.driver.findIndex(datas => datas.driver_id == e.target.value);
    this.licenseno = this.driver[index].licenseno;
    this.mobileno = this.driver[index].mobileno;
  }

  onchangeselect2(e) {
    let index1 = this.driver.findIndex(datas => datas.driver_id == e.target.value);
    this.licenseno2 = this.driver[index1].licenseno;
    this.mobileno2 = this.driver[index1].mobileno;
  }

  generatedcno() {
    if (!(this.datastate == "Update")) {
      const obj = {
        checkeddata: this.branch
      }
      this.tps.getDcno(obj).subscribe(
        data =>
          this.dcno = data['messasge']
      );
    }
  }

  displaydcdata() {

    this.dcdata = JSON.parse(localStorage.getItem("data"));
    localStorage.removeItem("data");
    this.checkflag = true;
    this.branch = this.dcdata.dcbo.branch;
    this.dcno = this.dcdata.dcbo.dc_no;
    this.dcdate = this.dcdata.dcbo.dc_date;
    this.vehicleno = this.dcdata.dcbo.vehicle_number;
    this.vehicletype = this.dcdata.dcbo.vehicle_type;
    this.vehicleowner = this.dcdata.dcbo.vehicle_owner;
    this.owneraddress = this.dcdata.dcbo.owner_address;
    this.ownercontact = this.dcdata.dcbo.owner_contact;
    this.drivername = this.dcdata.dcbo.driver_name;
    this.licenseno = this.dcdata.dcbo.license_name;
    this.mobileno = this.dcdata.dcbo.mobile_no;
    this.drivername2 = this.dcdata.dcbo.driver_name2;
    this.licenseno2 = this.dcdata.dcbo.license_name2;
    this.mobileno2 = this.dcdata.dcbo.mobile_no2;
    this.fromloc = this.dcdata.dcbo.from_loc;
    this.toloc = this.dcdata.dcbo.to_loc;
    this.transportmode = this.dcdata.dcbo.transport_mode;
    this.deliverytype = this.dcdata.dcbo.delivery_type;
    this.totalpackages = this.dcdata.dcbo.total_packages;
    this.totalweight = this.dcdata.dcbo.total_weight;
    this.remark = this.dcdata.dcbo.remarks
    this.dctabledata = this.dcdata.dcmastertabledataBO;
    this.rowdata = this.dcdata.dcmastertabledataBO;
    this.dcupdateid = this.dcdata.dcbo.dc_id;
    this.id = this.dcdata.dcbo.dc_id;
    this.splitdata();
  }

  splitdata() {
    let strdata: string = "";
    let strdatalr: string = "";
    this.rowdata.forEach(function (element) {
      if (!strdata.includes(element.lr_master_id.toString())) {
        strdata += element.lr_master_id.toString() + ",";
        strdatalr += "(" + element.lr_master_id.toString() + "," + "@@),"
      }
    })

    // console.log(strdata.toString());
    // console.log(this.rowdata);
    this.selectedlrstr = strdata;
    this.selectedirstr = strdata;
    this.sendcallbacklr = strdata;
    this.sendcallbacklr = this.openstr + strdata.substr(0, strdata.length - 1) + this.closestr;
    this.selectedlr = strdatalr;
  }

  displaybranches() {
    this.tps.getBranchlist().subscribe(
      data =>
        this.afterbranch(data)
    );
  }

  afterbranch(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
    this.getformdata();
    let index = this.branches.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branches[index].placeid;
    this.onbranch();
  }

  checkdata(obj): boolean {
    if (this.dctabledata.some(e => e.lr_no == obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkinv(obj): boolean {
    if (obj == '1') {
      return true;
    }
    else {
      return false;
    }
  }

  updatedcmaster() {
    if (this.selectedlrstr == "()") {
      swal('warning', 'warning'), error => swal('Please choose atleast one LR', error, 'warning')
    }
    else {
      this.invoice();
      var invdata = this.selectedinv.substr(0, this.selectedinv.length - 1);
      var datatbupdate = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
      var datatbupdate1 = this.openstr + this.selectedirstr.substr(0, this.selectedirstr.length - 1) + this.closestr;
      var lrdatatbupdate = this.selectedlr.substr(0, this.selectedlr.length - 1);
      // console.log("data is_" + datatbupdate);
      // console.log("lrdata is" + lrdatatbupdate);
      const dcobj = {

        dc_no: this.dcno,
        dc_date: this.dcdate,
        vehicle_number: this.vehicleno,
        vehicle_owner: this.vehicleowner,
        vehicle_type: this.vehicletype,
        owner_address: this.owneraddress,
        owner_contact: this.ownercontact,
        driver_name: this.drivername,
        license_name: this.licenseno,
        mobile_no: this.mobileno,
        driver_name2: this.drivername2,
        license_name2: this.licenseno2,
        mobile_no2: this.mobileno2,
        from_loc: this.fromloc,
        to_loc: this.toloc,
        transport_mode: this.transportmode,
        delivery_type: this.deliverytype,
        total_packages: this.totalpackages,
        total_weight: this.totalweight,
        remarks: this.remark,
        dcdata: invdata,
        lrdata: datatbupdate,
        branch: this.branch,
        dc_id: this.dcupdateid,
        callbackdata: this.sendcallbacklr,
        user_id: this.user

      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 150
      });
      swal.showLoading();
      this.tps.updateDC(dcobj).subscribe(
        data => this.afterupdate(data)
      );
    }
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => console.log(error);
    //this.route.navigate(['pages/DC']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  pdfredirect() {
    const ob = {
      id: this.id,
      type: 'dc_master',
    }
    this.tps.viewpdf(ob).subscribe(
      data => this.afterpdf(data)
      , error => swal('Please check your internet connection', error, 'error')
    );
  }

  afterpdf(data) {
    if (this.dcno == "") {
      swal('warning', 'Please add LR first!', 'warning')
    }
    else {
      swal({
        //title: 'Download File',
        showCancelButton: true,
        width: '80%',
        showCloseButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        html: '<embed id="viewfile" src="' + data['location'] + '" height="500px" width="100%"/>',
        preConfirm: () => {
          return document.getElementById("viewfile");
        }
      })
    }
  }

  removerow(index) {
    this.rowdatadelete(index);
    this.rowdata.splice(index, 1);
  }
  rowdatadelete(index) {
    var tmp: string;
    var data_str: String = "";
    var raw_str: String = "";
    this.rowdata = this.dctabledata;
    data_str = "'" + this.rowdata[index].inv_no + "'" + com + "'" + this.rowdata[index].inv_date + "'" + com + "'" + this.rowdata[index].description + "'" + com + "'" + this.rowdata[index].articles + "'" + com + "'" + this.rowdata[index].no_of_articles + "'" + com + "'" + this.rowdata[index].quantity + "'" + com + "'" + this.rowdata[index].inv_amt + "'" + com + "'" + this.rowdata[index].lr_no + "'" + com + "'" + this.rowdata[index].lr_date + "'" + com + "'" + this.rowdata[index].consigner + "'" + com + "'" + this.rowdata[index].from_loc + "'" + com + "'" + this.rowdata[index].consignee + "'" + com + "'" + this.rowdata[index].to_loc + "'" + com + "'" + this.rowdata[index].actual_wt + "'" + com + "'" + this.rowdata[index].lr_master_id + "'" + com + "@@";
    raw_str = openpr + data_str + closepr + com;
    this.mainstr = this.mainstr.replace(raw_str.toString(), "");
  }

  redirect() {
    this.route.navigate(['pages/DC']);
  }

}
