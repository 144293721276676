import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { permissions } from '../../Models/permissions';


declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './articles-list.component.html',
  providers: [TpserviceService]
})
export class ArticlesListComponent implements OnInit, AfterViewInit {
  permissions: permissions;
  grant_edit: any;
  art: any;
  cust: any;
  ch: boolean = true;
  branches: any = [];
  branch: string = "1";
  selectedcheck: string = "";
  openstr = "(";
  closestr = ")";
  com = ",";
  disablestate: any;

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));

    this.grant_edit = +this.permissions.MARTE;

    this.displaybranches();
    this.branch = localStorage.getItem("branch");

    this.disablestate = localStorage.getItem("disablestate");
    this.displayArticles();
  }


  ngAfterViewInit() { }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.art = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      //pagingType: Input,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();

    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });

  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data =>
        this.branches = data
    );
  }

  displayArticles() {
    const obj = {
      checkeddata: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getArticles(obj).subscribe(
      data => this.gettabledata(data),
      error => console.log(error)
    );
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");

      this.selectedcheck = temp;

      this.selectedcheck += data + this.com;

    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }


  deletearticle() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closestr;
    console.log(strdata);

    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const strobj = {
            articlesid: strdata
          }
          this.tps.deleteArticle(strobj).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {
    swal('Success', 'Article deleted Successfully', 'success');
    this.selectedcheck = "";

    $('#products-table').dataTable().fnClearTable();
    this.displayArticles();
  }

  getarticledata(obj) {
    const ob = {
      articlesid: obj
    }
    this.tps.getarticleobject(ob).subscribe(
      data => this.callafterdata(data)
    );
  }

  callafterdata(data) {
    localStorage.setItem("articledata", JSON.stringify(data));
    localStorage.setItem("articledatastate", "1");
    this.route.navigate(['/forms/Article']);
  }
}