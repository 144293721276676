import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../../../Services/tpservice.service';
import {DatePipe} from '@angular/common';
import { Excellservice } from '../../../../Services/excellservice.service';
import swal from "sweetalert2";
import { DCReport } from '../../../../Models/dcreport';
declare var $: any;


@Component({
  selector: 'app-dc-branchwise-report',
  templateUrl: './dc-branchwise-report.component.html',
  styleUrls: ['./dc-branchwise-report.component.css'],
  providers:[TpserviceService,DatePipe,Excellservice]
})
export class DcBranchwiseReportComponent implements OnInit {
  branch:string="0";
  branchlist:any=[];
  USRBRSTATE:any;
  dclistbybranch:DCReport[];
  dclistbybranchexport:DCReport[]=[];
  flag:boolean;
  todate:any;
  fromdate:any;


  constructor(private tps:TpserviceService,private datepipe:DatePipe,private excellservice:Excellservice,private detectchages: ChangeDetectorRef) { }

  ngOnInit() {
    this.displaybranches();
    //this.branch=localStorage.getItem("branch");
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
  }

  ngAfterViewInit() {

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    var self=this;
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     });
    
  }
  
  getlist(){
    this.dclistbybranch=[];
    this.flag=false;
    this.getdclistbybranch();
  }

  getdclistbybranch(){
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      branch:this.branch
    }
    // this.tps.getDcReportbyBranch(obj).subscribe(  
    //   data => this.gettabledata(data)
    // );
  }

  gettabledata(data){
    this.dclistbybranch=[];
    $("#products-table").dataTable().fnDestroy();
    this.dclistbybranch=data;
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  displaybranches() {

    this.tps.getBranch().subscribe(
        data => this.branchlist = data
    );
  }


  Exportasexcell(){

    if(this.fromdate=='' && this.todate==''){
      swal('Warning','Please Select Date First !','warning');
    }
    else
    {
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      branch:this.branch
    }
  //   this.tps.getDcReportbyBranch(obj).subscribe(
  //     data => this.Afterdata(data)
  // );
  }
  }
  
  Afterdata(data){
    this.dclistbybranch=[];
    this.dclistbybranchexport=data;
    this.excellservice.exportAsExcelFile(this.dclistbybranchexport, 'DCBYBRANCHReport');
  } 

}
