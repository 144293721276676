import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import{dclist} from '../../Models/dclist';
import{dcdata} from '../../Models/dcdata';
import {TpserviceService} from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
import { vehicleowner } from '../../Models/vehicleowner';
import { user } from '../../Models/user';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './userlist.component.html',
  providers:[TpserviceService]
})
export class UserlistComponent implements OnInit, AfterViewInit {

  dcdata:dcdata;
  userlist:user[]=[];
  selectedcheck:string="";
  openstr="(";
  closedtr=")";
  com=",";
  
branch:string="1";
branchlist:any=[];
USRBRSTATE:any;
  constructor(private tps:TpserviceService,private changedetect:ChangeDetectorRef,private route:Router ) { }

  ngOnInit() {
    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.getuserlist();
  }


  gettabledata(data)
  {
    $("#products-table").dataTable().fnDestroy();
this.userlist=data;
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }

  ngAfterViewInit() {
   var self=this;
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     self.getuserlist();
     });
  }

  onselected(data,e,index)
  {
if(e.target.checked)
{
   var temp=this.selectedcheck.replace(data+this.com,"");
   this.selectedcheck=temp;
   this.selectedcheck+=data+this.com;
  }
else{
  var temp1=this.selectedcheck.replace(data+this.com,"");
  this.selectedcheck=temp1;
}
}

  getuserlist()
  {
    const obj={
      checkeddata:this.branch
    }
    
    this.tps.getUsers(obj).subscribe(
      data => this.gettabledata(data)
      );
    }

toggleuser(data)
{
const obj={

checkeddata:data

}
  

  this.tps.activateUser(obj).subscribe(
    data => this.afteractivate(data)
);


}

afteractivate(data){

  swal('Success', data['messasge'].toString(),'success' );
this.getuserlist();
}

deleteuser(data)
{
  swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
      const obj={
        id:data
      }
      this.tps.deleteUser(obj).subscribe(
        data => this.afterdelete(data), error1 => console.log(error1));
      }
    });
  }

  afterdelete(data)
  {
    swal('Success', data['messasge'].toString(),'success' );
    this.getuserlist();
  }

displaybranches() {
   this.tps.getBranch().subscribe(
     data =>
     this.branchlist = data
    );
  }

  getuser(ob){
    const obj={
      checkeddata:ob
    }
    this.tps.getUser(obj).subscribe(
      data =>this.aftergetuser(data));
    }

  aftergetuser(data)
  {
    localStorage.setItem("userdata",JSON.stringify(data));
    localStorage.setItem("userdatastate","1");
    this.route.navigate(['/forms/user-registration']);
  }

  getpermission(ob){
    const obj={
      checkeddata:ob
    }
    this.tps.getUserPermission(obj).subscribe(
      data =>this.aftergetpermission(data));
    }

  aftergetpermission(data)
  {
    localStorage.setItem("userpermissiondata",JSON.stringify(data));
    localStorage.setItem("userdatastate","1");
    this.route.navigate(['/permissions']);
  }

}