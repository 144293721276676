import { Component, OnInit, AfterViewInit } from '@angular/core';
import {article} from '../../../Models/article';
import {FormGroup, FormBuilder} from '@angular/forms';
import {TpserviceService} from '../../../Services/tpservice.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { articles } from '../../../Models/articles';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './Articles.component.html',
  providers: [TpserviceService]
})
export class ArticlesComponent implements OnInit, AfterViewInit {
   message:string;
    result: any;
    artname:string="";
    description :string="";
    articleobj:articles;
    branchlist:any=[];
    branch:string="1";
    datastate:string="Save";
    disablestate:any;
  constructor(private tps: TpserviceService, public route: Router) {  }

  ngOnInit() {

    if(localStorage.getItem("articledatastate")=="1"){

      this.getarticledata();
      localStorage.removeItem("articledatastate");
    }
    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.disablestate=localStorage.getItem("disablestate");
   
  }

  ngAfterViewInit(){
    var self=this;
    $('#form-sample-1').validate({
      rules: {
        artname: {
          required: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function(form) {
        self.onsubmit();
    }
    });

    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     }); 
}

onsubmit()
{
  if(this.datastate=="Save")
  {
    const obj = {
        articlesname: this.artname,
        description: this.description,
        //branch:this.branch
    };
     this.tps.addArticle(obj).subscribe(res =>this.afteradd(res),
     error1 => console.log(error1)); 
}

else{

  this.updatearticle();

}
}

afteradd(data){
  swal('Success', data['messasge'].toString(),'success' ),
  this.redirect();
}

displaybranches() {

  this.tps.getBranch().subscribe(
      data =>
          this.branchlist = data
  );
}

    redirect()
    {
        this.route.navigate(['/pages/Article-Lists']);                                                                                                                                

    }

    getarticledata(){

      this.datastate="Update";
      this.articleobj=JSON.parse(localStorage.getItem("articledata"));
      localStorage.removeItem("articledata");
      console.log(this.articleobj);
      this.artname=this.articleobj.articlesname;
      this.description=this.articleobj.description;
  //    localStorage.setItem("articledatastate","1");
   
    }

    updatearticle(){

      const objupdate = {
        articlesname: this.artname,
        description: this.description,
        branch:this.branch,
        articlesid:this.articleobj.articlesid
    };
  
     this.tps.updateArticle(objupdate).subscribe(
     data=>this.afterupdate(data),
     error=> swal('Please check your internet connection',error, 'error' )  
     )  
  
    }

    afterupdate(data)
      {
      swal('Success',data['messasge'], 'success' )
      this.route.navigate(['/pages/Article-Lists']);
      
      }
      
        redirecttourl()
        {
          this.route.navigate(['/pages/Article-Lists']);
        }
    }

