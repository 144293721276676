import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { Branch } from '../../Models/Branch';
import { trbillmaster } from '../../Models/trbillmaster';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { permissions } from '../../Models/permissions';

declare var $: any;

@Component({
  selector: 'app-transporter-bill-list',
  templateUrl: './transporter-bill-list.component.html',
  styleUrls: ['./transporter-bill-list.component.css'],
  providers: [TpserviceService]
})
export class TransporterBillListComponent implements OnInit {
  branches: Branch[];
  branch: string;
  trlist: trbillmaster[];
  billno: string;
  billdate: string;
  vehicleid: string;
  vehicletype: string;
  contactno: string;
  address: string;
  transportername: string;
  permissions: permissions;
  grant_edit: any;

  com: string = ",";
  openstr: string = "(";
  closestr: string = ")"
  selectedtrlist: string = "";

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.BTBE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.displayrb();
  }

  ngAfterViewInit() {
    var self = this;
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onchange();
    });
  }

  getdata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.trlist = data
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 10,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }


  displayrb() {
    localStorage.setItem("branch", this.branch);
    const obj = {

      branchcode: this.branch
    }
    this.tps.getTRmaster(obj).subscribe(
      data => this.getdata(data)
    );
  }

  displaybranches() {
    this.tps.getBranchlist().subscribe(
      data => this.afterdata(data)

    );
  }

  afterdata(data) {
    this.branches = data;
    this.branch = localStorage.getItem("branch");
  }

  gettrdata(id) {
    const obj = {
      billno: id
    }
    this.tps.getTrData(obj).subscribe(
      data => this.calldcafterdata(data)
    );
  }

  calldcafterdata(data) {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("datastate", "1");
    this.route.navigate(['/Transactions/Transporterbill']);
  }

  onchange() {
    this.displayrb();
    localStorage.setItem("branch", this.branch);
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedtrlist.replace(data + this.com, "");
      this.selectedtrlist = temp;
      this.selectedtrlist += data + this.com;
    }
    else {

      var temp1 = this.selectedtrlist.replace(data + this.com, "");
      this.selectedtrlist = temp1;

    }
  }

  deletetr() {
    var strdata = this.openstr + this.selectedtrlist.substring(0, this.selectedtrlist.length - 1) + this.closestr;

    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const object = {
            selected: strdata

          }
          this.tps.deletetr(object).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
          this.selectedtrlist = "";
        }
      })
    }
  }

  callafterdel(data) {
    swal('Success', 'Transporter Bill ' + data['messasge'].toString() + ' deleted successfully', 'success');
    this.trlist = [];
    $('#products-table').dataTable().fnClearTable();
    this.displayrb();
  }

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',
      preConfirm: () => {
        return document.getElementById("viewfile");
      }
    })
  }

}
