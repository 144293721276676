import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Lr } from './Lr';
import { Router } from '@angular/router';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import swal from 'sweetalert2';
import { FormGroup, FormBuilder } from '@angular/forms';
import { lrdata } from '../../../Models/lrdata';
import { articles } from '../../../Models/articles';
import { customer } from '../../../Models/customer';
import { vehicle } from '../../../Models/vehicle';
import { DatePipe } from '@angular/common';
import { Branch } from '../../../Models/Branch';
import { user } from '../../../Models/user';
import { places } from '../../../Models/places';
const pdfurl: string = 'https://files.omsairamlogistics.ml/home/LR/';
declare var $: any;
const openpr = "(";
const closepr = ")";
var datastr: String = "";
const com = ",";

@Component({
    selector: 'app-form-layouts',
    templateUrl: './lr.component.html',
    styleUrls: ['./lr.component.css'],
    providers: [TpserviceService, Dataservices, DatePipe]
})

export class LrComponent implements OnInit, AfterViewInit {

    LRForm: FormGroup;
    lrid: string = "";
    lrnoload: string
    cust: customer[];
    cosignerob: customer[];
    consigneeob: customer[];
    place: any;
    places: places[];
    vehicles: vehicle[];
    lrdata: lrdata = null;
    branches: Branch[] = [];
    lrlist: any;
    stateu: any = false;
    user: string = "";
    consignote: string = "";
    consignorgst: string = "";
    consigneegst: string = "";
    tocontact: string = "";
    userdata: user;
    cc_char: any = 0;
    insuarance: any = 0;
    docket: any = 0;
    materialcost: any;
    paytype: string = "";
    gstpayby: string = "";
    paymode: string = "";
    bankname: string = "";
    chqno: string = "";
    chqdate: string = "";
    custcopy: string = "Acknowledge Copy";
    public datastate: any = "Save";
    ob: any = {};
    ob1: any = {};
    articles: articles[] = [];
    public row_num: any;
    public flag: boolean;
    public lr: Lr;
    public art: string="";
    public id: number;
    public desc: string;
    public nart: string="0";
    public oldnart: string="0";
    public rt: string = "0";
    public qty: string;
    public sr: string;
    public chwt: string = "0";
    public rtp: string = "Fixed";
    public wtact: string = "0";
    public oldwtact: string = "0";
    public mainstr: String = "";
    public total: any;
    public abbrevation: any = "";
    public hamali: any;
    public collection: any;
    public dates: any;
    public lrmaster_id: string = "@@";
    public Statistical: any;
    public sub_total: any;
    public igst: any;
    rtswitch: boolean = true;
    public sgst: any;
    public cgst: any;
    public lr_no: any = "";
    public lr_date: string = "";
    public truck_tempo_number: any;
    public truck_tempo_number_id: any;
    public consigner: any;
    public consigner_address: any;
    public consignee: any;
    public consignee_address: any;
    public thirdpartycust:any;
    public loc_from: any;
    public loc_to: any;
    public branch: any;
    public freight: any;
    public extra_charges: any;
    public delivery: any;
    public wt_charges: any;
    public other_charges: any;
    public igst_val: any;
    public cgst_val: any;
    public sgst_val: any;
    public delivery_type: any;
    public delivery_days: any = "0";
    public weight_type: any;
    public paybill: any;
    ulr_no: string;
    public to_billed: any;
    public collect_at_branch: any;
    public remarks: any;
    public frei: any;
    public oldfrei: any =0;
    transportmode: string = "Surface";
    disablestate: any;
    totalnoarticle: any = 0;
    totalweight: any = 0;

    public rows: Array<{
        sr_no: string;
        articles: string;
        no_of_articles: string;
        description: string;
        actual_wt: string;
        char_wt: string;
        rate_per: string;
        rate: string;
        frei: string;
        lr_master_id: string;
    }> = [];

    constructor(private fb: FormBuilder, private router: Router, private tps: TpserviceService, private datepipe: DatePipe) {
        this.lr = new Lr();
    }


    ngOnInit() {
        this.to_billed = "Consignor";
        this.paybill = "Bill";
        this.weight_type = "Part Load";
        this.delivery_type = "Door";
        this.paytype = "TBB";
        this.gstpayby ="Consignor"
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        this.user = this.userdata.id;
        this.lr_date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
        // this.onvehicle();
        // this.displayCustomers();
        // this.displayplaces();
        // this.getarticles();

        if (localStorage.getItem("datastate") == "1") {
            localStorage.removeItem("datastate");
            this.displaybranchesforupdate();
        }
        else {
            this.displaybranches();
            this.loc_from = this.branch;
            this.disablestate = localStorage.getItem("disablestate");
        }
    }

    ngAfterViewInit() {
        var self = this;
        $('#form-sample-1').validate({
            rules: {
                consigner: {
                    required: !0
                },
                consignee: {
                    required: !0
                },
                loc_from: {
                    required: !0
                },
                loc_to: {
                    required: !0,
                },
                freight: {
                    required: !0
                },
                delivery_type: {
                    required: !0
                },
                delivery_days: {
                    required: !0
                },
                weight_type: {
                    required: !0
                },
                to_billed: {
                    required: !0
                },
                collect_at_branch: {
                    required: !0
                }
            },
            errorClass: 'help-block error',
            highlight: function (e) {
                $(e).closest('.form-group.row').addClass('has-error');
            },
            unhighlight: function (e) {
                $(e).closest('.form-group.row').removeClass('has-error');
            },
            submitHandler: function (form) {
                self.onsubmited();
            }
        });

        var drop = true;
        $(document).on('focus', '.select2', function (e) {
            if (e.originalEvent && drop == true) {
                $(this).siblings('select').select2('open');
            }
            else {
                drop = true;
            }
        });

        $(document).on('focusout', '.select2-search__field', function () {
            drop = false;
        });

        $(document).ready(function () {
            $("#truck_tempo_number").select2({
                theme: "classic",
                //placeholder: "Select an Vehicle",
                allowClear: true
            });
        });

        $("#truck_tempo_number").on('select2:select', function (evt) {
            var name = evt.params.data.text;
            var id = evt.params.data.id;
            self.truck_tempo_number_id = id;
            self.truck_tempo_number = name;
        });

        $(document).ready(function () {
            $("#loc_from").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#loc_from").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.loc_from = id;
            self.onchangetoplace();
        });

        $(document).ready(function () {
            $("#loc_to").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#loc_to").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.loc_to = id;
            self.onchangetoplace();
        });

        $(document).ready(function () {
            $("#consigner").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#consigner").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.consigner = id;
            self.onchangecosigner();
        });

        $(document).ready(function () {
            $("#consignee").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#consignee").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.consignee = id;
            self.onchangecosignee();
        });

        $(document).ready(function () {
            $("#art").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#art").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.art = id;
        });

        $(document).ready(function () {
            $("#branch").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#branch").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.branch = id;
            self.refreshdata();
        });

        $(document).ready(function () {
            $("#thirdpartycust").select2({
                theme: "classic",
                allowClear: true
            });
        });

        $("#thirdpartycust").on('select2:select', function (evt) {
            var id = evt.params.data.id;
            self.thirdpartycust = id;
            //console.log(self.thirdpartycust);
        });

        if (localStorage.getItem("datastate") == "1") {
            this.LoadlrnoforUpdate();
        }
        this.collect_at_branch = this.branch;
    }

    onsubmit() {
      //  console.log(this.art,this.nart);
        if(this.art==''||this.art==null||this.art==undefined){
            swal('Error','Please Select article first!!!','error')
        }
        else{
            this.rows.push({
                sr_no: this.sr,
                articles: this.art,
                no_of_articles: this.nart,
                description: this.desc,
                actual_wt: this.wtact,
                char_wt: this.chwt,
                rate_per: this.rtp,
                rate: this.rt,
                frei: this.frei,
                lr_master_id: this.lrmaster_id
            });
            this.rowdatabuilder();
            this.calculatetotalfreight();
            this.addtotal();
            this.clear();
        }
    }

    calculatetotalfreight() {
        var totalfright = 0;
        this.rows.forEach(function (element) {
            totalfright = totalfright+ + element.frei;
        });
        this.freight = totalfright;  
        this.changed();
        // if(this.flag){
        //    this.freight = Number(this.freight) - Number(this.oldfrei); 
        //    this.freight = Number(this.freight) + Number(this.frei);
        // }
        // else{
        //     this.freight = Number(this.freight) + Number(this.frei);
        //     this.changed();
        // }
        //console.log(this.freight)
    }

    calc_freight(){
        this.frei = 0;
        console.log("this.rtp"+this.rtp);
        console.log("this.rtp"+this.rtp +"test"+ this.rtp =="Kg"  );
        if(!this.rtswitch && this.rtp =="Kg"){
            this.frei = Number(this.chwt) * Number(this.rt);
        }
        if(!this.rtswitch && this.rtp == "Case" ){
            this.frei = Number(this.nart) * Number(this.rt);
        }
       // console.log(this.frei);
        this.frei = Math.round(this.frei);
    }

    addtotal() {
        // console.log("wt", this.totalweight);
        // console.log("art", this.totalnoarticle);
       var totalnoarticle = 0;
       var totalweight = 0;
        this.rows.forEach(function (element) {
            totalnoarticle = totalnoarticle+ + element.no_of_articles;
            totalweight =  totalweight+ +element.char_wt;
        });
        this.totalnoarticle = totalnoarticle;
        this.totalweight = totalweight;
        // if(this.flag){
        //     this.totalnoarticle = Number(this.totalnoarticle) - Number(this.oldnart);
        //     this.totalweight = Number(this.totalweight) - Number(this.oldwtact);
        //     this.totalnoarticle = Number(this.totalnoarticle) + Number(this.nart);
        //     this.totalweight = Number(this.totalweight) + Number(this.chwt);

        // }
        // else{
        //     this.totalnoarticle = Number(this.totalnoarticle) + Number(this.nart);
        //     this.totalweight = Number(this.totalweight) + Number(this.chwt);
        // }
        // console.log("wt", this.totalweight);
        // console.log("art", this.totalnoarticle);
    }

    deleterow(index) {
        this.rowdatadelete(index);
        this.rows.splice(index, 1);
        this.calculatetotalfreight();
        this.addtotal();
    }

    editrow(row, index) {
        this.row_num = index;
        this.flag = true;
        this.sr = row.sr_no;
        this.art = row.articles;
        $("#art").val(this.art).trigger("change");
        //alert(this.art);
        this.nart = row.no_of_articles;
        this.oldnart = row.no_of_articles;
        this.desc = row.description;
        this.wtact = row.actual_wt;
        this.oldwtact = row.char_wt;
        this.chwt = row.char_wt;
        this.rtp = row.rate_per;
        this.rt = row.rate;
        this.frei = row.frei;
        this.oldfrei = row.frei;
    }

    clear() {
        this.chwt = null;
        this.sr = null;
        this.id = null;
        this.wtact = "0";
        $("#art").val(null).trigger('change');
        this.art = "";
        this.chwt = "0";
        this.desc = null;
        this.nart = "0";
        this.rt = "0";
        this.qty = "0";
        this.frei = "0";
        this.oldfrei = "0";
        this.oldnart = "0";
        this.oldwtact = "0";
        this.sr = null;
        this.rtp = "Fixed";
        this.rtswitch = true;
    }

    savetransactions(lr_Mastre_id) {
        this.tps.addLR_Trxn(this.mainstr).subscribe(res =>
            swal('Success', res['messasge'], 'success'), error1 => console.log(error1));
    }

    onupdate(index) {
        this.rows.push({
            sr_no: this.sr,
            articles: this.art,
            no_of_articles: this.nart,
            description: this.desc,
            actual_wt: this.wtact,
            char_wt: this.chwt,
            rate_per: this.rtp,
            rate: this.rt,
            frei: this.frei,
            lr_master_id: this.lrmaster_id
        });
        this.rowdatabuilder();
        this.deleterow(this.row_num);
        this.calculatetotalfreight();
        this.addtotal();
        this.flag = false;
        this.clear();
    }

    changed() {
        this.total = 0;
        //this.sub_total = +this.total + +this.freight + +this.extra_charges + +this.hamali + +this.collection + +this.delivery + +this.wt_charges + +this.other_charges + +this.Statistical;
        this.total = +this.freight + +this.cc_char + +this.delivery + +this.other_charges + +this.insuarance + +this.hamali + +this.docket;
        // this.total = Number(this.freight) + Number(this.cc_char) + Number(this.delivery)+Number(this.other_charges)+Number(this.insuarance)+Number(this.hamali)+Number(this.docket);
        // console.log(this.total);
        this.total = Math.round(this.total);
        // console.log(this.total);

    }


    // calcgst() {
    //     const temp = +this.sub_total;
    //     this.igst = 0;
    //     this.cgst = 0;
    //     this.sgst = 0;
    //     this.sgst = this.sgst_val * temp / 100;
    //     this.igst = this.igst_val * temp / 100;
    //     this.cgst = this.cgst_val * temp / 100;
    //     this.addgst();
    // }

    // addgst() {
    //     this.total = this.sub_total + this.igst + this.cgst + this.sgst;
    // }


    displayCustomers() {
       
        const obj = {
            checkeddata: this.branch
        }
      //  this.tps.getCusts(obj).subscribe(
        this.tps.getCustomerdetail(obj).subscribe(
            data => this.getafterdata(data)
        );
       
    }

    getafterdata(data){
        this.cust = data
        swal.close();
    }


    onToBilledChange(){
        //console.log(this.to_billed);
        if(this.to_billed == "Third Party"){
            document.getElementById('thirdpartydiv').style.display = 'block';
            $(document).ready(function () {
                $("#thirdpartycust").select2({
                    theme: "classic",
                    allowClear: true
                });
            }); 
        }
        else{
            document.getElementById('thirdpartydiv').style.display = 'none';
        }
    }

    // generatelrno() {
    //     const obj = {
    //         checkeddata: this.branch
    //     }
    //     this.tps.getLrno(obj).subscribe(
    //         data =>
    //             this.lr_no = data['messasge']
    //     );
    // }

    refreshdata() {
        localStorage.setItem("branch", this.branch);
        this.onbranch();
        let index = this.branches.findIndex(datas => datas.bid == this.branch);
        this.loc_from = this.branches[index].placeid;
        this.collect_at_branch = this.branch;
    }

    bank() {
        if (this.paymode == 'Cheque') {
            (<HTMLInputElement>document.getElementById("bankname")).disabled = false;
            (<HTMLInputElement>document.getElementById("chqno")).disabled = false;
            (<HTMLInputElement>document.getElementById("chqdate")).disabled = false;
        }
        else {
            (<HTMLInputElement>document.getElementById("bankname")).disabled = true;
            (<HTMLInputElement>document.getElementById("chqno")).disabled = true;
            (<HTMLInputElement>document.getElementById("chqdate")).disabled = true;
        }
    }

    getarticles() {

       
        const obj = {
            checkeddata: this.branch
        }
        this.tps.getArticles(obj).subscribe(
            data => this.articles = data
        );
       
    }

    displayplaces() {
     
        this.tps.getPlace().subscribe(
            data => this.place = data
        );
       
    }

    onbranch() {
        let index = this.branches.findIndex(datas => datas.bid == this.branch);
        this.loc_from = this.branches[index].placeid;
    }

    displaybranches() {
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
          });
        swal.showLoading();
        this.tps.getBranch().subscribe(
            data => this.afterdata(data)
        );

    }

    displaybranchesforupdate() {
       
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 100,
            heightAuto: true,
            background: "rgba(0, 0, 0, .1)",
          });
        swal.showLoading();
        this.onvehicle();
        this.getarticles();
        this.setdefaultvalues();
        this.displayCustomers();
        this.displayplaces();
        this.tps.getBranchlist().subscribe(
            data => this.displaylrdata(data)
        );

        
       
    }

    afterdata(data) {
        this.branches = data
        this.branch = localStorage.getItem("branch");
        this.onvehicle();
        this.getarticles();
        this.setdefaultvalues();
        this.displayCustomers();
        this.displayplaces();
        this.onbranch();
        this.collect_at_branch = this.branch;
        let index = this.branches.findIndex(datas => datas.bid == this.branch);
        this.loc_from = this.branches[index].placeid;
    }

    getUpdatecustomers() {
        const obj = {
            checkeddata: this.branch
        }
       // this.tps.getCusts(obj).subscribe(
        this.tps.getCustomerdetail(obj).subscribe(
            data =>
                this.AfterUpdatecustomers(data)
        );
    }

    AfterUpdatecustomers(data) {
        this.cust = data;
        this.consigner = this.lrdata.lorrymasterBO.consigner;
        this.consignee = this.lrdata.lorrymasterBO.consignee;
        let index = this.cust.findIndex(datas => datas.cid == this.consigner);
        this.consigner_address = this.cust[index].address;
        //this.consigner_address = "this.cust[index].address";
        let index1 = this.cust.findIndex(datas => datas.cid == this.consignee);
        this.consignee_address = this.cust[index1].address;
    }

    displaylrdata(data) {
       // console.log("in displaylrdata");
        this.branches = data;
        this.datastate = "Update";
        this.stateu = true;  
        //this.getUpdatecustomers(); 
        this.lrdata = JSON.parse(localStorage.getItem('data'));
        this.lrid = this.lrdata.lorrymasterBO.lrid;
        this.lr_no = this.lrdata.lorrymasterBO.lr_no;
        this.lr_date = this.lrdata.lorrymasterBO.lr_date;
        this.consignote = this.lrdata.lorrymasterBO.consignote;
        $('#loc_to').val(this.lrdata.lorrymasterBO.loc_to).trigger('change');
        this.truck_tempo_number = this.lrdata.lorrymasterBO.truck_tempo_number;
        this.consigner = this.lrdata.lorrymasterBO.consigner,
      //  console.log("this.consigner"+this.consigner);
        this.consignorgst = this.lrdata.lorrymasterBO.consignorgst,
        this.consigner_address = this.lrdata.lorrymasterBO.consigner_address;
        this.consignee = this.lrdata.lorrymasterBO.consignee;
      //  console.log("this.consignee"+this.consignee);
        this.consigneegst = this.lrdata.lorrymasterBO.consigneegst;
        this.consignee_address = this.lrdata.lorrymasterBO.consignee_address;
        this.loc_from = this.lrdata.lorrymasterBO.loc_from;
        this.loc_to = this.lrdata.lorrymasterBO.loc_to;
        this.tocontact = this.lrdata.lorrymasterBO.tocontact;
        this.freight = this.lrdata.lorrymasterBO.freight;
        this.cc_char = this.lrdata.lorrymasterBO.cc_char;
        this.delivery = this.lrdata.lorrymasterBO.delivery;
        this.other_charges = this.lrdata.lorrymasterBO.other_charges;
        this.insuarance = this.lrdata.lorrymasterBO.insuarance;
        this.hamali = this.lrdata.lorrymasterBO.hamali;
        this.docket = this.lrdata.lorrymasterBO.docket;
        this.total = this.lrdata.lorrymasterBO.total;
        this.materialcost = this.lrdata.lorrymasterBO.materialcost;
        this.delivery_type = this.lrdata.lorrymasterBO.delivery_type;
        this.delivery_days = this.lrdata.lorrymasterBO.delivery_days;
        this.paytype = this.lrdata.lorrymasterBO.paytype;
        this.to_billed = this.lrdata.lorrymasterBO.to_billed;
        this.collect_at_branch = this.lrdata.lorrymasterBO.collect_at_branch;
        this.gstpayby = this.lrdata.lorrymasterBO.gstpayby;
        this.paymode = this.lrdata.lorrymasterBO.paymode;
        this.bankname = this.lrdata.lorrymasterBO.bankname;
        this.chqno = this.lrdata.lorrymasterBO.chqno;
        this.chqdate = this.lrdata.lorrymasterBO.chqdate;
        this.custcopy = this.lrdata.lorrymasterBO.custcopy;
        this.remarks = this.lrdata.lorrymasterBO.remarks;
        this.branch = this.lrdata.lorrymasterBO.branch;
        this.rows = this.lrdata.tabledata;
        this.thirdpartycust = this.lrdata.lorrymasterBO.thirdpartycust;
        this.onToBilledChange();
        $("#thirdpartycust").val(this.thirdpartycust).trigger("change");
        localStorage.removeItem("data");
        for(let i=0; i<this.rows.length;i++){
            this.totalnoarticle = Number(this.totalnoarticle) + Number(this.rows[i].no_of_articles);
            this.totalweight = Number(this.totalweight) + Number(this.rows[i].char_wt);
        }
        
       // console.log("calling vehhicle")
        this.onvehicle();
        // console.log(this.totalnoarticle);
        // console.log(this.totalweight);
      
    }  

    onPayTypeChange(){
        if(this.paytype == "To Pay"){
            this.gstpayby = "Consignee";
            this.to_billed = "Consignee";
        }else if(this.paytype == "TBB"){
            this.gstpayby = "Consignor";
            this.to_billed = "Consignor";
        }
        else{
            this.gstpayby = "Consignee";
            this.to_billed = "Consignee";
        }
    }

    onchangecosigner() {
        let index = this.cust.findIndex(datas => datas.cid == this.consigner);
        this.consigner_address = this.cust[index].address;
        this.consignorgst = this.cust[index].gstno;
    }

    onchangecosignee() {
        let index = this.cust.findIndex(datas => datas.cid == this.consignee);
        this.consignee_address = this.cust[index].address;
        this.consigneegst = this.cust[index].gstno;
    }

    onchangetoplace() {
        let index = this.place.findIndex(datas => datas.placeid == this.loc_to);
        this.tocontact = this.place[index].contactnumber;
    }

    onvehicle() {
      
        const ob = {
            checkeddata: this.branch
        }
       // this.tps.getVehicle(ob).subscribe(
        this.tps.getVehicledetail(ob).subscribe(
            data => this.aftergetVehivle(data)
            , error => swal('Please check your internet connection', error, 'error')
        );
      
    }

    aftergetVehivle(data){
        this.vehicles = data;
       
        if(this.datastate == "Update"){
            for(let i=0;i<this.vehicles.length;i++){
                if(this.vehicles[i].vehicleno == this.truck_tempo_number){
                   this.truck_tempo_number_id = this.vehicles[i].vehicleid;
                    $("#truck_tempo_number").val(this.truck_tempo_number_id).trigger("change");
                }
            }
        }
       
    }

    rtswitched() {
        if (this.rtp == "Fixed") {
            this.rtswitch = true;
        }
        else {
            this.rtswitch = false;
        }
    }

    onchangeconsignee(event) {
        this.ob1 = JSON.parse(event.target.value);
    }

    onsubmited() {
        let rowsdata: string = "";
        if (this.rows.length <= 0) {
            swal('Warning', 'Please Fill LR Data', 'warning');
        }
        else {
            if (this.datastate == "Update") {
                this.lrupdate();
            }
            else {
                this.rows.forEach(function (element) {
                    rowsdata += "(" + "'" + element.sr_no + "'" + "," + "'" + element.description + "'" + "," + "'" + element.articles + "'" + "," + "'" + element.no_of_articles + "'" + "," + "'" + element.actual_wt + "'" + "," + "'" + element.char_wt + "'" + "," + "'" + element.rate_per + "'" + "," + "'" + element.rate + "'" + "," + "'" + element.frei + "'" + "," + "@@),"
                });
                const obj = {
                    lr_no: this.lr_no,
                    lr_date: this.lr_date,
                    consignote: this.consignote,
                    truck_tempo_number: this.truck_tempo_number,
                    consigner: this.consigner,
                    consignorgst: this.consignorgst,
                    consigner_address: this.consigner_address,
                    consignee: this.consignee,
                    consigneegst: this.consigneegst,
                    consignee_address: this.consignee_address,
                    loc_from: this.loc_from,
                    loc_to: this.loc_to,
                    tocontact: this.tocontact,
                    freight: this.freight,
                    cc_char: this.cc_char,
                    delivery: this.delivery,
                    other_charges: this.other_charges,
                    insuarance: this.insuarance,
                    hamali: this.hamali,
                    docket: this.docket,
                    total: this.total,
                    materialcost: this.materialcost,
                    delivery_type: this.delivery_type,
                    delivery_days: this.delivery_days,
                    paytype: this.paytype,
                    to_billed: this.to_billed,
                    collect_at_branch: this.collect_at_branch,
                    gstpayby: this.gstpayby,
                    paymode: this.paymode,
                    bankname: this.bankname,
                    chqno: this.chqno,
                    chqdate: this.chqdate,
                    custcopy: this.custcopy,
                    remarks: this.remarks,
                    lrdata: rowsdata,
                    branch: this.branch,
                    user_id: this.user,
                    thirdpartycust:this.thirdpartycust
                };
                swal({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    width: 150
                });
                swal.showLoading();
                this.tps.addLR(obj).subscribe(res => this.aftersave(res)
                    , error1 => swal('Error', error1, 'error')
                );
            }
        }
    }
    aftersave(data) {
        swal.close();
        this.id = data['res_code'];
        this.lrid = data['res_code'];
        swal('Success', data['messasge'], 'success');
        (<HTMLInputElement>document.getElementById("submit")).disabled = true;
        (<HTMLInputElement>document.getElementById("print")).disabled = false;
        (<HTMLInputElement>document.getElementById("printwithoutvalue")).disabled = false;
    }

    rowdatabuilder() {
        var datastr: String = "";
        datastr = "'" + this.sr + "'" + com + "'" + this.desc + "'" + com + "'" + this.art + "'" + com + "'" + this.nart + "'" + com + "'" + this.wtact + "'" + com + "'" + this.chwt + "'" + com + "'" + this.rtp + "'" + com + "'" + this.rt + "'" + com + "'" + this.frei + "'" + com + "@@";
        this.mainstr = this.mainstr + openpr + datastr + closepr + com;
    }


    rowdatadelete(index) {
        var tmp: string;
        var data_str: String = "";
        var raw_str: String = "";
        data_str = "'" + this.rows[index].sr_no + "'" + com + "'" + this.rows[index].description + "'" + com + "'" + this.rows[index].articles + "'" + com + "'" + this.rows[index].no_of_articles + "'" + com + "'" + this.rows[index].actual_wt + "'" + com + "'" + this.rows[index].char_wt + "'" + com + "'" + this.rows[index].rate_per + "'" + com + "'" + this.rows[index].rate + "'" + com + "'" + this.rows[index].frei + "'" + "@@";
        raw_str = openpr + data_str + closepr + com;
        this.mainstr = this.mainstr.replace(raw_str.toString(), "");
    }

    onchargewt() {
        this.chwt = this.wtact;
        this.calc_freight();
    }

    lrupdate() {
        let rowsdata: string = "";
        this.rows.forEach(function (element) {
            rowsdata += "(" + "'" + element.sr_no + "'" + "," + "'" + element.description + "'" + "," + "'" + element.articles + "'" + "," + "'" + element.no_of_articles + "'" + "," + "'" + element.actual_wt + "'" + "," + "'" + element.char_wt + "'" + "," + "'" + element.rate_per + "'" + "," + "'" + element.rate + "'" + "," + "'" + element.frei + "'" + "," + "@@),"
        });
        const obj = {
            lrid: this.lrid,
            lr_no: this.lr_no,
            lr_date: this.lr_date,
            consignote: this.consignote,
            truck_tempo_number: this.truck_tempo_number,
            consigner: this.consigner,
            consignorgst: this.consignorgst,
            consigner_address: this.consigner_address,
            consignee: this.consignee,
            consigneegst: this.consigneegst,
            consignee_address: this.consignee_address,
            loc_from: this.loc_from,
            loc_to: this.loc_to,
            tocontact: this.tocontact,
            freight: this.freight,
            cc_char: this.cc_char,
            delivery: this.delivery,
            other_charges: this.other_charges,
            insuarance: this.insuarance,
            hamali: this.hamali,
            docket: this.docket,
            total: this.total,
            materialcost: this.materialcost,
            delivery_type: this.delivery_type,
            delivery_days: this.delivery_days,
            paytype: this.paytype,
            to_billed: this.to_billed,
            collect_at_branch: this.collect_at_branch,
            gstpayby: this.gstpayby,
            paymode: this.paymode,
            bankname: this.bankname,
            chqno: this.chqno,
            chqdate: this.chqdate,
            custcopy: this.custcopy,
            remarks: this.remarks,
            branch: this.branch,
            user_id: this.user,
            lrdata: rowsdata,
            thirdpartycust:this.thirdpartycust
        };
        swal({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            width: 150
        });
        swal.showLoading();
        this.tps.updateLR(obj).subscribe(res => this.afterupdate(res)
            , error1 => swal('Error', error1, 'error'));
    }

    afterupdate(data) {
        swal.close();
        swal('Success', data['messasge'], 'success');
        //this.router.navigate(['/pages/LR']);
        //(<HTMLInputElement>document.getElementById("submit")).disabled = true;
        (<HTMLInputElement>document.getElementById("print")).disabled = false;
        (<HTMLInputElement>document.getElementById("printwithoutvalue")).disabled = false;
    }

    setdefaultvalues() {
      
        this.flag = false;
        this.dates = this.datepipe.transform(new Date(), "yyyy-MM-dd");
        this.total = 0;
        this.freight = 0;
        this.extra_charges = 0;
        this.hamali = 0;
        this.collection = 0;
        this.delivery = 0;
        this.wt_charges = 0;
        this.other_charges = 0;
        this.Statistical = 0;
        this.sub_total = 0;
        this.cgst_val = this.sgst_val = this.igst_val = this.sgst = this.cgst = this.igst = this.igst_val = 0;
     
    }

    pdfredirect() {
        const ob = {
            id: this.lrid,
            type: 'lorry_reciept_master',
        }
        this.tps.viewpdf(ob).subscribe(
            data => this.afterpdf(data)
            , error => swal('Please check your internet connection', error, 'error')
        );
    }

    afterpdf(data) {
        var url = data["location"];
        url = url.replace(".pdf", "_print.pdf");
        swal({
            //title: 'Download File',
            showCancelButton: true,
            width: '80%',
            showCloseButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            html: '<embed id="viewfile" src="' + url + '" height="500px" width="100%"/>',
            preConfirm: () => {
                return document.getElementById("viewfile");
            }
        })
    }

    pdfredirect1() {
        const ob = {
            id: this.lrid,
            type: 'lorry_reciept_master',
        }
        this.tps.viewpdf(ob).subscribe(
            data => this.afterpdf1(data)
            , error => swal('Please check your internet connection', error, 'error')
        );
    }

    afterpdf1(data) {
        var url = data["location"];
        url = url.replace(".pdf", "_printwithoutvalue.pdf");
        swal({
            //title: 'Download File',
            showCancelButton: true,
            width: '80%',
            showCloseButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            html: '<embed id="viewfile" src="' + url + '" height="500px" width="100%"/>',
            preConfirm: () => {
                return document.getElementById("viewfile");
            }
        })
    }

    redirect() {
        this.router.navigate(['/pages/LR']);
    }

    LoadlrnoforUpdate() {
        this.lrnoload = this.lrdata.lorrymasterBO.lr_no;
        this.ulr_no = this.lrnoload.toString().replace(this.lr_no, "");
    }

}