import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-table-5',
  templateUrl: './pricing-table-5.component.html',
})
export class PricingTable5Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
