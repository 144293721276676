import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-petty-cash',
  templateUrl: './petty-cash.component.html',
  styleUrls: ['./petty-cash.component.css']
})
export class PettyCashComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
