import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { vehicle } from '../../../Models/vehicle';
import { DatePipe } from '@angular/common';
import { Branch } from '../../../Models/Branch';
import swal from 'sweetalert2';
import { drivers } from '../../../Models/drivers';
import { lrmaster } from '../../../Models/lrmaster';
import { user } from '../../../Models/user';
import { Router } from '@angular/router';
import { lstabledata } from '../../../Models/lstabledata';
import { loadingtripsheet } from '../../../Models/loadingtipsheet';
import { loadingsheetmaster } from '../../../Models/loadingsheetmaster';
declare var $: any;

@Component({
  selector: 'app-loading-trip',
  templateUrl: './loading-trip.component.html',
  styleUrls: ['./loading-trip.component.css'],
  providers: [TpserviceService, DatePipe]
})
export class LoadingTripComponent implements OnInit, AfterViewInit {

  datastate: string = "Save";
  id: string = "";
  branch: string = "";
  challanno: string = "";
  date: string = "";
  vehicleno: string = "";
  vehicleowner: string = "";
  owner_addr: string = "";
  owner_cont: string = "";
  drivername: string = "";
  licenseno: string = "";
  mobileno: string = "";
  fromloc: string = "";
  toloc: string = "";
  tot_package: any;
  tot_weight: any;
  curr_time: string = "";
  reach_time: string = "";
  receive_bal: any = 0;
  receive_date: string = "";
  paymode: string = "Cash";
  bankname: string = "";
  cheqno: string = "";
  chqdate: string = "";
  payat: string = "";
  remark: string = "";
  branches: Branch[] = [];
  vehicles: vehicle[] = [];
  drivers: drivers[] = [];
  place: any;
  lrdata: lrmaster[] = [];
  lsmaster: loadingsheetmaster;
  selectedlrstr: string = "";
  selectedlr: string = "";
  selectedlrforshowFlag: string = "";
  openstr: string = "(";
  closestr: string = ")";
  com: string = ",";
  userdata: user;
  user: string = "";
  loadingsheetdata: lstabledata[] = [];
  loadingsheet: loadingtripsheet;
  hire: any = 0;
  ex_wt_char: any = 0;
  hamali: any = 0;
  commission: any = 0;
  adv_amt: any = 0;
  diesel: any = 0;
  petrolpump: any = 0;
  total: any = 0;
  sendcallbacklr: string = "";

  public rowdata: Array<{
    sr_no: string;
    lr_no: string;
    lr_date: string;
    description: string;
    articles: string;
    no_of_articles: string;
    inv_amt: string;
    consigner: string;
    from_loc: string;
    consignee: string;
    to_loc: string;
    actual_wt: string;
    lr_amt: string;
    lr_master_id: string;
    total: string;
    showAmountFlag:string;
  }> = [];

  constructor(private datepipe: DatePipe, private tps: TpserviceService, private route: Router) { }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.user = this.userdata.id;
    this.branch = localStorage.getItem("branch");
    this.date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.receive_date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.chqdate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
    this.getdriver();
    this.getplaces();

    if (localStorage.getItem("datastate") == "1") {
      this.datastate = "Update";
      localStorage.removeItem("datastate");
      this.displayloadingsheetdata();
      this.displayLRforUpdate();
    }
    else {
      this.displaylr();
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        vehicleno: {
          required: !0
        },
        drivername: {
          required: !0
        },
        loc_to: {
          required: !0,
        }
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

    var drop = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && drop == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        drop = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      drop = false;
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onchangebranch();
    });

    $(document).ready(function () {
      $("#vehicleno").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#vehicleno").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.vehicleno = id;
      self.onchangevehicle(evt);
    });

    $(document).ready(function () {
      $("#drivername").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#drivername").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.drivername = id;
      self.onchangedriver(evt);
    });

    $(document).ready(function () {
      $("#toloc").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#toloc").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.toloc = id;
    });

    $(document).ready(function () {
      $("#fromloc").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#fromloc").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.fromloc = id;
    });

    $(document).ready(function () {
      $("#payat").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#payat").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.payat = id;
    });
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data;
    this.branch = localStorage.getItem("branch");
    if(this.datastate!="Update"){
      let index = this.branches.findIndex(datas => datas.bid == this.branch);
      this.fromloc = this.branches[index].placeid;
    }
    this.getvehicle();
  }

  getvehicle() {
    const ob = {
      checkeddata: this.branch
    }
    
    this.tps.getVehicle(ob).subscribe(
      data => this.afterGetVehicle(data)
    );
  }

  afterGetVehicle(data){
    this.vehicles = data;
    swal.close();
  }

  onchangevehicle(e) {
    let index = this.vehicles.findIndex(datas => datas.vehicleid == e.target.value);
    this.vehicleowner = this.vehicles[index].vehical_owner_name;
    this.owner_addr = this.vehicles[index].address;
    this.owner_cont = this.vehicles[index].telephoneno;
  }

  getdriver() {
    this.tps.getDriverlist().subscribe(
      data => this.drivers = data
    );
  }

  onchangedriver(e) {
    let index = this.drivers.findIndex(datas => datas.driver_id == e.target.value);
    this.licenseno = this.drivers[index].licenseno;
    this.mobileno = this.drivers[index].mobileno;
  }

  getplaces() {
    this.tps.getPlace().subscribe(
      data => this.afterplace(data)
    );
  }

  afterplace(data) {
    this.place = data;
    //console.log(this.datastate)
    if( this.datastate == "Update"){
      $('#fromloc').val(this.fromloc).trigger('change');
      $('#toloc').val(this.toloc).trigger('change');
    }
  }

  onchangebranch() {
    localStorage.setItem("branch", this.branch);
    let index = this.branches.findIndex(datas => datas.bid == this.branch);
    this.fromloc = this.branches[index].placeid;
    this.displaylr();
  }

  displaylr() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getLRForLoadingTrip(obj).subscribe(
      data => this.lrdata = data
    );
  }

  checkShowAmount(index,e){
    if(this.datastate != "Update"){
    var data = this.lrdata[index].lrid;
    }
    else{
    var data = this.rowdata[index].lr_master_id;
    }
    // console.log(data);
    // console.log("Before"+this.selectedlrforshowFlag);
    // console.log(e.target.checked);
    if (e.target.checked) {
      var temp = this.selectedlrforshowFlag.replace(data + this.com, "");
      this.selectedlrforshowFlag = temp;
      this.selectedlrforshowFlag += data + this.com;
    }
    else {
      var temp1 = this.selectedlrforshowFlag.replace(data + this.com, "");
      this.selectedlrforshowFlag = temp1;
    }
    //console.log("After"+this.selectedlrforshowFlag);
  }

  bank() {
    if (this.paymode == 'Cheque' || this.paymode == 'NEFT/RTGS') {
      (<HTMLInputElement>document.getElementById("bankname")).disabled = false;
      (<HTMLInputElement>document.getElementById("cheqno")).disabled = false;
      (<HTMLInputElement>document.getElementById("chqdate")).disabled = false;
    }
    else {
      (<HTMLInputElement>document.getElementById("bankname")).disabled = true;
      (<HTMLInputElement>document.getElementById("cheqno")).disabled = true;
      (<HTMLInputElement>document.getElementById("chqdate")).disabled = true;
    }
  }

  checkclick(data, e) {
    if (e.target.checked) {
      var temp = this.selectedlrstr.replace(data + this.com, "");
      var tmp1 = this.selectedlr.replace(this.openstr + data + this.closestr + this.com, "")
      this.selectedlrstr = temp;
      this.selectedlr = tmp1;
      this.selectedlrstr += data + this.com;
      this.selectedlr += this.openstr + data + this.com + "1" + this.com + "@@" + this.closestr + this.com;
    }
    else {
      var temp1 = this.selectedlrstr.replace(data + this.com, "");
      var temp = this.selectedlr.replace(this.openstr + data + this.com + "1" + this.com  + "@@" + this.closestr + this.com, "");
      this.selectedlrstr = temp1;
      this.selectedlr = temp;
    }
    // console.log("AFTER:"+this.selectedlr);
    // console.log(this.selectedlrstr);
  }

  addtabledata() {
    let datatb = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
    const obj = {
      checkeddata: datatb,
    }
    this.tps.getLRDetailsFromCheckbox(obj).subscribe(
      data => this.afteradd(data)
    );
  }

  afteradd(data) {
    var totalwt = 0;
    var totalpck = 0;
    //console.log(data.length);
   // console.log(this.loadingsheetdata.length);
   /* if(data.length > 15){
      swal('Error','LR Out of Limit','error');
    }
    else{*/
      this.loadingsheetdata = data;
      this.rowdata=data;
      this.loadingsheetdata.forEach(function (element) {
        totalpck = totalpck + +element.no_of_articles;
        totalwt = totalwt + +element.actual_wt;
      });
      this.tot_package = totalpck;
      this.tot_weight = totalwt;
      this.selectedlrforshowFlag = "";
     // this.displayLRforUpdate();
   /* }*/
    
  }

  // updatedctabledata() {
  //   let datatb = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
  //   // console.log("data is_"+datatb);
  //   const obj = {
  //     checkeddata: datatb,
  //     checkeddata1: this.dcdata.dcbo.dc_id
  //   }
  //   this.tps.updateDctabledata(obj).subscribe(
  //     data =>this.afterupdatedctabledata(data)
  //   );
  // }

  changed() {
    this.total = 0;
    this.total = (Number(this.hire)+Number(this.ex_wt_char)) - (Number(this.adv_amt)+Number(this.diesel)+Number(this.hamali)+Number(this.commission));
    this.total = Number(this.total) - Number(this.receive_bal);
    this.total = Math.round(this.total);
  }

  onsubmit() {
    if ((this.selectedlrstr == "()" || this.selectedlrstr == "")) {
      swal('warning', 'Please choose atleast one LR', 'warning');
    }
    else {
      if (!(this.datastate == "Update")) {
        var datatb = this.selectedlrstr.substr(0, this.selectedlrstr.length - 1);
        var datatb2 = this.openstr + datatb + this.closestr;
        var lrdatatb = this.selectedlr.substr(0, this.selectedlr.length - 1);
        var lrtoshowAmount = this.openstr + this.selectedlrforshowFlag.substr(0,this.selectedlrforshowFlag.length-1) + this.closestr;

        const obj = {
          dc_no: this.challanno,
          dc_date: this.date,
          vehicle_number: this.vehicleno,
          driver_name: this.drivername,
          from_loc: this.fromloc,
          to_loc: this.toloc,
          hire: this.hire,
          extra_wt_ch: this.ex_wt_char,
          hamali: this.hamali,
          comm: this.commission,
          adv_amt: this.adv_amt,
          diesel_ch: this.diesel,
          petrol_pump: this.petrolpump,
          total: this.total,
          total_packages: this.tot_package,
          total_wt: this.tot_weight,
          bal_amt: this.receive_bal,
          bal_amt_date: this.receive_date,
          pay_mode: this.paymode,
          bank_name: this.bankname,
          cheque_rtgs: this.cheqno,
          cheque_rtgs_date: this.chqdate,
          payble_at: this.payat,
          remarks: this.remark,
          user_id: this.user,
          lrdata: datatb2,
          dcdata: lrdatatb,
          lrtoshowAmount: lrtoshowAmount,
          branch: this.branch,
        }
       // console.log(obj);
        swal({
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          width: 150
        });
        swal.showLoading();
        this.tps.addLoadingSheet(obj).subscribe(
          data => this.aftersubmit(data)
        );
      }
      else {
        this.updateLSmaster();
      }
    }
  }

  aftersubmit(data) {
    swal.close();
    this.id = data['res_code'];
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error');
    //this.route.navigate(['pages/DC']);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  displayLRforUpdate() {
    const obj = {
      branch: this.branch,
      dcid: this.id
    }
    this.tps.getLRForLoadingTripUpdate(obj).subscribe(
      data => this.lrdata = data
    );
  }

  checkdata(obj): boolean {
    if (this.loadingsheetdata.some(e => e.lr_no == obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkdataforshowflag(data){
    if(data == "true"){
      return true;
    }
    else{
      return false;
    }
  }

  displayloadingsheetdata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.lsmaster = JSON.parse(localStorage.getItem("data"));
    localStorage.removeItem("data");
    this.id = this.lsmaster.lsbo.dc_id;
    this.branch = this.lsmaster.lsbo.branch;
    this.challanno = this.lsmaster.lsbo.dc_no;
    this.date = this.lsmaster.lsbo.dc_date;
    this.vehicleno = this.lsmaster.lsbo.vehicle_number;
    this.vehicleowner = this.lsmaster.lsbo.vehicle_owner;
    this.owner_addr = this.lsmaster.lsbo.owner_address;
    this.owner_cont = this.lsmaster.lsbo.owner_contact;
    this.drivername = this.lsmaster.lsbo.driver_name;
    this.licenseno = this.lsmaster.lsbo.license_name;
    this.mobileno = this.lsmaster.lsbo.mobile_no;
    this.fromloc = this.lsmaster.lsbo.from_loc;
    this.toloc = this.lsmaster.lsbo.to_loc;
    this.hire = this.lsmaster.lsbo.hire;
    this.ex_wt_char = this.lsmaster.lsbo.extra_wt_ch;
    this.hamali = this.lsmaster.lsbo.hamali;
    this.commission = this.lsmaster.lsbo.comm;
    this.adv_amt = this.lsmaster.lsbo.adv_amt;
    this.diesel = this.lsmaster.lsbo.diesel_ch;
    this.petrolpump = this.lsmaster.lsbo.petrol_pump;
    this.total = this.lsmaster.lsbo.total;
    this.tot_package = this.lsmaster.lsbo.total_packages;
    this.tot_weight = this.lsmaster.lsbo.total_wt;
    this.receive_bal = this.lsmaster.lsbo.bal_amt;
    this.receive_date = this.lsmaster.lsbo.bal_amt_date;
    this.paymode = this.lsmaster.lsbo.pay_mode;
    this.bankname = this.lsmaster.lsbo.bank_name;
    this.cheqno = this.lsmaster.lsbo.cheque_rtgs;
    this.chqdate = this.lsmaster.lsbo.cheque_rtgs_date;
    this.payat = this.lsmaster.lsbo.payble_at;
    this.remark = this.lsmaster.lsbo.remarks;
    this.loadingsheetdata = this.lsmaster.lrdetailsbo;
    this.rowdata = this.lsmaster.lrdetailsbo;
    //console.log(this.fromloc);
    this.getplaces();
    $('#fromloc').val(this.fromloc).trigger('change');
    $('#toloc').val(this.toloc).trigger('change');
    this.splitdata();
  }

  splitdata() {
    let strdata: string = "";
    let strdatalr: string = "";
    this.rowdata.forEach(function (element) {
      if (!strdata.includes(element.lr_master_id.toString())) {
        strdata += element.lr_master_id.toString() + ",";
        strdatalr += "(" + element.lr_master_id.toString() + "," + "1" + "," + "@@),"
        
      }
    })
    this.selectedlrstr = strdata;
    this.sendcallbacklr = strdata;
    this.sendcallbacklr = this.openstr + strdata.substr(0, strdata.length - 1) + this.closestr;
    this.selectedlr = strdatalr;
    this.setflagstring();
  }

  setflagstring(){
    var strdatalrforshowamount= "";
    for(let i=0;i<this.rowdata.length;i++){
      if(this.rowdata[i].showAmountFlag == "true"){
        strdatalrforshowamount += this.rowdata[i].lr_master_id + ",";
      } 
    }

    this.selectedlrforshowFlag = strdatalrforshowamount;
    //console.log(this.selectedlrforshowFlag);
    swal.close();
  }
  

  updateLSmaster() {
    //alert(this.selectedlrstr);
    if (this.selectedlrstr == "()") {
      swal('warning', 'warning'), error => swal('Please choose atleast one LR', error, 'warning')
    }
    else {
      var datatbupdate = this.openstr + this.selectedlrstr.substr(0, this.selectedlrstr.length - 1) + this.closestr;
      var lrdatatbupdate = this.selectedlr.substr(0, this.selectedlr.length - 1);
      var lrtoshowAmount = this.openstr + this.selectedlrforshowFlag.substr(0,this.selectedlrforshowFlag.length-1) + this.closestr;

      const obj = {
        dc_id: this.id,
        dc_no: this.challanno,
        dc_date: this.date,
        vehicle_number: this.vehicleno,
        driver_name: this.drivername,
        from_loc: this.fromloc,
        to_loc: this.toloc,
        hire: this.hire,
        extra_wt_ch: this.ex_wt_char,
        hamali: this.hamali,
        comm: this.commission,
        adv_amt: this.adv_amt,
        diesel_ch: this.diesel,
        petrol_pump: this.petrolpump,
        total: this.total,
        total_packages: this.tot_package,
        total_wt: this.tot_weight,
        bal_amt: this.receive_bal,
        bal_amt_date: this.receive_date,
        pay_mode: this.paymode,
        bank_name: this.bankname,
        cheque_rtgs: this.cheqno,
        cheque_rtgs_date: this.chqdate,
        payble_at: this.payat,
        remarks: this.remark,
        user_id: this.user,
        lrdata: datatbupdate,
        dcdata: lrdatatbupdate,
        lrtoshowAmount: lrtoshowAmount,
        branch: this.branch,
      }
      //console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 150
      });
      swal.showLoading();
      this.tps.updateLoadingSheet(obj).subscribe(
        data => this.afterupdate(data)
      );
    }
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => console.log(error);
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
    swal.close();
  }

  redirect() {
    this.route.navigate(['pages/loading_trip']);
  }

  pdfredirect() {
    const ob = {
      id: this.id,
      type: 'dc_master', // dc_master , bills
    }
    this.tps.viewpdf(ob).subscribe(
      data => this.afterpdf(data)
      , error => swal('Please check your internet connection', error, 'error')
    );
  }

  afterpdf(data) {
    swal({
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + data['location'] + '" height="500px" width="100%"/>',
      preConfirm: () => {
        return document.getElementById("viewfile");
      }
    })
  }
}