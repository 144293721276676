import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { LRReport } from '../../../Models/lrreport';
import { Excellservice } from '../../../Services/excellservice.service';
import { Paymentcollectionreport } from '../../../Models/paymentcollectionreport';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs/Observable';

declare var $: any;

@Component({
  selector: 'app-pending-bill-for-payment',
  templateUrl: './pending-bill-for-payment.component.html',
  styleUrls: ['./pending-bill-for-payment.component.css'],
  providers: [TpserviceService, Dataservices, DatePipe,Excellservice]
})
export class PendingBillForPaymentComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  branch: string;  
  branchlist: any;
  customer:any="";
  cust:any;
  fromDate:string="";
  toDate:string="";
  paymode:string = "All";
  flag: boolean;
  payBillListobj:Paymentcollectionreport;
  payBillList:Paymentcollectionreport[]=[];
  total_billAmount:any;

  constructor(private tps: TpserviceService,private excellservice:Excellservice, private dts: Dataservices,private datepipe: DatePipe, private route: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.fromDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.toDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
  }



  ngAfterViewInit() {
    var self = this;

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onbranchchange();
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
      //self.displaylr();
    });
  }


  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.aftergetBranch(data)
    );
  }

  aftergetBranch(data) {
    this.branchlist = data;
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.cust = data;
    this.getPendingBillListByDefault();
  }
  onbranchchange() {
    localStorage.setItem("branch", this.branch);
    this.getPendingBillListByDefault();
  }

  getpaybilllist(){
    if(this.fromDate  != "" && this.toDate != "" && this.customer == ""){
      this.getPendingBillListBydate();
    }
    else if(this.fromDate  != "" && this.toDate != "" && this.customer != ""){
      this.getPendingBillListByCustomer();
    }
    else{
      this.getPendingBillListByDefault();
    }
  }

  getPendingBillListByDefault(){
    const OBJ = {
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getPendingBillListForreportByDefault(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }

  getPendingBillListBydate(){
    const OBJ = {
      branch: this.branch,
      from_date:this.fromDate,
      to_date:this.toDate
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getPendingBillListForreportByDate(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }

  getPendingBillListByCustomer(){
    const OBJ = {
      branch: this.branch,
      from_date:this.fromDate,
      to_date:this.toDate,
      cust_name:this.customer
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getPendingBillListForreportByCustomer(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }


  afterGetBillData(data){
    $("#products-table").dataTable().fnDestroy();
    this.payBillList = data;
    this.total_billAmount = 0;
    for(let i=0;i<this.payBillList.length;i++){
      this.total_billAmount = Number(this.total_billAmount) + Number(this.payBillList[i].bill_amount);
    }
    this.flag=false;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 20,
        //pagingType: 'full_numbers',
        fixedHeader: true,
        responsive: true,
        ordering: false,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  Exportasexcell(){
    // this.payBillListobj = {
    //   bill_no:"",
    //   bill_date:"",
    //   customer_name:"Total Pending Bill Amount",
    //   bill_amount:this.total_billAmount,
    //   paymode:"",
    //   bank_name:"",
    //   cheque_or_rtgs_no:"",
    //   cheque_or_rtgs_date:"",
    //   tds_deduction:"",
    //   party_deduction:"",
    //   received:""
    // }
    // this.payBillList.push(this.payBillListobj);

     // console.log(this.table.nativeElement);
     //const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
     //console.log("ws:",ws);
     // const wb: XLSX.WorkBook = XLSX.utils.book_new();
     
     // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     //const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
     //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     //console.log(excelBuffer);
     //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
     
     
     /* save to file */
     //this.excellservice.saveAsExcelFile(excelBuffer, 'PENDING_BILL_REPORTFILE');
    this.excellservice.exportAsExcelFile(this.payBillList, 'PENDING_BILL_REPORTFILE');
  }
}
