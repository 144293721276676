import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import { DatePipe } from '@angular/common';
import { Excellservice } from '../../../Services/excellservice.service';
import { Paymentcollectionreport } from '../../../Models/paymentcollectionreport';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-cash-memo-payment-collection-report',
  templateUrl: './cash-memo-payment-collection-report.component.html',
  styleUrls: ['./cash-memo-payment-collection-report.component.css'],
  providers: [TpserviceService, Dataservices, DatePipe,Excellservice]
})
export class CashMemoPaymentCollectionReportComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  branch: string;  
  branchlist: any;
  customer:any="";
  cust:any;
  fromDate:string="";
  toDate:string="";
  paymode:string = "All";
  flag: boolean;
  payBillList:Paymentcollectionreport[]=[];
  payBillList1:Paymentcollectionreport[]=[];
  total_Bill_Amount:any=0;
  total_received_Amount:any=0;
  total_tds_Ded_Amount:any=0;
  total_Party_Ded_Amount:any=0;


  constructor(private tps: TpserviceService,private excellservice:Excellservice, private dts: Dataservices,private datepipe: DatePipe, private route: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.fromDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.toDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
  }



  ngAfterViewInit() {
    var self = this;

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onbranchchange();
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
      //self.displaylr();
    });
  }


  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.aftergetBranch(data)
    );
  }

  aftergetBranch(data) {
    this.branchlist = data;
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.cust = data;
    //this.getPayBillListByDefault();
  }
  onbranchchange() {
    localStorage.setItem("branch", this.branch);
    this.getPayBillListByDefault();
  }

  getpaybilllist(){
    if(this.fromDate  != "" && this.toDate != "" && this.customer == ""){
      this.getPayBillListBydate();
    }
    else if(this.fromDate  != "" && this.toDate != "" && this.customer != ""){
      this.getPayBillListByCustomer();
    }
    else{
      this.getPayBillListByDefault();
    }
  }

  getPayBillListByDefault(){
    const OBJ = {
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getCashPayCollListForreportByDefault(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }

  getPayBillListBydate(){
    const OBJ = {
      branch: this.branch,
      from_date:this.fromDate,
      to_date:this.toDate
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getCashPayCollListForreportByDate(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }

  getPayBillListByCustomer(){
    const OBJ = {
      branch: this.branch,
      from_date:this.fromDate,
      to_date:this.toDate,
      cust_name:this.customer
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getCashPayCollListForreportByCustomer(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }


  afterGetBillData(data){
    $("#products-table").dataTable().fnDestroy();
    this.payBillList = data;
    this.flag=false;
    swal.close();
    this.calculate();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 20,
        //pagingType: 'full_numbers',
        fixedHeader: true,
        responsive: true,
        ordering: false,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
        table.column(2).search($(this).val()).draw();
    });
  }

  calculate(){
    this.payBillList1.length = 0;
    this.total_Bill_Amount = 0;
    this.total_Party_Ded_Amount = 0;
    this.total_tds_Ded_Amount = 0;
    this.total_received_Amount = 0;
    for(let i=0;i<this.payBillList.length;i++){
      // this.total_Bill_Amount = this.total_Bill_Amount + Number(this.payBillList[i].bill_amount);
      this.total_received_Amount = this.total_received_Amount + Number(this.payBillList[i].received);
      this.total_tds_Ded_Amount = this.total_tds_Ded_Amount + Number(this.payBillList[i].tds_deduction);
      this.total_Party_Ded_Amount = this.total_Party_Ded_Amount + Number(this.payBillList[i].party_deduction);
    }

    this.payBillList1.push(this.payBillList[0]);
    for(let i=0;i<this.payBillList.length;i++){
      if(this.payBillList1.find(x=>x.bill_no == this.payBillList[i].bill_no)){
      }
      else{
        this.payBillList1.push(this.payBillList[i]);
      }
    }
   // console.log(this.payBillList1);
    for(let j=0;j<this.payBillList1.length;j++){
      this.total_Bill_Amount = this.total_Bill_Amount + Number(this.payBillList1[j].bill_amount);
    }
  }
  
  Exportasexcell(){
    //console.log(this.table.nativeElement);
    //const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    //console.log("ws:",ws);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //console.log(excelBuffer);
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    
    
    /* save to file */
     // this.excellservice.saveAsExcelFile(excelBuffer, 'PAY_COL_REPORTFILE');
    //XLSX.writeFile(wb, 'SheetJS.xlsx');
    this.excellservice.exportAsExcelFile(this.payBillList, 'PAY_COL_REPORTFILE');
  }

}
