import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import { permissions } from '../../Models/permissions';
import { Branch } from '../../Models/Branch';
import { bill } from '../../Models/bill';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { deleteBo } from '../../Models/deleteBo';

declare var $: any;

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.css'],
  providers: [TpserviceService]
})
export class BillListComponent implements OnInit, AfterViewInit {

  permissions: permissions;
  grant_edit: any;
  branch: string = "";
  branches: Branch;
  billlist: bill[];
  email: string = "";
  id: string = "";
  bill_no: string = "";
  text: string = "";
  emails: Array<{ to: string; }>;
  emailarray: string = "";
  deletelist: string;
  multipleselect: Array<any> = [];
  deletestr: deleteBo[] = [];
  deletedbillnos: string = "";
  location: any;
  bill_search: any = "";
  customer_search: any = "";
  customerList: any;

  public pageid: any = 1;
  public last: any;
  totalcount: string = "";
  public firstrowno: any;
  public lastrowno: any;
  public search: any = "";
  disablestate: any; 
  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.TLRE;
    this.displaybranches();
  }

  ngAfterViewInit() {
    var self = this;
    $('#compose-box').validate({
      rules: {
        cemail: {
          required: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.getBilllist();
    });

    $(document).ready(function () {
        $("#customer_search").select2({
            theme: "classic",
            allowClear: true
        });
    });

    $("#customer_search").on('select2:select', function (evt) {
        var id = evt.params.data.id;
        self.customer_search = id;
    });

    $(document).ready(function () {
      $("#email").select2({
        theme: "classic",
        allowClear: true,
        tags: true,
        width: 300,
        tokenSeparators: [','],
        dropdownParent: $('#compose-box')
      });
    });

    $("#email").on('select2:select', function (evt) {
      self.emailarray = ($("#email").val());
    });
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
    this.getBilllist();
  }


  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.customerList = data;
   // this.getBilllist();
  }

  

  // gettabledata(data) {
  //   swal.close();
  //   $("#products-table").dataTable().fnDestroy();
  //   this.dclist = data;
  //   this.last = this.dclist[0].tot_count;
  //   this.totalcount = this.dclist[0].tot_row_count;
  //   this.firstrowno = this.dclist[0].row_count;
  //   this.lastrowno = this.dclist[this.dclist.length - 1].row_count;
  //   this.paginationbuttonenabledisable()
  //   this.changedetect.detectChanges();
  //   $('#products-table').DataTable({
  //     pageLength: 20,
  //     // pagingType: 'simple_numbers',
  //     bPaginate: false,
  //     fixedHeader: true,
  //     responsive: true,
  //     ordering: false,
  //     bInfo: false,
  //     'sDom': 'rtip',
  //     // 'sDom': 'lfrti, rtip',
  //     // 'dom': 'lfrti'
  //   });
  //   const table = $('#products-table').DataTable();
  //   $('#key-search').on('keyup', function () {
  //     table.search(this.value).draw();
  //   });
  //   $('#type-filter').on('change', function () {
  //     table.column(2).search($(this).val()).draw();
  //   });
  // }
  gettabledata(data) {
    var self = this;
    $("#products-table").dataTable().fnDestroy();
    this.billlist = data;
    swal.close();
    if(this.billlist.length == 0){
      this.last = 0;
      this.totalcount = null; 
      this.firstrowno = 0;
      this.lastrowno = 0;
    }else
    {
    this.last = this.billlist[0].tot_count;
    this.totalcount = this.billlist[0].tot_row_count;
    this.firstrowno = this.billlist[0].row_count;
    this.lastrowno = this.billlist[this.billlist.length - 1].row_count;
    }
     this.paginationbuttonenabledisable()
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      bPaginate: false,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      bInfo: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }
  paginationbuttonenabledisable() {
    if (Number(this.totalcount) <= 20) {
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
    }
    else {
      if (Number(this.pageid) == 1) {
        (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
      }
      else if (Number(this.pageid) == Number(this.last)) {
        (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
      }
      else {
        (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
      }
    }
  }
  

  getBilldata(ob) {
    const obj = {
      id: ob
    }
    this.tps.getBillData(obj).subscribe(
      data => this.aftergetbilldata(data)
    );
  }

  aftergetbilldata(data) {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("datastate", "1");
    this.route.navigate(['/Transactions/Bill']);
  }

  checkPdf(location, id) {
    this.location = "";
    this.location = location;
    //console.log(location+','+id);
    const OBJ = {
      checkeddata: id,
      checkeddata1: "bills"
    }
    // var xhr = new XMLHttpRequest();
    // xhr.open('HEAD',location,false);
    // xhr.send();
    // console.log(xhr.status);

    // if(xhr.status == 404 || String(xhr.status) == "404"){
    //     console.log("if"+xhr.status);
    //     swal("File Not Found !!", "File is not on server !!", "warning");
    //     this.tps.checkpdf(OBJ).subscribe(
    //         data => this.afterCheckPdf(data)
    //     )
    // }
    // else{
    //    this.pdfview(location); 
    // }

    this.tps.checkpdf(OBJ).subscribe(
      data => this.afterCheckPdf(data)
    )
  }

  afterCheckPdf(data) {
    //console.log(data)
    this.pdfview(this.location);
  }

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',

      preConfirm: () => {
        document.getElementById("download").style.display = 'none';
        return document.getElementById("viewfile");
      }
    })
  }

  view(obj, obj1, billno) {
    $('#getUnit').modal('show');
    this.id = obj;
    this.emails = obj1;
    this.bill_no = billno;
  }

  close() {
    $('#getUnit').modal('hide');
  }

  onsubmit() {
    const obj = {
      id: this.id,
      bill_no: this.bill_no,
      emails: this.emailarray,
      text: this.text,
      type: 'bills',
    }
    this.tps.SendMail(obj).subscribe(
      data => this.aftersubmit(data)
    );
  }

  aftersubmit(data) {
    $('#getUnit').modal('hide');
    this.clear();
  }

  clear() {
    this.text = "";
    $("#email").val('').trigger('change');
  }

  Multiselect(index, ev) {
    if (this.billlist[index].selected == 'true') {
      this.billlist[index].selected = 'false';
    }
    else {
      this.billlist[index].selected = 'true';
    }
  }

  Removechecked_() {
    const com = ",";
    this.deletelist = "";
    var records = 0;
    for (var i = 0; i < this.billlist.length; i++) {
      if (this.billlist[i].selected == 'true') {
        this.multipleselect.push(i);
        this.deletelist += this.billlist[i].id + com;
        records++;
      }
    }
    if (this.deletelist == "" || this.deletelist == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {

          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts = this.multipleselect.length;
          for (var i = this.multipleselect.length - 1; i >= 0; i--) {
            this.billlist.splice(this.multipleselect[i], 1);
            this.billlist.sort();
          }

          this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
          this.deleteBill();
          this.multipleselect = [];
        }
      })
    }
  }

  deleteBill() {
    const dcobject = {
      selected: this.deletelist
    }
    this.tps.deleteBill(dcobject).subscribe(
      data => this.callafterdel(data), error1 => console.log(error1));
  }

  callafterdel(data) {
    this.deletestr = data;
    this.buildbillno(this.deletestr);
    if (this.deletedbillnos == "" || this.deletedbillnos == "()") {

      swal('success', "Bill Deleted Successfully", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "Can not delete these Bills " + this.deletedbillnos + " already used".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.deletedbillnos = "";
    this.getBilllist();
  }

  buildbillno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletedbillnos = this.deletedbillnos + data[i].id + ",";
      }
    }
  }

  getBilllist() {
    localStorage.setItem("branch", this.branch);
    this.pageid=1; 
    const obj = {
      branch: this.branch,
      page_id: this.pageid,
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getBilllist(obj).subscribe(
      data => this.gettabledata(data)
    );
  }
  billsearch() {
    if (this.bill_search == "" && this.customer_search == "") {
      const obj = {
        branch: this.branch, 
        page_id: this.pageid,
      }
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
      // swal('Alert', 'Please select search parameter first!!!', 'warning');
    }
    else {
      this.pageid = 1;
      const obj = {
        
        bill_no: this.bill_search,
        branch: this.branch,
        customer_id: this.customer_search ,
        page_id: this.pageid,
      }
      console.log(obj);
      swal({ 
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
  /* This function return the dc list bybranch and previous page id along with search keyword 
          if present or requested. */
          prev() {
            swal({
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              width: 100,
              heightAuto: true,
              background: "rgba(0, 0, 0, .1)",
            });
            swal.showLoading();
            (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
            (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
            if (this.pageid > 1) {
              this.pageid = this.pageid - 1;
              (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
              (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
            }
            else {
              this.pageid = 1;
              (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
              (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
            }
            if (this.bill_search == "" && this.customer_search == "") {
              const obj = { 
                branch: this.branch, 
                page_id: this.pageid
              }
              this.tps.BillSearch(obj).subscribe(
                data => this.gettabledata(data)
              );
            } 
            else {
              const obj = {
                bill_no: this.bill_search,
                branch: this.branch,
                customer_id: this.customer_search, 
                page_id: this.pageid,
                checkeddata: this.search,
                checkeddata1: this.branch,
                checkeddata2: this.pageid
              }
              this.tps.BillSearch(obj).subscribe(
                data => this.gettabledata(data)
              );
            }
          }
          /* This function return the dc list bybranch and next page id along with search keyword 
          if present or requested. */
  next() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
      if (this.pageid == this.last) {
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
    }
    else {
      this.pageid = this.pageid + 1;
     
    }
    if (this.bill_search == "" && this.customer_search == "") {
      const obj = { 
        branch: this.branch, 
        page_id: this.pageid
      }
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
                branch: this.branch,
                customer_id: this.customer_search, 
                page_id: this.pageid,
      }
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
  
  /* This function return the dc list bybranch and user inserted page id along with search keyword 
          if present or requested. */
          input() {
            swal({
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              width: 100,
              heightAuto: true,
              background: "rgba(0, 0, 0, .1)",
            });
            swal.showLoading();
            // console.log('totalcount:', this.totalcount);
            // console.log('pageid:', this.pageid);
            // console.log('totalcount:', this.last);
            if ((Number(this.totalcount) <= 20 && Number(this.pageid != 1)) || Number(this.pageid) > Number(this.last)) {
              swal('Alert!!!', 'Record Not Available', 'warning');
            }
            else {
              if (this.pageid <= 0 || this.pageid == null || this.pageid == 1) {
                this.pageid = 1;
                (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
                (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
              }
              else {
                (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
                (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
              }
              if (this.pageid == this.last || this.pageid > this.last) {
                this.pageid = this.last;
                (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
                (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
              }
              else {
                (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
                (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
              }
              if (this.bill_search == "" && this.customer_search == "") {
                const obj = { 
                  branch: this.branch, 
                  page_id: this.pageid
                }  
                this.tps.BillSearch(obj).subscribe(
                  data => this.gettabledata(data)
                );
              }
              else {
                const obj = {
                  bill_no: this.bill_search,
                          branch: this.branch,
                          customer_id: this.customer_search, 
                          page_id: this.pageid,
                }
                this.tps.BillSearch(obj).subscribe(
                  data => this.gettabledata(data)
                );
              }
            }
          }
          
  /* This function return the dc list bybranch and last page id along with search keyword 
          if present or requested. */
  lastpage() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    this.pageid = this.last;
    if (this.pageid == this.last) {
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
    }
    if (this.bill_search == "" && this.customer_search == "") {
      const obj = { 
        branch: this.branch, 
        page_id: this.pageid
      }
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
                branch: this.branch,
                customer_id: this.customer_search, 
                page_id: this.pageid,
      }
      this.tps.BillSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
}
