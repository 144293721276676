import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { Router } from '@angular/router';
import { places } from '../../../Models/places';
import { TpserviceService } from '../../../Services/tpservice.service';
import swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-form-advanced',
  templateUrl: './place.component.html',
  providers: [TpserviceService]
})
export class PlaceComponent implements OnInit, AfterViewInit {

  constructor(private tps: TpserviceService, public router: Router) { }

  placedata: places;
  placename: string = "";
  placeabbre: string = "";
  contactno: string = "";
  placeid: string = "";
  datastate: string = "Save";

  ngOnInit() {
    if (localStorage.getItem("placedatastate") == "1") {
      this.datastate = "Update";
      this.displaybranchdata();
      localStorage.removeItem("placedatastate");
    }
  }
  
  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        placename: {
          minlength: 2,
          required: !0
        },
        placeabbrevation: {
          required: !0,
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onSubmit();
      }
    });
  }

  onSubmit() {
    if (!(this.datastate == "Update")) {
      const obj = {
        placename: this.placename,
        placeabbreviation: this.placeabbre,
        contactnumber: this.contactno,
      }
      this.tps.addPlace(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updateplace();
    }
  }

  aftersubmit(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Places-List']);

  }

  updateplace() {
    const obj = {
      placeid: this.placeid,
      placename: this.placename,
      placeabbreviation: this.placeabbre,
      contactnumber: this.contactno
    }
    this.tps.updatePlace(obj).subscribe(
      data => this.afterupdate(data)
    );
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Places-List']);
  }
  
  displaybranchdata() {
    this.placedata = JSON.parse(localStorage.getItem("placedata"));
    localStorage.removeItem("placedata");
    this.placeid = this.placedata.placeid;
    this.placename = this.placedata.placename;
    this.placeabbre = this.placedata.placeabbreviation;
    this.contactno = this.placedata.contactnumber;
  }

  redirect() {
    this.router.navigate(['pages/Places-List']);
  }

}