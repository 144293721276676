import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { user } from '../../Models/user';
import { Router } from '@angular/router';
import { permissions } from '../../Models/permissions';


declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './permissions.component.html',
  providers:[TpserviceService]
})
export class PermissionsComponent implements OnInit, AfterViewInit, OnDestroy {

  branch:string="1";
  branchlist:any=[];
  employee:string="";
  permissionsstr="";
  com=",";
  openstr="(";
  closestr=")"
  userlist:user[];

  lr:boolean=false;
  lredit:boolean=false;
  dc:boolean=false;
  dcedit:boolean=false;
  fm:boolean=false;
  fmedit:boolean=false;
  ir:boolean=false;
  iredit:boolean=false;
  pod:boolean=false;
  podedit:boolean=false;

  article:boolean=false;
  articleedit:boolean=false;
  places:boolean=false;
  placesedit:boolean=false;
  branches:boolean=false;
  branchesedit:boolean=false;
  customeredit:boolean=false;
  customer:boolean=false;
  employees:boolean=false;
  employeesedit:boolean=false;
  drivers:boolean=false;
  driversedit:boolean=false;
  vehicles:boolean=false;
  vehiclesedit:boolean=false;
  vehicletypes:boolean=false;
  vehicletypesedit:boolean=false;
  vehicleowners:boolean=false;
  vehicleownersedit:boolean=false;

  regularbills:boolean=false;
  regularbillsedit:boolean=false;
  transporterbills:boolean=false;
  transporterbillsedit:boolean=false;

  misreport:boolean=false;
  misreportedit:boolean=false;
  stockreport:boolean=false;
  stockreportedit:boolean=false;
  LRDWreport:boolean=false;
  LRDWreportedit:boolean=false;
  LRBWreport:boolean=false;
  LRBWreportedit:boolean=false;
  LRCWreport:boolean=false;
  LRCWreportedit:boolean=false;
  DCDWreport:boolean=false;
  DCDWreportedit:boolean=false;
  DCBWreport:boolean=false;
  DCBWreportedit:boolean=false;
  DCVWreport:boolean=false;
  DCVWreportedit:boolean=false;
  CFMreport:boolean=false;
  FMDWreportedit:boolean=false;
  LFMreport:boolean=false;
  FMBWreportedit:boolean=false;
  CEFMreport:boolean=false;
  FMVWreportedit:boolean=false;
  TRBreport:boolean=false;
  rpt:boolean=false;
  txn:boolean=false;
  USRBRC:any;
  datastate:string="Save";
  userpemissions:permissions;

constructor(private tps:TpserviceService,private route:Router) { }


  ngOnInit() {
    $('body').addClass('empty-layout');
    

    this.displaybranches();
    this.USRBRC=localStorage.getItem("USRBRSTATE");
   
   if(localStorage.getItem("userdatastate")=="1")
    {
      this.datastate="Update";
      localStorage.removeItem("userdatastate");
      this.getuserpermissionsdata();
      this.getalluserlist();
    }
    else{
      this.branch=localStorage.getItem("branch");
      this.getuserlist();
    }
  }

  ngAfterViewInit() {
    var self=this;
    $('#login-form').validate({
      
        rules: {
            employee: {required:true},
            branch: {required:true}
        },
        errorClass: 'help-block error',
        highlight: function(e) {
          $(e).closest('.form-group.row').addClass('has-error');
        },
        unhighlight: function(e) {
          $(e).closest('.form-group.row').removeClass('has-error');
        },
  
        submitHandler: function(form) {
          self.submitpermissions();
      }
    });

    $('branch-name').select2({
      tags:"true",
      theme:"classic",
      placeholder:'Select a Branch'
    });

    $('user-name').select2({
      tags:"true",
      theme:"classic",
      placeholder:'Select a User'
    })
  }

  ngOnDestroy() {
    $('body').removeClass('empty-layout');
  }

  getuserlist()
  {
    const obj={
  
      checkeddata:this.branch
  
    }
    this.tps.getNewUsers(obj).subscribe(
       data => this.userlist=data
  );

  }

  getalluserlist()
  {
    const obj={
      checkeddata:this.branch
    }
    this.tps.getUsers(obj).subscribe(
       data => this.userlist=data
  );
}

  displaybranches() {

    this.tps.getBranch().subscribe(
        data => this.branchlist = data
    );

}

submitpermissions(){
  if(this.datastate=="Save"){
    this.stringbuilder();
    const obj={
      checkeddata:this.permissionsstr,
      checkeddata1:this.employee
    }
    this.tps.grantPermissions(obj).subscribe(
      data=>this.aftersubmit(data),error=>  swal('Error', error.toString(),'error' ))
    }
    else{
      this.stringbuilder();
    const obj={
      checkeddata:this.permissionsstr,
      checkeddata1:this.employee
    }
    this.tps.grantPermissionsUpdate(obj).subscribe(
      data=>this.aftersubmit(data),error=>  swal('Error', error.toString(),'error' ))
    }
  }

stringbuilder(){
this.permissionsstr=
this.openstr+this.employee+this.com+"'"+'TLR'+"'"+this.com+this.lr+this.com+this.lredit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TDC'+"'"+this.com+this.dc+this.com+this.dcedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TFM'+"'"+this.com+this.fm+this.com+this.fmedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TIR'+"'"+this.com+this.ir+this.com+this.iredit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TPOD'+"'"+this.com+this.pod+this.com+this.podedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MART'+"'"+this.com+this.article+this.com+this.articleedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MBR'+"'"+this.com+this.branches+this.com+this.branchesedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MEMP'+"'"+this.com+this.employees+this.com+this.employeesedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MVC'+"'"+this.com+this.vehicles+this.com+this.vehiclesedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MVCO'+"'"+this.com+this.vehicleowners+this.com+this.vehicleownersedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MPL'+"'"+this.com+this.places+this.com+this.placesedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MCUS'+"'"+this.com+this.customer+this.com+this.customeredit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MDV'+"'"+this.com+this.drivers+this.com+this.driversedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'MVT'+"'"+this.com+this.vehicletypes+this.com+this.vehicletypesedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'REGB'+"'"+this.com+this.regularbills+this.com+this.regularbillsedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TRANSB'+"'"+this.com+this.transporterbills+this.com+this.transporterbillsedit+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RMIS'+"'"+this.com+this.misreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RSTOCK'+"'"+this.com+this.stockreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RLRDW'+"'"+this.com+this.LRDWreport+this.com+false+this.closestr+this.com+ 
this.openstr+this.employee+this.com+"'"+'RLRBW'+"'"+this.com+this.LRBWreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RLRCW'+"'"+this.com+this.LRCWreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RDCDW'+"'"+this.com+this.DCDWreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RDCBW'+"'"+this.com+this.DCBWreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RDCVW'+"'"+this.com+this.DCVWreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RCFM'+"'"+this.com+this.CFMreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RLFM'+"'"+this.com+this.LFMreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RCEFM'+"'"+this.com+this.CEFMreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RTRB'+"'"+this.com+this.TRBreport+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'TXN'+"'"+this.com+this.txn+this.com+false+this.closestr+this.com+
this.openstr+this.employee+this.com+"'"+'RPT'+"'"+this.com+this.rpt+this.com+false+this.closestr+this.com

console.log("value is"+this.permissionsstr);
}

aftersubmit(data){

  swal('Success', data['messasge'].toString(),'success' ); 
  this.route.navigate(['/User-List']);


}
onchange(){
  // console.log(this.branch);
  // console.log(evt);
  this.getuserlist();
}

getuserpermissionsdata(){
  this.datastate="Update";
  this.userpemissions=JSON.parse(localStorage.getItem('userpermissiondata'));
  localStorage.removeItem('userpermissiondata');

  this.branch=this.userpemissions.branch;
  this.employee=this.userpemissions.user;

  this.txn=(this.userpemissions.TXN == "1")? true:false;
  this.lr=(this.userpemissions.TLR == "1")? true:false;
  this.lredit=(this.userpemissions.TLRE == "1")? true:false;
  this.dc=(this.userpemissions.TDC == "1")? true:false;
  this.dcedit=(this.userpemissions.TDCE == "1")? true:false;
  this.fm=(this.userpemissions.TFM == "1")? true:false;
  this.fmedit=(this.userpemissions.TFME == "1")? true:false;
  this.ir=(this.userpemissions.TIR == "1")? true:false;
  this.iredit=(this.userpemissions.TIRE == "1")? true:false;
  this.pod=(this.userpemissions.TPOD == "1")? true:false;
  this.podedit=(this.userpemissions.TPODE == "1")? true:false;

  this.article=(this.userpemissions.MART == "1")? true:false;
  this.articleedit=(this.userpemissions.MARTE == "1")? true:false;
  this.branches=(this.userpemissions.MBR == "1")? true:false;
  this.branchesedit=(this.userpemissions.MBRE == "1")? true:false;
  this.places=(this.userpemissions.MPL == "1")? true:false;
  this.placesedit=(this.userpemissions.MPLE == "1")? true:false;
  this.customer=(this.userpemissions.MCUS == "1")? true:false;
  this.customeredit=(this.userpemissions.MCUSE == "1")? true:false;
  this.drivers=(this.userpemissions.MDV == "1")? true:false;
  this.driversedit=(this.userpemissions.MDVE == "1")? true:false;
  this.employees=(this.userpemissions.MEMP == "1")? true:false;
  this.employeesedit=(this.userpemissions.MEMPE == "1")? true:false;
  this.vehicles=(this.userpemissions.MVC == "1")? true:false;
  this.vehiclesedit=(this.userpemissions.MVCE == "1")? true:false;
  this.vehicleowners=(this.userpemissions.MVCO == "1")? true:false;
  this.vehicleownersedit=(this.userpemissions.MVCE == "1")? true:false;
  this.vehicletypes=(this.userpemissions.MVT == "1")? true:false;
  this.vehicletypesedit=(this.userpemissions.MVTE == "1")? true:false;

  this.rpt=(this.userpemissions.RPT == "1")? true:false;
  this.regularbills=(this.userpemissions.BRB== "1")? true:false;
  this.regularbillsedit=(this.userpemissions.BRBE== "1")? true:false;
  this.transporterbills=(this.userpemissions.BTB== "1")? true:false;
  this.transporterbillsedit=(this.userpemissions.BTBE== "1")? true:false;


  this.misreport=(this.userpemissions.RMIS == "1")? true:false;
  this.stockreport=(this.userpemissions.RStock == "1")? true:false;
  this.LRBWreport=(this.userpemissions.RLRB == "1")? true:false;
  this.LRCWreport=(this.userpemissions.RLRC == "1")? true:false;
  this.LRDWreport=(this.userpemissions.RLRD == "1")? true:false;
  this.DCBWreport=(this.userpemissions.RDCB == "1")? true:false;
  this.DCDWreport=(this.userpemissions.RDCD == "1")? true:false;
  this.DCVWreport=(this.userpemissions.RDCV == "1")? true:false;
  this.CFMreport=(this.userpemissions.RFMC == "1")? true:false;
  this.LFMreport=(this.userpemissions.RFML == "1")? true:false;
  this.CEFMreport=(this.userpemissions.RFMCU == "1")? true:false;
  this.TRBreport=(this.userpemissions.RTRB == "1")? true:false;
}

redirect()
{
  this.route.navigate(['/index']);
}

checktxn(){
  if(this.txn==false){
    this.lr=true;
    this.lredit=true;
    this.dc=true;
    this.dcedit=true;
    this.fm=true;
    this.fmedit=true;
    this.ir=true;
    this.iredit=true;
    this.pod=true;
    this.podedit=true;
  }
  else{
    this.lr=false;
    this.lredit=false;
    this.dc=false;
    this.dcedit=false;
    this.fm=false;
    this.fmedit=false;
    this.ir=false;
    this.iredit=false;
    this.pod=false;
    this.podedit=false;
  }
}

checkrpt(){
  if(this.rpt==false){
    this.misreport=true;
    this.stockreport=true;
    this.LRBWreport=true;
    this.LRCWreport=true;
    this.LRDWreport=true;
    this.DCBWreport=true;
    this.DCDWreport=true;
    this.DCVWreport=true;
    this.CFMreport=true;
    this.LFMreport=true;
    this.CEFMreport=true;
    this.TRBreport=true;
  }
  else{
    this.misreport=false;
    this.stockreport=false;
    this.LRBWreport=false;
    this.LRCWreport=false;
    this.LRDWreport=false;
    this.DCBWreport=false;
    this.DCDWreport=false;
    this.DCVWreport=false;
    this.CFMreport=false;
    this.LFMreport=false;
    this.CEFMreport=false;
    this.TRBreport=false;
  }
}

}
