import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { permissions } from '../../Models/permissions';
import { Branch } from '../../Models/Branch';
import { bill } from '../../Models/bill';
import { TpserviceService } from '../../Services/tpservice.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { deleteBo } from '../../Models/deleteBo';

declare var $: any;
@Component({
  selector: 'app-cash-memo-list',
  templateUrl: './cash-memo-list.component.html',
  styleUrls: ['./cash-memo-list.component.css'],
  providers: [TpserviceService]
})
export class CashMemoListComponent implements OnInit, AfterViewInit {

  permissions: permissions;
  grant_edit: any;
  branch: string = "";
  branches: Branch;
  cashmemolist: bill[];
  email: string = "";
  id: string = "";
  text: string = "";
  emails: Array<{ to: string; }>;
  emailarray: string = "";
  deletelist: string;
  multipleselect: Array<any> = [];
  deletestr: deleteBo[] = [];
  deletednos: string = "";
  location: any;
  bill_search: any = "";
  customer_search: any = "";
  customerList: any;
  last: any;
  totalcount: string = "";
  public firstrowno: any;
  public lastrowno: any;
  public pageid: any = 1;
  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.TLRE;
    this.displaybranches();
  }

  ngAfterViewInit() {
    var self = this;
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.getCashMemolist();
    });

    $(document).ready(function () {
      $("#customer_search").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer_search").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer_search = id;
    });
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
  
    this.displayCustomers();
    this.getCashMemolist();
  }


  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.customerList = data;
   // this.getCashMemolist();
  }

  getCashMemolist() {
    this.pageid=1;
    const obj = {
      branch: this.branch,
      page_id: this.pageid
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getCashMemolistPage(obj).subscribe(
      data => this.gettabledata(data)
    );
  }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.cashmemolist = data;
   // if(this.cashmemolist.length == 0)
    swal.close();
    if(this.cashmemolist.length == 0){
      this.last = 0;
      this.totalcount = null; 
      this.firstrowno = 0;
      this.lastrowno = 0;
    }else
    {
    this.last = this.cashmemolist[0].tot_count;
    this.totalcount = this.cashmemolist[0].tot_row_count;
    this.firstrowno = this.cashmemolist[0].row_count;
    this.lastrowno = this.cashmemolist[this.cashmemolist.length - 1].row_count;
  }
       this.changedetect.detectChanges();
    $('#products-table').DataTable({
    /*  pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',*/
      pageLength: 20,
      bPaginate: false,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      bInfo: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  getCashMemodata(ob) {
    const obj = {
      id: ob
    }
    this.tps.getCashMemoData(obj).subscribe(
      data => this.aftergerdata(data)
    );
  }

  aftergerdata(data) {
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("datastate", "1");
    this.route.navigate(['/Transactions/CashMemo']);
  }

  checkPdf(location, id) {
    this.location = "";
    this.location = location;
    // console.log(location+','+id);
    const OBJ = {
      checkeddata: id,
      checkeddata1: "cashmemo"
    }
    // var xhr = new XMLHttpRequest();
    // xhr.open('HEAD',location,false);
    // xhr.send();
    // console.log(xhr.status);

    // if(xhr.status == 404 || String(xhr.status) == "404"){
    //     console.log("if"+xhr.status);
    //     swal("File Not Found !!", "File is not on server !!", "warning");
    //     this.tps.checkpdf(OBJ).subscribe(
    //         data => this.afterCheckPdf(data)
    //     )
    // }
    // else{
    //    this.pdfview(location); 
    // }

    this.tps.checkpdf(OBJ).subscribe(
      data => this.afterCheckPdf(data)
    )
  }

  afterCheckPdf(data) {
    //console.log(data)
    this.pdfview(this.location);
  }

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',

      preConfirm: () => {
        document.getElementById("download").style.display = 'none';
        return document.getElementById("viewfile");
      }
    })
  }

  Multiselect(index, ev) {
    if (this.cashmemolist[index].selected == 'true') {
      this.cashmemolist[index].selected = 'false';
    }
    else {
      this.cashmemolist[index].selected = 'true';
    }
  }

  Removechecked_() {
    const com = ",";
    this.deletelist = "";
    var records = 0;
    for (var i = 0; i < this.cashmemolist.length; i++) {
      if (this.cashmemolist[i].selected == 'true') {
        this.multipleselect.push(i);
        this.deletelist += this.cashmemolist[i].id + com;
        records++;
      }
    }
    if (this.deletelist == "" || this.deletelist == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {

          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts = this.multipleselect.length;
          for (var i = this.multipleselect.length - 1; i >= 0; i--) {
            this.cashmemolist.splice(this.multipleselect[i], 1);
            this.cashmemolist.sort();
          }

          this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
          this.deleteBill();
          this.multipleselect = [];
        }
      })
    }
  }

  deleteBill() {
    const dcobject = {
      selected: this.deletelist
    }
    this.tps.deleteBill(dcobject).subscribe(
      data => this.callafterdel(data), error1 => console.log(error1));
  }

  callafterdel(data) {
    this.deletestr = data;
    this.buildbillno(this.deletestr);
    if (this.deletednos == "" || this.deletednos == "()") {

      swal('success', "Bill Deleted Successfully", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "Can not delete these Bills " + this.deletednos + " already used".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.deletednos = "";
    this.getCashMemolist();
  }

  buildbillno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletednos = this.deletednos + data[i].id + ",";
      }
    }
  }



  prev() {
    swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
    if (this.pageid > 1) {
        this.pageid = this.pageid - 1;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    }
    else {
        this.pageid = 1;
        (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
    }
   /* const obj = {
        branch: this.branch, 
        page_id: this.pageid 
    }
    this.tps.getCashMemolistPage(obj).subscribe(
        data => this.gettabledata(data)
    );*/
  

    if (this.bill_search == "" && this.customer_search == "") {
      const obj = {
        branch: this.branch,
        page_id: this.pageid
    }
    this.tps.getCashMemolistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
        branch: this.branch,
        customer_id: this.customer_search,
        page_id: this.pageid
      }
      console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
    //  this.tps.BillSearch(obj).subscribe(
        this.tps.CashMemoSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
}

next() {
    swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
    (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
   
    if (this.pageid == this.last) {
        (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
        (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
    }
    else {
        this.pageid = this.pageid + 1;
       }
  /*  const obj = {
        branch: this.branch,
        page_id: this.pageid
    }
    this.tps.getCashMemolistPage(obj).subscribe(
        data => this.gettabledata(data)
    ); */

    if (this.bill_search == "" && this.customer_search == "") {
      const obj = {
        branch: this.branch,
        page_id: this.pageid
    }
    this.tps.getCashMemolistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
        branch: this.branch,
        customer_id: this.customer_search,
        page_id: this.pageid
      }
      console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
     // this.tps.BillSearch(obj).subscribe(
      this.tps.CashMemoSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
}


input() {
  swal({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    width: 100,
    heightAuto: true,
    background: "rgba(0, 0, 0, .1)",
  });
  swal.showLoading();
  // console.log('totalcount:', this.totalcount);
  // console.log('pageid:', this.pageid);
  // console.log('totalcount:', this.last);
  if ((Number(this.totalcount) <= 20 && Number(this.pageid != 1)) || Number(this.pageid) > Number(this.last)) {
    swal('Alert!!!', 'Record Not Available', 'warning');
  }
  else {
    if (this.pageid <= 0 || this.pageid == null || this.pageid == 1) {
      this.pageid = 1;
      (<HTMLInputElement>document.getElementById("f_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("p_but")).disabled = true;
    }
    else {
      (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
      (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
    }
    if (this.pageid == this.last || this.pageid > this.last) {
      this.pageid = this.last;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
    }
    else {
      (<HTMLInputElement>document.getElementById("n_but")).disabled = false;
      (<HTMLInputElement>document.getElementById("l_but")).disabled = false;
    }
    if (this.bill_search == "" && this.customer_search == "") {
      const obj = {
        branch: this.branch,
        page_id: this.pageid
    }
    this.tps.getCashMemolistPage(obj).subscribe(
        data => this.gettabledata(data)
    );
    }
    else {
      const obj = {
        bill_no: this.bill_search,
        branch: this.branch,
        customer_id: this.customer_search,
        page_id: this.pageid
      }
      console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
     // this.tps.BillSearch(obj).subscribe(
      this.tps.CashMemoSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }
}


lastpage() {
  swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
  });
  swal.showLoading();
  (<HTMLInputElement>document.getElementById("f_but")).disabled = false;
  (<HTMLInputElement>document.getElementById("p_but")).disabled = false;
  this.pageid = this.last;
  if (this.pageid == this.last) {
      (<HTMLInputElement>document.getElementById("l_but")).disabled = true;
      (<HTMLInputElement>document.getElementById("n_but")).disabled = true;
  }
  /*const obj = {
      branch: this.branch,
      page_id: this.last
  }
  this.tps.getCashMemolistPage(obj).subscribe(
      data => this.gettabledata(data)
  );*/

  if (this.bill_search == "" && this.customer_search == "") {
    const obj = {
      branch: this.branch,
      page_id: this.pageid
  }
  this.tps.getCashMemolistPage(obj).subscribe(
      data => this.gettabledata(data)
  );
  }
  else {
    const obj = {
      bill_no: this.bill_search,
      branch: this.branch,
      customer_id: this.customer_search,
      page_id: this.pageid
    }
    console.log(obj);
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    //this.tps.BillSearch(obj).subscribe(
      this.tps.CashMemoSearch(obj).subscribe(
      data => this.gettabledata(data)
    );
  }
}





  cashmemosearch() {
    if (this.bill_search == "" && this.customer_search == "") {
      this.getCashMemolist();
      // swal('Alert', 'Please select search parameter first!!!', 'warning');
    }
    else {
      this.pageid = 1;
     
      const obj = {
        bill_no: this.bill_search,
        branch: this.branch,
        customer_id: this.customer_search,
        page_id: this.pageid
      } 
      console.log(obj);
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
    //  this.tps.BillSearch(obj).subscribe(
      this.tps.CashMemoSearch(obj).subscribe(
        data => this.gettabledata(data)
      );
    }
  }

}
