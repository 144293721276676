import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
import swal from 'sweetalert2';
import {TpserviceService} from '../../../../Services/tpservice.service';
import { Router } from '@angular/router';
import { customer } from '../../../../Models/customer';
import { employees } from '../../../../Models/emplyees';
import { user } from '../../../../Models/user';

@Component({
  selector: 'app-form-layouts',
  templateUrl: './userregistration.component.html',
  providers:[TpserviceService]
})
export class UserRegistration implements OnInit, AfterViewInit {

  status:any="0";
  branch:string="1";
  branchlist:any=[];
  employee:string="";
  username:string="";
  password:string="";
  confirmpassword:string="";
  employeelist:employees[];
  usertype:string="";
  USRBRC:any;
  datastate:string="Save";
  id:string="";
  user:user;

  constructor( private route:Router,private tps:TpserviceService) { }

  ngOnInit() {
  
   this.displaybranches();
   //this.branch=localStorage.getItem("branch");
   this.USRBRC=localStorage.getItem("USRBRSTATE");
   console.log("permissions"+this.USRBRC)
    this.getuserlist();

    if(localStorage.getItem("userdatastate")=="1")
    {

      this.datastate="Update"
      localStorage.removeItem("userdatastate");
      this.getuserdata();
    }
    else{
      this.branch=localStorage.getItem("branch");
    }
 
  }
  ngAfterViewInit(){
    
    var self=this;
    $('#form-sample-1').validate({
      rules: {
        branch: {
          required: !0
        },
        employee: {
          required: !0,         
        },

        fax: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },

        username: {
          required: !0,
          
        },

        password: {
          required: !0,
        },

        min: {
          required: !0,
          minlength: 3
        },

        IFSC: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },

        MICR: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },

        telephone: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },

        cpemail:{
          required: !0,
          email: !0
        },

        GST: {
          required: !0,
          minlength: 15,
          maxlength: 15
        },

        bcode: {
          required: !0,
          minlength: 2,
          maxlength: 2
        },

        max: {
          required: !0,
          maxlength: 4
        },
         
        password_confirmation: {
          required: !0,
          equalTo: self.password
        }
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },

      submitHandler: function(form) {
        self.onsubmit();
    }
});
    
$(document).ready(function(){
  $("#branch").select2({
    theme: "classic",
    allowClear: true
  });
});

$("#branch").on('select2:select', function(evt){
 var id = evt.params.data.id;
 self.branch=id;
 });

 $(document).ready(function(){
  $("#employee").select2({
    theme: "classic",
    allowClear: true
  });
});

$("#employee").on('select2:select', function(evt){
 var id = evt.params.data.id;
 self.employee=id;
 });
}
  
getuserlist()
{
  const obj={

    checkeddata:this.branch

  }
   this.tps.getEmployeeslist(obj).subscribe(
     data => this.employeelist=data
);
}

onsubmit(){
  if(!(this.datastate=="Update")){
 
    const regobj={
      username:this.username,
      password:this.password,
      employee_id:this.employee,
      status:this.status,
      branch:this.branch,
      usertype:this.usertype      
    }
    this.tps.registerUser(regobj).subscribe(
      data =>this.aftersubmit(data)      
  );
}
else{
    this.Update();
  }
}

  Update(){
    const regobj={
      id:this.id,
      username:this.username,
      password:this.password,
      employee_id:this.employee,
      status:this.status,
      branch:this.branch,
      usertype:this.usertype      
    }
    this.tps.UpdateUser(regobj).subscribe(
      data =>this.afterupdate(data));
    }
//   onchange(){

// this.getuserlist();


//   }
  
  aftersubmit(data)
  {  
  swal('Success',data['messasge'], 'success' ),error=> swal('Please check your internet connection',error, 'error' )
  this.route.navigate(['/User-List']);
  
  }
   afterupdate(data)
    {
    swal('Success',data['messasge'], 'success' )
    this.route.navigate(['/User-List']);
  }

    displaybranches() {

      this.tps.getBranch().subscribe(
          data =>
              this.branchlist = data
      );
  
  }

  redirect(){
    this.route.navigate(['/User-List']);
  }

  getuserdata(){
    this.datastate="Update";
    this.user=JSON.parse(localStorage.getItem('userdata'));
    localStorage.removeItem('userdata');
    this.branch=this.user.branch;
    this.usertype=this.user.usertype;
    this.id=this.user.id;
    this.employee=this.user.employee_id;
    this.username=this.user.username;
    this.password=this.user.password;
    this.confirmpassword=this.user.password;
  }

}
