import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../Services/tpservice.service';
import {Router} from '@angular/router';
import {irlist} from '../../Models/irlist';
import swal from "sweetalert2";
import {irmaster} from '../../Models/irmaster';
import {dcdata} from '../../Models/dcdata';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './inwardlist.component.html',
  providers:[TpserviceService]
})

export class InwardlistComponent implements OnInit, AfterViewInit {
  grant_edit:any;
  permissions:permissions;
irlist:irlist[]=[];
branch:string="";
selectedirlist:string="";
com:string=",";
openstr:string="(";
closestr:string=")";
irmaster:irmaster;
irtabledata:dcdata[]=[];
branchlist:any=[];
disablestate:any;
query:any;

deletelist:string="";
public multipleselect:Array<any>=[];
deletestr:deleteBo[]=[];
deletedirnos:string="";

  constructor(private tps: TpserviceService, private detectchages: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions=JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit=+this.permissions.TIRE;
    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.disablestate=localStorage.getItem("disablestate");
   
      this.getirlist();
  }
 
  ngAfterViewInit() {
    
    var self=this;
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     self.refreshdata();
     }); 
  }
  gettabledata(data)
  {

    $("#products-table").dataTable().fnDestroy();
this.irlist=data;
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }

  getirlist()
  {
    const obj={
      checkeddata:this.branch

    }
    this.tps.getIrtablelist(obj).subscribe(
      data => this.gettabledata(data)
  );
  
  
  }
  onselected(data,e,index)
  {
if(e.target.checked)
{
   var temp=this.selectedirlist.replace(data+this.com,"");
   //this.selectedc.splice(index,1);
//   var tmp1=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"")
  this.selectedirlist=temp;
//   this.selectedlr=tmp1;
   this.selectedirlist+=data+this.com;
   //this.selectedfmlist.push(data);
// this.selectedlr+=this.openstr+data+this.com+"@@"+this.closestr+this.com;
}
else{

   var temp1=this.selectedirlist.replace(data+this.com,"");
   //this.selectedc.splice(index,1);
  // var temp=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"");
   this.selectedirlist=temp1;
  // this.selectedlr=temp;
 

}
}

refreshdata(){
  localStorage.setItem("branch",this.branch);
    this.getirlist();
}

Multiselect(index,ev){
  if(this.irlist[index].selected=='true'){
    this.irlist[index].selected='false';
  }
  else{
    this.irlist[index].selected='true';
  }
}


// lrbillpdfpreview(data){
//   const ob={
//       lrid:data
//   }
//   console.log("pdfdatapreview"+this.pdfdata);
//   this.tps.getLrBillData(ob).subscribe(
//       data => this.callafterpdfdatapreview(data));
//   }
  
  pdfview(location){
      swal({
          //title: 'Download File',
           showCancelButton: true,
           width:'80%',
           showCloseButton:true,
           showConfirmButton:false,
           cancelButtonText:'Ok',
           html:'<embed id="viewfile" src="'+location+'" height="500px" width="100%"/>',
           preConfirm:()=>{
           return document.getElementById("viewfile");
           }
           })
  }

Removechecked_()
{
  // var strdata=this.openstr+this.selectedirlist.substring(0,this.selectedirlist.length-1)+this.closestr;
  // console.log(strdata);

  const com=",";
   this.deletelist="";
   var records=0;
        for(var i=0;i<this.irlist.length;i++)
        {
      if(this.irlist[i].selected=='true')
      {        
          this.multipleselect.push(i);
          this.deletelist+=this.irlist[i].id+com;
          records++;
      }
    }

  if(this.deletelist=="" || this.deletelist=="()")
    {
        swal({
            title: 'Nothing to delete!',
            text: "Please select at least one record!",
            type: 'warning',
        })
    }
    else{
    swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {

          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts=this.multipleselect.length;
          for (var i = this.multipleselect.length -1; i >= 0; i--)
          {
            this.irlist.splice(this.multipleselect[i],1);
            this.irlist.sort();
          }
          
          this.deletelist=this.deletelist.substring(0,this.deletelist.length-1);
          this.deleteIr();
          this.multipleselect=[];
        }
      })
    }
  }

deleteIr(){
  const dcobject={
    selected:this.deletelist

}
this.tps.deleteIr(dcobject).subscribe(
    data=>this.callafterdel(data), error1 => console.log(error1));
    this.selectedirlist="";
}

callafterdel(data){
  this.deletestr=data;
this.buildirno(this.deletestr); 
   if(this.deletedirnos=="" || this.deletedirnos=="()"){
       
       swal('success', "IR Deleted Successfully",'success' );
       $('#products-table').dataTable().fnClearTable();
   }
   else{        
       swal('warning',"Can not delete these Irs " +this.deletedirnos + " already used".toString(),'warning' );
   $('#products-table').dataTable().fnClearTable();
   }
   this.deletedirnos="";
   this.getirlist();
}

buildirno(data){
  for(var i=0;i<data.length;i++){
      if(data[i].status=="1")
      {
        this.deletedirnos=this.deletedirnos+data[i].id+",";
       
    }
  }
}

getirmaster(id)
{
  const obj={
    checkeddata:id

  }
  this.tps.getIrmaster(obj).subscribe(
    data => this.irmasterafterdata(data,id)
);
}

irmasterafterdata(irdata,id){

  this.getdcmasterdata(id,irdata)

}
dcmasterafterdata(dcdata){
this.irtabledata=dcdata;
console.log(this.irtabledata);
console.log(this.irmaster);
localStorage.setItem("irtabledata",JSON.stringify(this.irtabledata));
localStorage.setItem("irmasterdata",JSON.stringify(this.irmaster));
localStorage.setItem("irdatastate","1");
this.route.navigate(['Transactions/Inwards']);
}
getdcmasterdata(id,irdata)
{
  this.irmaster=irdata;
const obj={
  id:this.irmaster[0].dc_id
} 
this.tps.getDcData(obj).subscribe(
    data => this.dcmasterafterdata(data)
     
);

}


displaybranches() {

  this.tps.getBranch().subscribe(
      data =>
          this.branchlist = data
  );

}


}