import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { dclist } from '../../Models/dclist';
import { dcdata } from '../../Models/dcdata';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './dc-list.component.html',
  providers: [TpserviceService]
})

export class DclistComponent implements OnInit, AfterViewInit {

  deleteddcnos: string = "";
  branches: any = "";
  deletestr: deleteBo[] = [];
  permissions: permissions;
  branch: string = "1";
  dcdata: dcdata;
  public dclist: dclist[] = [];
  public checkbox_: boolean = false;
  deletelist: string;
  selectedcheck: string = "";
  openstr = "(";
  closedtr = ")";
  com = ",";
  public multipleselect: Array<any> = [];
  selectedc: Array<any> = [];
  grant_edit: any;
  dclisttabledata: {

  }
  disablestate: any;
  location: any;

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.TDCE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
    this.getdclist();
  }

  ngAfterViewInit() {
    var self = this;
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.getdclist();
    });
  }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.dclist = data;
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 10,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
      this.selectedc.push(data);
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }

  getdclist() {
    localStorage.setItem("branch", this.branch);
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getDclist(obj).subscribe(
      data => this.gettabledata(data)
    );
  }

  Multiselect(index, ev) {
    if (this.dclist[index].selected == 'true') {
      this.dclist[index].selected = 'false';
    }
    else {
      this.dclist[index].selected = 'true';
    }
  }

  Removechecked_() {
    const com = ",";
    this.deletelist = "";
    var records = 0;
    for (var i = 0; i < this.dclist.length; i++) {
      if (this.dclist[i].selected == 'true') {
        this.multipleselect.push(i);
        this.deletelist += this.dclist[i].id + com;
        records++;
      }
    }
    if (this.deletelist == "" || this.deletelist == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts = this.multipleselect.length;
          for (var i = this.multipleselect.length - 1; i >= 0; i--) {
            this.dclist.splice(this.multipleselect[i], 1);
            this.dclist.sort();
          }
          this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
          this.deleteDc();
          this.multipleselect = [];
        }
      })
    }
  }

  deleteDc() {
    const dcobject = {
      selected: this.deletelist
    }
    this.tps.deleteDc(dcobject).subscribe(
      data => this.callafterdel(data), error1 => console.log(error1));
  }

  callafterdel(data) {
    this.deletestr = data;
    this.builddcno(this.deletestr);
    if (this.deleteddcnos == "" || this.deleteddcnos == "()") {

      swal('success', "Dc Deleted Successfully", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "Can not delete these Dcs " + this.deleteddcnos + " already used".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.deleteddcnos = "";
    this.getdclist();
  }

  builddcno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deleteddcnos = this.deleteddcnos + data[i].id + ",";

      }
    }
  }


  checkdcupdate(obj) {
    this.tps.checkdcupdate(obj).subscribe(
      data => this.getdcmasterdata(obj, data)
    );
  }

  getdcmasterdata(obj, data1) {
    this.tps.getDcData(obj).subscribe(
      data => this.calldcafterdata(data, data1)
    );
  }

  calldcafterdata(data, data1) {
    if (data1.messasge == -1) {
      localStorage.setItem("data", JSON.stringify(data));
      localStorage.setItem("datastate", "1");
      this.route.navigate(['/Transactions/dc']);
    }
    else {
      swal({
        title: 'Can not edit this DC',
        text: "DC already used in " + data1.messasge + " Memo",
        type: 'warning',
      })

    }
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data =>
        this.branches = data
    );
  }

  checkPdf(location,id){
    this.location = "";
    this.location = location;
    //console.log(location+','+id);
    const OBJ = {
        checkeddata:id,
        checkeddata1:"dcs"
    }
    // var xhr = new XMLHttpRequest();
    // xhr.open('HEAD',location,false);
    // xhr.send();
    // console.log(xhr.status);

    // if(xhr.status == 404 || String(xhr.status) == "404"){
    //     console.log("if"+xhr.status);
    //   //  swal("File Not Found !!", "File is not on server !!", "warning");
    //     this.tps.checkpdf(OBJ).subscribe(
    //         data => this.afterCheckPdf(data)
    //     );
    // }
    // else{
    //    this.pdfview(location); 
    // }
    this.tps.checkpdf(OBJ).subscribe(
      data => this.afterCheckPdf(data)
  );
}

afterCheckPdf(data){
    //console.log(data)
    this.pdfview(this.location);
}

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',
      preConfirm: () => {
        return document.getElementById("viewfile");
      }
    })
  }

}