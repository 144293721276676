import { Component, NgModule, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
import { CommonModule } from '@angular/common';
import { TpserviceService } from '../../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { petrolpumps } from '../../../Models/Petropump';

NgModule({
  declarations: [

  ],
  imports: [
    CommonModule
  ],
  providers: [],
  bootstrap: []
});
@Component({
  selector: 'app-form-layouts',
  templateUrl: './petrolpump.component.html',
  providers: [TpserviceService]
})
export class PetrolpumpComponent implements OnInit, AfterViewInit {

  id: string = "";
  pumpname: string = "";
  ownername: string = "";
  address: string = "";
  contactno: string = "";
  emailid: string = "";
  datastate: string = "Save";
  pumpdata: petrolpumps;

  constructor(private tps: TpserviceService, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem("pumpdatastate") == "1") {
      this.datastate = "Update";
      localStorage.removeItem("pumpdatastate");
      this.displaypumpdata();
    }
  }


  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        pumpname: {
          minlength: 2,
          required: !0
        },
        ownername: {
          required: !0,
        },
        contactno: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },
        emailid: {
          required: !0,
          email: !0
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onSubmit();
      }
    });
  }

  onSubmit() {
    if (!(this.datastate == "Update")) {
      const obj = {
        petrolpumpname: this.pumpname,
        ownername: this.ownername,
        address: this.address,
        contact_number: this.contactno,
        emailid: this.emailid,
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.addPump(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updatepump();
    }
  }

  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Petrolpump-List']);
  }

  displaypumpdata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.pumpdata = JSON.parse(localStorage.getItem("pumpdata"));
    localStorage.removeItem("pumpdata");
    this.id = this.pumpdata.pp_id;
    this.pumpname = this.pumpdata.petrolpumpname;
    this.ownername = this.pumpdata.ownername;
    this.address = this.pumpdata.address;
    this.contactno = this.pumpdata.contact_number;
    this.emailid = this.pumpdata.emailid;
    swal.close();
  }

  updatepump() {
    const obj = {
      pp_id: this.id,
      petrolpumpname: this.pumpname,
      ownername: this.ownername,
      address: this.address,
      contact_number: this.contactno,
      emailid: this.emailid,
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updatePetrolPump(obj).subscribe(
      data => this.afterupdate(data)
    );
  }

  afterupdate(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.router.navigate(['pages/Petrolpump-List']);
  }

}
