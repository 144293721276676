import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-trans-history',
  templateUrl: './bank-trans-history.component.html',
  styleUrls: ['./bank-trans-history.component.css']
})
export class BankTransHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
