import { Component, OnInit, AfterViewInit , Pipe, PipeTransform} from '@angular/core';
import {TpserviceService} from './../../Services/tpservice.service';
import { DatePipe } from '@angular/common';
import { customer } from './../../Models/customer';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { lrmaster } from './../../Models/lrmaster';
import { dctabledata } from './../../Models/dctabledata';
import { user } from '../../Models/user';
import { rbdata } from '../../Models/rbdata';

declare var $: any;

@Component({
  selector: 'app-regular-bill',
  templateUrl: './regular-bill.component.html',
  styleUrls: ['./regular-bill.component.css'],
  providers:[TpserviceService,DatePipe]
})
export class RegularBillComponent implements OnInit {

  sendcallbacklr:string="";
  openstr:string="(";
  closestr:string=")";
  com:string=",";
  cust:customer[];
  cosignerob:customer[];
  consigneeob:customer[];
  dctabledata:dctabledata[]=[];
  branches:any="";
  branch:string="1";
  taxes:any;
  load909chk:any;
  load407chk:any;
  ftload:any;
  lcvload:any;
  load1199chk:any;
  mathadichk:any;
  loadchar:any;
  unloadchar:any;
  billname:any;
  userid:string="";
  username:string="";
  branchcode:string="";
  billtype:string="";
  consinorid:string="";
  consineeid:string="";
  deleverytrip:string="";
  collectiontrip:string="";
  thirdpartyid:any="0";
  billno:String="";
  billdate:string="";
  rate:any=0;
  deliverycharges:any=0;
  collectioncharges:any=0;
  pono:string="";
  taxtype:string="";
  igst:any=0;
  igstamt:any=0;
  sgst:any=0;
  sgstamt:any=0;
  cgst:any=0;
  cgstamt:any=0;
  load909:any=0;
  load407:any=0;
  loadlcv:any=0;
  delieverycharges:any=0;
  lrfreightchr:any=0;
  loadft:any=0;
  load1109:any=0;
  mathadi:any=0;
  loadingcharges:any=0;
  unloadingcharges:any=0;
  totalinvweight:any;
  lrcharges:any=0;
  nooflr:any=0;
  totalinvwt:any=0;
  disablestate:any;
  subtotal:any=0;
  total:any;
  ratekg:any=0;
  selectedstr:string="";
  selectedlr:string="";
  datastate:any="Save";
  lrdata:lrmaster[]=[];
  userdata:user;
  rbdata:rbdata;
  id:string;

  public rowdata: Array<{
    inv_no:string,
    inv_date:string;
    description:string;
    articles:string;
    no_of_articles:string;
    quantity:string;
    inv_amt:string;
    lr_no:string;
    lr_date:string;
    consigner:string;
    from_loc:string;
    consignee:string;
    to_loc:string;
    actual_wt:string;
    lr_master_id:string;
  }> = [];

  constructor(private tps:TpserviceService, private datepipe:DatePipe, private route:Router) { }

  ngOnInit() {
    this.displaybranches();
        this.branchcode=localStorage.getItem("branch");
        this.userdata=JSON.parse(localStorage.getItem("userdata"));
        this.userid=this.userdata.id;
        this.username=this.userdata.username;
    if(localStorage.getItem("datastate")=="1")
        {
          this.datastate="Update";          
          localStorage.removeItem("datastate");
          this.displaytrdata();
          //this.displaylr();
          this.displaylr1();
        }
        
  }

  ngAfterViewInit(){
    var self=this;
    this.billdate=this.datepipe.transform(new Date(),"yyyy-MM-dd")

    $('#form-sample-1').validate({
      rules:{
        billno:{
          required: !0
        },
        billdate:{
          required: !0
        },
        billtype:{
          required: !0
        },
        consinorid:{
          required: !0
        },
        consineeid:{
          required: !0
        },
        rate:{
          required: !0
        },
        deleverytrip:{
          required: !0
        },
        deliverycharges:{
          required: !0
        },
        collectiontrip:{
          required: !0
        },
        collectioncharges:{
          required: !0
        }
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function(form) {
        self.submitrbmaster();
    }
    });

    $(document).on('focus', '.select2',function(e){
      if (e.originalEvent) {
        $(this).siblings('select').select2('open');    
      }
    });
    
    $(document).ready(function(){
      $("#branchcode").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branchcode").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branchcode=id;
     });

    $(document).ready(function(){
      $("#consinorid").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#consinorid").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.consinorid=id;
     self.displaylr();
     });

     $(document).ready(function(){
      $("#consineeid").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#consineeid").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.consineeid=id;
     self.displaylr();
     });

  }
  
  submitrbmaster(){  
    if(this.selectedstr=="()" || this.selectedstr=="")
  {
   swal('warning','Please choose atleast one LR', 'warning' );
  }
  else{
if(!(this.datastate=="Update"))
{
    var  datatb=this.openstr+this.selectedstr.substr(0,this.selectedstr.length-1)+this.closestr;
    var lrdatatb=this.selectedlr.substr(0,this.selectedlr.length-1);
    console.log("data is_"+datatb);
    console.log("lrdata is"+lrdatatb);
    const dcobj={
  
    branchcode:this.branchcode,
    userid:this.userid,
    billno:this.billno,
    billdate:this.billdate, 
    billtype:this.billtype,
    consinorid:this.consinorid,
    consineeid:this.consineeid,
    rate:this.rate,
    deleverytrip:this.deleverytrip,
    deliverycharges:this.deliverycharges,
    collectiontrip:this.collectiontrip,   
    collectioncharges:this.collectioncharges,
    thirdpartyid:this.thirdpartyid,   
    pono:this.pono,
    taxtype:this.taxtype,
    igst:this.igst,
    igstamt:this.igstamt,
    cgst:this.cgst,
    cgstamt:this.cgstamt,
    sgst:this.sgst,
    sgstamt:this.sgstamt,    
    load909:this.load909,
    load407:this.load407,
    loadlcv:this.loadlcv, 
    loadft:this.loadft,
    load1109:this.load1109,
    mathadi:this.mathadi,
    loadingcharges:this.loadingcharges,
    unloadingcharges:this.unloadingcharges,
    totalinvweight:this.totalinvwt,
    lrcharges:this.lrcharges,
    nooflr:this.nooflr,
    dcdata:lrdatatb,
    lrdata:datatb,
    branch:this.branch
  }
  this.tps.addRB(dcobj).subscribe(
    data =>this.aftersubmit(data));

}
else{
this.updaterbmaster();
}

  }

  }

  updaterbmaster(){

    if(this.selectedstr=="()")
    {
      swal('warning', 'warning' ),error=> swal('Please choose atleast one LR',error, 'warning' )
    }
    else{
      var  datatb=this.openstr+this.selectedstr.substr(0,this.selectedstr.length-1)+this.closestr;
    var lrdatatb=this.selectedlr.substr(0,this.selectedlr.length-1);
    console.log("data is_"+datatb);
    console.log("lrdata is"+lrdatatb);
    const obj={
  
    branchcode:this.branchcode,
    userid:this.userid,
    billno:this.billno,
    billdate:this.billdate, 
    billtype:this.billtype,
    consinorid:this.consinorid,
    consineeid:this.consineeid,
    rate:this.rate,
    deleverytrip:this.deleverytrip,
    deliverycharges:this.deliverycharges,
    collectiontrip:this.collectiontrip,   
    collectioncharges:this.collectioncharges,
    thirdpartyid:this.thirdpartyid,   
    pono:this.pono,
    taxtype:this.taxtype,
    igst:this.igst,
    igstamt:this.igstamt,
    cgst:this.cgst,
    cgstamt:this.cgstamt,
    sgst:this.sgst,
    sgstamt:this.sgstamt,    
    load909:this.load909,
    load407:this.load407,
    loadlcv:this.loadlcv, 
    loadft:this.loadft,
    load1109:this.load1109,
    mathadi:this.mathadi,
    loadingcharges:this.loadingcharges,
    unloadingcharges:this.unloadingcharges,
    totalinvweight:this.totalinvwt,
    lrcharges:this.lrcharges,
    nooflr:this.nooflr,
    dcdata:lrdatatb,
    lrdata:datatb,
    branch:this.branch
    }
  
   this.tps.updateRB(obj).subscribe(
    data =>this.afterupdate(data));
    }
  }
  
  afterupdate(data)
  {
  swal('Success',data['messasge'], 'success' ),error=>console.log(error)
  this.route.navigate(['pages/rb']);
  
  }
  
  calnooflr(){
    var str=this.selectedstr.split(',');
    this.nooflr=str.length-1;
  }

  changed() {
    this.total = 0;
    this.subtotal = this.total + this.load909 + this.load407 + this.loadlcv + this.loadft + this.load1109 + this.mathadi + this.loadingcharges + this.unloadingcharges;
    this.total = this.subtotal + this.igst + this.cgst + this.sgst;
}

calsubtotal(){
  if(this.load909=="" && this.load407=="" && this.loadlcv=="" && this.loadft=="" && this.load1109==""){

    this.subtotal=(this.rate * this.totalinvwt) + this.deliverycharges + this.collectioncharges + (this.nooflr * this.lrcharges) + this.mathadi + this.loadingcharges + this.unloadingcharges;
  
  }
  
  else{
    if(this.load909==""){
      this.load909=0;
    }
    if(this.load407==""){
      this.load407=0;
    }
    if(this.loadlcv==""){
      this.loadlcv=0;
    }
    if(this.loadft==""){
      this.loadft=0;
    }
    if(this.load1109==""){
      this.load1109=0;
    }
    this.subtotal=this.load909 + this.load407 + this.loadlcv + this.loadft + this.load1109 + this.deliverycharges + this.collectioncharges + (this.nooflr * this.lrcharges) + this.mathadi + this.loadingcharges + this.unloadingcharges;
  }
}

 calcgst() {
     this.calnooflr();
     this.calsubtotal();
     const temp = +this.subtotal;
     this.sgstamt = 0;
     this.igstamt = 0;
     this.cgstamt = 0;
     this.sgstamt = this.sgst * temp / 100;
     this.igstamt = this.igst * temp / 100;
     this.cgstamt = this.cgst * temp / 100;
 }


 aftersubmit(data)
 {
  swal('Success',data['messasge'], 'success' ),error=> swal('Please check your internet connection',error, 'error' )
  this.route.navigate(['pages/rb']);

 }


 checkclick(data,e)
  {
if(e.target.checked)
{
  var temp=this.selectedstr.replace(data+this.com,"");
  var tmp1=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"")
  this.selectedstr=temp;
  this.selectedlr=tmp1;
  this.selectedstr+=data+this.com;
  this.selectedlr+=this.openstr+data+this.com+"@@"+this.closestr+this.com;
}
else{

  var temp1=this.selectedstr.replace(data+this.com,"");
  var temp=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"");
  this.selectedstr=temp1;
  this.selectedlr=temp;
}
  }

  insertabledata()
  {
let  datatb=this.openstr+this.selectedstr.substr(0,this.selectedstr.length-1)+this.closestr;
// console.log("data is_"+datatb);
const obj={

  checkeddata:datatb

}
  this.tps.getDctabledata(obj).subscribe(
      data =>
          this.afterdctabledata(data),
      error=>
    console.log(error)
  );      
  }

  afterdctabledata(data){
          var totalwt=0;
          var totalinvwt1=0
          this.dctabledata=data;    
          this.dctabledata.forEach(function(element){
            totalinvwt1= totalinvwt1+ +element.actual_wt;            
          totalwt=totalwt+ +element.actual_wt;
        })
        this.totalinvwt=totalinvwt1;
      }

  splitdata()
{
  let strdata:string="";
  let strdatalr:string="";
  this.rowdata.forEach(function(element){
  if(!strdata.includes(element.lr_master_id.toString()))
  {
  strdata+=element.lr_master_id.toString()+",";
  strdatalr+="("+element.lr_master_id.toString()+","+"@@),"
  }
})

console.log(strdata.toString());
console.log(this.rowdata);
this.selectedstr=strdata;
this.sendcallbacklr=strdata;
this.sendcallbacklr=this.openstr+strdata.substr(0,strdata.length-1)+this.closestr;
this.selectedlr=strdatalr;
}

checkdata(obj):boolean{
  if(this.rbdata.dctabledata.some(e => e.lr_master_id == obj))
  {
    return true;
  }
  else{
    return false;
  }
}

  displaybranches() {

        this.tps.getBranch().subscribe(
        data =>
        this.branches = data

    );
      this.displayCustomers();
  }

  onchangecosigner() {   

    let index=this.cust.findIndex(datas=>datas.cid == this.consinorid);
    this.displaylr();

}

onchangecosignee() {
  
    let index=this.cust.findIndex(datas=>datas.cid == this.consineeid);
    this.displaylr();

}

displayCustomers() {
  const obj={
      checkeddata:this.branch
  }
          this.tps.getCustomer(obj).subscribe(
          data =>
          this.cust = data
      );
  }

  displaylr() {
    const obj={
      consignee:this.consineeid,
      consigner:this.consinorid
    }
       this.tps.getLrmasterforrb(obj).subscribe(
        data => this.lrdata=data
    );
  }

  displaylr1() {
    const obj={
      billno:this.billno
    }
       this.tps.getLrmasterforrb1(obj).subscribe(
        data => this.lrdata=data
    );
  }

  displaytrdata()
{
  this.rbdata=JSON.parse(localStorage.getItem("data"));
  localStorage.removeItem("data");
  console.log(this.rbdata);
  // this.branchcode=this.rbdata.rbbo.branchcode;
  // this.userid= this.rbdata.rbbo.userid;
  this.id=this.rbdata.rbbo.id;
  this.billno=this.rbdata.rbbo.billno;
  this.billdate=this.rbdata.rbbo.billdate;
  this.billtype=this.rbdata.rbbo.billtype;
  this.consinorid=this.rbdata.rbbo.consinorid;
  this.consineeid=this.rbdata.rbbo.consineeid;
  this.rate=this.rbdata.rbbo.rate;
  this.deleverytrip=this.rbdata.rbbo.deleverytrip;
  this.deliverycharges=this.rbdata.rbbo.deliverycharges;
  this.collectiontrip=this.rbdata.rbbo.collectiontrip;
  this.collectioncharges=this.rbdata.rbbo.collectioncharges;
  this.thirdpartyid=this.rbdata.rbbo.thirdpartyid;
  this.pono=this.rbdata.rbbo.pono;
  this.taxtype=this.rbdata.rbbo.taxtype;
  this.igst=this.rbdata.rbbo.igst;
  this.igstamt=this.rbdata.rbbo.igstamt;
  this.cgst=this.rbdata.rbbo.cgst;
  this.cgstamt=this.rbdata.rbbo.cgstamt;
  this.sgst=this.rbdata.rbbo.sgst;
  this.sgstamt=this.rbdata.rbbo.sgstamt;
  this.load909=this.rbdata.rbbo.load909;
  this.load407=this.rbdata.rbbo.load407;
  this.loadlcv=this.rbdata.rbbo.loadlcv;
  this.loadft=this.rbdata.rbbo.loadft;
  this.load1109=this.rbdata.rbbo.load1109;
  this.mathadi=this.rbdata.rbbo.mathadi;
  this.loadingcharges=this.rbdata.rbbo.loadingcharges;
  this.unloadingcharges=this.rbdata.rbbo.unloadingcharges;
  this.totalinvwt=this.rbdata.rbbo.totalinvweight;
  this.lrcharges=this.rbdata.rbbo.lrcharges;
  this.nooflr=this.rbdata.rbbo.nooflr;
  this.dctabledata=this.rbdata.dctabledata;
  this.rowdata=this.rbdata.dctabledata;
  this.branch=this.rbdata.rbbo.branchcode;
  this.splitdata();
}

  goto()
  {
    this.route.navigate(["/index"]);
  }

}
