import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-line',
  templateUrl: './tabs-line.component.html',
})
export class TabsLineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
