import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lr-freight-challan',
  templateUrl: './lr-freight-challan.component.html',
  styleUrls: ['./lr-freight-challan.component.css']
})
export class LRFreightChallanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
