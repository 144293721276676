///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Route, Router } from '@angular/router';
import swal from 'sweetalert2';
import { employees } from '../../../Models/emplyees';
declare var $: any;

@Component({
  selector: 'app-form-layouts',
  templateUrl: './employee.component.html',
  providers: [TpserviceService]
})

export class EmployeesComponent implements OnInit, AfterViewInit {

  employeeobject: employees;
  employeename: string = "";
  address: string = "";
  peraddress: string = "";
  dob: string = "";
  mobileno: string = "";
  email: string = "";
  joiningdate: string = "";
  qualification: string = "";
  bloodgroup: string = "";
  designation: string = "";
  branch = "1";
  datastate: string = "Save";
  branchlist: any = [];
  disablestate: any;
  constructor(private tps: TpserviceService, private route: Router) { }
  ngOnInit() {
    if (localStorage.getItem("employeedatastate") == "1") {
      this.getemployeedata();
      localStorage.removeItem("employeedatastate");
    }
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
  }


  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        name: {
          minlength: 5,
          maxlength: 25,
          required: !0
        },
        employeename: {
          required: !0,
        },
        email: {
          required: !0,
          email: !0
        },
        designation: {
          required: !0,
        },
        mobileno: {
          required: !0,
          number: !0,
          minlength: 10,
          maxlength: 10
        },
        min: {
          required: !0,
          minlength: 3
        },
        address: {
          required: !0,
        },
        joiningdate: {
          required: !0
        }
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
  }

  onsubmit() {

    if (this.datastate == "Save") {
      const obj = {
        branch: this.branch,
        employeename: this.employeename,
        correspaddress: this.address,
        dateofbirth: this.dob,
        emailid: this.email,
        joiningdate: this.joiningdate,
        permanataddress: this.peraddress,
        qualification: this.qualification,
        mobileno: this.mobileno,
        bloodgroup: this.bloodgroup,
        designation: this.designation
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.addEmployee(obj).subscribe(
        data => this.aftersubmit(data),
        error => swal('Please check your internet connection', error, 'error')
      )
    }
    else {
      this.updateEmployee();
    }
  }

  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success');
    this.route.navigate(['pages/Employee-List']);
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getBranch().subscribe(
      data =>
        this.branchlist = data
    );
    swal.close();
  }

  afterupdate(data) {
    swal.close();
    swal('Success', data['messasge'], 'success');
    this.route.navigate(['pages/Employee-List']);
  }



  updateEmployee() {
    const obj = {
      branch: this.branch,
      employeename: this.employeename,
      correspaddress: this.address,
      dateofbirth: this.dob,
      emailid: this.email,
      joiningdate: this.joiningdate,
      permanataddress: this.peraddress,
      qualification: this.qualification,
      mobileno: this.mobileno,
      bloodgroup: this.bloodgroup,
      designation: this.designation,
      empid: this.employeeobject[0].empid
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updateEmployee(obj).subscribe(
      data => this.afterupdate(data),
      error => swal('Please check your internet connection', error, 'error')
    )
  }

  getemployeedata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.employeeobject = JSON.parse(localStorage.getItem("employeedata"));
    localStorage.removeItem("employeedata");
    this.employeename = this.employeeobject[0].employeename;
    this.address = this.employeeobject[0].correspaddress;
    this.dob = this.employeeobject[0].dateofbirth;
    this.email = this.employeeobject[0].emailid;
    this.joiningdate = this.employeeobject[0].joiningdate;
    this.peraddress = this.employeeobject[0].permanataddress;
    this.qualification = this.employeeobject[0].qualification;
    this.mobileno = this.employeeobject[0].mobileno;
    this.bloodgroup = this.employeeobject[0].bloodgroup;
    this.designation = this.employeeobject[0].designation;
    swal.close();
  }

  redirect() {
    this.route.navigate(['pages/Employee-List']);
  }
}
