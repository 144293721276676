import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import {fmlist} from '../../Models/fmlist';
import {TpserviceService} from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './fm-list.component.html',
  providers:[TpserviceService]
})
export class FmListComponent implements OnInit, AfterViewInit {

  fmlist:fmlist[];
  selectedfmlist:string="";
  openstr:string="(";
  closestr:string=")";
  com:string=",";

  constructor(private tps:TpserviceService, private detectchages:ChangeDetectorRef,private route:Router) { }

  ngOnInit() {
    this.getfmlist();
  }


  ngAfterViewInit() { }
  
    gettabledata(data)
    {
  this.fmlist=data;
      this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        responsive: true,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }


  getfmlist()
  {
    const obj={
      checkeddata:"1"

    }
    this.tps.getFmtablelist(obj).subscribe(
      data => this.gettabledata(data)
  );
  
  
  }
  
  onselected(data,e,index)
  {
if(e.target.checked)
{
   var temp=this.selectedfmlist.replace(data+this.com,"");
   //this.selectedc.splice(index,1);
//   var tmp1=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"")
  this.selectedfmlist=temp;
//   this.selectedlr=tmp1;
   this.selectedfmlist+=data+this.com;
   //this.selectedfmlist.push(data);
// this.selectedlr+=this.openstr+data+this.com+"@@"+this.closestr+this.com;
}
else{
   var temp1=this.selectedfmlist.replace(data+this.com,"");
   //this.selectedc.splice(index,1);
  // var temp=this.selectedlr.replace(this.openstr+data+this.com+"@@"+this.closestr+this.com,"");
   this.selectedfmlist=temp1;
  // this.selectedlr=temp;
}
}

  deletefm()
{
  var strdata=this.openstr+this.selectedfmlist.substring(0,this.selectedfmlist.length-1)+this.closestr;
  console.log(strdata);

  if(strdata=="" || strdata=="()")
    {
        swal({
            title: 'Nothing to delete!',
            text: "Please select at least one record!",
            type: 'warning',
        })
    }

    else{
    swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
         const dcobject={
    selected:strdata
}
this.tps.deleteFm(dcobject).subscribe(
    data=>this.callafterdel(data), error1 => console.log(error1));
 }        
})
}
}

callafterdel(data){
  swal('Success', data['messasge'].toString(),'success' );
  this.fmlist=[];
  const obj={
    checkeddata:"1"
  }
  this.tps.getFmtablelist(obj).subscribe(
    data1 => this.fmlist=data1
  );
}

getfmmasterdata(obj)
{
const idobj={
  checkeddata:obj
  };
  this.tps.getFmData(idobj).subscribe(
    data => this.calldcafterdata(data)     
);
}

calldcafterdata(data){
  localStorage.setItem("fmmasterdata",JSON.stringify(data));
  localStorage.setItem("fmmasterdatastate","1");
  this.route.navigate(['/Transactions/fm']);
}

}
