import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { email } from '../../../Models/email';
import { TpserviceService } from '../../../Services/tpservice.service';
declare var $: any;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
  providers: [TpserviceService]
})
export class EmailComponent implements OnInit, AfterViewInit {
  email: string = "";
  id: string = "";
  emailobj: email;
  datastate: string = "Save";
  constructor(private route: Router, private tps: TpserviceService) { }

  ngOnInit() {

    if (localStorage.getItem("emaildatastate") == "1") {
      this.getemaildata();
      localStorage.removeItem("emaildatastate");
    }

  }
  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        email: {
          minlength: 2,
          required: !0
        },
       
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

   }

   onsubmit() {
   console.log("onsubmit");
    if (!(this.datastate == "Update")) {
      const obj = {
       
        email: this.email,
      
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
     this.tps.addEmailId(obj).subscribe(
      data => this.aftersubmit(data)
      );
    }
    else {
      this.updateEmail();
    }

  }

  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/customer-email-list']);
  }

  updateEmail() {
    const obj = {
      id: this.id,
      email: this.email,
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updateEmailId(obj).subscribe(
      data => this.afterupdate(data),
      error => swal('Please check your internet connection', error, 'error')
      );
  }

  afterupdate(data) {
    swal.close();
    swal('Success', data['messasge'], 'success')
    this.route.navigate(['pages/customer-email-list']);
  }

  redirect() {
    this.route.navigate(['pages/customer-email-list']);
  }


  getemaildata() {
    console.log("getemaildata");
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.emailobj = JSON.parse(localStorage.getItem("emaildata"));
   // this.emailobj=localStorage.getItem("emaildata");
    console.log("emailobj..."+localStorage.getItem("emaildata"));
    localStorage.removeItem('emaildata');
  this.id=this.emailobj.id;
  this.email = this.emailobj.email;
   //console.log("this.email.."+this.email);
    localStorage.removeItem("emaildata");
    swal.close();
  }


}
