import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
import { tyresupplier } from '../../Models/tyresupplier';
declare var $: any;

@Component({
  selector: 'app-tyre-supplier-list',
  templateUrl: './tyre-supplier-list.component.html',
  styleUrls: ['./tyre-supplier-list.component.css'],
  providers:[TpserviceService]
})
export class TyreSupplierListComponent implements OnInit {

  supplierlist:tyresupplier;
  selectedcheck: string = "";
  openstr = "(";
  closestr = ")";
  com = ",";
  disabledata: any;
  grant_edit: any;
  permissions: permissions;
  disablestate: any;
  selectedc: Array<any> = [];
  branchlist: any = [];
  query: any;
  deletedvehicle: string = "";
  deletestr: deleteBo[] = [];

  constructor(private tps:TpserviceService, private changedetect: ChangeDetectorRef, private route:Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MPLE;
    this.gettyresupplierlist();
  }

  gettyresupplierlist() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.tps.getTyreSupplier().subscribe(
      data => this.gettabledata(data)
    )
  }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.supplierlist = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  getsupplierdata(obj) {
    const ob = {
      sid: obj
    }
    this.tps.getTyreSupplierById(ob).subscribe(
      data => this.callafterdata(data)
    );
  }

  callafterdata(data) {
    localStorage.setItem("supplierdata", JSON.stringify(data));
    localStorage.setItem("supplierdatastate", "1");
    this.route.navigate(['forms/tyresupplier']);
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
      this.selectedc.push(data);
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }

  deletetyresupplier() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closestr;
    console.log(strdata);

    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const obj = {
            sid: strdata
          }
          this.tps.deleteTyreSupplier(obj).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {
    alert(JSON.stringify(data));
    swal('Success', data['messasge'], 'success');
    this.selectedcheck = "";
    $('#products-table').dataTable().fnClearTable();
    this.gettyresupplierlist();
  }

}
