import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Route, Router } from '@angular/router';
import swal from 'sweetalert2';
import { driverobject } from '../../../Models/driverobject';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './driver.component.html',
  providers: [TpserviceService,DatePipe]
})
export class DriverComponent implements OnInit, AfterViewInit {

  constructor(private route: Router, private tps: TpserviceService, private datepipe: DatePipe) { }
  datastate: string = "Save";
  id: string = "";
  name: string = "";
  address: string = ""
  peraddress: string = "";
  dob: string = "";
  telephone: string = "";
  fname: string = "";
  referenced: string = "";
  eyesight: string = "";
  licenseno: string = "";
  licensetype: string = "";
  remark: string = "";
  qualification: string = "";
  joiningdate: string = "";
  bloodgroup: string = "";
  renewdate: string = "";
  expirydate: string = "";
  branch: string = "";
  branchlist: any = [];
  driverobj: driverobject;
  disablestate: any;
  ngOnInit() {
    if (localStorage.getItem("driverstate") == "1") {
      this.getdriverdata();
      localStorage.removeItem("driverstate");
    }
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");

  }
  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        name: {
          minlength: 4,
          required: !0
        },
        address: {
          required: !0,
        },
        telephone: {
          required: !0,
          number: !0
        },
        expirydate: {
          required: !0,
        },
        licenseno: {
          required: !0,
        },
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

  }


  onsubmit() {

    if (this.datastate == "Save") {
      const obj = {
        branch: this.branch,
        drivername: this.name,
        correspaddress: this.address,
        dateofbirth: this.dob,
        telephoneno: this.telephone,
        fathername: this.fname,
        referenceby: this.referenced,
        eyesight: this.eyesight,
        licenseno: this.licenseno,
        licensetype: this.licensetype,
        remarks: this.remark,
        permanataddress: this.peraddress,
        qualification: this.qualification,
        mobileno: this.telephone,
        joiningdate: this.joiningdate,
        bloodgroup: this.bloodgroup,
        renewdate: this.renewdate,
        expiry: this.expirydate
      }
      this.tps.addDriver(obj).subscribe(
        data => this.aftersubmit(data),
        error => swal('Please check your internet connection', error, 'error')
      )
    }
    else {
      this.updateDriver();
    }
  }

  aftersubmit(data) {
    swal('Success', data['messasge'], 'success');
    this.route.navigate(['pages/Driver-List']);
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getBranch().subscribe(
      data =>
        this.branchlist = data
    );
    swal.close();
  }

  getdriverdata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.driverobj = JSON.parse(localStorage.getItem("driverdata"));
    localStorage.getItem('driverdata');
    this.id = this.driverobj[0].did;
    this.name = this.driverobj[0].drivername;
    this.address = this.driverobj[0].correspaddress;
    this.dob = this.driverobj[0].dateofbirth;
    this.telephone = this.driverobj[0].telephoneno;
    this.fname = this.driverobj[0].fathername;
    this.referenced = this.driverobj[0].referenceby;
    this.eyesight = this.driverobj[0].eyesight;
    this.licenseno = this.driverobj[0].licenseno;
    this.licensetype = this.driverobj[0].licensetype;
    this.remark = this.driverobj[0].remarks;
    this.peraddress = this.driverobj[0].permanataddress;
    this.qualification = this.driverobj[0].qualification;
    this.joiningdate = this.driverobj[0].joiningdate;
    this.bloodgroup = this.driverobj[0].bloodgroup;
    this.renewdate = this.driverobj[0].renewdate;
    this.expirydate = this.driverobj[0].expiry;
    this.branch = this.driverobj[0].branch;
    this.dob = this.datepipe.transform(this.driverobj[0].dateofbirth, "yyyy-MM-dd");
    swal.close();

  }

  updateDriver() {

    const obj = {
      did: this.id,
      branch: "",
      drivername: this.name,
      correspaddress: this.address,
      dateofbirth: this.dob,
      telephoneno: this.telephone,
      fathername: this.fname,
      referenceby: this.referenced,
      eyesight: this.eyesight,
      licenseno: this.licenseno,
      licensetype: this.licensetype,
      remarks: this.remark,
      permanataddress: this.peraddress,
      qualification: this.qualification,
      mobileno: this.telephone,
      joiningdate: this.joiningdate,
      bloodgroup: this.bloodgroup,
      renewdate: this.renewdate,
      expiry: this.expirydate
    }
    this.tps.updateDriver(obj).subscribe(
      data => this.afterupdate(data),
      error => swal('Please check your internet connection', error, 'error')
    )
  }

  afterupdate(data) {
    swal('Success', data['messasge'], 'success')
    this.route.navigate(['pages/Driver-List']);
  }


  redirect() {
    this.route.navigate(['pages/Driver-List']);

  }
}
