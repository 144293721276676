import { Component, OnInit, AfterViewInit , Pipe, PipeTransform} from '@angular/core';
import {TpserviceService} from '../../../Services/tpservice.service';
import {Router} from '@angular/router';
import {dcdata} from '../../../Models/dcdata';
import {fmtransactions} from '../../../Models/fmtransactions';
import {dctabledata} from '../../../Models/dctabledata';
import {irmaster} from '../../../Models/irmaster';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { user } from '../../../Models/user';
import { Branch } from '../../../Models/Branch';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './Inward.component.html',
  providers:[TpserviceService,DatePipe]
})

@Pipe({ name: 'search' })
export class InwardComponent implements OnInit, AfterViewInit, PipeTransform {
    lrs: any[] = [];
    inwards: any[] = [];
dcdata:dcdata;
dclist:fmtransactions[];
getdate:any;
irtabledata:dctabledata[]=[];
branches:Branch[]=[];
placedata:any=[];
branch:string="1";
irmaster:irmaster[]=[];
dcno:string="";
irdate:string="";
vehicleno:string="";
vehicleowner:string="";
address:string="";
fromloc:string="";
toloc:string="";
userdata:user;
supervisor:string="";
totalpackages:any="0";
totalweight:any="0";
datastate:string="Save";
disablestate:any;

    lrsearch= '';
  constructor(private tps:TpserviceService,private route:Router, private datepipe:DatePipe) {


  }


  ngOnInit() {
   
    this.displaybranches();
    this.branch=localStorage.getItem("branch");
    this.disablestate=localStorage.getItem("disablestate");
    this.userdata=JSON.parse(localStorage.getItem("userdata"));
    this.supervisor=this.userdata.username;

      if(localStorage.getItem("irdatastate")=="1")
      {
this.getupdatedata();
this.datastate="Update";


localStorage.removeItem("irdatastate");
      }
      else{
     
  
        //this.getdate=Date.now();
  }}
  ngAfterViewInit(){
    var self=this;
    this.irdate= this.datepipe.transform(new Date(),"yyyy-MM-dd");
    $('#form-sample-1').validate({
      rules: {
        dcno: {
        
          required: !0
        },
        irdate: {

          required: !0,
         
        },
        vehicleno: {
          required: !0,
          
        },
        vehicleowner: {
          required: !0,
          
        },
        fromloc: {
          required: !0,
           
        },
        toloc: {
          required: !0,
          
        },
        supervisor: {
          required: !0,
         
        },

        totalpackages: {
          required: !0,
          
        },
        totalweight: {
          required: !0,
         
        },
        GST: {
          required: !0,
          minlength: 15,
          maxlength: 15
        },

        bcode: {
          required: !0,
          minlength: 2,
          maxlength: 2
        },
        max: {
          required: !0,
          maxlength: 4
        },
        password: {
          required: !0
        },
        password_confirmation: {
          required: !0,
          equalTo: '#password'
        }
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function(form) {
        self.onsubmitir();
    }
    });

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     self.refreshdata();
     });

    $(document).ready(function(){
      $("#dcno").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#dcno").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.dcno=id;
     self.getdcmasterdata(evt);
     });
  }


    public transform(value, keys: string, term: string) {

        if (!term) return value;
        return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));

    }


    getdcmasterdata(obj)
    {
      
    const dcconst={
      id:obj.target.value
    } 
    console.log(dcconst);
          this.tps.getDcData(dcconst).subscribe(
        data => this.calldcafterdata(data)
         
    );
    
    }

    refreshdata(){
      localStorage.setItem("branch",this.branch);
     this.displaydc();

    }
    
    calldcafterdata(data){
    
      this.dcdata=data;
      this.irtabledata=this.dcdata.dcmastertabledataBO;
      this.irdate=this.dcdata.dcbo.dc_date;
      this.fromloc=this.dcdata.dcbo.from_loc;
      this.toloc=this.dcdata.dcbo.to_loc;
      this.address=this.dcdata.dcbo.owner_address;
      this.vehicleno=this.dcdata.dcbo.vehicle_number_desc;
      this.vehicleowner=this.dcdata.dcbo.vehicle_owner;
      this.totalpackages=this.dcdata.dcbo.total_packages;
      this.totalweight=this.dcdata.dcbo.total_weight;
      
      var totalwt=0;
      var totalpck=0;
      
      this.irtabledata.forEach(function(element){
        totalpck= totalpck+ +element.no_of_articles;
        totalwt=totalwt+ +element.actual_wt;
      })
      
  this.totalpackages=totalpck;
  this.totalweight=totalwt;
    
      console.log(this.dcdata);
    
    }

    displaydc() {
      var data;
      let index=this.branches.findIndex(datas=>datas.bid ==this.branch);
 
      if(this.datastate=="Save"){
     data=this.branches[index].placeid;
      const fmbr={

        checkeddata:data
      }
    
  this.tps.getDclistDataIR(fmbr).subscribe(
    data =>
        this.dclist = data
);
  }
  else{
    data=this.branches[index].placeid;
      const fmbr={

        checkeddata:data
      }
    
  this.tps.getDclistDataIRAll(fmbr).subscribe(
    data =>
        this.dclist = data
);
  }
  }


  displaybranches() {

    this.tps.getBranchlist().subscribe(
        data =>this.afterdatas(data)
           
    );

    this.tps.getPlace().subscribe(
      data =>
          this.placedata = data,error=> swal('Please check your internet connection',error, 'error' )
      );

     
  }
  afterdatas(data){

    this.branches = data;
    this.branch=localStorage.getItem("branch");
    this.displaydc();

  }
  onsubmitir()
  {
     if(this.datastate=="Update")
      {
this.updateIrmaster();

       }
     else{
let irobj={
	dc_id:this.dcno, 
	ir_date:this.irdate,
	vehicle_number:this.vehicleno,
	vehicle_owner:this.vehicleowner,
	owner_address:this.address,
	from_loc:this.fromloc,
	to_loc:this.toloc,
	supervisor:this.supervisor,
	total_packages:this.totalpackages,
	total_weight:this.totalweight,
	branch:this.branch

}
swal({
  allowOutsideClick:false,
  allowEscapeKey:false,
  allowEnterKey:false,
  width:150
});
swal.showLoading();
this.tps.addIR(irobj).subscribe(res =>this.aftersave(res)
, error1 => swal('Error',error1, 'error' )
);

}
}

aftersave(data){
  swal('Success',data['messasge'], 'success' );
  //this.route.navigate(['/pages/Inward']);
  (<HTMLInputElement> document.getElementById("submit")).disabled = true;
  (<HTMLInputElement> document.getElementById("print")).disabled = false;
} 

getupdatedata()
{
  this.dcdata=JSON.parse(localStorage.getItem("irtabledata"));
  localStorage.removeItem("irtabledata");
  this.irmaster=JSON.parse(localStorage.getItem("irmasterdata"));
  localStorage.removeItem("irmasterdata");
  localStorage.setItem("irdatastate","1");
  this.irtabledata=this.dcdata.dcmastertabledataBO;
  this.irdate=this.dcdata.dcbo.dc_date;
  this.fromloc=this.dcdata.dcbo.from_loc;
  this.toloc=this.dcdata.dcbo.to_loc;
  this.address=this.dcdata.dcbo.owner_address;
  this.vehicleno=this.dcdata.dcbo.vehicle_number_desc;
  this.vehicleowner=this.dcdata.dcbo.vehicle_owner;
  this.totalpackages=this.dcdata.dcbo.total_packages;
  this.totalweight=this.dcdata.dcbo.total_weight;
  this.branch=this.irmaster[0].branch;
  this.supervisor=this.irmaster[0].supervisor;
  this.dcno=this.irmaster[0].dc_id;
}




updateIrmaster(){

const irobj={
  ir_id:this.irmaster[0].ir_id,
	dc_id:this.dcno, 
	ir_date:this.irdate,
	vehicle_number:this.vehicleno,
	vehicle_owner:this.vehicleowner,
	owner_address:this.address,
  from_loc:this.fromloc,
	to_loc:this.toloc,
	supervisor:this.supervisor,
	total_packages:this.totalpackages,
	total_weight:this.totalweight,
	branch:this.branch
}
swal({
  allowOutsideClick:false,
  allowEscapeKey:false,
  allowEnterKey:false,
  width:150
});
swal.showLoading();
 this.tps.updateIR(irobj).subscribe(
  data =>this.afterupdate(data)
        
);  
}

afterupdate(data)
{
swal('Success',data['messasge'], 'success' ),error=> swal('Please check your internet connection',error, 'error' );
//this.route.navigate(['pages/Inward']);
(<HTMLInputElement> document.getElementById("submit")).disabled = true;
(<HTMLInputElement> document.getElementById("print")).disabled = false;

}

pdfredirect(){
  const ob={    
      id:this.dcno,
      type:'ir_master',

    }
  this.tps.viewirpdf(ob).subscribe(
      data =>this.afterpdf(data)
     ,error=> swal('Please check your internet connection',error, 'error')          
  );
  }

afterpdf(data){
if(this.dcno==""){

swal('warning','Please add LR first!', 'warning' )
}
else{

swal({
 //title: 'Download File',
  showCancelButton: true,
  width:'80%',
  showCloseButton:true,
  showConfirmButton:false,
  cancelButtonText:'Ok',
  html:'<embed id="viewfile" src="'+data['location']+'" height="500px" width="100%"/>',
  preConfirm:()=>{
  return document.getElementById("viewfile");
  }
  })
}
}

redirecttolist()
{
  this.route.navigate(['pages/Inward']);
}
}
