import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { drivers } from '../../Models/drivers';
import { Router } from '@angular/router';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './driver-list.component.html',
  providers: [TpserviceService]
})
export class DriverListComponent implements OnInit, AfterViewInit {
  permissions: permissions;
  grant_edit: any;
  disablestate: any;
  deletestr: deleteBo[] = [];
  deleteddriver: string = "";

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }
  selectedcheck: string = "";
  branches: any = [];
  branch: string = "1";
  driverlist: drivers[];
  com = ","; openstr = "("; closestr = ")";

  ngOnInit() {

    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MDVE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
    this.getdriverlist();
  }

  ngAfterViewInit() { }
  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.driverlist = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      "sDom": 'rtip',
    });
    var table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  displaybranches() {

    this.tps.getBranch().subscribe(
      data =>
        this.branches = data
    );
  }

  getdriverlist() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getDriverlist().subscribe(
      data =>
        this.gettabledata(data)
    );
  }


  getdriverobject(data) {
    const obj = {
      did: data
    }
    this.tps.getDriverObject(obj).subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    localStorage.setItem("driverdata", JSON.stringify(data));
    localStorage.setItem("driverstate", "1");
    this.route.navigate(['/forms/driver']);
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }

  deletedrivers() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closestr;

    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const obj = {
            did: strdata
          }
          this.tps.deleteDriver(obj).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {

    this.deletestr = data;
    this.builddriverno(this.deletestr);
    if (this.deleteddriver == "" || this.deleteddriver == "()") {
      swal('success', "Driver Deleted Successfully!", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "can not Driver this Customer " + this.deleteddriver + ", already used in DC Records..".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.selectedcheck = "";
    this.deleteddriver = '';
    this.getdriverlist();
  }

  builddriverno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deleteddriver = this.deleteddriver + data[i].id + ",";
      }
    }
  }
}