import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private _script: ScriptLoaderService, private Router: Router) { }

  ngOnInit() {

    if (localStorage.getItem('userdata') == "") {
      this.Router.navigate(['/login-5']);
    }

  }

  ngAfterViewInit() {
    // this._script.load('./assets/js/scripts/dashboard_visitors.js');
  }

}
