import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../Services/tpservice.service';
import {Router} from '@angular/router';
import {irlist} from '../../Models/irlist';
import swal from "sweetalert2";
import {irmaster} from '../../Models/irmaster';
import {dcdata} from '../../Models/dcdata';
import { permissions } from '../../Models/permissions';
import { mis } from '../../Models/mis';
import { Excellservice } from '../../Services/excellservice.service';
import { DatePipe } from '@angular/common';
import { customer } from '../../Models/customer';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './mislist.component.html',
  providers:[TpserviceService,Excellservice,DatePipe]
})

export class MISlistComponent implements OnInit, AfterViewInit {
  grant_edit:any;
  permissions:permissions;
irlist:mis[]=[];
irlistexport:mis[]=[];
branch:string="0";
selectedirlist:string="";
com:string=",";
openstr:string="(";
closestr:string=")";
irmaster:irmaster;
irtabledata:dcdata[]=[];
branchlist:any=[];
disablestate:any;
fromdate:any="";
todate:any="";
query:any;
flag:boolean;
cust:customer[];
customer:string="0";

  constructor(private tps: TpserviceService,private datepipe:DatePipe, private detectchages: ChangeDetectorRef, private route: Router,private excellservice:Excellservice) { }

  ngOnInit() {
    this.displaybranches();
    this.displayCustomers();
    this.flag=true;
    // this.permissions=JSON.parse(localStorage.getItem("permissions"));
    // this.grant_edit=+this.permissions.TIRE;
    // this.displaybranches();
    // this.branch=localStorage.getItem("branch");
    // this.disablestate=localStorage.getItem("disablestate");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
     
  }
 
  ngAfterViewInit() {
    var self=this;

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });

    $(document).ready(function(){
      $("#branch").select2({
        theme:"classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     });

     $(document).ready(function(){
      $("#customer").select2({
        theme:"classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.customer=id;
     });
    
  }

  gettabledata(data)
  {
    this.irlist=[];
    $("#products-table").dataTable().fnDestroy();
    this.irlist=data;
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }

  getlist(){
    this.irlist=[];
    this.flag=false;
    this.getirlist();
  }
  getirlist()
  {
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate,
      cust_name:this.customer,
      branch:this.branch
    }
    this.tps.getMis(obj).subscribe(
      
      data => this.gettabledata(data)
  );
}

Exportasexcell(){

  if(this.fromdate=='' && this.todate==''){
    swal('Warning','Please Select Date First !','warning');
  }
  else
  {
  const obj={
    from_date:this.fromdate,
    to_date:this.todate,
    branch:this.branch

  }

  this.tps.getMis(obj).subscribe(
    data => this.Afterdata(data)
);
}
}

Afterdata(data){
  this.irlistexport=[];
  this.irlistexport=data;
  this.excellservice.exportAsExcelFile(this.irlistexport, 'MISReport');
}

displaybranches(){
  this.tps.getBranch().subscribe(
      data =>this.branchlist = data
  );
}

displayCustomers(){
      this.tps.getallCustomer().subscribe(
          data =>this.cust = data
      );

  }
}