import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { dclist } from '../../Models/dclist';
import { dcdata } from '../../Models/dcdata';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { vehicle } from '../../Models/vehicle';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './vehiclelist.component.html',
  providers: [TpserviceService]
})

export class VehicleListComponent implements OnInit, AfterViewInit {

  vehiclelist: vehicle[];
  branch: string = "1";
  dcdata: dcdata;
  dclist: dclist[] = [];
  selectedcheck: string = "";
  openstr = "(";
  closedtr = ")";
  com = ",";
  disabledata: any;
  grant_edit: any;
  permissions: permissions;
  disablestate: any;
  selectedc: Array<any> = [];
  branchlist: any = [];
  query: any;
  deletedvehicle: string = "";
  deletestr: deleteBo[] = [];

  dclisttabledata: {}

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.disabledata = +localStorage.getItem("disablestate");
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MVCE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
    this.getvehiclelist();
  }


  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.vehiclelist = data;
    swal.close();
    this.changedetect.detectChanges();

    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }
  ngAfterViewInit() {

  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
      this.selectedc.push(data);
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }

  getvehiclelist() {
    const ob = {
      checkeddata: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getVehicle(ob).subscribe(
      data => this.gettabledata(data)
    )
  }


  deletevehicle() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closedtr;
    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const object = {
            vehicleid: strdata
          }
          this.tps.deleteVehicle(object).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {
    this.deletestr = data;
    this.buildvehicleno(this.deletestr);
    if (this.deletedvehicle == "" || this.deletedvehicle == "()") {

      swal('success', "Vehicle Deleted Successfully!", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "can not delete this Vehicle " + this.deletedvehicle + ", already used in DC Records..".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.selectedcheck = "";
    this.deletedvehicle = '';
    this.getvehiclelist();
  }

  buildvehicleno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletedvehicle = this.deletedvehicle + data[i].id + ",";
      }
    }
  }

  getvehicledata(obj) {
    const ob = {
      vehicleid: obj
    }
    this.tps.getVehicleObject(ob).subscribe(
      data => this.callafterdata(data)
    );
  }

  callafterdata(data) {
    localStorage.setItem("vehicledata", JSON.stringify(data));
    localStorage.setItem("vehicledatastate", "1");
    this.route.navigate(['forms/vehicle']);
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.branchlist = data
    );
  }

}