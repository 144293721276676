import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-inward-details',
  templateUrl: './material-inward-details.component.html',
  styleUrls: ['./material-inward-details.component.css']
})
export class MaterialInwardDetailsComponent implements OnInit {

  datastate:string="Save";
  branch:string="";
  lrno:string="";
  date:string="";
  invno:string="";
  truck_tempo_number:string="";
  consigner:string="";
  consignorgst:string="";
  consigner_addr:string="";
  consignee:string="";
  consigneegst:string="";
  consignee_addr:string="";
  loc_from:string="";
  loc_to:string="";
  art:string="";
  nart:string="";
  desc:string="";
  actwt:string="";
  constructor() { }

  ngOnInit() {
  }

}
