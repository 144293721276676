import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { DatePipe } from '@angular/common';
import { Branch } from '../../../Models/Branch';
import { customer } from '../../../Models/customer';
import { lrmaster } from '../../../Models/lrmaster';
import { lorrymasterBO } from '../../../Models/lorrymasterBO';
import { user } from '../../../Models/user';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { bill } from '../../../Models/bill';

declare var $: any;

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css'],
  providers: [TpserviceService, DatePipe]
})
export class BillComponent implements OnInit, AfterViewInit {

  datastate: string = "Save";
  branch: string = "";
  id: string = "";
  billno: string = "";
  date: string = "";
  bill_type: string = "To Pay";
  customer: string = "";
  vendor_code: string = "";
  lrno: string = "";
  lrdate: string = "";
  consignor: string = "";
  consignee: string = "";
  from: string = "";
  to: string = "";
  gstno: string = "";
  gstpayby: string = "";
  lr_freight: any = 0;
  insuarance: any = 0;
  lr_hamali: any = 0;
  lr_other_char: any = 0;
  collection: any = 0;
  docket: any = 0;
  doordel: any = 0;
  lr_total: any = 0;
  tot_art: string = "";
  freight: any = 0;
  hamali: any = 0;
  del_chage: any = 0;
  other_char: any = 0;
  tot_ccchar: any = 0;
  tot_insurance: any = 0;
  tot_docket: any = 0;
  demurage: any = 0;
  tds_char: any = 0;
  total: any = 0;
  remark: string = "";
  branches: Branch[] = [];
  cust: customer[];
  lrnos: lrmaster[];
  lrid: string = "";
  lrdata: lorrymasterBO;
  row_num: string = "";
  flag: boolean;
  tot_wt: string = "";
  user: string = "";
  userdata: user;
  billdetails: bill;

  public rows: Array<{
    table_lr_id: string;
    table_lr_no: string;
    table_lr_date: string;
    table_lr_consignee: string;
    table_lr_consignor: string;
    table_lr_from: string;
    table_lr_to: string;
    table_lr_gstpayby: string;
    table_lr_freight: string;
    table_lr_insuarance: string;
    table_lr_hamali: any;
    table_lr_otherchar: string;
    table_lr_collection: string;
    table_lr_docket: string;
    table_lr_delivery: string;
    table_lr_total: any;
    table_lr_totart: string;
    table_lr_totwt: string;
  }> = [];

  constructor(private tps: TpserviceService, private datepipe: DatePipe, private route: Router) { }

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.user = this.userdata.id;
    this.displaybranches();
    this.date = this.datepipe.transform(new Date(), "yyyy-MM-dd");

    if (localStorage.getItem("datastate") == "1") {
      this.datastate = "Update";
      this.displaybilldata();
      localStorage.removeItem("datastate");
    }
  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        invno: {
          required: !0
        },
        bill_type: {
          required: !0
        },
        customer: {
          required: !0,
        },
        lrno: {
          required: !0
        }
      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });

    var drop = true;
    $(document).on('focus', '.select2', function (e) {
      if (e.originalEvent && drop == true) {
        $(this).siblings('select').select2('open');
      }
      else {
        drop = true;
      }
    });

    $(document).on('focusout', '.select2-search__field', function () {
      drop = false;
    });

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
      self.onchangecustomer(evt);
    });

    $(document).ready(function () {
      $("#lrid").select2({
        theme: "classic",
        allowClear: true,
        hidden: true,
      });
    });

    $("#lrid").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.lrid = id;
      self.getlrdata(id);
    });
  }

  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
  swal.showLoading();
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branches = data
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.afterDisplayCustomers(data)
    );
   
  }

  afterDisplayCustomers(data){
    this.cust = data;
    swal.close();
  }

  onchangecustomer(e) {
    let index = this.cust.findIndex(datas => datas.cid == e.target.value);
    this.vendor_code = this.cust[index].vendorcode;
    this.gstno = this.cust[index].gstno;
    //console.log(this.bill_type);
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    if(this.bill_type!=""){
      if(this.bill_type == 'Third Party'){
        //alert('Third Party');
        this.getlrforbillByThirdParty();
      }
      else{
        this.getlrforbill();
      }
    }
    else{
      swal('Error','Please Select Bill Type First!!!','error');
    }
  }

  getlrforbillByThirdParty() {
    const obj = {
      checkeddata: this.customer
    }
    this.tps.getlrforbillByThirdParty(obj).subscribe(
      data => this.aftergetlrforbill(data)
    );
    
  }

  getlrforbill() {
    const obj = {
      checkeddata: this.customer,
      checkeddata1: this.bill_type
    }
    this.tps.getLRforBill(obj).subscribe(
      data => this.aftergetlrforbill(data)
    );
    
  }
 
  aftergetlrforbill(data){
    this.lrnos = data;
    swal.close();
  }
  getlrdata(ob) {
    const obj = {
      lrid: ob
    }
    this.tps.getLrDataForBill(obj).subscribe(
      data => this.callafterlrdata(data)
    );
  }

  callafterlrdata(data) {
    this.lrdata = data;
    this.lrno = this.lrdata.lr_no;
    this.lrdate = this.lrdata.lr_date;
    this.consignor = this.lrdata.consigner;
    this.consignee = this.lrdata.consignee;
    this.from = this.lrdata.loc_from;
    this.to = this.lrdata.loc_to;
    this.gstpayby = this.lrdata.gstpayby;
    this.lr_freight = this.lrdata.freight;
    this.lr_hamali = this.lrdata.hamali;
    this.lr_other_char = this.lrdata.other_charges;
    this.collection = this.lrdata.cc_char;
    this.insuarance = this.lrdata.insuarance;
    this.doordel = this.lrdata.delivery;
    this.docket = this.lrdata.docket;
    this.tot_art = this.lrdata.tot_article;
    this.tot_wt = this.lrdata.tot_wt;
    this.lr_total = this.lrdata.total;
  }

  callrtotal() {
    this.lr_total = 0;
    this.lr_total = +this.lr_freight + +this.insuarance + +this.lr_hamali + +this.lr_other_char + +this.collection + +this.docket + +this.doordel;
  }

  addlrdetails() {
    if(this.lrno != ""){
    this.rows.push({
      table_lr_id: this.lrid,
      table_lr_no: this.lrno,
      table_lr_date: this.lrdate,
      table_lr_consignor: this.consignor,
      table_lr_consignee: this.consignee,
      table_lr_from: this.from,
      table_lr_to: this.to,
      table_lr_gstpayby: this.gstpayby,
      table_lr_freight: this.lr_freight,
      table_lr_hamali: this.lr_hamali,
      table_lr_collection: this.collection,
      table_lr_delivery: this.doordel,
      table_lr_insuarance: this.insuarance,
      table_lr_otherchar: this.lr_other_char,
      table_lr_docket: this.docket,
      table_lr_total: this.lr_total,
      table_lr_totart: this.tot_art,
      table_lr_totwt: this.tot_wt,
    });
    this.clear();
    this.calculateBillDetails();
  }
  else{
    swal("Alert",'Please select LR First!!!','warning');
  }
    // var totfreight = 0;
    // var tothamali = 0;
    // var totcccharge = 0;
    // var totdelcharge = 0;
    // var totothercharge = 0;
    // var totinsurance = 0;
    // var totdocket = 0;
    // this.rows.forEach(function (element) {
    //   totfreight = totfreight + +element.table_lr_freight;
    //   tothamali = tothamali + +element.table_lr_hamali;
    //   totcccharge = totcccharge+ +element.table_lr_collection;
    //   totdelcharge =totdelcharge+ +element.table_lr_delivery;
    //   totothercharge = totothercharge+ +element.table_lr_otherchar;
    //   totinsurance = totinsurance+ +element.table_lr_insuarance;
    //   totdocket = totdocket+ +element.table_lr_docket;
    // })
    // this.freight = totfreight;
    // this.hamali = tothamali;
    // this.tot_ccchar = totcccharge;
    // this.del_chage = totdelcharge;
    // this.other_char = totothercharge;
    // this.tot_insurance = totinsurance;
    // this.tot_docket = totdocket;
  }

  clear() {
    $("#lrid").val('').trigger('change');
    //this.lrid = "0";
    this.lrid = "";
    this.lrno = "";
    this.lrdate = "";
    this.consignor = "";
    this.consignee = "";
    this.from = "";
    this.to = "";
    this.gstpayby = "";
    this.lr_freight = "";
    this.lr_hamali = "";
    this.collection = "";
    this.doordel = "";
    this.tot_art = "";
    this.insuarance = "";
    this.lr_other_char = "";
    this.docket = "";
    this.total = "";
    this.tot_art = "";
  }

  editrow(row, index) {
    $('#lrid').next(".select2-container").hide();
    (<HTMLInputElement>document.getElementById("lrno")).hidden = false;
    this.row_num = index;
    this.lrid = row.table_lr_id;
    this.lrno = row.table_lr_no;
    this.lrdate = row.table_lr_date;
    this.consignor = row.table_lr_consignor;
    this.consignee = row.table_lr_consignee;
    this.from = row.table_lr_from;
    this.to = row.table_lr_to;
    this.gstpayby = row.table_lr_gstpayby;
    this.lr_freight = row.table_lr_freight;
    this.insuarance = row.table_lr_insuarance;
    this.lr_hamali = row.table_lr_hamali;
    this.lr_other_char = row.table_lr_otherchar;
    this.collection = row.table_lr_collection;
    this.docket = row.table_lr_docket;
    this.doordel = row.table_lr_delivery;
    this.lr_total = row.table_lr_total;
    this.tot_art = row.table_lr_totart;
    this.flag = true;
  }

  onupdate() {
    this.rows.push({
      table_lr_id: this.lrid,
      table_lr_no: this.lrno,
      table_lr_date: this.lrdate,
      table_lr_consignor: this.consignor,
      table_lr_consignee: this.consignee,
      table_lr_from: this.from,
      table_lr_to: this.to,
      table_lr_gstpayby: this.gstpayby,
      table_lr_freight: this.lr_freight,
      table_lr_hamali: this.lr_hamali,
      table_lr_collection: this.collection,
      table_lr_delivery: this.doordel,
      table_lr_insuarance: this.insuarance,
      table_lr_otherchar: this.lr_other_char,
      table_lr_docket: this.docket,
      table_lr_total: this.lr_total,
      table_lr_totart: this.tot_art,
      table_lr_totwt: this.tot_wt,
    });
    this.clear();
    $('#lrid').next(".select2-container").show();
    (<HTMLInputElement>document.getElementById("lrno")).hidden = true;
    this.flag = false;
    this.deleterow(this.row_num);
    this.calculateBillDetails();
  }

  calculateBillDetails(){
    var totfreight = 0;
    // var tothamali = 0;
    // var totcccharge = 0;
    // var totdelcharge = 0;
    // var totothercharge = 0;
    // var totinsurance = 0;
    // var totdocket = 0;
    this.rows.forEach(function (element) {
      // totfreight = totfreight + +element.table_lr_freight+ +element.table_lr_hamali+ +element.table_lr_collection+ +element.table_lr_delivery+ +element.table_lr_otherchar+ +element.table_lr_insuarance+ +element.table_lr_docket;
      totfreight = totfreight + +element.table_lr_total;
      // tothamali = tothamali + +element.table_lr_hamali;
      // totcccharge = totcccharge+ +element.table_lr_collection;
      // totdelcharge =totdelcharge+ +element.table_lr_delivery;
      // totothercharge = totothercharge+ +element.table_lr_otherchar;
      // totinsurance = totinsurance+ +element.table_lr_insuarance;
      // totdocket = totdocket+ +element.table_lr_docket;
    });
    this.freight = totfreight;
    // this.hamali = tothamali;
    // this.tot_ccchar = totcccharge;
    // this.del_chage = totdelcharge;
    // this.other_char = totothercharge;
    // this.tot_insurance = totinsurance;
    // this.tot_docket = totdocket;
    this.caltotal();

  }

  deleterow(index) {
    this.rowdatadelete(index);
    this.rows.splice(index, 1);
    this.calculateBillDetails();
  }

  rowdatadelete(index) {
    var openpr: "(";
    var closepr: ")";
    var com: ",";
    var data_str: String = "";
    var raw_str: String = "";
    data_str = "'" + this.rows[index].table_lr_no + "'" + com + "'" + this.rows[index].table_lr_date + "'" + com + "'" + this.rows[index].table_lr_consignor + "'" + com + "'" + this.rows[index].table_lr_consignee + "'" + com + "'" + this.rows[index].table_lr_from + "'" + com + "'" + this.rows[index].table_lr_to + "'" + com + "'" + this.rows[index].table_lr_gstpayby + "'" + com + "'" + this.rows[index].table_lr_freight + "'" + com + "'" + this.rows[index].table_lr_insuarance + "'" + com + "'" + this.rows[index].table_lr_hamali + "'" + com + "'" + this.rows[index].table_lr_otherchar + "'" + com + "'" + this.rows[index].table_lr_collection + "'" + com + "'" + this.rows[index].table_lr_docket + "'" + com + "'" + this.rows[index].table_lr_delivery + "'" + com + "'" + this.rows[index].table_lr_total + "'" + com + "'" + this.rows[index].table_lr_totart + "'" + com + "@@";
    raw_str = openpr + data_str + closepr + com;
  }

  caltotal() {
    this.total = 0;
    this.total = +this.freight + +this.hamali + +this.del_chage + +this.other_char + +this.tot_ccchar + +this.tot_insurance;
    this.total = Math.round(this.total);
  }

  onsubmit() {
    if (this.datastate == "Save") {
      const obj = {
        bill_no: this.billno,
        bill_date: this.date,
        bill_type: this.bill_type,
        customer_id: this.customer,
        tot_freight: this.freight,
        tot_hamali: this.hamali,
        delivery_ch: this.del_chage,
        other_ch: this.other_char,
        demurage: this.demurage,
        tds: this.tds_char,
        cc_charg:this.tot_ccchar,
        insurance:this.tot_insurance,
        docket:this.tot_docket,
        tot_amount: this.total,
        remarks: this.remark,
        user_id: this.user,
        branch: this.branch,
        bill_details: this.rows
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 150
      });
      swal.showLoading();
      this.tps.addBill(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.update();
    }
  }

  aftersubmit(data) {
    this.id = data['res_code'];
    swal.close();
    swal('Success', data['messasge'], 'success');
    (<HTMLInputElement>document.getElementById("submit")).disabled = true;
    (<HTMLInputElement>document.getElementById("print")).disabled = false;
  }

  displaybilldata() {
    this.billdetails = JSON.parse(localStorage.getItem("data"));
    localStorage.removeItem("data");
    this.id = this.billdetails.id;
    this.billno = this.billdetails.bill_no;
    this.date = this.billdetails.bill_date;
    this.bill_type = this.billdetails.bill_type;
    this.customer = this.billdetails.customer_id;
    this.vendor_code=this.billdetails.cust_vendor_code;
    this.gstno=this.billdetails.cust_gstno;
    this.freight = this.billdetails.tot_freight;
    this.hamali = this.billdetails.tot_hamali;
    this.del_chage = this.billdetails.delivery_ch;
    this.other_char = this.billdetails.other_ch;
    this.tot_ccchar = this.billdetails.cc_charg;
    this.tot_insurance = this.billdetails.insurance;
    this.tot_docket = this.billdetails.docket;
    this.demurage = this.billdetails.demurage;
    this.tds_char = this.billdetails.tds;
    this.total = this.billdetails.tot_amount;
    this.remark = this.billdetails.remarks;
    this.rows = this.billdetails.bill_details;
    this.getlrforbill();
  }

  // splitdata() {
  //   let strdata: string = "";
  //   let strdatalr: string = "";
  //   this.rowdata.forEach(function (element) {
  //     if (!strdata.includes(element.lr_master_id.toString())) {
  //       strdata += element.lr_master_id.toString() + ",";
  //       strdatalr += "(" + element.lr_master_id.toString() + "," + "1" + "," + "@@),"
  //     }
  //   })
  //   this.selectedlrstr = strdata;
  //   this.sendcallbacklr = strdata;
  //   this.sendcallbacklr = this.openstr + strdata.substr(0, strdata.length - 1) + this.closestr;
  //   this.selectedlr = strdatalr;
  // }

  update() {
    const obj = {
      id: this.id,
      bill_no: this.billno,
      bill_date: this.date,
      bill_type: this.bill_type,
      customer_id: this.customer,
      tot_freight: this.freight,
      tot_hamali: this.hamali,
      delivery_ch: this.del_chage,
      other_ch: this.other_char,
      demurage: this.demurage,
      tds: this.tds_char,
      cc_charg:this.tot_ccchar,
      insurance:this.tot_insurance,
      docket:this.tot_docket,
      tot_amount: this.total,
      remarks: this.remark,
      user_id: this.user,
      branch: this.branch,
      bill_details: this.rows
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 150
    });
    swal.showLoading();
    this.tps.updateBill(obj).subscribe(
      data => this.aftersubmit(data)
    );
  }

  redirect() {
    this.route.navigate(['pages/Bill-List']);
  }

  pdfredirect() {
    const ob = {
      id: this.id,
      type: 'bills', // dc_master , bills
    }
    this.tps.viewpdf(ob).subscribe(
      data => this.afterpdf(data)
      , error => swal('Please check your internet connection', error, 'error')
    );
  }

  afterpdf(data) {
    swal({
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + data['location'] + '" height="500px" width="100%"/>',
      preConfirm: () => {
        return document.getElementById("viewfile");
      }
    })
  }

}
