import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { LRReport } from '../../../Models/lrreport';
import { Excellservice } from '../../../Services/excellservice.service';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs/Observable';

declare var $: any;


@Component({
  selector: 'app-lorry-receipt-register',
  templateUrl: './lorry-receipt-register.component.html',
  styleUrls: ['./lorry-receipt-register.component.css'],
  providers: [TpserviceService, Dataservices, DatePipe,Excellservice]
})
export class LorryReceiptRegisterComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  branch: string;  
  branchlist: any;
  customer:any="";
  cust:any;
  fromDate:string="";
  toDate:string="";
  paymode:string = "All";
  lrList:LRReport[]=[];
  flag: boolean;
  total_articles:any;
  total_weight:any;
  total_amount:any;

  constructor(private tps: TpserviceService,private excellservice:Excellservice, private dts: Dataservices,private datepipe: DatePipe, private route: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.fromDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.toDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
  }

  ngAfterViewInit() {
    var self = this;

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onbranchchange();
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
      //self.displaylr();
    });
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.aftergetBranch(data)
    );
  }

  aftergetBranch(data) {
    this.branchlist = data;
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.cust = data;
    this.getLRListByDefault();
  }
  onbranchchange() {
    localStorage.setItem("branch", this.branch);
    this.getLRListByDefault();
  }

  getLRList(){
    if (this.fromDate != "" && this.toDate != "" && this.customer =="" && this.paymode == "All") {
      this.getLRListByDate();
    }
    // else if(this.fromDate == "" && this.toDate == "" && this.customer !="" && this.paymode == "All"){
    //   this.getLRListByCustomer();
    // }
    // else if(this.fromDate == "" && this.toDate == "" && this.customer =="" && this.paymode != "All"){
    //   this.getLRListByPayMode();
    // }
    else if(this.fromDate != "" && this.toDate != "" && this.customer !="" && this.paymode == "All"){
      this.getLRListByDateAndCustomer();
    }
    else if(this.fromDate != "" && this.toDate != "" && this.customer =="" && this.paymode != "All"){
      this.getLRListByDateAndPayMode();
    }
    else if(this.fromDate != "" && this.toDate != "" && this.customer !="" && this.paymode != "All"){
      this.getLRListByAll();
    }
    else{
      this.getLRListByDefault();
    }

  }

  getLRListByDefault() {
    const OBJ = {
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
   // console.log(OBJ);
    this.tps.getLrListForreportByDefault(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  getLRListByDate() {
    const OBJ = {
      branch: this.branch,
      from_date: this.fromDate,
      to_date: this.toDate
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  ///  console.log(OBJ);
    this.tps.getLrListForreportByDate(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  getLRListByDateAndCustomer() {
    const OBJ = {
      branch: this.branch,
      from_date: this.fromDate,
      to_date: this.toDate,
      cust_name: this.customer
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getLrListForreportBycustomer(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }

  // getLRListByPayMode() {
  //   const OBJ = {
  //     branch: this.branch,
  //     paymode: this.paymode
  //   }
  //   console.log(OBJ);
  //   this.tps.getLrListForreportByPayMode(OBJ).subscribe(
  //     data => this.afterGetLrData(data)
  //   );
  // }

  getLRListByDateAndPayMode() {
    const OBJ = {
      branch: this.branch,
      from_date: this.fromDate,
      to_date: this.toDate,
      paymode: this.paymode
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getLrListForreportByPayMode(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }


  getLRListByAll() {
    const OBJ = {
      branch: this.branch,
      from_date: this.fromDate,
      to_date: this.toDate,
      cust_name: this.customer,
      paymode: this.paymode
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  //  console.log(OBJ);
    this.tps.getLrListForreportByAll(OBJ).subscribe(
      data => this.afterGetLrData(data)
    );
  }
  
  afterGetLrData(data){
    $("#products-table").dataTable().fnDestroy();
    this.lrList = data;
    this.flag=false;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 20,
        //pagingType: 'full_numbers',
        fixedHeader: true,
        responsive: true,
        ordering: false,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
        table.column(2).search($(this).val()).draw();
    });
    this.calculate_total();
  }

  calculate_total(){
   this.total_articles = 0;
   this.total_weight = 0;
   this.total_amount = 0;

   for(let i=0;i<this.lrList.length;i++){
     this.total_articles = Number(this.total_articles) + Number(this.lrList[i].no_of_articles);
     this.total_weight = Number(this.total_weight) + Number(this.lrList[i].chargeble_wt);
     this.total_amount = Number(this.total_amount) + Number(this.lrList[i].total_amount);
   }
  }
  
  Exportasexcell(){
    // console.log(this.table.nativeElement);
   // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    //console.log("ws:",ws);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //console.log(excelBuffer);
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    
    
    /* save to file */
   // this.excellservice.saveAsExcelFile(excelBuffer, 'LR_REG_REPORTFILE');
    //XLSX.writeFile(wb, 'SheetJS.xlsx');
     this.excellservice.exportAsExcelFile(this.lrList, 'LRREPORTFILE');
  }

}
