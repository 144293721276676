import { Component, OnInit , AfterViewInit} from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-form-layouts',
  templateUrl: './bank-accounts.component.html',
})
export class BankAccountsComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(){

    $('#form-sample-1').validate({
      rules: {

        req: {
          required: !0
        },
        name: {
          minlength: 2,
          required: !0
        },
        email: {
          required: !0,
          email: !0
        },
        url: {
          required: !0,
          url: !0
        },
        number: {
          required: !0,
          number: !0
        },
        min: {
          required: !0,
          minlength: 3
        },
        IFSC: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },
        MICR: {
          required: !0,
          minlength: 11,
          maxlength: 11
        },
        phone: {
          required: !0,
          minlength: 10,
          maxlength: 10
        },
        account: {
          required: !0,
          minlength: 10,
          maxlength: 11
        },
        max: {
          required: !0,
          maxlength: 4
        },
        password: {
          required: !0
        },
        password_confirmation: {
          required: !0,
          equalTo: '#password'
        }
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
    });





  }

}
