import { Component,OnInit, AfterViewInit,Compiler } from '@angular/core';
import { user } from '../../Models/user';
import { permissions } from '../../Models/permissions';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TpserviceService } from '../../Services/tpservice.service';
import { Branch } from '../../Models/Branch';

declare var $: any;

@Component({
  selector: '[app-header]',
  templateUrl: './app-header.component.html',
  providers:[DatePipe,TpserviceService]
})
export class AppHeader implements AfterViewInit,OnInit {

userdata:user;
permissions:permissions;
username:string="";
date:any;

TXN:any="";
TLR:any="";
TLRE:any="";
TDC:any="";
TDCE:any="";
TFM:any="";
TFME:any;
TIR:any="";
TIRE:any="";
TPOD:any="";
TPODE:any="";

MART:any="";
MARTE:any="";
MBR:any="";
MBRE:any="";
MEMP:any="";
MEMPE:any="";
MVC:any="";
MVCE:any="";
MVCO:any="";
MVCOE:any="";
MPL:any="";
MPLE:any="";
MCUS:any="";
MCUSE:any="";
MDV:any="";
MDVE:any="";
MVT:any="";
MVTE:any="";
BRB:any="";
BRBE:any="";
BTB:any="";
BTBE:any="";

RPT:any="";
RMIS:any="";
RStock:any="";
RLRB:any="";
RLRD:any="";
RLRC:any="";
RDCB:any="";
RDCD:any="";
RDCV:any="";
RFMC:any="";
RFML:any="";
RFMCU:any="";
RTRB:any="";

Oprint:any="";
branchconstant:Branch[]=[]
USRBRSTATE:any="0";
USERMODULE:any="0";


  constructor(private tps:TpserviceService, private route:Router,private datepipe:DatePipe,private _compiler:Compiler) { }


    ngOnInit() {

    this.loadbranches();
      this.date= this.datepipe.transform(new Date(),"dd-MM-yyyy");
      this.userdata=JSON.parse(localStorage.getItem("userdata"));
      console.log(this.userdata);
      this.username=this.userdata.username;
     
       if(this.userdata.usertype=="0")
       {
         this.normaluser();
         localStorage.setItem("disablestate","true");
       }
       else if(this.userdata.usertype=="1")
       {
         this.admin();
         localStorage.setItem("disablestate","false");
       }
       else if(this.userdata.usertype=="2")
       {
         this.superadmin();
         localStorage.setItem("disablestate","false");
       }
      
  }
  ngAfterViewInit()  {
    $(document).ready(function(){
      $('.dropdown-submenu a.test').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
    });

    $(document).ready(function(){
      $('.has-submenu a.has-tip dropdowm-item').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
    });

    localStorage.setItem("USRBRSTATE",this.USRBRSTATE);
  }

  logoff(){
    localStorage.removeItem("permissions");
    localStorage.removeItem("userdata");
    localStorage.removeItem("USRBRSTATE");
    localStorage.removeItem("disablestate");
    localStorage.removeItem("USERMODULE");
    localStorage.removeItem("branchconstant");
    localStorage.clear();
    this._compiler.clearCache();
    this.route.navigate(['/login-5']);
  }
  
  normaluser(){

    this.permissions=JSON.parse(localStorage.getItem("permissions"));
   
    this.TXN=+this.permissions.TXN;
     this.TLR= +this.permissions.TLR;
     this.TLRE= +this.permissions.TLRE;
     this.TDC= +this.permissions.TDC;
     this.TDCE= +this.permissions.TDCE;
     this.TFM= +this.permissions.TFM;
     this.TFME= +this.permissions.TFME;
     this.TIR= +this.permissions.TIR;
     this.TIRE= +this.permissions.TIRE;
     this.TPOD= +this.permissions.TPOD;
     this.TPODE= +this.permissions.TPODE;
     this.MART= +this.permissions.MART;
     this.MARTE= +this.permissions.MARTE;
     this.MBR= +this.permissions.MBR;
     this.MBRE= +this.permissions.MBRE;
     this.MEMP= +this.permissions.MEMP;
     this.MEMPE= +this.permissions.MEMPE;
     this.MVC= +this.permissions.MVC;
     this.MVCE= +this.permissions.MVCE;
     this.MVCO= +this.permissions.MVCO;
     this.MVCOE= +this.permissions.MVCOE;
     this.MPL= +this.permissions.MPL;
     this.MPLE= +this.permissions.MPLE;
     this.MCUS= +this.permissions.MCUS;
     this.MCUSE= +this.permissions.MCUSE;
     this.MDV= +this.permissions.MDV;
     this.MDVE= +this.permissions.MDVE;
     this.MVT= +this.permissions.MVT;
     this.MVTE= +this.permissions.MVTE;
     this.BRB= +this.permissions.BRB;
     this.BRBE= +this.permissions.BRBE;
     this.BTB= +this.permissions.BTB;
     this.BTBE= +this.permissions.BTBE;
     this.RPT= +this.permissions.RPT;
     this.RDCB= +this.permissions.RDCB;
     this.RDCD= +this.permissions.RDCD;
     this.RDCV= +this.permissions.RDCV;
     this.RLRB= +this.permissions.RLRB;
     this.RLRC= +this.permissions.RLRC;
     this.RLRD= +this.permissions.RLRD;
     this.RMIS= +this.permissions.RMIS;
     this.RStock= +this.permissions.RStock;
     this.RFMC= +this.permissions.RFMC;
     this.RFML= +this.permissions.RFML;
     this.RFMCU= +this.permissions.RFMCU;
     this.RTRB= +this.permissions.RTRB;
     this.USERMODULE=0;
     this.USRBRSTATE="true";
  }

  admin(){
    console.log("in admin");
  
    this.TXN=1;
    this.TLR=1;
    this.TLRE=1;
    this.TDC=1;
    this.TDCE=1;
    this.TFM= 1;
    this.TFME=1;
    this.TIR=1;
    this.TIRE=1;
    this.TPOD=1;
    this.TPODE=1;

    this.MART=1;
    this.MARTE=1;
    this.MBR=1;
    this.MBRE=1;
    this.MEMP= 1;
    this.MEMPE= 1;
    this.MVC= 1;
    this.MVCE= 1;
    this.MVCO=1;
    this.MVCOE=1;
    this.MPL= 1;
    this.MPLE=1;
    this.MCUS= 1;
    this.MCUSE= 1;
    this.MDV= 1;
    this.MDVE=1;
    this.MVT=1;
    this.MVTE=1;

    this.BRB=1;
    this.BRBE=1;
    this.BTB=1;
    this.BTBE=1;

    this.RPT=1;
    this.RMIS=1;
    this.RStock=1;
    this.RLRB=1;
    this.RLRD=1;
    this.RLRC=1;
    this.RDCB=1;
    this.RDCD=1;
    this.RDCV=1;
    this.RFMC=1;
    this.RFML=1;
    this.RFMCU=1;
    this.RTRB=1;

    this.Oprint=1;
    this.USERMODULE=1;
    this.USRBRSTATE="false";

var permissions={

  branch:"",
  user:"",

  TXN:"1",
  TLR:"1",
  TLRE:"1",
  TDC:"1",
  TDCE:"1",
  TFM:"1",
  TFME:"1",
  TIR:"1",
  TIRE:"1",
  TPOD:"1",
  TPODE:"1",
 
  MART:"1",
  MARTE:"1",
  MBR:"1",
  MBRE:"1",
  MEMP:"1",
  MEMPE:"1",
  MVC:"1",
  MVCE:"1",
  MVCO:"1",
  MVCOE:"1",
  MPL:"1",
  MPLE:"1",
  MCUS:"1",
  MCUSE:"1",
 
  MDV:"1",
  MDVE:"1",
  MVT:"1",
  MVTE:"1",

  BRB:"1",
  BRBE:"1",
  BTB:"1",
  BTBE:"1",
  
  RPT:"1",
  RMIS:"1",
  RStock:"1",
	RLRB:"1",
	RLRD:"1",
	RLRC:"1",
	RDCB:"1",
	RDCD:"1",
  RDCV:"1",
  RFMC:"1",
  RFML:"1",
  RFMCU:"1",
  RTRB:"1",
  Oprint:"1"

}
this.permissions=permissions;

localStorage.setItem("permissions",JSON.stringify(this.permissions));
console.log(localStorage.getItem("permissions"));
 }
  
  superadmin(){

    this.TXN=1;
    this.TLR=1;
    this.TLRE=1;
    this.TDC=1;
    this.TDCE=1;
    this.TFM= 1;
    this.TFME=1;
    this.TIR=1;
    this.TIRE=1;
    this.TPOD=1;
    this.TPODE=1;

    this.MART= 1;
    this.MARTE=1;
    this.MBR=1;
    this.MBRE=1;
    this.MEMP= 1;
    this.MEMPE= 1;
    this.MVC= 1;
    this.MVCE= 1;
    this.MVCO=1;
    this.MVCOE=1;
    this.MPL= 1;
    this.MPLE=1;
    this.MCUS= 1;
    this.MCUSE= 1;
    this.MDV= 1;
    this.MDVE=1;
    this.MVT=1;
    this.MVTE=1;

    this.BRB=1;
    this.BRBE=1;
    this.BTB=1;
    this.BTBE=1;
    
    this.RPT=1;
    this.RMIS=1;
    this.RStock=1;
    this.RLRB=1;
    this.RLRD=1;
    this.RLRC=1;
    this.RDCB=1;
    this.RDCD=1;
    this.RDCV=1;
    this.RFMC=1;
    this.RFML=1;
    this.RFMCU=1;
    this.RTRB=1;
    
    this.Oprint=1;
    this.USERMODULE=1;
    this.USRBRSTATE="false";

  }

  loadbranches(){
    this.tps.getBranch().subscribe(
      data=> this.afterbraches(data)
      )
    }

  afterbraches(data){
    this.branchconstant=data;
    localStorage.setItem("branchconstant", JSON.stringify(this.branchconstant));
    console.log(localStorage.getItem("permissions"));
  }
}