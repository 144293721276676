import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { fmlist } from '../../../Models/fmlist';
import { TpserviceService } from '../../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { permissions } from '../../../Models/permissions';
import { deleteBo } from '../../../Models/deleteBo';
declare var $: any;

@Component({
  selector: 'app-touching-fmlist',
  templateUrl: './touching-fmlist.component.html',
  styleUrls: ['./touching-fmlist.component.css'],
  providers: [TpserviceService]
})
export class TouchingFMListComponent implements OnInit {

  grant_edit: any;
  permissions: permissions;
  fmlist: fmlist[];
  branchlist: any = [];
  branch: string = "";
  selectedfmlist = '';
  openstr = '(';
  closestr = ')';
  com = ',';
  fmtype: any = "4";
  disablestate: any;
  query: any;
  deletelist: string = "";
  public multipleselect: Array<any> = [];
  deletestr: deleteBo[] = [];
  deletedfmnos: string = "";

  constructor(private tps: TpserviceService, private detectchages: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.TIRE;
    this.displaybranches();
    this.disablestate = localStorage.getItem("disablestate");
  }

  ngAfterViewInit() {
    var self = this;
    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.refreshdata();
    });
  }

  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.fmlist = data;
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
      pageLength: 10,
      fixedHeader: true,
      responsive: true,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }

  refreshdata() {
    localStorage.setItem("branch", this.branch);
    this.getfmlist();
  }

  getfmlist() {
    const obj = {
      type: this.fmtype,
      branch: this.branch
    }
    this.tps.getFmtablelist(obj).subscribe(
      data => this.gettabledata(data)
    );
  }

  onselected(data, e, index) {
    if (e.target.checked) {
      let temp = this.selectedfmlist.replace(data + this.com, '');
      this.selectedfmlist = temp;
      this.selectedfmlist += data + this.com;
    }
    else {
      let temp1 = this.selectedfmlist.replace(data + this.com, '');
      this.selectedfmlist = temp1;
    }
  }

  Multiselect(index, ev) {
    if (this.fmlist[index].selected == 'true') {
      this.fmlist[index].selected = 'false';
    }
    else {
      this.fmlist[index].selected = 'true';
    }
  }

  Removechecked_() {
    const com = ",";
    this.deletelist = "";
    var records = 0;
    for (var i = 0; i < this.fmlist.length; i++) {
      if (this.fmlist[i].selected == 'true') {
        this.multipleselect.push(i);
        this.deletelist += this.fmlist[i].id + com;
        records++;
      }
    }


    if (this.deletelist == '' || this.deletelist == '()') {
      swal({
        title: 'Nothing to delete!',
        text: 'Please select at least one record!',
        type: 'warning',
      });
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {

          this.multipleselect = this.multipleselect.filter((el, i, a) => i === a.indexOf(el))
          var lenghts = this.multipleselect.length;
          for (var i = this.multipleselect.length - 1; i >= 0; i--) {
            this.fmlist.splice(this.multipleselect[i], 1);
            this.fmlist.sort();
          }

          this.deletelist = this.deletelist.substring(0, this.deletelist.length - 1);
          this.deleteFm();
          this.multipleselect = [];
        }
      });
    }
  }

  deleteFm() {
    const object = {
      selected: this.deletelist

    };
    this.tps.deleteFm(object).subscribe(
      data => this.callafterdel(data), error1 => console.log(error1));
  }

  callafterdel(data) {
    this.deletestr = data;
    this.buildfmno(this.deletestr);
    if (this.deletedfmnos == "" || this.deletedfmnos == "()") {

      swal('success', "Fm Deleted Successfully", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "Can not delete these Fms " + this.deletedfmnos + " already used".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.deletedfmnos = "";
    this.getfmlist();
  }

  buildfmno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletedfmnos = this.deletedfmnos + data[i].id + ",";

      }
    }
  }

  checkmemoupdate(obj) {
    const idobj = {
      id: obj
    };
    this.tps.checkmemoupdate(idobj).subscribe(
      data => this.getfmmasterdata(obj, data)
    );
  }

  getfmmasterdata(obj, data1) {
    const idobj = {
      checkeddata: obj
    };
    this.tps.getFmData(idobj).subscribe(
      data => this.calldcafterdata(data, data1));
  }

  calldcafterdata(data, data1) {
    if (data1.messasge == -1) {
      localStorage.setItem("fmmasterdata", JSON.stringify(data));
      localStorage.setItem("fmmasterdatastate", "1");
      this.route.navigate(['/Transactions/fmtouching']);
    }
    else {
      swal({
        title: 'Can not edit this Memo',
        text: "Memo already used in " + data1.messasge + " Transporter Bill",
        type: 'warning',
      })
    }
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.afterdata(data)
    );
  }

  afterdata(data) {
    this.branchlist = data
    this.branch = localStorage.getItem("branch");
    this.getfmlist();
  }

  pdfview(location) {
    swal({
      //title: 'Download File',
      showCancelButton: true,
      width: '80%',
      showCloseButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      html: '<embed id="viewfile" src="' + location + '" height="500px" width="100%"/>',
      preConfirm: () => {
        return document.getElementById("viewfile");
      }
    })
  }

}
