import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { dcdata } from '../../Models/dcdata';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { vehicleowner } from '../../Models/vehicleowner';
import { permissions } from '../../Models/permissions';
import { deleteBo } from '../../Models/deleteBo';

declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './vehicleownerlist.component.html',
  providers: [TpserviceService]
})
export class VehicleOwnerListComponent implements OnInit, AfterViewInit {

  dcdata: dcdata;
  vehicleownerlist: vehicleowner[] = [];
  selectedcheck: string = "";
  openstr = "(";
  closedtr = ")";
  com = ",";
  grant_edit: any;
  permissions: permissions;
  branch: string = "1";
  branchlist: any = [];
  disablestate: any;
  query: any;
  deletestr: deleteBo[] = [];
  deletedvehicleown: string = "";

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MVCOE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
    this.getvolist();
  }


  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.vehicleownerlist = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }
  ngAfterViewInit() {

  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }
  getvolist() {
    const obj = {
      checkeddata: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getVehicleOwners(obj).subscribe(
      data => this.gettabledata(data)
    );
  }


  deletevo() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closedtr;
    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const obj = {
            vid: strdata
          }
          this.tps.deleteVehicleOwner(obj).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {
    this.deletestr = data;
    this.buildvehicleownno(this.deletestr);
    if (this.deletedvehicleown == "" || this.deletedvehicleown == "()") {
      swal('success', "Vehicle Owner Deleted Successfully!", 'success');
      $('#products-table').dataTable().fnClearTable();
    }
    else {
      swal('warning', "can not delete this Vehicle " + this.deletedvehicleown + ", already used in Vehicle...".toString(), 'warning');
      $('#products-table').dataTable().fnClearTable();
    }
    this.selectedcheck = "";
    this.deletedvehicleown = '';
    this.getvolist();
  }

  buildvehicleownno(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].status == "1") {
        this.deletedvehicleown = this.deletedvehicleown + data[i].id + ",";
      }
    }
  }

  getvehicleownerdata(obj) {
    const ob = {
      vid: obj
    }
    this.tps.getVehicleownerObject(ob).subscribe(
      data => this.callafterdata(data)
    );
  }

  callafterdata(data) {
    localStorage.setItem("vodata", JSON.stringify(data));
    localStorage.setItem("vodatastate", "1");
    this.route.navigate(['/forms/vehicle-owner']);
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data => this.branchlist = data
    );
  }
}