import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {TpserviceService} from '../../../../Services/tpservice.service';
import {DatePipe} from '@angular/common';
import { Excellservice } from '../../../../Services/excellservice.service';
import swal from "sweetalert2";
import { DCReport } from '../../../../Models/dcreport';
declare var $: any;

@Component({
  selector: 'app-dc-datewise-report',
  templateUrl: './dc-datewise-report.component.html',
  styleUrls: ['./dc-datewise-report.component.css'],
  providers:[TpserviceService,Excellservice,DatePipe]
})
export class DcDatewiseReportComponent implements OnInit {
  branch:string="";
  branchlist:any=[];
  USRBRSTATE:any;
  dclistbydate:DCReport[];
  dclistbydateexport:DCReport[]=[];
  flag:boolean;
  todate:any;
  fromdate:any;


  constructor(private tps:TpserviceService,private datepipe:DatePipe,private excellservice:Excellservice,private detectchages: ChangeDetectorRef) { }

  ngOnInit() {
    this.displaybranches();
    //this.branch=localStorage.getItem("branch");
    this.USRBRSTATE=localStorage.getItem("USRBRSTATE");
    this.todate=this.datepipe.transform(new Date(),"yyyy-MM-dd");
  }

  getlist(){
    this.dclistbydate=[];
    this.flag=false;
    this.getdclistbydate();
  }

  getdclistbydate(){
    $('#products-table').dataTable().fnClearTable();
    const obj={
      from_date:this.fromdate,
      to_date:this.todate
    }
    // this.tps.getDcReportbyDate(obj).subscribe(  
    //   data => this.gettabledata(data)
    // );
  }

  gettabledata(data){
    this.dclistbydate=[];
    $("#products-table").dataTable().fnDestroy();
    this.dclistbydate=data;
    console.log(this.dclistbydate);
    this.detectchages.detectChanges();
    $('#products-table').DataTable({
        pageLength: 10,
        fixedHeader: true,
        overflow: scroll,
        //responsive: true,        
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function() {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  displaybranches() {
    this.tps.getBranch().subscribe(
        data => this.branchlist = data
    );
  }


  Exportasexcell(){

    if(this.fromdate=='' && this.todate==''){
      swal('Warning','Please Select Date First !','warning');
    }
    else
    {
    const obj={
      from_date:this.fromdate,
      to_date:this.todate
    }
  
  //   this.tps.getDcReportbyDate(obj).subscribe(
  //     data => this.Afterdata(data)
  // );
  }
  }
  
  Afterdata(data){
    this.dclistbydate=[];
    this.dclistbydateexport=data;
    this.excellservice.exportAsExcelFile(this.dclistbydateexport, 'DCBYDATEReport');
  } 

}
