import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { dclist } from '../../Models/dclist';
import { dcdata } from '../../Models/dcdata';
import { TpserviceService } from '../../Services/tpservice.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { employees } from '../../Models/emplyees';
import { permissions } from '../../Models/permissions';
declare var $: any;

@Component({
  selector: 'app-products-list',
  templateUrl: './employeelist.component.html',
  providers: [TpserviceService]
})
export class EmployeeListComponent implements OnInit, AfterViewInit {

  permissions: permissions;
  grant_edit: any;

  dcdata: dcdata;
  employees: employees[];
  selectedcheck: string = "";
  openstr = "(";
  closedtr = ")";
  com = ",";
  selectedc: Array<any> = [];
  branches: any = [];
  branch: string = "1";

  disablestate: any;

  constructor(private tps: TpserviceService, private changedetect: ChangeDetectorRef, private route: Router) { }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
    this.grant_edit = +this.permissions.MEMPE;
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");
    this.getemloyeelist();

  }


  gettabledata(data) {
    $("#products-table").dataTable().fnDestroy();
    this.employees = data;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
      pageLength: 20,
      fixedHeader: true,
      responsive: true,
      ordering: false,
      'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
      table.column(2).search($(this).val()).draw();
    });
  }
  ngAfterViewInit() {

  }

  onselected(data, e, index) {
    if (e.target.checked) {
      var temp = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp;
      this.selectedcheck += data + this.com;
      this.selectedc.push(data);
    }
    else {
      var temp1 = this.selectedcheck.replace(data + this.com, "");
      this.selectedcheck = temp1;
    }
  }

  getemloyeelist() {
    const obj = {
      checkeddata: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getEmployeeslist(obj).subscribe(
      data => this.gettabledata(data)
    );
  }

  deleteempolyee() {
    var strdata = this.openstr + this.selectedcheck.substring(0, this.selectedcheck.length - 1) + this.closedtr;

    if (strdata == "" || strdata == "()") {
      swal({
        title: 'Nothing to delete!',
        text: "Please select at least one record!",
        type: 'warning',
      })
    }
    else {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const objstr = {
            empid: strdata
          }
          this.tps.deleteEmployee(objstr).subscribe(
            data => this.callafterdel(data), error1 => console.log(error1));
        }
      })
    }
  }

  callafterdel(data) {
    swal('Success', data['messasge'].toString(), 'success');
    this.employees = [];
    $('#products-table').dataTable().fnClearTable();
    this.getemloyeelist();
  }

  getemployeedata(obj) {
    const ob = {
      empid: obj
    }
    this.tps.getEmployeeObject(ob).subscribe(
      data => this.callafterdata(data)
    );
  }

  callafterdata(data) {
    localStorage.setItem("employeedata", JSON.stringify(data));
    localStorage.setItem("employeedatastate", "1");
    this.route.navigate(['/forms/employee']);
  }

  displaybranches() {
    this.tps.getBranch().subscribe(
      data =>
        this.branches = data
    );
  }
}