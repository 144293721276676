import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import swal from 'sweetalert2';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Router } from '@angular/router';
import { vehicletype } from '../../../Models/vehicletype';
declare var $: any;
@Component({
  selector: 'app-form-advanced',
  templateUrl: './vehicle-type.component.html',
  providers: [TpserviceService]
})
export class VehicleTypeComponent implements OnInit, AfterViewInit {

  vehicletype: string = "";
  tyreqty = "";
  datastate = "Save";
  branch = "1";
  branchlist: any = [];
  vehicletypeobject: vehicletype;
  disablestate: any;
  constructor(private _script: ScriptLoaderService, private tps: TpserviceService, private route: Router) { }

  ngOnInit() {
    if (localStorage.getItem("vtdatastate") == "1") {

      this.getvtypedata();
      localStorage.removeItem("vtdatastate");
    }
    this.displaybranches();
    this.branch = localStorage.getItem("branch");
    this.disablestate = localStorage.getItem("disablestate");

  }

  ngAfterViewInit() {
    var self = this;
    $('#form-sample-1').validate({
      rules: {
        vehicletype: {
          required: !0,
        },
        tyreqty: {
          required: !0
        },

      },
      errorClass: 'help-block error',
      highlight: function (e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function (e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function (form) {
        self.onsubmit();
      }
    });
  }

  displaybranches() {

    this.tps.getBranch().subscribe(
      data =>
        this.branchlist = data
    );
  }


  onsubmit() {
    if (!(this.datastate == "Update")) {
      const obj = {
        tyreqty: this.tyreqty,
        vehicletype: this.vehicletype,
        branch: this.branch
      }
      swal({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 100,
        heightAuto: true,
        background: "rgba(0, 0, 0, .1)",
      });
      swal.showLoading();
      this.tps.addVehicleType(obj).subscribe(
        data => this.aftersubmit(data)
      );
    }
    else {
      this.updatevehicleowner();
    }
  }

  updatevehicleowner() {
    const vtupdateobj = {
      vtid: this.vehicletypeobject.vtid,
      tyreqty: this.tyreqty,
      vehicletype: this.vehicletype,
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.updateVehicletype(vtupdateobj).subscribe(
      data => this.aftersubmit(data)
    );
  }

  getvtypedata() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.datastate = "Update";
    this.vehicletypeobject = JSON.parse(localStorage.getItem("vtdata"));
    localStorage.removeItem("vtdata");
    localStorage.setItem("vtdatastate", "1");
    this.vehicletype = this.vehicletypeobject.vehicletype;
    this.tyreqty = this.vehicletypeobject.tyreqty;
    swal.close();
  }


  aftersubmit(data) {
    swal.close();
    swal('Success', data['messasge'], 'success'), error => swal('Please check your internet connection', error, 'error')
    this.route.navigate(['pages/VehicleType-List']);
  }

  redirect() {
    this.route.navigate(['pages/VehicleType-List']);
  }

}
