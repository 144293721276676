import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-animated',
  templateUrl: './button-animated.component.html',
})
export class ButtonAnimatedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
