import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-table-2',
  templateUrl: './pricing-table-2.component.html',
})
export class PricingTable2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
