import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Dataservices } from '../../../Services/dataservices.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { LRReport } from '../../../Models/lrreport';
import { Excellservice } from '../../../Services/excellservice.service';
import { Billreport } from '../../../Models/billreport';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs/Observable';

declare var $: any;

@Component({
  selector: 'app-billed-lr-status',
  templateUrl: './billed-lr-status.component.html',
  styleUrls: ['./billed-lr-status.component.css'],
  providers: [TpserviceService, Dataservices, DatePipe,Excellservice]
})
export class BilledLrStatusComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  branch: string;  
  branchlist: any;
  customer:any="";
  cust:any;
  fromDate:string="";
  toDate:string="";
  paymode:string = "All";
  billList:Billreport[]=[];
  flag: boolean;
  total_Amount:any;

  constructor(private tps: TpserviceService,private excellservice:Excellservice, private dts: Dataservices,private datepipe: DatePipe, private route: Router, private changedetect: ChangeDetectorRef) { }

  ngOnInit() {
    this.fromDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.toDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.displaybranches();
  }



  ngAfterViewInit() {
    var self = this;

    $(document).ready(function () {
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.branch = id;
      self.onbranchchange();
    });

    $(document).ready(function () {
      $("#customer").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#customer").on('select2:select', function (evt) {
      var id = evt.params.data.id;
      self.customer = id;
      //self.displaylr();
    });
  }


  displaybranches() {
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
    this.tps.getBranch().subscribe(
      data => this.aftergetBranch(data)
    );
  }

  aftergetBranch(data) {
    this.branchlist = data;
    this.branch = localStorage.getItem("branch");
    this.displayCustomers();
  }

  displayCustomers() {
    const obj = {
      checkeddata: this.branch
    }
    this.tps.getCustomer(obj).subscribe(
      data => this.aftergetCustomers(data)
    );
  }

  aftergetCustomers(data) {
    this.cust = data;
    this.getBillListByDefault();
  }
  onbranchchange() {
    localStorage.setItem("branch", this.branch);
    this.getBillListByDefault();
  }

  getBillListByDefault(){
    const OBJ = {
      branch: this.branch
    }
    swal({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 100,
      heightAuto: true,
      background: "rgba(0, 0, 0, .1)",
    });
    swal.showLoading();
  // console.log(OBJ);
    this.tps.getNotBilledLRListForreport(OBJ).subscribe(
        data => this.afterGetBillData(data), error => swal('Error', error, 'error')
    );
  }

  afterGetBillData(data){
    $("#products-table").dataTable().fnDestroy();
    this.billList = data;
    this.total_Amount=0;
    for(let i=0;i<this.billList.length;i++){
      this.total_Amount = Number(this.total_Amount) + Number(this.billList[i].lr_amount);
    }
    console.log(this.total_Amount);
    this.flag=false;
    swal.close();
    this.changedetect.detectChanges();
    $('#products-table').DataTable({
        pageLength: 20,
        //pagingType: 'full_numbers',
        fixedHeader: true,
        responsive: true,
        ordering: false,
        'sDom': 'rtip',
    });
    const table = $('#products-table').DataTable();
    $('#key-search').on('keyup', function () {
        table.search(this.value).draw();
    });
    $('#type-filter').on('change', function () {
        table.column(2).search($(this).val()).draw();
    });
  }
  
  Exportasexcell(){
    // console.log(this.table.nativeElement);
    //const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    //console.log("ws:",ws);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   // const workbook: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //console.log(excelBuffer);
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    
    
    /* save to file */
    //this.excellservice.saveAsExcelFile(excelBuffer, 'Billed_LR_REPORTFILE');
    //XLSX.writeFile(wb, 'SheetJS.xlsx');
     this.excellservice.exportAsExcelFile(this.billList, 'BILLREPORTFILE');
  }
}
