import { Component, OnInit,AfterViewInit,ViewChild, ElementRef } from '@angular/core';
import { TpserviceService } from '../../../Services/tpservice.service';
import { Branch } from '../../../Models/Branch';
import { lrdata } from '../../../Models/lrdata';
import { lrmaster } from '../../../Models/lrmaster';
import { DatePipe } from '@angular/common';
import { dctabledata } from '../../../Models/dctabledata';
import swal from 'sweetalert2';
import { pod } from '../../../Models/pod';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
const endpoint1: string = 'ws.opentenders.ml/api/uploadFile';

declare var $: any;
@Component({
  selector: 'app-podupload',
  templateUrl: './podupload.component.html',
  styleUrls: ['./podupload.component.css'],
  providers:[TpserviceService,DatePipe]
})
export class PODUploadComponent implements OnInit,AfterViewInit {

disablestate:any;
poddate:string="";
datastate:string="Save";
branch:string;
branches:Branch[];
uploaduri:string;
poddata:pod;
lrno:any;
lrid:any;
selectedFiles:FileList
files:File;
updatelrid:string;
lrdata:lrmaster[];
checkedlrdata:dctabledata[]=[];
lrstr:string="";
podno:string;
file:any;
currentFileUpload: File;
subtotal:any=0;
view:any;
flag:any;

  constructor(private tps:TpserviceService,private datepipe:DatePipe,private route:Router,private http:HttpClient) { }

  ngOnInit() {
    this.uploaduri=endpoint1;
    this.lrstr="201811";
    this.displaybranches();
   
  }
  ngAfterViewInit() {   

    this.poddate= this.datepipe.transform(new Date(),"yyyy-MM-dd");
    var self=this;      
  
   
    $('#pod_form').validate({
      rules: {
        podno: {
          required: !0
        },
       
      },
      errorClass: 'help-block error',
      highlight: function(e) {
        $(e).closest('.form-group.row').addClass('has-error');
      },
      unhighlight: function(e) {
        $(e).closest('.form-group.row').removeClass('has-error');
      },
      submitHandler: function(form) {
        self.onsubmit();
    }
    });

    var flag=true;
    $(document).on('focus', '.select2', function (e) {
        if (e.originalEvent && flag==true) {
            $(this).siblings('select').select2('open');
        }
        else{
            flag=true;
        }
    });

    $(document).on('focusout', '.select2-search__field', function () {
        flag=false;
    });
    
    $(document).ready(function(){
      $("#branch").select2({
        theme: "classic",
        allowClear: true
      });
    });

    $("#branch").on('select2:select', function(evt){
     var id = evt.params.data.id;
     self.branch=id;
     self.refreshdata();
     });

  }

  displaybranches() {

    this.tps.getBranchlist().subscribe(
        data =>this.afterdata(data)
           
    );
}

afterdata(data){
  this.branches = data;
  this.branch=localStorage.getItem("branch");

  if(localStorage.getItem("poddatastate")=="1"){
    this.getpoddata();
  }
  else{
    this.displaylr();
  }
}

checkclick(data,e,lrno)
  {
 this.lrno=lrno;
 this.lrid=data;
 const obj={
  checkeddata:data
}
  this.tps.getlrdataforpod(obj).subscribe(
      data =>
         this.aftertabdata(data),
      error=>
    console.log(error)
  );
}

aftertabdata(data){
    var tot=0;
    var lrn;
    this.checkedlrdata=data;
    this.checkedlrdata.forEach(function(element){
    tot= tot+ +element.total;
    lrn=element.lr_no;  
  })
  this.lrno=lrn;
  this.subtotal= tot;
  }

displaylr() {

    let index=this.branches.findIndex(datas=>datas.bid == this.branch);
    const obj={
    checkeddata:this.branches[index].placeid  
  }

  this.tps.getLrmasterforpod(obj).subscribe(
      data => this.lrdata=data
  );
}

refreshdata(){

  localStorage.setItem("branch",this.branch);
this.displaylr();
}

onsubmit(){
 
if(this.datastate=="Update")
{
  this.UpdatePOD();
}
else
{
  
const ob={
  podno:this.podno,
	poddate:this.poddate,
  lrid:this.lrid,
  lrno:this.lrno,
	upload:"",
	branch:this.branch
}

this.tps.addPod(ob).subscribe(

data=>this.aftersubmit(data)

)

}
}

aftersubmit(data){
  swal("Success",data["messasge"],"success");
  this.redirect();
}

getpoddata(){

  this.datastate="Update";
  this.poddata=JSON.parse(localStorage.getItem("poddata"));
 
  this.poddate=this.poddata.poddate;
  this.podno=this.poddata.podno;
  this.updatelrid=this.poddata.lrid;
  this.displaylr();
 
const obj={

  checkeddata:this.updatelrid

}

  this.tps.getlrdataforpod(obj).subscribe(
      data =>
         this.aftertabdata(data),
      error=>
    console.log(error)
  );



}

redirect(){
  this.route.navigate(['pages/PODUploadList']);
  localStorage.removeItem("poddatastate");
}


checkdata(obj):boolean{
  
  if(this.datastate=="Update")
  {
  if(this.lrdata.some(e => obj == this.updatelrid))
  {
  this.lrno=this.updatelrid;
return true;
  }
  else{
    return false;
  }
}
else{

  return false;
}
}

UpdatePOD(){


  const ob1={

    id:this.poddata.id,
    podno:this.podno,
    poddate:this.poddate,
    lrid:this.lrstr,
    upload:"",
    branch:this.branch
  }
  
  this.tps.updatePod(ob1).subscribe(
  
  data=>swal("Success",data["messasge"],"success")

  )
  this.route.navigate(['pages/PODUploadList']);
  localStorage.removeItem("poddatastate");

}

uplodfile(event){
 
    this.selectedFiles = event.target.files;
}


upload(){
  
  this.tps.UploadFile(this.file).subscribe(

    data=>console.log(data)
   );



}

afterupdatedata(data){

 swal("Success",data.toString(),"success")
}


fileChange(event) {
  let fileList: FileList = event.target.files;
  if (fileList.length > 0) {
    this.file = fileList[0];
   }  
}
 
 gr(){

  this.route.navigate(['forms/petrolpump']);
 }

 showfile(){
   
   var $el = $('<div class="ibox"><div class="ibox-head"><div class="ibox-title">Attached File</div><div class="ibox-tools"> <a class="ibox-collapse"><i class="ti-angle-down"></i></a> <a class="fullscreen-link"><i class="ti-fullscreen"></i></a></div></div> <div class="ibox-body" style="padding-right: 2px"><div class="row"><img src="https://files.omsairamlogistics.com/home/pod/'+this.lrno+'.jpeg" alt="Smiley face" height="420" width="800"></div></div></div>').appendTo('#view');
 }

}