import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-table-4',
  templateUrl: './pricing-table-4.component.html',
})
export class PricingTable4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
